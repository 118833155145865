import { useState, useEffect } from 'react'

const Form6c = ({name, setInput, input, index,red,setRed,key2,indy })=> {
  // useEffect(()=>{
  //   console.log(name)
  //   console.log(input)
  //   console.log(index)  
  // },[])
  // const [red, setRed] = useState(false)


  return (
    <div>
  {
    input[key2]!=undefined&&
    <input
      style={{width:'100%'}}
      type="text" 
      value={input[key2][indy][name]}
      onChange={(e) =>{

        setInput((prevState) => {
              let clone = JSON.parse(JSON.stringify(prevState));
              console.log(key2,indy,name,e.target.value)

              clone[key2][indy][name]= e.target.value;
              console.log(clone)
              return clone
            }
          )
        }
      } 
    />
  }
    </div>
  );
}

export default Form6c