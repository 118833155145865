import '../App.css';
import Form from './Form.js';
import Form3 from './Form3.js';
import Menu from './Menu.js';
import NavMenu from './NavMenu.js';
import useLongPress from './useLongPress.js';
import { useState, useEffect, useRef } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col, Button } from "react-bootstrap";
import { getFunctions, httpsCallable } from "firebase/functions";
import {  } from "firebase/firestore";
import { getFirestore,deleteDoc,getDoc,setDoc, collection, query, where, doc, getDocs, orderBy, arrayUnion, onSnapshot } from "firebase/firestore";
import { getAuth, createUserWithEmailAndPassword, onAuthStateChanged, signInWithEmailAndPassword, signOut, sendEmailVerification, sendPasswordResetEmail } from "firebase/auth";
import firebase from './firebase.js';
import LoginModal from './LoginModal.js'
import costEstimate from '../jsons/costEstimateData.json'
import { FaCaretDown,FaCaretUp,FaSearch,FaRedo,FaBars } from 'react-icons/fa';




function CostEstimate({user,db,auth, openMenu, setOpenMenu,setShowLogin,setPage,openNavMenu,setOpenNavMenu,userData,obj,setObj,next,number,estNum}) {
  
  const [newNew, setNewNew] = useState(true);
  const [docData, setDocData] = useState([])
  const [nextEstNum,setNextEstNum] = useState("")
  const [estDate,setEstDate] = useState("")
  const [searchQ,setSearchQ] = useState("")
  const [docDataMenu, setDocDataMenu] = useState({})

  const dateQueryRef = useRef();
  const dragItem = useRef();
  const dragOverItem = useRef();

  // const [obj, setObj] = useState({"Estimate No":"","Customer":"","Date":"","Contact Person":"","Part No":"","Customer Part No":"","Description":"","Quantity":""});
  const [obj1, setObj1] = useState([{'Cost':'','25%':'','30%':'','33.33%':'','35%':'','Custom':''}])
  // const [list, setList] = useState(["Estimate No","Customer","Date","Contact Person","Part No","Customer Part No","Description","Quantity"])
  const [list, setList] = useState(["Estimate No","Date","Part No","Description","Quantity"])
  const [list1, setList1] = useState(['Cost','25%','30%','33.33%','35%','Custom'])
  const list2 = [.25,.30,.3333,.35]
  const list3 = ['25%','30%','33.33%','35%']
  const emptyCompObj = {'Cost':'','25%':'','30%':'','33.33%':'','35%':'','Custom':''};
  const [keyListBy2,setKeyListBy2]=useState([])

  useEffect(()=>{
    if(userData['currCompany']!=undefined&&userData['currCompany']!=''){
      if(number!=''&&estNum==''){
        console.log('nexting..............................')
        console.log(number,next)
        const get=async()=>{

          const docRef = doc(db, "companies", userData['currCompany'],'parts',number);
          const docSnap = await getDoc(docRef);

          if (docSnap.exists()) {
            console.log("Document data:", docSnap.data());
            const data=docSnap.data()
            let compTot=0;
            if(data['components']!=undefined){
              data['components'].forEach((objj,index)=>{
                if(!Number.isNaN(Number(objj['COST']))){
                  compTot+= Number(objj['COST'])*Number(objj['QTY.'])
                }
              })
            }

            if(!Number.isNaN(Number(data['Total Cost']))){
              console.log('is number')
              list2.forEach((item,num)=>{
                const v=(Number(data['Total Cost'])*item)+Number(data['Total Cost']);
                data[list3[num]]=v.toFixed(2)
                console.log(num,data[list3[num]],Number(data['Total Cost'])*item)
              })
            }
            
            setObj((prevState) => ({
              ...prevState,
                ['Part No']: data['Part No'],
                ['Description']: data['Description'],
                ['Cost']: data['Total Cost'],
                ['25%']: data['25%'],
                ['30%']: data['30%'],
                ['33.33%']: data['33.33%'],
                ['35%']: data['35%'],

              }
            ))


          } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
          }
        }
        get()

      }else if(estNum!=''){

      }
    }

  },[number,userData,next])  

  useEffect(()=>{
    let num=0
      console.log(costEstimate)
    const partNum = {}
    Object.keys(costEstimate).forEach((item)=>{
      // console.log(item)
      const item1 = costEstimate[item]['Part No'].replace(/\//g,' , ')
      partNum[item1]={}
      partNum[item1]['Part No'] = item1
      partNum[item1]['Description'] = costEstimate[item]['Description']!=undefined?costEstimate[item]['Description']:""
      partNum[item1]['components'] = costEstimate[item]['components']!=undefined?costEstimate[item]['components']:[]
      partNum[item1]['Total Cost'] = 0
      for(let z=0;z<partNum[item1]['components'].length;z++){
        if(partNum[item1]['components'][z]['DESCRIPTION']=='Total cost'||partNum[item1]['components'][z]['DESCRIPTION']=='Total'){
          partNum[item1]['Total Cost'] = partNum[item1]['components'][z]['COST']
        }
      }
        num++
      // deleteDoc(doc(db,'companies','fbins','costEstimate',reitem))

    })

    Object.keys(partNum).forEach((item)=>{
      // console.log(item)
      // console.log(partNum[item])
      // setDoc(doc(db, 'companies', 'Fostoria Bushings', 'parts', partNum[item]['Part No']), partNum[item] , { merge: false })
      // deleteDoc(doc(db,'companies','fbins','costEstimate',reitem))
    })
    console.log(num)

  },[])

  const dragStart = (e, position) => {
    dragItem.current = position;
    // console.log(e.target.innerHTML);
  };
 
  const dragEnter = (e, position) => {
    dragOverItem.current = position;
    // console.log(e.target.innerHTML);
  };

  const drop = (e) => {
      const copyListItems = [...obj1];
      const dragItemContent = copyListItems[dragItem.current];
      copyListItems.splice(dragItem.current, 1);
      copyListItems.splice(dragOverItem.current, 0, dragItemContent);
      dragItem.current = null;
      dragOverItem.current = null;
      setObj1(copyListItems);
    };

  useEffect(()=>{
    const date=new Date();
    const year = date.getFullYear();
      // console.log(year)
    const year2 = (year-2000)
    let month = date.getMonth();
    month++
    if(month<10){
      month = "0"+month
    }
    let day = date.getDate();
    if(day<10){
      day = "0"+day
    }

    const date1 = month+"/"+day+"/"+year;
    setEstDate(date1)
    const searchQuery = "E"+year2+month
    setSearchQ(searchQuery)
    if(userData['currCompany']!=undefined&&userData['currCompany']!=''){
      const q = query(collection(db,'companies', userData['currCompany'], "costEstimate"), where('Estimate No', ">=", searchQuery), where('Estimate No', "<=", searchQuery+'\uf8ff'));

      const getD = async(q)=>{
        setDocData([])

        dateQueryRef.current = onSnapshot(q, (snapshot) => {
          snapshot.docChanges().forEach((change) => {
            // console.log(change)
            if (change.type === "added") {
                // console.log("New : ", change.doc.data());
                setDocData(docData =>[...docData, change.doc.data()])
            }
            // if (change.type === "modified") {
            //     console.log("Modified : ", change.doc.data());
            //   setDocData(docData =>[...docData, change.doc.data()])
            // }
            // if (change.type === "removed") {
            //     console.log("Removed : ", change.doc.data());
            // }
          });
        });
      }
      getD(q)
    }
    return ()=>{
      if(dateQueryRef.current!=null){
        dateQueryRef.current()
        dateQueryRef.current=null
      }
    }
  },[userData])


useEffect(()=>{
  const date=new Date();
  const year = date.getFullYear();
    // console.log(year)
  const year2 = (year-2000)
  let month = date.getMonth();
  month++
  if(month<10){
    month = "0"+month
  }

  console.log('docData.len', docData)
  if(docData.length>0){
    // const num=docData[docData.length-1]['Estimate No']+1<10?"0"+(docData[docData.length-1]['Estimate No']+1):docData[docData.length-1]['Estimate No']+1;
    const split = docData[docData.length-1]['Estimate No'].split("E")
    const split1 = split[1].split('-')
    const num=parseInt(split1[0])+1
    console.log(docData[docData.length-1]['Estimate No'])
    console.log(num)
    // const estNum="E"+year2+month+num;
    setNextEstNum("E"+num)
    // might need to do all this again when resetting or after entering a new doc to check to see if any new docs are present...i.e. more then 1 person is entering data
    if(newNew){

      setObj((prevState) => ({
              ...prevState,
                ['Estimate No']: "E"+num,
                ['Date']: estDate
              }
            ))
    }
  }else {
    if(newNew){
      setObj((prevState) => ({
              ...prevState,
                ['Estimate No']: "E"+year2+month+"01",
                ['Date']: estDate
              }
            ))
    }
  }
},[docData])

  function chunk(array, size) {
    return array.reduce((chunks, item, i) => {
      if (i % size === 0) {
        chunks.push([item]);
      } else {  
        chunks[chunks.length - 1].push(item);
      }
      return chunks;
    }, []);
  }

  const enter = async()=>{
    // const obj2 = JSON.parse(JSON.stringify(obj))
    // obj2['components'] = obj1
    // console.log(obj2)
    // if(userData['currCompany']!=undefined&&userData['currCompany']!=''){
      setDoc(doc(db,'companies', userData['currCompany'], 'costEstimate', obj['Estimate No']), obj , { merge: true })
      setNewNew(false)
    // }
  }
  const add = ()=>{
    console.log('add to row')
  }
  const edit = ()=>{
    for(let a=1;a<100;a++){
      let query=''
      if(obj['Estimate No'].includes('-')){
        const split=obj['Estimate No'].split('E')
        const split1=split[1].split('-')
        query="E"+split1[0]+'-'+(parseInt(split1[1])+a)
      }else{
        query=obj['Estimate No']+"-"+a
      }
      if(docDataMenu[query] == undefined){
        const obj2 = JSON.parse(JSON.stringify(obj))
        // obj2['components'] = obj1
        obj2['Estimate No'] = query
        setObj((prevState) => ({
              ...prevState,
                ['Estimate No']: query,
              }
            ))
        console.log(obj2)
        if(userData['currCompany']!=undefined&&userData['currCompany']!=''){
          setDoc(doc(db,'companies', userData['currCompany'], 'costEstimate', obj2['Estimate No']), obj2 , { merge: true })
        }
        break;
      } else{
      console.log(a)
    }
    }

  }
  const addRow = ()=>{
    console.log('addd')
    // setObj1([...obj1, emptyCompObj])
  }
  const removeRow = (x)=>{
    console.log('remove', x)
    // setObj1((previousArr) => {
    //   // console.log(newArray,' 1', x)
    //   if(previousArr.length>1){

    //   const first = previousArr.slice(0, x)
    //   console.log(first)
      
    //   // console.log(newArray,' 2')
    //   const second =previousArr.slice(x+1)
    //   console.log(second)
    //   // newArray.concat(second)
    //   // console.log(newArray,' 3')
    //   return first.concat(second);
    //   }else{
    //     return previousArr;
    //   }
    // })
  }

  useEffect(()=>{
    setKeyListBy2(chunk(list,2))

  },[])
  
  useEffect(()=>{
    // console.log(obj1)
    let add=false
    list1.forEach((item, index)=>{
      if(obj1.length-1>=0&&obj1[obj1.length-1][item]!==""){
        add=true
      }
    })
    for(let x=0;x<=obj1.length-2;x++){
      let remove=0
      list1.forEach((item, index)=>{
        if(obj1[x][item]==""){
          remove+=1
        }
      })
      if(remove==4){
        removeRow(x)
      }
    }
    if(add){
      addRow()
    }
    
  },[obj1])
  useEffect(()=>{
    console.log(obj)
    
    
  },[obj])

  const reset = ()=>{
    setNewNew(true)
    setObj({"Estimate No":nextEstNum,"Customer":"","Date":estDate,"Contact Person":"","Part No":"","Customer Part No":"","Description":"","Quantity":""})
    // setObj1([{'COMPONENT':'','DESCRIPTION':'','QTY.':'','COST':''},{'COMPONENT':'','DESCRIPTION':'','QTY.':'','COST':''}])
  }
  const onLongPress = () => {
      console.log('longpress is triggered');
      window.history.pushState({}, document.title, window.location.pathname);
      const paartNum = obj['Part No'].replace('/',' , ')
      window.open((String(window.location)+"?page=Parts"+"&number="+paartNum),'_blank');
      // if ('URLSearchParams' in window) {
      //     var searchParams = new URLSearchParams(window.location.search);
      //     searchParams.set('page','Parts');
      //     // searchParams.set('next','true');
      //     searchParams.set('number',obj['Part No']);
      //     window.location.search = searchParams.toString();
      // }
  };

  const onClick = () => {
      console.log('click is triggered')
  }

  const defaultOptions = {
      shouldPreventDefault: true,
      delay: 500,
  };
  const useLongPressEvent = useLongPress(onLongPress,onClick,defaultOptions)

  // const next = ()=>{
  //   console.log("go to cost Estimate Page")
  //   // need to make on App.js a componentObj, partNumObj, and costEstimateObj 
  //   // window.history.pushState({}, document.title, window.location.pathname);
  //   if ('URLSearchParams' in window) {
  //       var searchParams = new URLSearchParams(window.location.search);
  //       searchParams.set('page','Cost Estimate');
  //       searchParams.set('next','true');
  //       searchParams.set('number',obj['Part No']);
  //       window.location.search = searchParams.toString();
  //   }
  // }

  return (
    <div>
      <Row onClick={()=>{if(openMenu){setOpenMenu(false)};if(openNavMenu){setOpenNavMenu(false)}}} >
      <Row>
        <div style={{ marginBottom: 20}} >
        {userData['currCompany']!=undefined?
          userData['currCompany']
          :''}
        </div>
      </Row>
      <Row>
        <div style={{ marginBottom: 20}} >
          <span style={{cursor: 'pointer'}} onClick={reset}>
            <FaRedo />  Cost Estimate
            {/*<FaCaretDown />*/}
          </span>
        </div>
      </Row>
    
    {keyListBy2.map((objj, index) => {
        return (
          <Row key={index+'Top'}> 
            <Col xs="12" lg="6">
              {objj[0]=='Part No'?
              <Form name={objj[0]} input={obj} setInput={setObj} useLongPressEvent={useLongPressEvent} />
              :<Form name={objj[0]} input={obj} setInput={setObj} />
              }
            </Col>
            <Col xs="12" lg="6">
              <Form name={objj[1]} input={obj} setInput={setObj} useLongPress={useLongPress}/>
            </Col>
          </Row>
        );

      })}
    
    <Row> 
      <Col xs="2" lg="2">
        <div>Cost</div>
      </Col>
      <Col xs="2" lg="2">
        <div>25%</div>
      </Col>
      <Col xs="2" lg="2">
        <div>30%</div>
      </Col>
      <Col xs="2" lg="2">
        <div>33.33%</div>
      </Col>
      <Col xs="2" lg="2">
        <div>35%</div>
      </Col>
      <Col xs="2" lg="2">
        <div>Custom</div>
      </Col>
    </Row>
      
    {obj1.map((objj1, index1) => {
      return (
          <Row 
            onDragStart={(e) => dragStart(e, index1)}
            onDragEnter={(e) => dragEnter(e, index1)}
            onDragEnd={drop}
            key={index1}
            draggable
          > 
            <Col xs="2" lg="2" style={{paddingRight:0, paddingLeft:0,}}>
              <Form3 index={index1} name={'Cost'} input={obj} setInput={setObj} />
            </Col>
            <Col xs="2" lg="2" style={{paddingRight:0, paddingLeft:0,}}>
              <Form3 index={index1} name={'25%'} input={obj} setInput={setObj} />
            </Col>
            <Col xs="2" lg="2" style={{paddingRight:0, paddingLeft:0,}}>
              <Form3 index={index1} name={'30%'} input={obj} setInput={setObj} />
            </Col>
            <Col xs="2" lg="2" style={{paddingRight:0, paddingLeft:0,}}>
              <Form3 index={index1} name={'33.33%'} input={obj} setInput={setObj} />
            </Col>
            <Col xs="2" lg="2" style={{paddingRight:0, paddingLeft:0,}}>
              <Form3 index={index1} name={'35%'} input={obj} setInput={setObj} />
            </Col>
            <Col xs="2" lg="2" style={{paddingRight:0, paddingLeft:0,}}>
              <Form3 index={index1} name={'Custom'} input={obj} setInput={setObj} />
            </Col>
            
          </Row>
        );
      })}

    <Row style={{paddingBottom:"10px",borderBottom:"1px solid #000"}}>
      {/*<Col xs="0" lg="3">
      </Col>*/}
      {/*{
        newNew?*/}
      <Col xs="12" lg="6">
        <Button style={{ marginTop: 30}} onClick={edit} >Save</Button>
      </Col>
      {/*: enter????
      <Col xs="12" lg="6">
        <Button style={{ marginTop: 30}} onClick={edit} >Edit</Button>
      </Col>
      
      }*/}
      <Col xs="12" lg="6">
        <Button style={{ marginTop: 30}} onClick={add} >Add to Quote</Button>
      </Col>
    </Row>

  </Row>
  <div className='nav1'>
    <Menu 
        open={openMenu} 
        setOpen={setOpenMenu} 
        db={db}
        list={list}
        setObj={setObj}
        setObj1={setObj1}
        setNewNew={setNewNew}
        docData={docDataMenu}
        setDocData={setDocDataMenu}
        userData={userData}
        dbSection="costEstimate"
      />
      <NavMenu 
        open={openNavMenu} 
        setOpen={setOpenNavMenu} 
        db={db}  
        user={user} 
        auth={auth}     
        setShowLogin={setShowLogin}     
        setPage={setPage}  
        userData={userData}   
      />
  </div>

  </div>
  );
}

export default CostEstimate;
