import { useState, useEffect,useRef } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import useLongPress from './useLongPress.js';
import { collection, query, where, getDoc, doc, orderBy, onSnapshot } from "firebase/firestore";
import SearchItem from './SearchItem.js';
import { BrowserRouter as useNavigate,useLocation } from "react-router-dom";
import { Container, Row, Col, Button } from "react-bootstrap";
import Dropdown from 'react-dropdown';
import { FaCaretDown, FaCaretUp  } from 'react-icons/fa';




const Labels = ({obj,setObj,userData,db,page1,searchLabel,setSearchLabel})=> {
  // useEffect(()=>{
  //   console.log(name)
  //   console.log(input)
  //   console.log(index)  
  // },[])
  // useEffect(()=>{
  //   console.log(input)
  // },[input])
  const location = useLocation()
  const [focused,setFocused] = useState(false)
  const [docData,setDocData] = useState({})
  const onFocus = () => setFocused(true)
  const onBlur = () => startTimer()
  const queryRef = useRef();

  const [list, setList] = useState(["0"]);
  // const searchInputRef = useRef('');
  // const [searchInput,setSearchInput]= useState('');
  const [list1, setList1] = useState(['1','2','3','4','5','6']);
  const [openDrop1, setOpenDrop1] = useState(false);
  
  // const [input,setInput]= useState('');
  const [openDrop, setOpenDrop] = useState(false);
  

  const [counter, setCounter] = useState(0);
  const timer = useRef(null); // we can save timer in useRef and pass it to child

  const startTimer = ()=>{
     timer.current = setTimeout(() => {setFocused(false);}, 250);
  }

    useEffect(
    () => {

      let num=0;
      const nums=[]
      while(num<100){
        nums.push(String(num));
        num++;
      }

      setList(nums)

      // if(multiObj.length>=1){
      //   multiObj.forEach((item,index)=>{
      //     const listy = []
      //     if(item['toShip'].length>=1){
            
      //     }
        
      //   })
      // }

      return () => {
        if(timer.current!=null){
          clearTimeout(timer.current);
        }
      };
    },[]);

  
  useEffect(()=>{
    return ()=>{
      if(queryRef.current!=null){
        queryRef.current()
        queryRef.current=null
      }

    }
  },[]) 


const numBoxChange = (num,partIndy)=>{

  setObj((prev)=>{
    const clone = JSON.parse(JSON.stringify(prev))
    console.log(clone)
    console.log(partIndy)
    console.log(clone['toShip'][page1-1]['Parts'][partIndy]['Description'])

    clone['toShip'][page1-1]['Parts'][partIndy]['NumOfBoxes']=Number(num);
    if(clone['toShip'][page1-1]['Parts'][partIndy]['Labels']==undefined){
      clone['toShip'][page1-1]['Parts'][partIndy]['Labels']=[];
    }
    for(let x=0;x<clone['toShip'][page1-1]['Parts'][partIndy]['NumOfBoxes'];x++){
      if(clone['toShip'][page1-1]['Parts'][partIndy]['Labels'][x]==undefined){
        clone['toShip'][page1-1]['Parts'][partIndy]['Labels'][x]={'showAddressInput':false,'showPartsInput':false,'Address':[clone['Customer'],clone['toShip'][page1-1]['Address'],clone['toShip'][page1-1]['Address 2'],(clone['toShip'][page1-1]['City']+', '+clone['toShip'][page1-1]['State']+' '+clone['toShip'][page1-1]['Zip'])],'Parts':['P.O. No. '+clone['Customer PO Id'],('Part No.'+clone['toShip'][page1-1]['Parts'][partIndy]['Part Id']+' - Qty.('+clone['toShip'][page1-1]['Parts'][partIndy]['Quantity']+') pc.'),clone['toShip'][page1-1]['Parts'][partIndy]['Description']]}
      }
    }
    for(let x=clone['toShip'][page1-1]['Parts'][partIndy]['Labels'].length-1;x>clone['toShip'][page1-1]['Parts'][partIndy]['NumOfBoxes']-1;x--){
      if(clone['toShip'][page1-1]['Parts'][partIndy]['Labels'][x]!=undefined){
        clone['toShip'][page1-1]['Parts'][partIndy]['Labels'].splice(x,1)
      }
    }

    return clone

  })
}

  const remove = (index) => {

  }

const onLongPress = () => {
      console.log('longpress is triggered');
      // if(name=="Customer"){

      //   // need to set up worker page

      //   // setObj1({Worker:input['Worker'],Rate:input['Rate']})
      //   window.history.pushState({}, document.title, window.location.pathname);
      //   let loc = String(window.location);
      //   let locSplit = loc.split('/')
      //   window.open(String(locSplit[0]+'/Customers'+"?CustomerId="+input['Customer Id']),'_blank');
        
      // }
  };

  const onClick = () => {
      // console.log('click is triggered')
  }

  const defaultOptions = {
      shouldPreventDefault: true,
      delay: 500,
  };
  const useLongPressEvent = useLongPress(onLongPress,onClick,defaultOptions)

  return (
    <div>
    {
      obj['toShip'][page1-1]['Parts'].map((item,index)=>{

        if(item['Part Id']==''){
          return
        }

        return(
          <div key={index+"jfdklsa"}>
            <Row style={{paddingBottom:10}}>
              <Col xs="12" lg="6">
              Start At 
              </Col>
              <Col xs="12" lg="6">
              <Dropdown 
                  className="dropdown"
                  placeholderClassName="fuck"
                  arrowClosed={<FaCaretDown className="arrow-closed" />}
                  arrowOpen={<FaCaretUp className="arrow-open" />}
                  controlClassName="dropdownC" open={openDrop1} options={list1} onChange={(e)=>{setSearchLabel(e.value)}} value={searchLabel}>
                </Dropdown> 
              </Col>
            </Row>

            <Row style={{borderTop:'1px solid black',padding:10}} />

            <Row>
              <Col xs="12" lg="12">
              <h4>
              {obj['toShip'].length>1? 'Split '+ page1 + " / Line Item "+(item['Line Item']+1)+" / Part Id "+ item['Part Id']:"Line Item "+(index+1)+" / Part Id: "+ item['Part Id']}
              </h4>
              </Col>
            </Row>

            <Row>
              <Col xs="6" lg="6" >
                <span>
                  Number of Boxes
                </span>
              </Col>
              <Col xs="6" lg="6" >
                <Dropdown 
                  className="dropdown"
                  placeholderClassName="fuck"
                  arrowClosed={<FaCaretDown className="arrow-closed" />}
                  arrowOpen={<FaCaretUp className="arrow-open" />}
                  controlClassName="dropdownC" open={item['openLabelDrop']!=undefined?item['openLabelDrop']:false} options={list} onChange={(e)=>{numBoxChange(e.value,index)}} value={item['NumOfBoxes']!=undefined?String(item['NumOfBoxes']):'0'}>
                </Dropdown>
              </Col>     

            </Row>

            {
              item['Labels']!=undefined&&
              item['Labels'].map((item1,index1)=>{
                {/*console.log(item1)*/}
                return(
                  <div>
                  <Row key={index1+"jfdklsa1"}>
                    <Col xs="12" lg="12">
                      <h5>Box {index1+1}</h5>
                    </Col>
                  </Row>
                    {

                    item1['Address'].map((item2,index2)=>{

                      return(
                      <Row>
                        <Col onClick={()=>{setObj((prevState)=>{
                              const clone = JSON.parse(JSON.stringify(prevState));
                              clone['toShip'][page1-1]['Parts'][index]['Labels'][index1]['showAddressInput'] = true
                              clone['toShip'][page1-1]['Parts'][index]['Labels'][index1]['showPartsInput'] = false
                              return clone
                            })}} style={{textAlign:'left', paddingLeft:25}} xs="12" lg="12">
                          {
                            item1['showAddressInput']?
                          <input onBlur={()=>{setObj((prevState)=>{
                              const clone = JSON.parse(JSON.stringify(prevState));
                              clone['toShip'][page1-1]['Parts'][index]['Labels'][index1]['showAddressInput'] = false
                              return clone
                            })}} style={{width:'100%', background:"rgba(0,0,0,0)"}} 
                          value={item2}
                          onKeyDown={(e)=>{
                            console.log(e.key)
                            if(e.key=='Enter'){
                              setObj((prevState)=>{
                                const clone = JSON.parse(JSON.stringify(prevState));
                                clone['toShip'][page1-1]['Parts'][index]['Labels'][index1]['Address'].splice(index2+1,0,'')
                                clone['toShip'][page1-1]['Parts'][index]['Labels'][index1]['showAddressInput'] = true
                                return clone
                              })
                            }

                          }} 
                          onChange={(e)=>{
                            setObj((prevState)=>{
                              const clone = JSON.parse(JSON.stringify(prevState));
                              clone['toShip'][page1-1]['Parts'][index]['Labels'][index1]['Address'][index2] = e.target.value

                              return clone
                            })
                          }}
                          ></input> 
                          : <span>{item2}</span>
                          }
                          
                        </Col>
                      </Row>
                        )

                    })

                    }
                    <div style={{paddingTop:10}} />
                    {

                    item1['Parts'].map((item2,index2)=>{

                      return(
                      <Row>
                        <Col onClick={()=>{setObj((prevState)=>{
                              const clone = JSON.parse(JSON.stringify(prevState));
                              clone['toShip'][page1-1]['Parts'][index]['Labels'][index1]['showPartsInput'] = true
                              clone['toShip'][page1-1]['Parts'][index]['Labels'][index1]['showAddressInput'] = false
                              return clone
                            })}} style={{textAlign:'left'}} xs="12" lg="12">
                          {
                            item1['showPartsInput']?
                          <input onBlur={()=>{setObj((prevState)=>{
                              const clone = JSON.parse(JSON.stringify(prevState));
                              clone['toShip'][page1-1]['Parts'][index]['Labels'][index1]['showPartsInput'] = false
                              return clone
                            })}} style={{width:'100%', background:"rgba(0,0,0,0)"}} 
                          value={item2} 
                          onKeyDown={(e)=>{
                            console.log(e.key)
                            if(e.key=='Enter'){
                              setObj((prevState)=>{
                                const clone = JSON.parse(JSON.stringify(prevState));
                                clone['toShip'][page1-1]['Parts'][index]['Labels'][index1]['Parts'].splice(index2+1,0,'')
                                clone['toShip'][page1-1]['Parts'][index]['Labels'][index1]['showPartsInput'] = true
                                return clone
                              })
                            }

                          }}
                          onChange={(e)=>{


                            setObj((prevState)=>{
                              const clone = JSON.parse(JSON.stringify(prevState));
                              clone['toShip'][page1-1]['Parts'][index]['Labels'][index1]['Parts'][index2] = e.target.value

                              return clone
                            })
                          }}
                          ></input> 
                          : <span>{item2}</span>
                          }
                          
                        </Col>
                      </Row>
                        )

                    })

                    }
                  </div>
                  )
              })
            }
          </div>
        
        )
      })
    }

    </div>
  );
}

export default Labels