import { useState, useEffect,useRef } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import useLongPress from './useLongPress.js';
import { collection, query, where, getDoc,doc, orderBy, onSnapshot } from "firebase/firestore";
import SearchItem from './SearchItem.js';



const Form7b = ({name, setInput, input, index, userData, db ,setObj1,setSearch,isArray,searchName,key2,indy})=> {
  // useEffect(()=>{
  //   console.log(name)
  //   console.log(input)
  //   console.log(index)  
  // },[])
  // useEffect(()=>{
  //   console.log(input)
  // },[input])

  const [focused, setFocused] = useState(false)
  const [docData, setDocData] = useState({})
  const onFocus = () => setFocused(true)
  const onBlur = () => startTimer()
  const queryRef = useRef();

  const [counter, setCounter] = useState(0);
  const timer = useRef(null); // we can save timer in useRef and pass it to child

  const startTimer = ()=>{
     timer.current = setTimeout(() => {setFocused(false);}, 250);
  }

    useEffect(
    () => {

      // this will clear Timeout
      // when component unmount like in willComponentUnmount
      // and show will not change to true
      return () => {
        if(timer.current!=null){
          clearTimeout(timer.current);
        }
      };
    },
    // useEffect will run only one time with empty []
    // if you pass a value to array,
    // like this - [data]
    // than clearTimeout will run every time
    // this value changes (useEffect re-run)
    []
  );

  
  useEffect(()=>{
    // console.log('fuck')
    return ()=>{
      if(queryRef.current!=null){
        queryRef.current()
        queryRef.current=null
      }

    }
  },[])


  const searchWorkers = async (e)=>{
    // console.log(e.target.value)
    // console.log(docData)
    // if(e.target.value.length>2){
    // console.log('fuck1')
      setDocData([])
      if(userData['currCompany']!=undefined&&userData['currCompany']!=''&&input['Customer Id']!=''){
    // console.log('fuck2')
        const q = doc(db,'companies', userData['currCompany'], 'Customer',input['Customer Id']);
        const docSnap = await getDoc(q);
        const data = docSnap.data()
        if(name=='Contact Person'){
          setDocData(data['contactPerson'])
        }else{
          setDocData(data['Addresses'])
        }
   
      }
    // }
  }

  const fillInForm = (obj,key1)=>{
    console.log(obj)
    if(obj['Id']==undefined){
      obj['Id']=key1
    }
    if(name=='Contact Person'){
      setInput((prevState)=>{
        const clone = JSON.parse(JSON.stringify(prevState));
        console.log(clone)
        console.log(key2,indy,name)
        clone[key2][indy][name]=obj['Contact Person']
        return clone
      })
    }else{

      setInput((prevState)=>{
        const clone = JSON.parse(JSON.stringify(prevState));
          clone[key2][indy]["Address"]=obj['Address']
          clone[key2][indy]["Address 2"]=obj['Address 2']
          clone[key2][indy]["City"]=obj["City"]
          clone[key2][indy]["Zip"]=obj['Zip']
          clone[key2][indy]["State"]=obj['State']
          clone[key2][indy]["Country"]=obj['Country']
        return clone
      })
    }
    
  }

  return (
    <span key={indy+name+'fjkdlfspan'}>
      <input
        key={indy+name+'fjkdlf'}
        style={{float:"left",width:'100%'}}
        type="text" 
        value={input[key2]!=undefined?input[key2][indy][name]:''}
        onFocus={onFocus} onBlur={onBlur}
        onChange={(e) =>{
          setInput((prevState)=>{
            const clone = JSON.parse(JSON.stringify(prevState));
            console.log(clone[key2][indy][name],e.target.value)
            console.log(clone[key2])
            clone[key2][indy][name]=e.target.value
            return clone
          })
          searchWorkers(e)
          }
        } 
      />
      {focused && Object.keys(docData).length >= 1 &&
        Object.keys(docData).map((objj, index) => {
          {/*console.log(objj)*/}
         return (

            <SearchItem key={index+"floof1"} key1={objj} obj={docData[objj]} setFocused={setFocused} onClick={fillInForm} searchLabel={name} /> 
          );

        })
      }
    </span>
  );
}

export default Form7b