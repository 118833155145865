import '../App.css';
import Form2 from './Form2.js';
import Form1 from './Form1.js';
import Form1a from './Form1a.js';
import Form1a1 from './Form1a1.js';
import Form5 from './Form5.js';
import Form6 from './Form6.js';
import Form6c from './Form6c.js';
import Form7 from './Form7.js';
import Form7a from './Form7a.js';
import Form7a1 from './Form7a1.js';
import Form7a11 from './Form7a11.js';
import Form7b from './Form7b.js';
import Form7b1 from './Form7b1.js';
import Form7b2 from './Form7b2.js';
// import FormBillOfLading from './FormBillOfLading.js';
import WorkerMenu from './WorkerMenu.js';
import NavMenu from './NavMenu.js';
import { useState, useEffect, useRef } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col, Button } from "react-bootstrap";
import { getFunctions, httpsCallable } from "firebase/functions";
import { getDoc } from "firebase/firestore";
import { getFirestore, setDoc, collection, query, where, doc, getDocs, orderBy, arrayUnion, onSnapshot } from "firebase/firestore";
import { getAuth, createUserWithEmailAndPassword, onAuthStateChanged, signInWithEmailAndPassword, signOut, sendEmailVerification, sendPasswordResetEmail } from "firebase/auth";
import firebase from './firebase.js';
import LoginModal from './LoginModal.js'
import { FaCaretDown,FaCaretUp,FaSearch,FaRedo,FaBars } from 'react-icons/fa';
import inventory from '../jsons/inventoryData.json'
import {Modal, Spinner} from 'react-bootstrap';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import Dropdown from 'react-dropdown';



function Returns({user,db,auth,openMenu,setOpenMenu,setShowLogin,setPage,openNavMenu,setOpenNavMenu,userData,obj,setObj,quoteId,emptyObj,emptyToShipObj,currCompany,setCurrCompany,openDrop,setOpenDrop}) {
  

  const [list, setList] = useState(["Id","Date","Customer PO Id","Customer","Contact Person","Total"])
  const [list1, setList1] = useState(["Part Id","Customer Part Id","Description","Quantity",'Price'])
  const [list2, setList2] = useState(['Contact Person','Address','Address 2','City','Zip','State','Country'])
  const [list4, setList4] = useState(['Attention','Address','Address 2','City','Zip','State','Country'])
  const [list3, setList3] = useState(['Ship By Date','Carrier','Tracking','# Units','Weight','Freight Prepaid'])
  
  // const emptyObj = {"Id":"","Open":true,"Status":"Quote","Customer PO Id":"","Customer":"","Customer Id":"","Date":"","Contact Person":"","Total":"","PartIds":[],"CustomerPartIds":[],'BillingInfo':{'Contact Person':'','Address':'','Address2':'','City':'','Zip':'','State':'','Country':''},'toShip':[{"Parts":[{"Part Id":"","Customer Part Id":"","Description":"","Quantity":"",'Price':''}],'Attention':'','Address':'','Address2':'','City':'','Zip':'','State':'','Country':'','Ship By Date':'','Carrier':'','Tracking':'','# Units':'','Weight':'','Freight Prepaid':''}]}
  const [search, setSearch] = useState(false)
  const [docData, setDocData] = useState([])
  const [docData1,setDocData1] = useState({})
  const [screenWidth,setScreenWidth] = useState(0)
  const ref = useRef()
  const emptyPartObj = {"Part Id":"","Customer Part Id":"","Description":"","Quantity":"",'Price':'','Cost':''}


  useEffect(()=>{
    if(obj['Id']==''){
      const date=new Date();
      const year = date.getFullYear();
        // console.log(year)
      const year2 = (year-2000)
      let month = date.getMonth()+1;
      let day = date.getDate();

      const date1=month+"/"+day+"/"+year2
      setObj((prevState)=>({
        ...prevState,
        ['Date']:date1,
        ['Id']:String(Date.now()),
        ['Return Parts']:[emptyPartObj],
      }))
    }
  },[])

  const addRow = ()=>{
    console.log('addd')
    setObj((prevState) => {
      const clone = JSON.parse(JSON.stringify(prevState));
      clone['Return Parts'].push(emptyPartObj)
      return clone;

    })
    // setObjj([...objj, emptyPartObj])
  }
  const removeRow = (x)=>{
    console.log('remove', x)
    setObj((prevState) => {
      const clone = JSON.parse(JSON.stringify(prevState));

      const previousArr = clone['Return Parts'];
        if(previousArr.length>1){

        const first = previousArr.slice(0, x)
        console.log(first)
        
        // console.log(newArray,' 2')
        const second =previousArr.slice(x+1)
        console.log(second)
        // newArray.concat(second)
        // console.log(newArray,' 3')
        clone['Return Parts']=first.concat(second)
        return clone;
        }else{
          return clone;
        }
      }
    )
  }


  useEffect(()=>{
    if(obj['Return Parts']==undefined){
      console.log('return partsssss')
      setObj((prevState)=>({
        ...prevState,
        ['Return Parts']:[emptyPartObj],
      }))
    }

  },[obj['Parts']])

  useEffect(()=>{

    if(obj['Return Parts']!=undefined){
        console.log(obj['Return Parts'])
      let add=false
      list1.forEach((item, index)=>{
        if(obj['Return Parts'].length-1>=0&&obj['Return Parts'][obj['Return Parts'].length-1][item]!==""){
          console.log(item,obj['Return Parts'][obj['Return Parts'].length-1][item])
          add=true
        }
      })
      for(let x=0;x<=obj['Return Parts'].length-2;x++){
        let remove=0
        list1.forEach((item, index)=>{
          if(obj['Return Parts'][x][item]==""){
            remove+=1
          }
        })
        if(remove==list1.length){
          removeRow(x)
        }
      }
      if(add){
        console.log('add')
        addRow()
      }
    }
  },[obj['Return Parts']])

  useEffect(()=>{
    if(userData['currCompany']!=undefined&&userData['currCompany']!=''){
    
      if(quoteId!=''){
        const q = doc(db,'companies', userData['currCompany'], "order",quoteId);
        finish(q)
      }
    }
  },[quoteId,userData])

  const finish = async (q)=>{
    console.log('finishing')
    const docSnap = await getDoc(q);
    const data = docSnap.data()

    const clone = JSON.parse(JSON.stringify(emptyObj));
    clone['Id']=String(Date.now())
    const date=new Date();
    const year = date.getFullYear();
      // console.log(year)
    const year2 = (year-2000)
    let month = date.getMonth();
    let day = date.getDate();

    clone['Date']=month+"/"+day+"/"+year2
    clone['Id'] = data['Id']
    clone['Customer'] = data['Customer']
    clone['Customer Id'] = data['Customer Id']
    clone['Contact Person'] = data['Contact Person']
    clone['Total'] = data['Total']

    Object.keys(clone).forEach((item,index)=>{
      if(data[item]==undefined){
        clone[item] = data[item]
      }
    })
    if(clone['totalQuantity']){
      let totalQuantity=0
      data['Parts'].forEach((objj,index)=>{
        totalQuantity+=Number(objj['Quantity'])>0?Number(objj['Quantity']):0
      })
      clone['totalQuantity']=totalQuantity
    }

    // const q1 = doc(db,'companies', userData['currCompany'], "Customer",data['Customer Id']);
    
    // const docSnap1 = await getDoc(q1);
    // const data1 = docSnap1.data()
    // clone['Address'] = data1['Addresses'][0]['Address']
    // clone['City'] = data1['Addresses'][0]['City']
    // clone['Zip'] = data1['Addresses'][0]['Zip']
    // clone['State'] = data1['Addresses'][0]['State']
    // clone['Country'] = data1['Addresses'][0]['Country']

    // console.log(clone)
    setObj(clone)
  }



  useEffect(()=>{
    if(ref.current!=null){
      console.log(ref.current.offsetWidth)
      setScreenWidth(window.innerWidth);

    }
    window.addEventListener("resize", setScreenWidth(window.innerWidth));

    return () => {
      window.removeEventListener("resize", setScreenWidth(window.innerWidth));
    };
  },[ref])

  const edit = ()=>{
    console.log(obj)
    if(obj['Id']==''){
      // setShowModal(true)
    }else{
      //need to add part ids and customer ids to searchable array
      const clone = JSON.parse(JSON.stringify(obj));
      if(clone['Customer Id']==''){
        alert(" Need To add Customer to Database")
        return 
      }
      clone['PartIds']=[]
      clone['CustomerPartIds']=[]
      

      clone['toShip'].forEach((item1,index1)=>{
        if(!item1['StatusOverride']){
          if(item1['Tracking']!=''){
            clone['toShip'][index1]['Status'] = 'Sent'
          }else{
            clone['toShip'][index1]['Status'] = 'Purchase Order'
          }
        }
      })

      clone['Parts'].forEach((item1,index1)=>{
        console.log(item1)
        console.log(item1["Part Id"])
        console.log(item1["Customer Part Id"])
          if(item1["Part Id"]!=""){
            clone['PartIds'].push(item1["Part Id"])
          }
          if(item1["Customer Part Id"]!=""){
            clone['CustomerPartIds'].push(item1["Customer Part Id"])
          }

        })
      console.log(clone)

      setDoc(doc(db, 'companies', userData['currCompany'], "order", String(clone['Id'])), clone , { merge: true })
    }
  }

  useEffect(()=>{
    console.log()
    // 

  },[obj['Parts']])


  const reset = ()=>{
      const clone = JSON.parse(JSON.stringify(emptyObj));
    clone['Id']=String(Date.now())
    const date=new Date();
    const year = date.getFullYear();
      // console.log(year)
    const year2 = (year-2000)
    let month = date.getMonth()+1;
    let day = date.getDate();

    clone['Date']=month+"/"+day+"/"+year2
    setObj(clone)
  }

  const split = ()=>{
    setObj((prevState)=>{
      const clone = JSON.parse(JSON.stringify(prevState));
      let quantity=0
      clone['Parts'].forEach((item,index)=>{
        quantity+=item['Quantity']
      })
      if(quantity>clone['toShip'].length){
        const clone1 = JSON.parse(JSON.stringify(emptyToShipObj));
        const clone2 = JSON.parse(JSON.stringify(emptyToShipObj['Parts'][0]));
        
        for(let x=1;x<clone['Parts'].length-1;x++){
          if(clone['Parts'][x]['Part Id']!=''){
            clone1['Parts'].push(clone2)
            if(clone['toShip'][0]['Parts'].length<clone['Parts'].length-1){
              console.log(clone['toShip'][0]['Parts'].length,clone['Parts'].length)
              clone['toShip'][0]['Parts'].push(clone2)
            }
          }
        }
        clone['toShip'].push(clone1)
        clone['splitOrder']=true


      }
      return clone

    })

  }

  return(
    <Container style={{height:'100%','paddingTop':56}}>
      <Row onClick={()=>{if(openMenu){setOpenMenu(false)};if(openNavMenu){setOpenNavMenu(false)}}} >
      <Row>
        <div style={{ marginBottom: 20}} >
        {userData['currCompany']!=undefined?
          userData['companies'].length>1?
          <span className="fuck1">
            <Dropdown 
              className="dropdown"
              placeholderClassName="fuck"
              arrowClosed={<FaCaretDown className="arrow-closed" />}
              arrowOpen={<FaCaretUp className="arrow-open" />}
              controlClassName="dropdownC" open={openDrop} options={Object.keys(userData['companiesMap'])} onChange={(e)=>{setCurrCompany(e.value)}} value={currCompany}>
              
            </Dropdown>
          </span>      
          :
          <span>{userData['currCompany']}</span>
          :''}
        </div>
      </Row>
      <Row>
        <div style={{ marginBottom: 20}} >
          <span style={{cursor: 'pointer'}} onClick={reset}>
            <FaRedo />  Return
            {/*<FaCaretDown />*/}
          </span>
        </div>
      </Row>
    
      <Row> 
      {
        list.map((item,index)=>{
          let size = "2";
          {/*{switch(item){
            case "Date": size=1;
              break;
            case "Total": size=1;
              break;
            default:size=2;
          }}*/}
          
          return (
            <Col xs={size} lg={size}>
              <div style={{overflowX:"hidden"}}>{item}</div>
            </Col>

            )
        })
      }
      
      </Row>
      
    
          <Row 
          > 
        {/*no forward slashes, need to program*/}
        {/*["Id","Customer","Date","Contact Person","Part Id","Customer Part Id","Description","Quantity",'Price']*/}
            <Col xs="2" lg="2" style={{paddingRight:0, paddingLeft:0,}}>
              <Form7 emptyObj={emptyObj} name={'Id'} input={obj} setInput={setObj} db={db} userData={userData} setSearch={setSearch} searchName={'order'} />
            </Col>
            <Col xs="2" lg="2" style={{paddingRight:0, paddingLeft:0,}}>
              {/*<Form6 name={'Rate'} input={obj} setInput={setObj} docData={docData1} red={red} setRed={setRed} />*/}
              <Form6 name={'Date'} input={obj} setInput={setObj} db={db} userData={userData} docData={docData1}setDocData={setDocData1} search={search} setSearch={setSearch} />
            </Col>
            <Col xs="2" lg="2" style={{paddingRight:0, paddingLeft:0,}}>
              <Form7 emptyObj={emptyObj} name={'Customer PO Id'} input={obj} setInput={setObj} db={db} userData={userData} setSearch={setSearch} searchName={'order'} />
            </Col>
            <Col xs="2" lg="2" style={{paddingRight:0, paddingLeft:0,}}>
              <Form7 emptyObj={emptyObj} name={'Customer'} input={obj} setInput={setObj} db={db} userData={userData} docData={docData1}setDocData={setDocData1} search={search} setSearch={setSearch} searchName={'order'} />
            </Col>
            <Col xs="2" lg="2" style={{paddingRight:0, paddingLeft:0,}}>
              <Form6 name={'Contact Person'} input={obj} setInput={setObj} />
            </Col>
            <Col xs="2" lg="2" style={{paddingRight:0, paddingLeft:0,}}>
              <Form6 name={'Total'} input={obj} setInput={setObj} />
            </Col>
            
          </Row>


          {/*<Row style={{paddingTop:10,paddingBottom:10}}> 
            <Col xs="12" lg="12">
              <span style={{borderBottom:"1px solid #000"}}>
              Billing Info
              </span>
            </Col> 
          </Row> 

          <Row> 
          {
            list2.map((item,index)=>{
              let size = "2";
              switch(item){
                case "Address": size=3;
                  break;
                case "Zip": size=1;
                  break;
                case "State": size=1;
                  break;
                case "Country": size=1;
                  break;
                default:size=2;
              }
              
              return (
                <Col xs={size} lg={size}>
                  <div style={{overflowX:"hidden"}}>{item}</div>
                </Col>

                )
            })
          }
          
          </Row>
          <Row>
          <Col xs="2" lg="2" style={{paddingRight:0, paddingLeft:0,}}>
            <Form7b1 name={'Contact Person'} key2={'BillingInfo'} input={obj} setInput={setObj} db={db} userData={userData} />
          </Col>
          <Col xs="3" lg="3" style={{paddingRight:0, paddingLeft:0,}}>
            <Form7b1 name={'Address'} key2={'BillingInfo'} input={obj} setInput={setObj} db={db} userData={userData} />
          </Col>
          <Col xs="2" lg="2" style={{paddingRight:0, paddingLeft:0,}}>
            <Form7b1 name={'Address 2'} key2={'BillingInfo'} input={obj} setInput={setObj} db={db} userData={userData} />
          </Col>
          <Col xs="2" lg="2" style={{paddingRight:0, paddingLeft:0,}}>
            <Form7b1 name={'City'} key2={'BillingInfo'} input={obj} setInput={setObj} db={db} userData={userData} />
          </Col>
          <Col xs="1" lg="1" style={{paddingRight:0, paddingLeft:0,}}>
            <Form7b1 name={'Zip'} key2={'BillingInfo'} input={obj} setInput={setObj} db={db} userData={userData} />
          </Col>
          <Col xs="1" lg="1" style={{paddingRight:0, paddingLeft:0,}}>
            <Form7b1 name={'State'} key2={'BillingInfo'} input={obj} setInput={setObj} db={db} userData={userData} />
          </Col>
          <Col xs="1" lg="1" style={{paddingRight:0, paddingLeft:0,}}>
            <Form7b1 name={'Country'} key2={'BillingInfo'} input={obj} setInput={setObj} db={db} userData={userData} />
          </Col>
                    
          </Row>
*/}
          <Row style={{paddingTop:10,paddingBottom:10}}> 
            <Col xs="12" lg="12">
              <span style={{borderBottom:"1px solid #000"}}>
              Parts
              </span>
            </Col> 
          </Row> 

          <Row> 
          {
            list1.map((item,index)=>{
              let size = "2";
              switch(item){
                case "Description": size=4;
                  break;
                default:size=2;
              }
              
              return (
                <Col key={item+index+'ccc'} xs={size} lg={size}>
                  <div style={{overflowX:"hidden"}}>{item}</div>
                </Col>

                )
            })
          }
          
          </Row>
          
          {
          obj['Parts'].map((item,index)=>{
                  
                return (
                  <Row style={{paddingRight:0,paddingBottom:5}}>
                  {
                    (item['Part Id']!=''||obj['Parts'].length<2)?
                  <Row key={index+'Partsccc'} style={{paddingRight:0}}  > 

                    <Col xs="2" lg="2" style={{paddingRight:0, paddingLeft:0}}>
                      <Form7a name={'Part Id'} input={obj} index={index}  setInput={setObj} db={db} userData={userData} setSearch={setSearch} searchName={'parts'} key1={'Parts'} />
                    </Col>
                    <Col xs="2" lg="2" style={{paddingRight:0, paddingLeft:0}}>
                      <Form1a name={'Customer Part Id'} input={obj} index={index}  setInput={setObj} key1={'Parts'} />
                    </Col>
                    <Col xs="4" lg="4" style={{paddingRight:0, paddingLeft:0}}>
                      <Form1a name={'Description'} input={obj} index={index}  setInput={setObj} key1={'Parts'} />
                    </Col>
                    <Col xs="2" lg="2" style={{paddingRight:0, paddingLeft:0}}>
                      <Form1a name={'Quantity'} input={obj} index={index}  setInput={setObj} key1={'Parts'} />
                    </Col>
                    <Col xs="2" lg="2" style={{paddingRight:0, paddingLeft:0}}>
                      <Form1a name={'Price'} input={obj} index={index}  setInput={setObj} key1={'Parts'} />
                    </Col>
                  </Row>:<span></span>
                  }
                  </Row>
                  )
          
              })
          }
          {obj['Return Parts']!=undefined&&
          <Row style={{paddingTop:5,paddingBottom:10,backgroundColor:"#e8e8e8"}}> 
            <Col xs="12" lg="12">
              <span style={{borderBottom:"1px solid #000"}}>
              Return Parts
              </span>
            </Col> 
          </Row>
          }
          <Row style={{backgroundColor:"#e8e8e8"}}> 
          {obj['Return Parts']!=undefined&&
            list1.map((item,index)=>{
              let size = "2";
              switch(item){
                case "Description": size=4;
                  break;
                default:size=2;
              }
              
              return (
                <Col key={item+index+'ccc1'} xs={size} lg={size}>
                  <div style={{overflowX:"hidden"}}>{item}</div>
                </Col>

                )
            })
          }
          
          </Row>
        {obj['Return Parts']!=undefined&&!obj['splitOrder']&&
          obj['Return Parts'].map((item,index)=>{
                  
                return (
                  <Row style={{paddingRight:0,backgroundColor:"#e8e8e8",paddingBottom:5}}>
                  
                  <Row key={index+'Partsccc1'} style={{paddingRight:0}}  > 

                    <Col xs="2" lg="2" style={{paddingRight:0, paddingLeft:0}}>
                      <Form7a11 name={'Part Id'} input={obj} index={index}  setInput={setObj} db={db} userData={userData} setSearch={setSearch} searchName={'parts'} key1={'Return Parts'} />
                    </Col>
                    <Col xs="2" lg="2" style={{paddingRight:0, paddingLeft:0}}>
                      <Form1a name={'Customer Part Id'} input={obj} index={index}  setInput={setObj} key1={'Return Parts'} />
                    </Col>
                    <Col xs="4" lg="4" style={{paddingRight:0, paddingLeft:0}}>
                      <Form1a name={'Description'} input={obj} index={index}  setInput={setObj} key1={'Return Parts'} />
                    </Col>
                    <Col xs="2" lg="2" style={{paddingRight:0, paddingLeft:0}}>
                      <Form1a name={'Quantity'} input={obj} index={index}  setInput={setObj} key1={'Return Parts'} />
                    </Col>
                    <Col xs="2" lg="2" style={{paddingRight:0, paddingLeft:0}}>
                      <Form1a name={'Price'} input={obj} index={index}  setInput={setObj} key1={'Return Parts'} />
                    </Col>
                  </Row>
                  
                  </Row>
                  )
          
              })
          }


{/*//////////////////////////////////////////////////////////////////////////////////////////////////*/}
          {obj['splitOrder']&&

          <Row style={{paddingTop:10,paddingBottom:10,borderBottom:"1px solid #000"}}> 
            <Col xs="12" lg="12">
              <span style={{borderBottom:"1px solid #000"}}>
              Split Order
              </span>
            </Col> 
          </Row> 
          }
          {
          obj['toShip']!= undefined&&
          obj['toShip'].map((objj,indy)=>{

            return(

              <Row key={indy+'nnn'} style={{paddingRight:0}} > 
              {obj['splitOrder']&&

              <Row style={{paddingTop:10,paddingBottom:10}}> 
                <Col xs="12" lg="12">
                  <span style={{borderBottom:"1px solid #000"}}>
                  Parts {indy+1}
                  </span>
                </Col> 
              </Row> 
              }

              <Row> 
              {obj['splitOrder']&&
                list1.map((item,index)=>{
                  let size = "2";
                  switch(item){
                    case "Description": size=4;
                      break;
                      {/*case "Quantity": size=4;
                      break;*/}
                    default:size=2;
                  }
                  
                  return (
                    <Col key={item+index+'ccc'} xs={size} lg={size}>
                      <div style={{overflowX:"hidden"}}>{item}</div>
                    </Col>

                    )
                })
              }
              
              </Row>
          
    
            {obj['splitOrder']&&
              objj['Parts'].map((item,index)=>{
                return (
                  <Row key={index+'Partsccc'} style={{paddingRight:0}} > 
                    <Col xs="2" lg="2" style={{paddingRight:0, paddingLeft:0,}}>
                      <Form7a1 name={'Part Id'} input={obj} index={index}  setInput={setObj} db={db} userData={userData} setSearch={setSearch} searchName={'parts'} key1={'Parts'} key2={'toShip'} indy={indy} />
                    </Col>
                    <Col xs="2" lg="2" style={{paddingRight:0, paddingLeft:0,}}>
                      <Form1a1 name={'Customer Part Id'} input={obj} index={index}  setInput={setObj} key1={'Parts'} key2={'toShip'} indy={indy} />
                    </Col>
                    <Col xs="4" lg="4" style={{paddingRight:0, paddingLeft:0,}}>
                      <Form1a1 name={'Description'} input={obj} index={index}  setInput={setObj} key1={'Parts'} key2={'toShip'} indy={indy} />
                    </Col>
                    <Col xs="2" lg="2" style={{paddingRight:0, paddingLeft:0,}}>
                      <Form1a1 name={'Quantity'} input={obj} index={index}  setInput={setObj} key1={'Parts'} key2={'toShip'} indy={indy} />
                    </Col>
                    <Col xs="2" lg="2" style={{paddingRight:0, paddingLeft:0,}}>
                      <Form1a1 name={'Price'} input={obj} index={index}  setInput={setObj} key1={'Parts'} key2={'toShip'} indy={indy} />
                    </Col>
                    
                  </Row>
                  
                  )
              })
            }


            {obj['splitOrder']&&

              <Row style={{paddingTop:10,paddingBottom:10}}> 
                <Col xs="12" lg="12">
                  <span style={{borderBottom:"1px solid #000"}}>
                  Return Parts {indy+1}
                  </span>
                </Col> 
              </Row> 
              }

              <Row> 
              {obj['splitOrder']&&
                list1.map((item,index)=>{
                  let size = "2";
                  switch(item){
                    case "Description": size=4;
                      break;
                      {/*case "Quantity": size=4;
                      break;*/}
                    default:size=2;
                  }
                  
                  return (
                    <Col key={item+index+'ccc'} xs={size} lg={size}>
                      <div style={{overflowX:"hidden"}}>{item}</div>
                    </Col>

                    )
                })
              }
              
              </Row>
          
    
            {obj['splitOrder']&&
              objj['Parts'].map((item,index)=>{
                return (
                  <Row key={index+'Partsccc'} style={{paddingRight:0}} > 
                    <Col xs="2" lg="2" style={{paddingRight:0, paddingLeft:0,}}>
                      <Form7a1 name={'Part Id'} input={obj} index={index}  setInput={setObj} db={db} userData={userData} setSearch={setSearch} searchName={'parts'} key1={'Parts'} key2={'toShip'} indy={indy} />
                    </Col>
                    <Col xs="2" lg="2" style={{paddingRight:0, paddingLeft:0,}}>
                      <Form1a1 name={'Customer Part Id'} input={obj} index={index}  setInput={setObj} key1={'Parts'} key2={'toShip'} indy={indy} />
                    </Col>
                    <Col xs="4" lg="4" style={{paddingRight:0, paddingLeft:0,}}>
                      <Form1a1 name={'Description'} input={obj} index={index}  setInput={setObj} key1={'Parts'} key2={'toShip'} indy={indy} />
                    </Col>
                    <Col xs="2" lg="2" style={{paddingRight:0, paddingLeft:0,}}>
                      <Form1a1 name={'Quantity'} input={obj} index={index}  setInput={setObj} key1={'Parts'} key2={'toShip'} indy={indy} />
                    </Col>
                    <Col xs="2" lg="2" style={{paddingRight:0, paddingLeft:0,}}>
                      <Form1a1 name={'Price'} input={obj} index={index}  setInput={setObj} key1={'Parts'} key2={'toShip'} indy={indy} />
                    </Col>
                    
                  </Row>
                  
                  )
              })
            }
            
          {/*<Row style={{paddingTop:10,paddingBottom:10}}> 
            <Col xs="12" lg="12">
              <span style={{borderBottom:"1px solid #000"}}>
              Shipping Address {obj['splitOrder']&&(indy+1)}
              </span>
            </Col> 
          </Row> 
          <Row> 
          {
            list4.map((item,index)=>{
              let size = "2";
              switch(item){
                case "Address": size=3;
                  break;
                case "Zip": size=1;
                  break;
                case "State": size=1;
                  break;
                case "Country": size=1;
                  break;
                
                default:size=2;
              }
              
              return (
                <Col key={item+index+'ddd'} xs={size} lg={size}>
                  <div style={{overflowX:"hidden"}}>{item}</div>
                </Col>

                )
            })
          }
          
          </Row>
          <Row key={indy+'row'+'fjkdlsdfasfdsafaf'} style={{paddingRight:0}} >
          <Col key={indy+'col'+'fjkdlsfdsafsdfdsfdsafadsaf'} xs="2" lg="2" style={{paddingRight:0, paddingLeft:0,}}>
            <Form7b key={indy+'ContactPerson'+'fjkdlsaf'} name={'Contact Person'} input={obj} setInput={setObj} db={db} userData={userData} key2={'toShip'} indy={indy} />
          </Col>
          <Col xs="3" lg="3" style={{paddingRight:0, paddingLeft:0,}}>
            <Form7b name={'Address'} input={obj} setInput={setObj} db={db} userData={userData} key2={'toShip'} indy={indy} />
          </Col>
          <Col xs="2" lg="2" style={{paddingRight:0, paddingLeft:0,}}>
            <Form7b name={'Address2'} input={obj} setInput={setObj} db={db} userData={userData} key2={'toShip'} indy={indy} />
          </Col>
          <Col xs="2" lg="2" style={{paddingRight:0, paddingLeft:0,}}>
            <Form7b name={'City'} input={obj} setInput={setObj} db={db} userData={userData} key2={'toShip'} indy={indy} />
          </Col>
          <Col xs="1" lg="1" style={{paddingRight:0, paddingLeft:0,}}>
            <Form7b name={'Zip'} input={obj} setInput={setObj} db={db} userData={userData} key2={'toShip'} indy={indy} />
          </Col>
          <Col xs="1" lg="1" style={{paddingRight:0, paddingLeft:0,}}>
            <Form7b name={'State'} input={obj} setInput={setObj} db={db} userData={userData} key2={'toShip'} indy={indy} />
          </Col>
          <Col xs="1" lg="1" style={{paddingRight:0, paddingLeft:0,}}>
            <Form7b name={'Country'} input={obj} setInput={setObj} db={db} userData={userData} key2={'toShip'} indy={indy} />
          </Col>
                    
          </Row>

          <Row style={{paddingTop:10,paddingBottom:10}}> 
            <Col xs="12" lg="12">
              <span style={{borderBottom:"1px solid #000"}}>
              Return Shipping Address {obj['splitOrder']&&(indy+1)}
              </span>
            </Col> 
          </Row> 
          <Row> 
          {
            list4.map((item,index)=>{
              let size = "2";
              switch(item){
                case "Address": size=3;
                  break;
                case "Zip": size=1;
                  break;
                case "State": size=1;
                  break;
                case "Country": size=1;
                  break;
                
                default:size=2;
              }
              
              return (
                <Col key={item+index+'ddd1'} xs={size} lg={size}>
                  <div style={{overflowX:"hidden"}}>{item}</div>
                </Col>

                )
            })
          }
          
          </Row>
          <Row key={indy+'row'+'fjkdlsdfasfdsafaf1'} style={{paddingRight:0}} >
          <Col key={indy+'col'+'fjkdlsfdsafsdfdsfdsafadsaf1'} xs="2" lg="2" style={{paddingRight:0, paddingLeft:0,}}>
            <Form7b2 key={indy+'ContactPerson'+'fjkdlsaf1'} name={'From Contact Person'} input={obj} setInput={setObj} db={db} userData={userData} key2={'toShip'} indy={indy} searchName={'Customer'} />
          </Col>
          <Col xs="3" lg="3" style={{paddingRight:0, paddingLeft:0,}}>
            <Form7b2 name={'From Address'} input={obj} setInput={setObj} db={db} userData={userData} key2={'toShip'} indy={indy} />
          </Col>
          <Col xs="2" lg="2" style={{paddingRight:0, paddingLeft:0,}}>
            <Form7b2 name={'From Address2'} input={obj} setInput={setObj} db={db} userData={userData} key2={'toShip'} indy={indy} />
          </Col>
          <Col xs="2" lg="2" style={{paddingRight:0, paddingLeft:0,}}>
            <Form7b2 name={'From City'} input={obj} setInput={setObj} db={db} userData={userData} key2={'toShip'} indy={indy} />
          </Col>
          <Col xs="1" lg="1" style={{paddingRight:0, paddingLeft:0,}}>
            <Form7b2 name={'From Zip'} input={obj} setInput={setObj} db={db} userData={userData} key2={'toShip'} indy={indy} />
          </Col>
          <Col xs="1" lg="1" style={{paddingRight:0, paddingLeft:0,}}>
            <Form7b2 name={'From State'} input={obj} setInput={setObj} db={db} userData={userData} key2={'toShip'} indy={indy} />
          </Col>
          <Col xs="1" lg="1" style={{paddingRight:0, paddingLeft:0,}}>
            <Form7b2 name={'From Country'} input={obj} setInput={setObj} db={db} userData={userData} key2={'toShip'} indy={indy} />
          </Col>
                    
          </Row>
          
            <Row style={{paddingTop:10,paddingBottom:10}}> 
              <Col xs="12" lg="12">
                <span style={{borderBottom:"1px solid #000"}}>
                Shipping Info {obj['splitOrder']&&indy+1}
                </span>
              </Col> 
            </Row> 
          
        
          <Row> 
          {
            list3.map((item,index)=>{
              let size = "2";
            
              
              return (
                <Col key={item+index+'fff'} key={item+index+'yyy'} xs={size} lg={size}>
                  <div style={{overflowX:"hidden"}}>{item}</div>
                </Col>

                )
            })
          }
          
          </Row>
          <Row style={{paddingRight:0,}}>

          <Col xs="2" lg="2" style={{paddingRight:0, paddingLeft:0,}}>
            <Form6c name={'Ship By Date'} input={obj} setInput={setObj} key2={'toShip'} indy={indy} />
          </Col>
          <Col xs="2" lg="2" style={{paddingRight:0, paddingLeft:0,}}>
            <Form6c name={'Carrier'} input={obj} setInput={setObj} key2={'toShip'} indy={indy} />
          </Col>
          <Col xs="2" lg="2" style={{paddingRight:0, paddingLeft:0,}}>
            <Form6c name={'Tracking'} input={obj} setInput={setObj} key2={'toShip'} indy={indy} />
          </Col>
          <Col xs="2" lg="2" style={{paddingRight:0, paddingLeft:0,}}>
            <Form6c name={'# Units'} input={obj} setInput={setObj} key2={'toShip'} indy={indy} />
          </Col>
          <Col xs="2" lg="2" style={{paddingRight:0, paddingLeft:0,}}>
            <Form6c name={'Weight'} input={obj} setInput={setObj} key2={'toShip'} indy={indy} />
          </Col>
          <Col xs="2" lg="2" style={{paddingRight:0, paddingLeft:0,}}>
            <Form6c name={'Freight Prepaid'} input={obj} setInput={setObj} key2={'toShip'} indy={indy} />
          </Col>
                    
          </Row>
        */}

    <div style={{paddingBottom:"10px",borderBottom:"1px solid #000"}}/>
          </Row>
              )
            })
          }

    <Row style={{paddingBottom:"10px",borderBottom:"1px solid #000"}}>
      
      <Col xs="12" lg={obj['totalQuantity']>1?'6':'12'}>
        <Button style={{ marginTop: 30}} onClick={edit} >Save Purchase Order</Button>
      </Col>
      {
        obj['totalQuantity']>1&&
      <Col xs="12" lg="6">
        <Button style={{ marginTop: 30}} onClick={split} >Split / Partial Order</Button>
      </Col>
      }






      {/*<Col xs="12" lg="6">
        <Button style={{ marginTop: 30}} onClick={billOfLading} >Split/Partial Order</Button>
      </Col>*/}





      
    </Row>

  </Row>

  </Container>
  );
}

export default Returns;
