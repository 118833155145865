import '../App.css';
import Form2 from './Form2.js';
import Form1 from './Form1.js';
import Form1a2 from './Form1a2.js';
import Form1a3 from './Form1a3.js';
import Form5 from './Form5.js';
import Form6 from './Form6.js';
import Form6a from './Form6a.js';
import Form7 from './Form7.js';
import Form7b4 from './Form7b4.js';
import ComponentMenu from './ComponentMenu.js';
import NavMenu from './NavMenu.js';
import { useState, useEffect, useRef } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col, Button } from "react-bootstrap";
import { getFunctions, httpsCallable } from "firebase/functions";
import {  } from "firebase/firestore";
import { getFirestore, setDoc, collection, query, where, doc, getDocs, orderBy, arrayUnion, onSnapshot } from "firebase/firestore";
import { getAuth, createUserWithEmailAndPassword, onAuthStateChanged, signInWithEmailAndPassword, signOut, sendEmailVerification, sendPasswordResetEmail } from "firebase/auth";
import firebase from './firebase.js';
import LoginModal from './LoginModal.js'
import { FaCaretDown,FaCaretUp,FaSearch,FaRedo,FaBars } from 'react-icons/fa';
import inventory from '../jsons/inventoryData.json'
import {Modal, Spinner} from 'react-bootstrap';
import Dropdown from 'react-dropdown';



function Component({user,db,auth,openMenu,setOpenMenu,setShowLogin,setPage,openNavMenu,setOpenNavMenu,userData,number,obj,setObj,currCompany,setCurrCompany,openDrop,setOpenDrop}) {
  
  const [newNew, setNewNew] = useState(true);
  const [docData, setDocData] = useState([])
  const [nextEstNum,setNextEstNum] = useState("")
  const [estDate,setEstDate] = useState("")
  const [searchQ,setSearchQ] = useState("")
  const [docDataMenu, setDocDataMenu] = useState({})
  const [docData1,setDocData1] = useState({})
  const [red, setRed] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [showModal1, setShowModal1] = useState(false)
  const [search, setSearch] = useState(false)
  const [per, setPer] = useState(1)



  const queryRef = useRef();
  const dateQueryRef = useRef();
  const dragItem = useRef();
  const dragOverItem = useRef();

  // const [obj, setObj] = useState({"Description":"","Cost":"","Description":"","Quantity":""});
  // const [obj1, setObj1] = useState({Worker:'',Rate:''})

  const [list, setList] = useState(['Id','Description','Inventory'])
  const [list1, setList1] = useState(['Worker','Rate','Parts/hr','Labor'])
  const [list2, setList2] = useState(['Overhead','Conversion','Material','Total'])
  const [list4, setList4] = useState(['Order No.','Order Date','Received Date','Vendor','Qty','Price','Shipping & Other','Total'])
  const [list5, setList5] = useState(['Order No.','Date','Date1','Qty','Price','Shipping','Total','Per Price','Per Shipping','Per Total'])

  const [list41, setList41] = useState(['Order No.','Order Date','Received Date','Vendor','Qty','Per Price','Per Shipping & Other','Per Total'])
  const [list43, setList43] = useState(['Order No.','Order Date','Received Date','Vendor','Qty','Note'])
  const emptyPurchObj = {'Customer':'','Date':'','Date1':'','Order No.':'','Qty':'','Price':'','Shipping':'','Total':'','Per Price':'','Per Shipping':'','Per Total':''};

  // const [list1, setList1] = useState(['Worker','Rate'])
  // const emptyCompObj = {'COMPONENT':'','DESCRIPTION':'','QTY.':'','COST':''};
  // const [keyListBy2,setKeyListBy2]=useState([])


  // useEffect(()=>{
  //   if(userData['currCompany']!=undefined&&userData['currCompany']!=''){

  //       if(queryRef.current!=null){
  //       queryRef.current()
  //       queryRef.current=null
  //     }
  //       const q = query(collection(db,'companies', userData['currCompany'], "components"), where("Id", "!=", ''));
        
  //       queryRef.current = onSnapshot(q, (snapshot) => {
            
  //           console.log(snapshot.size)
  //         snapshot.docChanges().forEach((change) => {
  //           const data = change.doc.data()
  //           // console.log(data)
  //           if (change.type === "added") {
  //           setNewNew(false)
  //               // console.log("New : ", change.doc.data());
  //               // setDocData(docData =>[...docData, change.doc.data()])
  //               const data = change.doc.data();
  //               if(data['ApprovedVendors']!=undefined){

  //               Object.keys(data['ApprovedVendors']).forEach((item,index)=>{
  //                 if(item=="5e0462674d4b450695391f43342023cd"){
  //                   console.log("5e0462674d4b450695391f43342023cd",data['Id'])
  //                 }else
  //                 if(item=="d780d4d1488e4dd183168d28e5dff89a"){
  //                   console.log("d780d4d1488e4dd183168d28e5dff89a",data['Id'])
  //                 }
                  
  //               })
  //               }
  //           }            
  //         });
  //       });
      
  //   }
  //   return ()=>{
  //     if(queryRef.current!=null){
  //       queryRef.current()
  //       queryRef.current=null
  //     }
  //   }
  // },[userData])

  


  // function chunk(array, size) {
  //   return array.reduce((chunks, item, i) => {
  //     if (i % size === 0) {
  //       chunks.push([item]);
  //     } else {  
  //       chunks[chunks.length - 1].push(item);
  //     }
  //     return chunks;
  //   }, []);
  // }


  const edit = async ()=>{
    if(obj['Description']==''){
      setShowModal(true)
    }else{     

      try{
        setShowModal1(true)
        await setDoc(doc(db, 'companies', userData['currCompany'], "components", obj['Id']), obj , { merge: true })
        setShowModal1(false)
      }
      catch(err){
        setShowModal1(false)
        console.log(err)
        alert(err)
      }
    }
  }
  
  const handleOverWrite = ()=>{
    setDoc(doc(db, 'companies', userData['currCompany'], "components", obj['Id']), obj , { merge: true })
    setShowModal(false)
    
  }

  // 5e0462674d4b450695391f43342023cd

  useEffect(()=>{
    if(userData['currCompany']!=undefined&&userData['currCompany']!=''){
      if(number!=''){
        const q = query(collection(db,'companies', userData['currCompany'], "components"), where("Id", "==", number));
        
        queryRef.current = onSnapshot(q, (snapshot) => {
          snapshot.docChanges().forEach((change) => {
            // console.log(change)
            const data = change.doc.data()
            if (change.type === "added") {
                console.log("New : ", change.doc.data());
                // setDocData(docData =>[...docData, change.doc.data()])
                      setObj( data)
                    
            }
            if (change.type === "modified") {
                // console.log("Modified : ", change.doc.data());
                      setObj( data)
                
            }
            
          });
        });
      }
    }
    return ()=>{
      if(queryRef.current!=null){
        queryRef.current()
        queryRef.current=null
      }
    }
  },[number,userData])

  
  useEffect(()=>{
    if(obj['PurchaseHistory']==undefined){
      setObj((prevState) => ({
        ...prevState,
          ['PurchaseHistory']: [emptyPurchObj],
        }
      )) 
    }
  },[obj])
  // useEffect(()=>{
  //   console.log(obj)
    
  const addRow = ()=>{
    console.log('addd')
    setObj((prevState) => {
      const clone = JSON.parse(JSON.stringify(prevState));
      clone['PurchaseHistory'].push(emptyPurchObj)
      return clone;

    })
    // setObjj([...objj, emptyPurchObj])
  }
  const removeRow = (x)=>{
    console.log('remove', x)
    setObj((prevState) => {
      const clone = JSON.parse(JSON.stringify(prevState));

      const previousArr = clone['PurchaseHistory'];
        if(previousArr.length>1){

        const first = previousArr.slice(0, x)
        // console.log(first)
        
        // console.log(newArray,' 2')
        const second =previousArr.slice(x+1)
        // console.log(second)
        // newArray.concat(second)
        // console.log(newArray,' 3')
        clone['PurchaseHistory']=first.concat(second)
        return clone;
        }else{
          return clone;
        }
      }
    )
  }

  useEffect(()=>{
    // console.log(obj['PurchaseHistory'])
    // console.log('***************************************')
    let add=false
      if(obj['PurchaseHistory']!=undefined){

      list5.forEach((item, index)=>{
        // console.log(item)
        // console.log(obj['PurchaseHistory'].length-1)
        // console.log(obj['PurchaseHistory'][obj['PurchaseHistory'].length-1][item])
        if(obj['PurchaseHistory'].length-1>=0&&obj['PurchaseHistory'][obj['PurchaseHistory'].length-1][item]!==""){

          add=true
        }
      })
      for(let x=obj['PurchaseHistory'].length-2;x>=0;x--){
        let remove=0
        list5.forEach((item, index)=>{
          if(obj['PurchaseHistory'][x][item]==""){
            remove+=1
          }
        })
        if(remove==list5.length){
          removeRow(x)
        }
      }
      if(add){
        console.log('add')
        addRow()
      }
  // const emptyPurchObj = {'Customer':'','Date':'','Order No.':'','Qty':'','Price':'','Shipping & Other':'','Inspection':'','Freight':'','Other':'','Total':''};


      // setObj((prevState)=>{
      //   const clone = JSON.parse(JSON.stringify(prevState));
      //   // const previousArr = clone['PurchaseHistory'];

      //   const lab = Number(clone['Labor'])!=='NaN'?Number(clone['Labor']):0
      //   const labO = Number(clone['LaborOverhead'])!=='NaN'?Number(clone['LaborOverhead']):0
      //   const labC = Number(clone['Conversion'])!=='NaN'?Number(clone['Conversion']):0
      //   let lastPrice=0;
      //   let lastShipping=0;
      //   let lastTotal=0;
      //   for(let x= clone['PurchaseHistory'].length-1;x>0;x--){
      //     if(Number(clone['PurchaseHistory'][clone['PurchaseHistory'].length-1]['Per Price'])!=='NaN'){
      //     lastPrice = Number(clone['PurchaseHistory'][clone['PurchaseHistory'].length-1]['Per Price'])
      //     lastShipping = Number(clone['PurchaseHistory'][clone['PurchaseHistory'].length-1]['Per Shipping'])!=='NaN'?Number(clone['PurchaseHistory'][clone['PurchaseHistory'].length-1]['Per Shipping']):0;
      //     lastTotal = Number(clone['PurchaseHistory'][clone['PurchaseHistory'].length-1]['Per Total'])!=='NaN'?Number(clone['PurchaseHistory'][clone['PurchaseHistory'].length-1]['Per Total']):0;
      //     break;  
      //     }
      //   }              
        
      //   clone['Total'] = labC+lastTotal
      //   return clone

      // })\

      console.log(obj)
    
      }
    
  },[obj['PurchaseHistory']]) 

  

    

  // },[obj['Rate'],obj['Overhead'],obj['Fixed Overhead'],obj['Material'],obj['Parts/hr']])

  // useEffect(()=>{
  //   const fixedOverhead = obj['Fixed Overhead']!=''?Number(obj['Fixed Overhead']):0
  //   if(fixedOverhead>0){
  //     setObj((prevState) => ({
  //       ...prevState,
  //         ['Material']: Number(Number(obj['Total'])-Number(fixedOverhead)).toFixed(2),
  //       }
  //     )) 
  //   }
  // },[obj['Total']])

  const reset = ()=>{
    setNewNew(true)
    setObj({'Id':'','Description':'','Conversion':0,'Worker':'','Location':'','Inventory':0,'Acct. Dist.':'','Rate':0,'Note':'','Note1':'','Parts/hr':0,'Labor':0,'Overhead%':0,'LaborOverhead':0,'Material':0,'MaterialOverhead':0,'Total':0})
  }

  return (
    <Container style={{height:'100%','marginTop':56}}>
      <Row onClick={()=>{if(openMenu){setOpenMenu(false)};if(openNavMenu){setOpenNavMenu(false)}}} >
      <Row>
        <div style={{ marginBottom: 20}} >
        {userData['currCompany']!=undefined?
          userData['companies'].length>1?
          <span className="fuck1">
            <Dropdown 
              className="dropdown"
              placeholderClassName="fuck"
              arrowClosed={<FaCaretDown className="arrow-closed" />}
              arrowOpen={<FaCaretUp className="arrow-open" />}
              controlClassName="dropdownC" open={openDrop} options={Object.keys(userData['companiesMap'])} onChange={(e)=>{setCurrCompany(e.value)}} value={currCompany}>
              
            </Dropdown>
          </span>      
          :
          <span>{userData['currCompany']}</span>
          :''}
        </div>
      </Row>
      <Row>
        <div style={{ marginBottom: 20}} >
          <span style={{cursor: 'pointer'}} onClick={reset}>
            <FaRedo />  Component
            {/*<FaCaretDown />*/}
          </span>
        </div>
      </Row>
    
        <Row> 
        {/*'Description','Rate','Parts/hr','Labor','Overhead','Total'*/}
      <Col xs="2" lg="2">
        <div style={{overflowX:"hidden"}}>Id</div>
      </Col>
      <Col xs="4" lg="4">
        <div style={{overflowX:"hidden"}}>Description</div>
      </Col>
      <Col xs="1" lg="1">
        <div style={{overflowX:"hidden"}}>Acct. Dist.</div>
      </Col>
      <Col xs="1" lg="1">
        <div style={{overflowX:"hidden"}}>Location</div>
      </Col>
      <Col xs="2" lg="2">
        <div style={{overflowX:"hidden"}}>Inventory</div>
      </Col>
      <Col xs="2" lg="2">
        <div style={{overflowX:"hidden"}}>Cost</div>
      </Col>

      </Row>
      <Row 
      > 
    {/*no forward slashes, need to program*/}
        <Col xs="2" lg="2" style={{paddingRight:0, paddingLeft:0,}}>
          <Form7 name={'Id'} input={obj} setInput={setObj} db={db} userData={userData} setSearch={setSearch} searchName={'components'} />
        </Col>
        <Col xs="4" lg="4" style={{paddingRight:0, paddingLeft:0,}}>
          <Form7 name={'Description'} input={obj} setInput={setObj} db={db} userData={userData} setSearch={setSearch} searchName={'components'} />
        </Col>
        <Col xs="1" lg="1" style={{paddingRight:0, paddingLeft:0,}}>
          <Form6a name={'Acct. Dist.'} input={obj} setInput={setObj} />
        </Col>
        <Col xs="1" lg="1" style={{paddingRight:0, paddingLeft:0,}}>
          <Form6a name={'Location'} input={obj} setInput={setObj} />
        </Col>
        <Col xs="2" lg="2" style={{paddingRight:0, paddingLeft:0,}}>
          <Form6a name={'Inventory'} input={obj} setInput={setObj} />
        </Col>
        <Col xs="2" lg="2" style={{paddingRight:0, paddingLeft:0,}}>
          {
            obj['Fixed Overhead']>0?
          <Form6a name={'Total'} input={obj} setInput={setObj} />
          :
          <div style={{height:'30px',width:'100%', border:'1px solid black',borderRadius:3,textAlign:'left',paddingLeft: '3px'}}>{obj['Total']==undefined?0:obj['Total']==''?0:obj['Total']}</div>
          }
        </Col>

        <Row>
          <div style={{ marginBottom: 20,marginTop: 20}} >
            <span >
             Labor Cost
              {/*<FaCaretDown />*/}
            </span>
          </div>
        </Row>

        </Row>
      <Row>
      
      <Col xs="4" lg="4">
        <div style={{overflowX:"hidden"}}>Worker</div>
      </Col>
      <Col xs="4" lg="4">
        <div style={{overflowX:"hidden"}}>Rate</div>
      </Col>
      <Col xs="4" lg="4">
        <div style={{overflowX:"hidden"}}>Parts/hr</div>
      </Col>
     

      </Row>
            <Row>
            
            <Col xs="4" lg="4" style={{paddingRight:0, paddingLeft:0,}}>
              <Form5 name={'Worker'} input={obj} setInput={setObj} db={db} userData={userData} docData={docData1}setDocData={setDocData1} red={red} setRed={setRed} search={search} setSearch={setSearch} />
            </Col>
            <Col xs="4" lg="4" style={{paddingRight:0, paddingLeft:0,}}>
              {/*<Form6 name={'Rate'} input={obj} setInput={setObj} docData={docData1} red={red} setRed={setRed} />*/}
              <div style={{height:'30px',width:'100%', border:'1px solid black',borderRadius:3,textAlign:'left',paddingLeft: '3px'}}>{obj['Rate']==undefined?0:obj['Rate']==''?0:obj['Rate']}</div>
            </Col>
            <Col xs="4" lg="4" style={{paddingRight:0, paddingLeft:0,}}>
              <Form6a name={'Parts/hr'} input={obj} setInput={setObj} />
            </Col>
            

            </Row>
      <Row style={{marginTop:10}}>
      <Col xs="3" lg="3">
        <div style={{overflowX:"hidden"}}>Labor Cost</div>
      </Col>
      <Col xs="3" lg="3">
        <div style={{overflowX:"hidden"}}>Overhead %</div>
      </Col>
      <Col xs="3" lg="3">
        <div style={{overflowX:"hidden"}}>Labor Overhead</div>
      </Col>
      <Col xs="3" lg="3">
        <div style={{overflowX:"hidden"}}>Labor Conversion</div>
      </Col> 
      
      {/*<Col style={{overflowX:"hidden"}} xs="2" lg="2">
        <div style={{overflowX:"hidden"}}>Total</div>
      </Col>*/}
      
    </Row>
      
    
            <Row>
            <Col xs="3" lg="3" style={{paddingRight:0, paddingLeft:0,}}>
              {/*<div style={{height:'30px',width:'100%', border:'1px solid black',borderRadius:3,textAlign:'left',paddingLeft: '3px'}}>{obj['Labor']==undefined?0:obj['Labor']==''?0:obj['Labor']}</div>*/}
              <Form6a name={'Labor'} input={obj} setInput={setObj} />
            </Col>

            <Col xs="3" lg="3" style={{paddingRight:0, paddingLeft:0,}}>
              <Form6a name={'Overhead%'} input={obj} setInput={setObj} />
            </Col>
            <Col xs="3" lg="3" style={{paddingRight:0, paddingLeft:0,}}>
              <Form6a name={'LaborOverhead'} input={obj} setInput={setObj} />
            </Col>
            <Col xs="3" lg="3" style={{paddingRight:0, paddingLeft:0,}}>
              <div style={{height:'30px',width:'100%', border:'1px solid black',borderRadius:3,textAlign:'left',paddingLeft: '3px'}}>{obj['Conversion']==undefined?0:obj['Conversion']==''?0:obj['Conversion']}</div>
            </Col>
            
            

            
          </Row>

          <Row style={{marginTop:10}}>
            <Col xs="12" lg="12">
              <div style={{overflowX:"hidden"}}>Note</div>
            </Col>
          </Row>

          <Row style={{marginTop:10}}>
            <Col xs="12" lg="12" style={{paddingRight:0, paddingLeft:0,}}>
              <Form6a name={'Note'} input={obj} setInput={setObj} />
            </Col>
          </Row>

        

    <div style={{paddingBottom:"10px",borderBottom:"1px solid #000"}}/>

     <Row>
      <div style={{ marginBottom: 20,marginTop: 20}} >
        <span style={{cursor: 'pointer'}}>
          Purchase History
        </span>
      </div>
    </Row>


    <Row>
    {
      per==2&&
      list4.map((item,index)=>{
        let size=1;

        switch(item){
        case 'Order No.' : size=2
          break;
        case 'Vendor' : size=3
          break;
        case 'Total' : size=2
          break;
       
        }

        return(

          <Col xs={size} lg={size} style={{paddingRight:0, paddingLeft:0,}}>
            {
              (item=='Price'|| item=='Shipping & Other'|| item=='Total')?
            <div onClick={()=>{setPer(per<3?per+1:1);console.log(item)}}>
            {item}
            </div>
            :
            <div>
            {item}
            </div>
            }
          </Col>
          )
      })
    }
    {
      per==1&&
      list41.map((item,index)=>{
        let size=1;

        switch(item){
        case 'Order No.' : size=2
          break;
        case 'Vendor' : size=3
          break;
        case 'Per Total' : size=2
          break;
        
        }

        return(
          <Col xs={size} lg={size} style={{paddingRight:0, paddingLeft:0,}} >
            {
              (item=='Per Price'|| item=='Per Shipping & Other'|| item=='Per Total')?
            <div onClick={()=>{setPer(per<3?per+1:1);console.log(item)}}>
            {item}
            </div>
            :
            <div>
            {item}
            </div>
            }
          </Col>
          )
      })
    }
    {
      per==3&&
      list43.map((item,index)=>{
        let size=1;

        switch(item){
        case 'Order No.' : size=2
          break;
        case 'Vendor' : size=3
          break;
        case 'Note' : size=4
          break;
        
        }

        return(
          <Col xs={size} lg={size} style={{paddingRight:0, paddingLeft:0,}}>
            {
              (item=='Note')?
            <div onClick={()=>{setPer(per<3?per+1:1);console.log(item)}}>
            {item}
            </div>
            :
            <div>
            {item}
            </div>
            }
          </Col>
          )
      })
    }
    </Row>

    {
      per==2&&
      obj['PurchaseHistory']!=undefined&&
      obj['PurchaseHistory'].map((intem,index)=>{

        return(
          <Row>
            <Col xs="2" lg="2" style={{paddingRight:0, paddingLeft:0,}}>
              <Form1a2 key={index+'ppifdsa'} name={'Order No.'} input={obj} setInput={setObj} db={db} userData={userData} key2={'PurchaseHistory'} indy={index} />
            </Col>
            <Col xs="1" lg="1" style={{paddingRight:0, paddingLeft:0,}}>
              <Form1a2 key={index+'date'} name={'Date'} input={obj} setInput={setObj} db={db} userData={userData} key2={'PurchaseHistory'} indy={index} />
            </Col>
            <Col xs="1" lg="1" style={{paddingRight:0, paddingLeft:0,}}>
              <Form1a2 key={index+'date112213312'} name={'Date1'} input={obj} setInput={setObj} db={db} userData={userData} key2={'PurchaseHistory'} indy={index} />
            </Col>
            <Col xs="3" lg="3" style={{paddingRight:0, paddingLeft:0,}}>
              <Form7b4 key={index+'jfkldsa'} name={'Customer'} input={obj} setInput={setObj} db={db} userData={userData} key2={'PurchaseHistory'} indy={index} searchName={'Customer'} />
            </Col>
            <Col xs="1" lg="1" style={{paddingRight:0, paddingLeft:0,}}>
              <Form1a2 key={index+'nfdsa'} name={'Qty'} input={obj} setInput={setObj} db={db} userData={userData} key2={'PurchaseHistory'} indy={index} />
            </Col>
            <Col xs="1" lg="1" style={{paddingRight:0, paddingLeft:0,}}>
              <Form1a2 key={index+'fna'} name={'Price'} input={obj} setInput={setObj} db={db} userData={userData} key2={'PurchaseHistory'} indy={index} />
            </Col>
            <Col xs="1" lg="1" style={{paddingRight:0, paddingLeft:0,}}>
              <Form1a2 key={index+'njof'} name={'Shipping'} input={obj} setInput={setObj} db={db} userData={userData} key2={'PurchaseHistory'} indy={index} />
            </Col>           
            <Col xs="2" lg="2" style={{paddingRight:0, paddingLeft:0,}}>
              <Form1a2 key={index+'paruur'} name={'Total'} input={obj} setInput={setObj} db={db} userData={userData} key2={'PurchaseHistory'} indy={index} />
            </Col>
            
          </Row>
          
          )
      })
    }
    {
      per==1&&
      obj['PurchaseHistory']!=undefined&&
      obj['PurchaseHistory'].map((item,index)=>{

        return(
          <Row>
            <Col xs="2" lg="2" style={{paddingRight:0, paddingLeft:0,}}>
              <Form1a2 key={index+'ppifdsa1'} name={'Order No.'} input={obj} setInput={setObj} db={db} userData={userData} key2={'PurchaseHistory'} indy={index} />
            </Col>
            <Col xs="1" lg="1" style={{paddingRight:0, paddingLeft:0,}}>
              <Form1a2 key={index+'date1'} name={'Date'} input={obj} setInput={setObj} db={db} userData={userData} key2={'PurchaseHistory'} indy={index} />
            </Col>
            <Col xs="1" lg="1" style={{paddingRight:0, paddingLeft:0,}}>
              <Form1a2 key={index+'date1312213123'} name={'Date1'} input={obj} setInput={setObj} db={db} userData={userData} key2={'PurchaseHistory'} indy={index} />
            </Col>
            <Col xs="3" lg="3" style={{paddingRight:0, paddingLeft:0,}}>
              <Form7b4 key={index+'jfkldsa1'} name={'Customer'} input={obj} setInput={setObj} db={db} userData={userData} key2={'PurchaseHistory'} indy={index} searchName={'Customer'} />
            </Col>
            <Col xs="1" lg="1" style={{paddingRight:0, paddingLeft:0,}}>
              <Form1a2 key={index+'nfdsa1'} name={'Qty'} input={obj} setInput={setObj} db={db} userData={userData} key2={'PurchaseHistory'} indy={index} />
            </Col>
            <Col xs="1" lg="1" style={{paddingRight:0, paddingLeft:0,}}>
              <Form1a2 key={index+'fna1'} name={'Per Price'} input={obj} setInput={setObj} db={db} userData={userData} key2={'PurchaseHistory'} indy={index} />
            </Col>
            <Col xs="1" lg="1" style={{paddingRight:0, paddingLeft:0,}}>
              <Form1a2 key={index+'njof1'} name={'Per Shipping'} input={obj} setInput={setObj} db={db} userData={userData} key2={'PurchaseHistory'} indy={index} />
            </Col>
            <Col xs="2" lg="2" style={{paddingRight:0, paddingLeft:0,}}>
              <Form1a2 key={index+'paruur1'} name={'Per Total'} input={obj} setInput={setObj} db={db} userData={userData} key2={'PurchaseHistory'} indy={index} />
            </Col>
          </Row>
          
          )
      })
    }
    {
      per==3&&
      obj['PurchaseHistory']!=undefined&&
      obj['PurchaseHistory'].map((item,index)=>{

        return(
          <Row>
            <Col xs="2" lg="2" style={{paddingRight:0, paddingLeft:0,}}>
              <Form1a2 key={index+'jfkldsa'} name={'Order No.'} input={obj} setInput={setObj} db={db} userData={userData} key2={'PurchaseHistory'} indy={index} />
            </Col>
            <Col xs="1" lg="1" style={{paddingRight:0, paddingLeft:0,}}>
              <Form1a2 key={index+'date1'} name={'Date'} input={obj} setInput={setObj} db={db} userData={userData} key2={'PurchaseHistory'} indy={index} />
            </Col>
            <Col xs="1" lg="1" style={{paddingRight:0, paddingLeft:0,}}>
              <Form1a2 key={index+'njsdafjksd'} name={'Date1'} input={obj} setInput={setObj} db={db} userData={userData} key2={'PurchaseHistory'} indy={index} />
            </Col>
            <Col xs="3" lg="3" style={{paddingRight:0, paddingLeft:0,}}>
              <Form7b4 key={index+'jfkldsa1'} name={'Customer'} input={obj} setInput={setObj} db={db} userData={userData} key2={'PurchaseHistory'} indy={index} searchName={'Customer'} />
            </Col>
            <Col xs="1" lg="1" style={{paddingRight:0, paddingLeft:0,}}>
              <Form1a2 key={index+'nfdsa1'} name={'Qty'} input={obj} setInput={setObj} db={db} userData={userData} key2={'PurchaseHistory'} indy={index} />
            </Col>
            <Col xs="4" lg="4" style={{paddingRight:0, paddingLeft:0,}}>
              <Form1a2 key={index+'fna1'} name={'Note'} input={obj} setInput={setObj} db={db} userData={userData} key2={'PurchaseHistory'} indy={index} />
            </Col>
            
          </Row>
          
          )
      })
    }

    <Row style={{marginTop:10}}>
      <Col xs="12" lg="12">
        <div style={{overflowX:"hidden"}}>Note</div>
      </Col>
    </Row>

    <Row style={{marginTop:10}}>
      <Col xs="12" lg="12" style={{paddingRight:0, paddingLeft:0,}}>
        <Form6a name={'Note1'} input={obj} setInput={setObj} />
      </Col>
    </Row>

    <div style={{paddingBottom:"10px",borderBottom:"1px solid #000"}}/>

     <Row>
      <div style={{ marginBottom: 20,marginTop: 20}} >
        <span style={{cursor: 'pointer'}}>
          Approved Vendors
        </span>
      </div>
    </Row>

    <Row>
    <Col xs="4" lg="4" style={{paddingRight:0, paddingLeft:0,}}>
    </Col>
    <Col xs="4" lg="4" style={{paddingRight:0, paddingLeft:0,}}>
      <Form7 name={'Customer'} input={obj} setInput={setObj} db={db} userData={userData} searchName={'Customer'} />
    </Col>
    <Col xs="4" lg="4" style={{paddingRight:0, paddingLeft:0,}}>
    </Col>
    </Row>

    {
      obj['ApprovedVendors']!=undefined&&
      Object.keys(obj['ApprovedVendors']).map((item,index)=>{
        return(
          <Row>
            <Col xs="12" lg="12" style={{paddingRight:0, paddingLeft:0,}}>
              <Form1a3 key={index+'ppifdsa1'} item={item} obj={obj['ApprovedVendors']} />
            </Col>
          </Row>
          )
      })
    }

    <Row style={{paddingBottom:"10px",borderBottom:"1px solid #000"}}>
      <Col xs="0" lg="3">
      </Col>
      <Col xs="12" lg="6">
        <Button style={{ marginTop: 30}} onClick={edit} >Save Component</Button>
      </Col>
      
      <Col xs="0" lg="3">
      </Col>
    </Row>

  </Row>
  {showModal&&
    <Modal
      show={true}
      onHide={()=>{setShowModal(false)}}
      backdrop="static"
      // keyboard={false}
    >
      <Modal.Header closeButton>
        {obj['Description']==''?
          <Modal.Title>Description</Modal.Title>
          :
          <Modal.Title>Worker</Modal.Title>
        }
      </Modal.Header>

      <Modal.Body>
        {obj['Description']==''?
        <div>
          The Description field can not be blank.
        </div>
        :
        <div>
          Are you sure you want to over-write the workers rate?  This will change the rate for all Labor with this worker.
        </div>
        
        }
      </Modal.Body>

      <Modal.Footer>
        {obj['Description']==''?
        <div>
        </div>
        :
          <Button variant="primary" onClick={handleOverWrite} >Over-Write</Button>
        }
      </Modal.Footer>

    </Modal>
  }  
  {showModal1&&
    <Modal
      show={true}
      onHide={()=>{setShowModal1(false)}}
      backdrop="static"
      // keyboard={false}
    >
      <Modal.Header closeButton>
        
        <Modal.Title>Saving</Modal.Title>
          
      </Modal.Header>

      <Modal.Body>
        Saving...
      </Modal.Body>

    </Modal>
  }
    <div className='nav1'>

      <ComponentMenu 
          open={openMenu} 
          setOpen={setOpenMenu} 
          db={db}
          list={list}
          setObj={setObj}
          setNewNew={setNewNew}
          docData={docDataMenu}
          setDocData={setDocDataMenu}
          userData={userData}
          dbSection="labor"
        />
      
      {/*<NavMenu 
          open={openNavMenu} 
          setOpen={setOpenNavMenu} 
          db={db}  
          user={user} 
          auth={auth}     
          setShowLogin={setShowLogin}     
          setPage={setPage}     
          userData={userData}

        />*/}
    </div>

  </Container>
  );
}

export default Component;
