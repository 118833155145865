import { useState, useEffect,useRef } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import useLongPress from './useLongPress.js';
import { collection, query, where, getDocs, orderBy, onSnapshot } from "firebase/firestore";
import SearchItem from './SearchItem.js';
import { Container, Row, Col, Button } from "react-bootstrap";
import { FaCaretDown,FaCaretUp} from 'react-icons/fa';



const FormSwitchId = ({item, index, size, list, setList,ascDesc, whichLabel, rearrange})=> {
  // useEffect(()=>{
  //   console.log(name)
  //   console.log(input)
  //   console.log(index)  
  // },[])
  // useEffect(()=>{
  //   console.log(input)
  // },[input])

  const [focused,setFocused] = useState(false)
  const [docData, setDocData] = useState({})
  const onFocus = () => setFocused(true)
  const onBlur = () => startTimer()
  const queryRef = useRef();

  const [counter, setCounter] = useState(0);
  const timer = useRef(null); // we can save timer in useRef and pass it to child

  const startTimer = ()=>{
     timer.current = setTimeout(() => {setFocused(false);}, 250);
  }

    useEffect(
    () => {

      // this will clear Timeout
      // when component unmount like in willComponentUnmount
      // and show will not change to true
      return () => {
        if(timer.current!=null){
          clearTimeout(timer.current);
        }
      };
    },
    // useEffect will run only one time with empty []
    // if you pass a value to array,
    // like this - [data]
    // than clearTimeout will run every time
    // this value changes (useEffect re-run)
    []
  );

  
  useEffect(()=>{
    return ()=>{
      if(queryRef.current!=null){
        queryRef.current()
        queryRef.current=null
      }

    }
  },[])


  

  

const onLongPress = () => {
      console.log('longpress is triggered', item, list[0]);
      if(item=="Id"||item=="Customer PO Id"){
        if(list[0]=="Id"){
          setList((prevState)=>{
            const clone = [...prevState]
            clone[0]='Customer PO Id'
            return clone
            })
        }else{
          console.log('why')
          setList((prevState)=>{  
            const clone = [...prevState]
            clone[0]='Id'
            return clone
            })
        }
      }
  };

  const onClick = () => {
      // console.log('click is triggered')
      rearrange(item)
  }

  const defaultOptions = {
      shouldPreventDefault: true,
      delay: 500,
  };
  const useLongPressEvent = useLongPress(onLongPress,onClick,defaultOptions)

  return (
    <Col {...useLongPressEvent} key={"list"+index+item} xs={size} lg={size}>
      {
        whichLabel==item&&!ascDesc?

      <span>
        <FaCaretDown className="arrow-closed" />
        <span key={"list1"+index+item} style={{overflowX:"hidden"}}>{item}</span>
      </span>
      :whichLabel==item?
      <span>
        <FaCaretUp className="arrow-open" />
        <span key={"list1"+index+item} style={{overflowX:"hidden"}}>{item}</span>
      </span>
      :<span>
        <span key={"list1"+index+item} style={{overflowX:"hidden"}}>{item}</span>
      </span>
      
      }
    </Col>
  );
}

export default FormSwitchId