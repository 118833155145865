import '../App.css';
import Form2 from './Form2.js';
import Form1 from './Form1.js';
import Form1a from './Form1a.js';
import Form1a1 from './Form1a1.js';
import Form5 from './Form5.js';
import Form6 from './Form6.js';
import Form6c from './Form6c.js';
import Form7 from './Form7.js';
import Form7a from './Form7a.js';
import Form7a1 from './Form7a1.js';
import Form7b from './Form7b.js';
import Form7b1 from './Form7b1.js';
import Form7b2 from './Form7b2.js';
// import FormBillOfLading from './FormBillOfLading.js';
import WorkerMenu from './WorkerMenu.js';
import NavMenu from './NavMenu.js';
import { useState, useEffect, useRef } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col, Button } from "react-bootstrap";
import { getFunctions, httpsCallable } from "firebase/functions";
import { getDoc } from "firebase/firestore";
import { getFirestore, setDoc, collection, query, where, doc, getDocs, orderBy, arrayUnion, onSnapshot } from "firebase/firestore";
import { getAuth, createUserWithEmailAndPassword, onAuthStateChanged, signInWithEmailAndPassword, signOut, sendEmailVerification, sendPasswordResetEmail } from "firebase/auth";
import firebase from './firebase.js';
import LoginModal from './LoginModal.js'
import { FaCaretDown,FaCaretUp,FaSearch,FaRedo,FaBars } from 'react-icons/fa';
import inventory from '../jsons/inventoryData.json'
import {Modal, Spinner} from 'react-bootstrap';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import Dropdown from 'react-dropdown';
import { getStorage,ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { useNavigate } from "react-router-dom"



function Cart({user,db,auth,openMenu,setOpenMenu,setShowLogin,setPage,openNavMenu,setOpenNavMenu,userData,cartObj,setCartObj,quoteId,emptyObj,emptyToShipObj,currCompany,setCurrCompany,openDrop,setOpenDrop}) {
  
  const storage = getStorage(firebase);
  const navigate = useNavigate()

  const [list, setList] = useState(["Id","Date","Customer PO Id","Customer","Contact Person","Terms","Total"])
  const [list1, setList1] = useState(["Part Id","Customer Part Id",'Job Id',"Description","Description 2","Quantity",'Price'])
  const [list2, setList2] = useState(['Contact Person','Address','Address 2','City','Zip','State','Country'])
  const [list4, setList4] = useState(['Attention','Address','Address 2','City','Zip','State','Country'])
  const [list3, setList3] = useState(['Ship By Date','Carrier','Tracking','# Units','Weight','Freight'])
  
  // const emptyObj = {"Id":"","Open":true,"Status":"Quote","Customer PO Id":"","Customer":"","Customer Id":"","Date":"","Contact Person":"","Total":"","PartIds":[],"CustomerPartIds":[],'BillingInfo':{'Contact Person':'','Address':'','Address2':'','City':'','Zip':'','State':'','Country':''},'toShip':[{"Parts":[{"Part Id":"","Customer Part Id":"","Description":"","Quantity":"",'Price':''}],'Attention':'','Address':'','Address2':'','City':'','Zip':'','State':'','Country':'','Ship By Date':'','Carrier':'','Tracking':'','# Units':'','Weight':'','Freight':''}]}
  const [search, setSearch] = useState(false)
  const [docData, setDocData] = useState([])
  const [docData1,setDocData1] = useState({})
  const [imageRef,setImageRef] = useState({})
  const [cartRef,setCartRef] = useState({total:0,itemNum:0})
  const [screenWidth,setScreenWidth] = useState(0)
  const emptyPartObj = {"Part Id":"","Customer Part Id":"",'Job Id':'',"Description":"","Quantity":"",'Price':''}
  const [numList, setNumList] = useState([]);


  useEffect(()=>{
    const array = []
    for(let x=0;x<=99;x++){
      array.push(String(x))
    }
    setNumList(array)
  },[])

  useEffect(()=>{
    console.log(cartObj)
    if(Object.keys(cartObj).length>=1){
          console.log('yup1')
      setCartRef({total:0,itemNum:0})
      setImageRef({})
      Object.keys(cartObj).forEach((item,index)=>{
        console.log(item)
        if(cartObj[item]['prod'][0]['photos']!=undefined&&cartObj[item]['prod'][0]['photos'].length>0){

          const downRef = ref(storage,'/photos/'+cartObj[item]['prod'][0]['photos'][0].storeId)
          getDownloadURL(downRef).then((url) => {
            console.log('yup2')
            // Insert url into an <img> tag to "download"
            setImageRef((prev)=>{
              const clone = JSON.parse(JSON.stringify(prev))

              clone[cartObj[item]['prod'][0]['Id']] = {url:url,pdf:cartObj[item]['prod'][0]['photos'][0].name.includes('.pdf')}
              // [...prev,{url:url,pdf:cartObj[item].name.includes('.pdf')}]
            console.log(clone)
              return clone
            })
          })
        }


        setCartRef((prev)=>{
              const clone = JSON.parse(JSON.stringify(prev))

              clone['total'] += Number(((Number(cartObj[item]['prod'][cartObj[item]['sellerIndex']]['price'])*.4)*Number(cartObj[item]['qty'])).toFixed(2))
              clone['itemNum'] += Number(cartObj[item]['qty'])
            console.log(clone)
              return clone
            })

      })
    }else{
      setCartRef({total:0,itemNum:0})
    }
  },[cartObj])

  const checkout = ()=>{
    navigate('/Checkout')
  }


  return(
    <Container style={{height:'100%','paddingTop':56}}>
      <Row onClick={()=>{if(openMenu){setOpenMenu(false)};if(openNavMenu){setOpenNavMenu(false)}}} >
      <Row>
        <Col xs='10'>
      <Row>
        <Col xs='6'>
        <h3 style={{paddingTop:10,textAlign:'left'}}>
          Shopping Cart
        </h3>
        </Col>
        <Col xs='6' style={{paddingTop:10,paddingBottom:0,display:'flex',flex:1,width:'100%',alignItems:'flex-end',justifyContent:'flex-end'}}>
          <div style={{flex:1,textAlign:'right' ,alignItems:'flex-end',justifyContent:'flex-end'}}>
            Price
          </div>
        
        </Col>
      </Row>
      <Row>
        <Col xs='12'>
          {
            cartObj!=undefined&&
            Object.keys(cartObj).map((item,index)=>{

              if(cartObj[item]['prod']==undefined){
                return
              }
              return(
                <Row key={cartObj[item]['prod'][0]['Id']+index} style={{padding:5,borderBottom:'1px solid black',borderTop:'1px solid black',}}>
                  <Col xs='12' lg='2' style={{padding:1}} >
                    {
                      imageRef[cartObj[item]['prod'][0]['Id']]!=undefined&&
                      <div>
                      {

                    !imageRef[cartObj[item]['prod'][0]['Id']]['pdf']?
                    <img style={{width:'100%'}} src={imageRef[cartObj[item]['prod'][0]['Id']]['url']}></img>
                    
                    :
                    
                    <object data={imageRef[cartObj[item]['prod'][0]['Id']]['url']} type="application/pdf">
                    </object>
                      }
                      </div>
                    }
                </Col>
                <Col xs='12' lg='8' style={{textAlign:'left'}}>
                  <Row>
                    <Col xs='12' lg='12' style={{}}>
                      {cartObj[item]['prod'][0]['Id']}
                    </Col>
                  </Row>
                  <Row>
                    <Col xs='12' lg='12' style={{}}>
                      <span>Qty : </span>
                      <Dropdown 
                        placeholderClassName="fuck"
                        arrowClosed={<FaCaretDown className="arrow-closed" />}
                        arrowOpen={<FaCaretUp className="arrow-open" />}
                        className="dropdown"
                        controlClassName="dropdownC"
                        menuClassName='menuClassName'
                        open={openDrop} options={numList} onChange={(e)=>{setCartObj((prev)=>{const clone = JSON.parse(JSON.stringify(prev));if(e.value=='0'){delete clone[item]}else{clone[item]['qty']=e.value}; return clone });console.log(e.value)}} value={cartObj[item]['qty']} placeholder={cartObj[item]['qty']}>
                      </Dropdown>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs='12' style={{fontSize:12,padding:5,paddingLeft:15}} onClick={()=>{setCartObj((prev)=>{const clone = JSON.parse(JSON.stringify(prev));delete clone[item]; return clone})}}>
                    Delete
                    </Col>
                  </Row>
                </Col>
                <Col xs='12' lg='2' style={{textAlign:'right'}}>
                  {'$'+((Number(cartObj[item]['prod'][cartObj[item]['sellerIndex']]['price'])*.4)*Number(cartObj[item]['qty'])).toFixed(2)}
                </Col>

                </Row>
                )
            })
          }
        </Col>
      </Row>
      <Row>
        <Col xs='12' style={{textAlign:'right'}}>
         {'SubTotal ('+cartRef['itemNum']+' items): $'+cartRef['total']} 
        </Col>
      </Row>

      {/*need to make checkout Box*/}


        </Col>
        <Col xs='2' style={{paddingTop:25}}>
          <Row>
            <Col xs='12' style={{textAlign:'right'}}>
             {'SubTotal ('+cartRef['itemNum']+' items): $'+cartRef['total']} 
            </Col>
          </Row>
          <Row>
            <Col xs='12' style={{textAlign:'right'}}>
             <Button onClick={checkout}>Checkout</Button> 
            </Col>
          </Row>
          
        </Col>

      </Row>
      </Row>

    </Container>
  );
}

export default Cart;
