import { useState, useEffect } from 'react'
import useLongPress from './useLongPress.js';


const Form1a1 = ({name,setInput,input,index,key1,key2,indy })=> {
  useEffect(()=>{
    // console.log(input['components'][index])
    
  })
const onLongPress = () => {
      // console.log('longpress is triggered');
      if(name=="Part Id"){

        window.history.pushState({}, document.title, window.location.pathname);
        let loc = String(window.location);
        let locSplit = loc.split('/')
        window.open(String(locSplit[0]+'/Parts'+"?number="+input[key2][indy][key1][index]['Part Id']),'_blank');
      
      }
  };

  const onClick = () => {
      // console.log('click is triggered')
  }

  const defaultOptions = {
      shouldPreventDefault: true,
      delay: 500,
  };
  const useLongPressEvent = useLongPress(onLongPress,onClick,defaultOptions)

  return (
    <div>
      <input
        {...useLongPressEvent}
      style={{float:"left",width:'100%'}}
      type="text" 
      value={input[key2]!=undefined&&input[key2][indy]!=undefined&&input[key2][indy][key1][index]!=undefined?input[key2][indy][key1][index][name]:''}
      onChange={(e) =>{

        // console.log(index)
        // console.log(key1)
        // console.log(input[key1])
        // console.log(input[key1][index])
        setInput((prevState) => {
            const clone = JSON.parse(JSON.stringify(prevState));
            const previousArr = clone[key2][indy][key1];
            if(previousArr[index]==undefined){
              previousArr[index]=[]
            }

            previousArr[index][name]=e.target.value;

            if(name=="Quantity"){
              console.log('quant change')
              let indyPrice=0;
              console.log(clone['Parts'])
              console.log(previousArr)
              clone['Parts'].forEach((item,index)=>{
                previousArr.forEach((item1,index1)=>{

                  if(previousArr[index1]['Part Id']==item['Part Id'] && item['Part Id']!=''){
                    console.log(item['Price'],item['Quantity'])
                    indyPrice=Number(item['Price'])/Number(item['Quantity'])
                  }

                })
              })
              console.log(indyPrice)
              previousArr[index]['Price'] = ((Number(previousArr[index]['Quantity'])*indyPrice)).toFixed(2)
              let total=0
              previousArr.forEach((item,index)=>{
                total+=Number(item['Price'])
              })
              clone[key2][indy]['Total'] = Number(total).toFixed(2)

            }
            // else if(name=="Price"){
            //   previousArr[index]['Mark Up'] = (((Number(previousArr[index]['Price'])-Number(previousArr[index]['Cost']))/Number(previousArr[index]['Cost']))*100).toFixed(2)
            // }
            
            clone[key2][indy][key1]=previousArr
            console.log(clone[key2][indy])
            return clone;   
          })
        // console.log('fuckkkk')
        // const clone = [...input];
        // clone[index][name] = e.target.value;
        // setInput(clone)
        }
      } 
    />
    </div>
  );
}

export default Form1a1