import React from 'react';
import { StyledMenu } from './Menu.styled';
import SearchItem from './SearchItem.js';
import { useState, useEffect, useRef } from "react";
import { collection, query, where, getDocs, orderBy, onSnapshot } from "firebase/firestore";
import { FaCaretDown, FaCaretUp  } from 'react-icons/fa';
import Dropdown from 'react-dropdown';



const WorkerMenu = ({open, setOpen, db, list,list1, setObj, setObj1, setNewNew, docData, setDocData,userData}) => {
    
  const [which, setWhich] = useState('Worker Tier');
  const [searchLabel, setSearchLabel] = useState('Id');
  const [searchInput, setSearchInput] = useState('');
  const [openDrop, setOpenDrop] = useState(false);
  const [done, setDone] = useState(false);
  const queryRef = useRef();

  
  useEffect(()=>{
    return ()=>{
      if(queryRef.current!=null){
        queryRef.current()
        queryRef.current=null
      }
    }
  },[])
  // useEffect(()=>{
  //   if(done){
  //   }
  // },[done])

  const search = async (e)=>{

    // if(e.target.value.length>2){
      setDocData([])
      if(userData['currCompany']!=undefined&&userData['currCompany']!=''){
        const q = query(collection(db,'companies', userData['currCompany'], which=='Worker Tier'?'workerTier':'worker'), where(searchLabel, ">=", e.target.value), where(searchLabel, "<=", e.target.value+'\uf8ff'));
        if(queryRef.current!=null){
          queryRef.current()
        }
        queryRef.current = onSnapshot(q, (snapshot) => {
          snapshot.docChanges().forEach((change) => {
            // console.log(change)
            if (change.type === "added") {
                // console.log("New : ", change.doc.data());
                // setDocData(docData =>[...docData, change.doc.data()])
                setDocData((prevState) => ({
                ...prevState,
                  [change.doc.id]: change.doc.data()
                }
              ))
            }
            if (change.type === "modified") {
                // console.log("Modified : ", change.doc.data());
                setDocData((prevState) => ({
                ...prevState,
                  [change.doc.id]: change.doc.data()
                }
              ))
            }
            if (change.type === "removed") {
                setDocData((prevData) => {
                  const newData = {...prevData}
                  delete newData[change.doc.id]
                  return newData;
                })
            }
          });
        });
      }
    // }
  }
  const saveTemplateAsFile = (filename, dataObjToWrite) => {
    var fields = which=='Worker'?list1:list
    // console.log(fields)
    var replacer = function(key, value) { return value === null ? '' : value } 
    var csv = Object.keys(dataObjToWrite).map(function(row){
      return fields.map(function(fieldName){
        // console.log(fieldName,row,dataObjToWrite[row][fieldName])
        return JSON.stringify(dataObjToWrite[row][fieldName], replacer)
      }).join(',')
    })
    csv.unshift(fields.join(',')) // add header column
     csv = csv.join('\r\n');
    console.log(csv)


    const blob = new Blob([csv], { type: "text/csv" });
    const link = document.createElement("a");

    link.download = filename;
    link.href = window.URL.createObjectURL(blob);
    link.dataset.downloadurl = ["text/csv", link.download, link.href].join(":");

    const evt = new MouseEvent("click", {
        view: window,
        bubbles: true,
        cancelable: true,
    });

    link.dispatchEvent(evt);
    link.remove()
};

const download = ()=>{
  console.log(docData) 
  saveTemplateAsFile("worker_Data.csv",docData)
}

  const fillInForm = (obj)=>{
    // fill in the cost estimate form
    // console.log(obj)
         // console.log(docData) 
    // saveTemplateAsFile("component_Data.json",docData)

    window.history.pushState({}, document.title, window.location.pathname);
    if(which=='Worker'){
      setObj1(obj)
    }else{
      setObj(obj)
    }
  }

  return (
    <StyledMenu open={open} left={true}>
      <div style={{float:'top'}}>
        <span style={{ float: 'right', cursor: 'pointer', padding: '10px'}} onClick={()=>{setOpen(false)}}>
          X
        </span>
      </div>
      <div style={{float:'top'}}>
        <button style={{ float: 'center', cursor: 'pointer', padding: '10px'}} onClick={download}>
          Download result file
        </button>
      </div>
      
        <div>
          {/*<span style={{display:'flex'}}>*/}
            {/*<FaCaretDown style={{'flex':2}} />*/}
          <Dropdown 
            className="dropdown"
            placeholderClassName="fuck"
            arrowClosed={<FaCaretDown className="arrow-closed" />}
            arrowOpen={<FaCaretUp className="arrow-open" />}
            controlClassName="dropdownC" open={openDrop} options={['Worker Tier','Worker']} onChange={(e)=>{setWhich(e.value)}} value={which}>
            
          </Dropdown>
      </div>
        <div>
          {/*<span style={{display:'flex'}}>*/}
            {/*<FaCaretDown style={{'flex':2}} />*/}
          
          <Dropdown 
            className="dropdown"
            placeholderClassName="fuck"
            arrowClosed={<FaCaretDown className="arrow-closed" />}
            arrowOpen={<FaCaretUp className="arrow-open" />}
            controlClassName="dropdownC" open={openDrop} options={which=='Worker'?list1:list} onChange={(e)=>{setSearchLabel(e.value)}} value={searchLabel}>
          </Dropdown>

      </div>
      <div>
          {/*</span>*/}
          
          <input
            // style={{float:"right"}}
            type="text" 
            value={searchInput}
            onChange={(e) => {setSearchInput(e.target.value);setDocData([]);search(e)}}
          />
      </div>


    {/*map of search output*/}
    {Object.keys(docData).length >= 1 &&
      Object.keys(docData).map((objj, index) => {
        {/*console.log(objj)*/}
       return (

          <SearchItem key={index+"floof"} obj={docData[objj]} onClick={fillInForm} searchLabel={searchLabel} /> 
        );

      })
    }
      
    </StyledMenu>
  )
}
export default WorkerMenu;