import '../App.css';
import Form2 from './Form2.js';
import Form1 from './Form1.js';
import Form1a from './Form1a.js';
import Form5 from './Form5.js';
import Form6 from './Form6.js';
import Form7 from './Form7.js';
// import Form7a from './Form7a.js';
import Form7c from './Form7c.js';
import Form99 from './Form99.js';
import FormSwitchId from './FormSwitchId.js';
import FlowMenu from './FlowMenu.js';
import NavMenu from './NavMenu.js';
import { useState, useEffect, useRef } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col, Button } from "react-bootstrap";
import { getFunctions, httpsCallable } from "firebase/functions";
import {  } from "firebase/firestore";
import { getFirestore, setDoc, collection, query, where, doc, getDocs, orderBy, arrayUnion, onSnapshot } from "firebase/firestore";
import { getAuth, createUserWithEmailAndPassword, onAuthStateChanged, signInWithEmailAndPassword, signOut, sendEmailVerification, sendPasswordResetEmail } from "firebase/auth";
import firebase from './firebase.js';
import LoginModal from './LoginModal.js'
import { FaCaretDown,FaCaretUp,FaSearch,FaRedo,FaBars } from 'react-icons/fa';
import inventory from '../jsons/inventoryData.json'
import {Modal, Spinner} from 'react-bootstrap';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import Dropdown from 'react-dropdown';
import { BrowserRouter as Router, Route, Link, useNavigate,useLocation } from "react-router-dom";
import useLongPress from './useLongPress.js';

  


function Flow({user,db,auth,openMenu,setOpenMenu,setShowLogin,setPage,openNavMenu,setOpenNavMenu,userData,obj,setObj,custObj,setCustObj,currCompany,setCurrCompany,openDrop,setOpenDrop,orderObj,setOrderObj,list,setList}) {
  
  const navigate = useNavigate();

  const [list1, setList1] = useState(["Open","Status"])
  const [ascDesc, setAscDesc] = useState(false)
  const [whichLabel, setWhichLabel] = useState('Date')
  
  const emptyObj = {"Workers":[{"Id":""}],"Components":[{"Id":""}],"Parts":[{"Id":"","Approved":false,"Description":"","Inventory":"","Base":"","Labor":"","Overhead":"","Material":"","Total":""}],"Orders":[]}
  const emptyPartObj = {"Part Id":"","Customer Part Id":"","Description":"","Quantity":"",'Cost':"",'Mark Up':"",'Price':''}
  
  const [search, setSearch] = useState(false)
  const [docData, setDocData] = useState([])
  const [docData1,setDocData1] = useState({})
  const [docDataMenu, setDocDataMenu] = useState({})
  const [option, setOption] = useState('Open')
  const [openDrop1, setOpenDrop1] = useState(false)

  const [screenWidth,setScreenWidth] = useState(0)
  const ref = useRef()
  const queryRef = useRef()
  const queryRef1 = useRef();

  // get open orders
  // get parts and quotes that need approval


  useEffect(()=>{
    console.log(obj)

    // would need to put to query in app.js for the any modified updates for when on another page.
    // if(obj['Orders'].length<1){
      getFlow()
    // }
    
    return ()=>{
      if(queryRef.current!=null){
        queryRef.current()
        queryRef.current=null
      }
    }
  },[userData])

  const rearrange = (item)=>{
    
    if(whichLabel!=item){
      setWhichLabel(item)
    }else{
      setAscDesc(current => !current)
    }

  }

  useEffect(()=>{
    console.log(ascDesc,whichLabel)
    setObj((prevState)=>{
      const clone = JSON.parse(JSON.stringify(prevState));
        if(whichLabel=='Parts'){
          if(ascDesc){
            clone['Orders'] = clone['Orders'].sort((a,b)=> a[whichLabel][0]['Part Id']>b[whichLabel][0]['Part Id']? 1 : -1)
          }else{
            clone['Orders'] = clone['Orders'].sort((a,b)=> b[whichLabel][0]['Part Id']>a[whichLabel][0]['Part Id']? 1 : -1)
          }
        }else if(whichLabel=='Total'){
          if(ascDesc){
            clone['Orders'] = clone['Orders'].sort((a,b)=> Number(a[whichLabel])>Number(b[whichLabel])? 1 : -1)
          }else{
            clone['Orders'] = clone['Orders'].sort((a,b)=> Number(b[whichLabel])>Number(a[whichLabel])? 1 : -1)
          }
        }else if(whichLabel=='Date'){
          if(ascDesc){
            clone['Orders'] = clone['Orders'].sort((a,b)=> String(+ new Date(a['toShip'][0]['Ship By Date']))>String(+ new Date(b['toShip'][0]['Ship By Date']))? 1 : -1)
          }else{
            clone['Orders'] = clone['Orders'].sort((a,b)=> String(+ new Date(b['toShip'][0]['Ship By Date']))>String(+ new Date(a['toShip'][0]['Ship By Date']))? 1 : -1)
          }
        }else{
          if(ascDesc){
            clone['Orders'] = clone['Orders'].sort((a,b)=> a[whichLabel]>b[whichLabel]? 1 : -1)
          }else{
            clone['Orders'] = clone['Orders'].sort((a,b)=> b[whichLabel]>a[whichLabel]? 1 : -1)
          }
        }

      return clone
    })
  },[ascDesc])

  useEffect(()=>{
    console.log(whichLabel, ascDesc)
    setObj((prevState)=>{
      const clone = JSON.parse(JSON.stringify(prevState));
        if(whichLabel=='Parts'){
          if(ascDesc){
            clone['Orders'] = clone['Orders'].sort((a,b)=> a[whichLabel][0]['Part Id']>b[whichLabel][0]['Part Id']? 1 : -1)
          }else{
            clone['Orders'] = clone['Orders'].sort((a,b)=> b[whichLabel][0]['Part Id']>a[whichLabel][0]['Part Id']? 1 : -1)
          }
        }else if(whichLabel=='Total'){
          if(ascDesc){
            clone['Orders'] = clone['Orders'].sort((a,b)=> Number(a[whichLabel])>Number(b[whichLabel])? 1 : -1)
          }else{
            clone['Orders'] = clone['Orders'].sort((a,b)=> Number(b[whichLabel])>Number(a[whichLabel])? 1 : -1)
          }
        }else if(whichLabel=='Date'){
          if(ascDesc){
            clone['Orders'] = clone['Orders'].sort((a,b)=> String(+ new Date(a['toShip'][0]['Ship By Date']))>String(+ new Date(b['toShip'][0]['Ship By Date']))? 1 : -1)
          }else{
            clone['Orders'] = clone['Orders'].sort((a,b)=> String(+ new Date(b['toShip'][0]['Ship By Date']))>String(+ new Date(a['toShip'][0]['Ship By Date']))? 1 : -1)
          }
        }else{
          if(ascDesc){
            clone['Orders'] = clone['Orders'].sort((a,b)=> a[whichLabel]>b[whichLabel]? 1 : -1)
          }else{
            clone['Orders'] = clone['Orders'].sort((a,b)=> b[whichLabel]>a[whichLabel]? 1 : -1)
          }
        }

      return clone
    })
  },[whichLabel])

  // const changeOption = (op)=>{
  //   setOption(op)
  //   getFlow(op)
  // }

  const getFlow = (op)=>{

    if(userData['currCompany']!=undefined&&userData['currCompany']!=''){
        let opp=option
        if(op!=undefined){
          opp=op
        }
        setObj(emptyObj)
        const qOrder = query(collection(db,'companies', userData['currCompany'], "order"), where("Open", "==", true));
        
        if(queryRef.current!=null){
          queryRef.current()
          queryRef.current=null
        }
        if(queryRef1.current!=null){
          queryRef1.current()
          queryRef1.current=null
        }
        
        queryRef.current = onSnapshot(qOrder, (snapshot) => {
          snapshot.docChanges().forEach((change) => {
            const data = change.doc.data()
            // console.log(change.type)
            // console.log(data)
            if (change.type === "added") {
          
              // console.log("New : ", change.doc.data());
              // setDocData(docData =>[...docData, change.doc.data()])
              setObj((prevState)=>{
                const clone = JSON.parse(JSON.stringify(prevState));

                  clone['Orders'].push(data)
                return clone
              })

              rearrange(whichLabel)
                
            }
            if (change.type === "modified") {
          
              // console.log("Modified : ", change.doc.data());
              console.log(obj['Orders'])
              setObj((prevState)=>{
                const clone = JSON.parse(JSON.stringify(prevState));
                console.log(clone)
                let indy=0
                clone['Orders'].forEach((item,index)=>{
                  if(item['Id']==data['Id']){
                    indy=index;
                console.log(indy)
                  }
                console.log(indy)
                })

                if(opp=='Open'?!data['Open']:data['Open']){
                  clone['Orders'].splice(indy, 1);
                  console.log('not open',indy)
                } else{
                  clone['Orders'][indy]=data;
                }
                console.log(clone['Orders'])
                return clone;
              })
              rearrange(whichLabel)
              
            }
            if (change.type === "deleted" ||change.type === "removed") {
              setObj((prevState)=>{
                const clone = JSON.parse(JSON.stringify(prevState));
                console.log(clone)
                let indy=0
                clone['Orders'].forEach((item,index)=>{
                  if(item['Id']==data['Id']){
                    indy=index;
                  }
                })
                console.log(indy)
                clone['Orders'].splice(indy, 1);
                return clone;
              })
              rearrange(whichLabel)
            }
            
          });
        });


      }
  }
  
  const edit = ()=>{
    console.log('edit')
    // if(obj['Id']==''){
    //   // setShowModal(true)
    // }else{
    //   //need to add part ids and customer ids to searchable array
    //   const clone = JSON.parse(JSON.stringify(obj));
    //   if(clone['Customer Id']==''){
    //     alert(" Need To add Customer to Database")
    //     return 
    //   }
    //   clone['PartIds']=[]
    //   clone['CustomerPartIds']=[]
      
    //   clone['Parts'].forEach((item1,index1)=>{
    //     console.log(item1)
    //     console.log(item1["Part Id"])
    //     console.log(item1["Customer Part Id"])
    //       if(item1["Part Id"]!=""){
    //         clone['PartIds'].push(item1["Part Id"])
    //       }
    //       if(item1["Customer Part Id"]!=""){
    //         clone['CustomerPartIds'].push(item1["Customer Part Id"])
    //       }

    //     })
    //   console.log(clone)

    //   setDoc(doc(db, 'companies', userData['currCompany'], "Quote", String(clone['Id'])), clone , { merge: true })
    // }
  }

  const next = ()=>{
    console.log('next')
    // const id = obj['Id']
    // window.history.pushState({}, document.title, window.location.pathname);
    // window.open((String(window.location)+"?page=Purchase+Order"+"&QuoteId="+id),'_blank');
    
    
  };

  const reset = ()=>{
    if(queryRef1.current!=null){
      queryRef1.current()
    }

    getFlow()
  }

  const gotTo=(item)=>{
    console.log(item)
    let page1='Quotes';
    switch(item['Status']){
      case 'Purchase Order': page1='PurchaseOrders';
      break;
      case 'Built': page1='BillOfLading';
      break;
      case 'Bill Of Lading': page1='BillOfLading';
      break;
      case 'Invoice': page1='Invoice';
      break;
      
    }
    setOrderObj(item)
    navigate('/'+page1)
    setPage(page1)
  }

  
  return (
    <Container style={{'marginTop':56,'marginBottom':56}}>
      <Row onClick={()=>{if(openMenu)setOpenMenu((prev)=>{return !prev})}} >
      <Row>
        <div style={{ marginBottom: 20}} >
        {userData['currCompany']!=undefined?
          userData['companies'].length>1?
          <span className="fuck1">
            <Dropdown 
              className="dropdown"
              placeholderClassName="fuck"
              arrowClosed={<FaCaretDown className="arrow-closed" />}
              arrowOpen={<FaCaretUp className="arrow-open" />}
              controlClassName="dropdownC" open={openDrop} options={Object.keys(userData['companiesMap'])} onChange={(e)=>{setCurrCompany(e.value)}} value={currCompany}>
              
            </Dropdown>
          </span>      
          :
          <span>{userData['currCompany']}</span>
          :''}
        </div>
      </Row>
      <Row>
        <div style={{ marginBottom: 20}} >
          <span style={{cursor: 'pointer'}} onClick={reset}>
            <FaRedo />  Flow
          </span>
        </div>
      </Row>
    {/*<Row>
        <div style={{ marginBottom: 20}} >
        <span className="fuck1">
          <Dropdown 
              className="dropdown"
              placeholderClassName="fuck"
              arrowClosed={<FaCaretDown className="arrow-closed" />}
              arrowOpen={<FaCaretUp className="arrow-open" />}
              controlClassName="dropdownC" open={openDrop1} options={['Open','Closed','Returns']} onChange={(e)=>{changeOption(e.value)}} value={option}>
              
            </Dropdown>
            </span>
        </div>
      </Row>*/}
    
      <Row style={{paddingBottom:15}}> 
      {
        list.map((item,index)=>{
          {/*console.log(item)*/}
          const showTot = userData['companiesMap']!=undefined&&userData['companiesMap'][userData['currCompany']]!=undefined&&userData['companiesMap'][userData['currCompany']]['admin']?true:false

          let size = showTot?'2':'3'
          switch(item){
            case "Parts": size=3;
              break;
            case "Customer": size=3;
              break;
            default:size=showTot?'2':'3';
          }

          if(!showTot&&item=='Total'){
            return
          }

          if(item=='Id'||item=='Customer PO Id'){
            return (<FormSwitchId item={item} index={index} size={size} list={list} setList={setList} ascDesc={ascDesc} rearrange={rearrange} whichLabel={whichLabel} />)
          }
          
          return (
            <Col key={"list"+index+item} xs={size} lg={size}>
            {
              whichLabel==item&&!ascDesc?
              <span>
              <FaCaretDown className="arrow-closed" />
              <span onClick={()=>{rearrange(item)}} key={"list1"+index+item} style={{overflowX:"hidden"}}>{item}</span>
              </span>
              : whichLabel==item?
              <span>
              <FaCaretUp className="arrow-open" />
              <span onClick={()=>{rearrange(item)}} key={"list1"+index+item} style={{overflowX:"hidden"}}>{item}</span>
              </span>
              :
              <div onClick={()=>{rearrange(item)}} key={"list1"+index+item} style={{overflowX:"hidden"}}>{item}</div>              
            }
            </Col>

            )
        })
      }
      
      </Row>
      
          
          {
            obj['Orders'].map((item,index)=>{
              {/*console.log(item)*/}
              if(item['Id']==""){
                return
              }
              const showTot = userData['companiesMap']!=undefined&&userData['companiesMap'][userData['currCompany']]!=undefined&&userData['companiesMap'][userData['currCompany']]['admin']?true:false
              if(item['toShip'].length>1){
                 return item['toShip'].map((item1,index1)=>{
              {/*console.log(item1)*/}
                    let status = item1['Status']!=undefined?item1['Status']:''
                    const parts = []
                    let price=0
                    item1['Parts'].forEach((item2,index2)=>{
              {/*console.log(item2)*/}

                      if(item2['Part Id']!=''){
                        price+=Number(item2['Price'])
                        parts.push(item2['Part Id'])
                      }
                    })
                    {/*if(item1['Tracking']!=''){
                      status='Bill Of Lading'
                    } else if(item1['Customer PO ID']!=''||item1['Ship By Date']!=''){
                      status='Purchase Order'
                    }*/}
              {/*console.log(status)*/}

                    return(
                      <Row >
                        <Col xs="12" lg="12" style={{paddingRight:0, paddingLeft:0,}}>
                          <Row key={"o"+index}> 
                            <Col xs={showTot?'2':'3'} lg={showTot?'2':'3'} style={{overflowX:'auto'}}>
                              <Form99 indy={index1} db={db} userData={userData} item={item} setOrderObj={setOrderObj} setPage={setPage} name={list[0]} />
                            </Col>
                            <Col xs={showTot?'2':'3'} lg={showTot?'2':'3'} style={{overflowX:'auto'}}>
                              <Form99 indy={index1} db={db} userData={userData} item={item} status={true} setOrderObj={setOrderObj} setPage={setPage} name={item1['Ship By Date']}/>
                              </Col>
                            <Col xs="3" lg="3" style={{overflowX:'auto'}}>
                              <Form99 indy={index1} db={db} userData={userData} item={item} setOrderObj={setOrderObj} setPage={setPage} name={'Customer'}/>
                            </Col>
                            <Col xs="3" lg="3" style={{overflowX:'auto'}}>
                              <Form99 indy={index1} db={db} userData={userData} item={item} status={true} setOrderObj={setOrderObj} setPage={setPage} name={parts.length>1?parts.join(' : '):parts}/>
                            </Col>
                            {
                              showTot&&
                              <Col xs="2" lg="2" style={{overflowX:'auto'}}>
                                <Form99 indy={index1} db={db} userData={userData} item={item} status={true} setOrderObj={setOrderObj} setPage={setPage} name={price}/>
                              </Col>
                            }
                          </Row>
                          <Row key={"Status"+index} style={item1['StatusOverride']?{backgroundColor:'#ebebeb'}:{}}> 
                            <Col xs="3" lg="3" style={status=='Quote'||status=='Purchase Order'||status=='Bill Of Lading'||status=='Invoice'?{color:'black',borderBottom:'2px solid #06c967'}:{color:'black',borderBottom:'2px solid #ed053c'}}>
                              <Form99 indy={index1} db={db} userData={userData} item={item} status={true} setOrderObj={setOrderObj} setPage={setPage} name={'Quote'}/>
                            </Col>
                            <Col xs="3" lg="3" style={status=='Purchase Order'||status=='Bill Of Lading'||status=='Invoice'?{color:'black',borderBottom:'2px solid #06c967'}:{color:'black',borderBottom:'2px solid #ed053c'}}>
                              <Form99 indy={index1} db={db} userData={userData} item={item} status={true} setOrderObj={setOrderObj} setPage={setPage} name={'Purchase Order'}/>
                              </Col>
                            <Col xs="3" lg="3" style={status=='Bill Of Lading'||status=='Invoice'?{color:'black',borderBottom:'2px solid #06c967'}:{color:'black',borderBottom:'2px solid #ed053c'}}>
                              <Form99 indy={index1} db={db} userData={userData} item={item} status={true} setOrderObj={setOrderObj} setPage={setPage} name={'Bill Of Lading'}/>
                            </Col>
                            <Col xs="3" lg="3" style={status=='Invoice'?{color:'black',borderBottom:'2px solid #06c967'}:{color:'black',borderBottom:'2px solid #ed053c'}}>
                              <Form99 indy={index1} db={db} userData={userData} item={item} status={true} setOrderObj={setOrderObj} setPage={setPage} name={'Invoice'}/>
                            </Col>
                            {/*<Col xs="2" lg="2" style={{}}>
                              <div></div>
                            </Col>*/}
                          </Row>
                        </Col>
                      </Row>
                      
                      )
                  })
                }else{

                  let status = item['toShip'][0]['Status']!=undefined?item['toShip'][0]['Status']:''
                  {/*console.log(status)*/}
                  const parts = []
                  item['Parts'].forEach((item2,index2)=>{
                  {/*console.log(item2)*/}
                    if(item2['Part Id']!=''){
                      parts.push(item2['Part Id'])
                    }
                  })
                  {/*if(item['toShip'][0]['Tracking']!=''){
                    status='Bill Of Lading'
                  } else if(item['Customer PO ID']!=''||item['toShip'][0]['Ship By Date']!=''){
                    status='Purchase Order'
                  } */}

              return(

                <Row >
                  <Col xs="12" lg="12" style={{paddingRight:0, paddingLeft:0,}}>
                    <Row key={"o"+index}> 
                      <Col xs={showTot?'2':'3'} lg={showTot?'2':'3'} style={{overflowX:'auto'}}>
                        <Form99 indy={0} db={db} userData={userData} item={item} setOrderObj={setOrderObj} setPage={setPage} name={list[0]} />
                      </Col>
                      <Col xs={showTot?'2':'3'} lg={showTot?'2':'3'} style={{overflowX:'auto'}}>
                        <Form99 indy={0} db={db} userData={userData} item={item} status={true} setOrderObj={setOrderObj} setPage={setPage} name={item['toShip'][0]['Ship By Date']}/>
                        </Col>
                      <Col xs="3" lg="3" style={{overflowX:'auto'}}>
                        <Form99 indy={0} db={db} userData={userData} item={item} setOrderObj={setOrderObj} setPage={setPage} name={'Customer'}/>
                      </Col>
                      <Col xs="3" lg="3" style={{overflowX:'auto'}}>
                        <Form99 indy={0} db={db} userData={userData} item={item} status={true} setOrderObj={setOrderObj} setPage={setPage} name={parts.length>1?parts.join(' : '):parts}/>
                      </Col>
                      {
                        showTot&&
                      <Col xs="2" lg="2" style={{overflowX:'auto'}}>
                        <Form99 indy={0} db={db} userData={userData} item={item} setOrderObj={setOrderObj} setPage={setPage} name={'Total'}/>
                      </Col>
                      }
                    </Row>
                    <Row key={"Status"+index} style={item['toShip'][0]['StatusOverride']?{backgroundColor:'#ebebeb'}:{}}> 
                      <Col xs="3" lg="3" style={status=='Quote'||status=='Purchase Order'||status=='Bill Of Lading'||status=='Invoice'?{color:'black',borderBottom:'2px solid #06c967'}:{color:'black',borderBottom:'2px solid #ed053c'}}>
                        <Form99 indy={0} db={db} userData={userData} item={item} status={true} setOrderObj={setOrderObj} setPage={setPage} name={'Quote'}/>
                      </Col>
                      <Col xs="3" lg="3" style={status=='Purchase Order'||status=='Bill Of Lading'||status=='Invoice'?{color:'black',borderBottom:'2px solid #06c967'}:{color:'black',borderBottom:'2px solid #ed053c'}}>
                        <Form99 indy={0} db={db} userData={userData} item={item} status={true} setOrderObj={setOrderObj} setPage={setPage} name={'Purchase Order'}/>
                        </Col>
                      <Col xs="3" lg="3" style={status=='Bill Of Lading'||status=='Invoice'?{color:'black',borderBottom:'2px solid #06c967'}:{color:'black',borderBottom:'2px solid #ed053c'}}>
                        <Form99 indy={0} db={db} userData={userData} item={item} status={true} setOrderObj={setOrderObj} setPage={setPage} name={'Bill Of Lading'}/>
                      </Col>
                      <Col xs="3" lg="3" style={status=='Invoice'?{color:'black',borderBottom:'2px solid #06c967'}:{color:'black',borderBottom:'2px solid #ed053c'}}>
                        <Form99 indy={0} db={db} userData={userData} item={item} status={true} setOrderObj={setOrderObj} setPage={setPage} name={'Invoice'}/>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                
                )
                }
            })
          }

          

          

    <div style={{paddingBottom:"10px",borderBottom:"1px solid #000"}}/>

  </Row>

    <div className='nav1'>
  

      <FlowMenu 
          open={openMenu} 
          setOpen={setOpenMenu} 
          db={db}
          obj={obj}
          setObj={setObj}
          queryRef={queryRef}
          queryRef1={queryRef1}
          docData={docDataMenu}
          setDocData={setDocDataMenu}
          userData={userData}
          dbSection="order"

        />
      
    </div>

  </Container>
  );
}

export default Flow;




// const q = query(collection(db,'companies', userData['currCompany'], "parts"), where("Approved", "==", false));
        
        // queryRef.current = onSnapshot(q, (snapshot) => {
        //   snapshot.docChanges().forEach((change) => {
        //     // console.log(change)
        //     const data = change.doc.data()
        //     if (change.type === "added") {
          
        //       // console.log("New : ", change.doc.data());
        //       // setDocData(docData =>[...docData, change.doc.data()])
        //       setObj((prevState)=>{
        //         const clone = JSON.parse(JSON.stringify(prevState));
        //         clone['Parts'].push(data)
        //         return clone;

        //       })

                
        //     }
        //     if (change.type === "modified") {
          
        //       // console.log("Modified : ", change.doc.data());
        //       const data =change.doc.data();
        //       setObj((prevState)=>{
        //         const clone = JSON.parse(JSON.stringify(prevState));
        //         let indy=0
        //         clone['Parts'].forEach((item,index)=>{
        //           if(item['Id']==data['Id']){
        //             indy=index;
        //           }
        //         })

        //         if(data['Approved']){
        //           clone['Parts'].splice(indy, 1);
        //         } else{
        //           clone['Parts'][indy]=data;
        //         }
        //         return clone;
        //       })
        //     }
        //     if (change.type === "deleted") {
        //       const data =change.doc.data();
        //       setObj((prevState)=>{
        //         const clone = JSON.parse(JSON.stringify(prevState));
        //         let indy=0
        //         clone['Parts'].forEach((item,index)=>{
        //           if(item['Id']==data['Id']){
        //             indy=index;
        //           }
        //         })
        //         clone['Parts'].splice(indy, 1);
        //         return clone;
        //       })
        //     }
            
        //   });
        // });