import { useState, useEffect,useRef } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import useLongPress from './useLongPress.js';
import { collection, query, where, getDocs, orderBy, onSnapshot } from "firebase/firestore";
import SearchItem from './SearchItem.js';



const Form7a11 = ({name,setInput,input,index,userData,db ,setObj1,setSearch,isArray,searchName,key1,key2,indy})=> {
  // useEffect(()=>{
  //   console.log(name)
  //   console.log(input)
  //   console.log(index)  
  // },[])
  // useEffect(()=>{
  //   console.log(input)
  // },[input])

  const [focused,setFocused] = useState(false)
  const [docData, setDocData] = useState({})
  const onFocus = () => setFocused(true)
  const onBlur = () => startTimer()
  const queryRef = useRef();

  const [counter, setCounter] = useState(0);
  const timer = useRef(null); // we can save timer in useRef and pass it to child

  const startTimer = ()=>{
     timer.current = setTimeout(() => {setFocused(false);}, 250);
  }

    useEffect(
    () => {

      // this will clear Timeout
      // when component unmount like in willComponentUnmount
      // and show will not change to true
      return () => {
        if(timer.current!=null){
          clearTimeout(timer.current);
        }
      };
    },
    // useEffect will run only one time with empty []
    // if you pass a value to array,
    // like this - [data]
    // than clearTimeout will run every time
    // this value changes (useEffect re-run)
    []
  );

  
  useEffect(()=>{
    return ()=>{
      if(queryRef.current!=null){
        queryRef.current()
        queryRef.current=null
      }

    }
  },[])


  const searchWorkers = async (e)=>{
    console.log(e.target.value, searchName, name)
    console.log(docData)
    // if(e.target.value.length>2){
      setDocData([])
      setDocData(input['Parts'])
         
    // }
  }

  const fillInForm = (obj)=>{
    console.log(obj)
    // setInput(obj)
    setInput((prevState) => {
      const clone = JSON.parse(JSON.stringify(prevState));
      const previousArr = clone[key1];
      if(previousArr[index]==undefined){
        previousArr[index]=[]
      }
      previousArr[index]['Part Id']=obj['Part Id'];
      previousArr[index]['Customer Part Id']=obj['Customer Part Id'];
      previousArr[index]['Description']=obj['Description'];
      previousArr[index]['Cost']=obj['Cost'];
      clone[key1]=previousArr
      console.log(clone)
      return clone;   
      }
    )

    if(setSearch!=undefined){
      setSearch(true)
    }
  }

const onLongPress = () => {
      // console.log('longpress is triggered');
      if(name=="Part Id"){

        window.history.pushState({}, document.title, window.location.pathname);
        // window.open((String(window.location)+"?page=Parts"+"&number="+input['Parts'][index]['Part Id']),'_blank');
        let loc = String(window.location);
        let locSplit = loc.split('/')
        window.open(String(locSplit[0]+'/Parts'+"?number="+input[key1][index]['Part Id']),'_blank');
        
      }
  };

  const onClick = () => {
      // console.log('click is triggered')
  }

  const defaultOptions = {
      shouldPreventDefault: true,
      delay: 500,
  };
  const useLongPressEvent = useLongPress(onLongPress,onClick,defaultOptions)

  return (
    <div>
      <input
        {...useLongPressEvent}
        style={{float:"left",width:'100%'}}
        type="text" 
        value={input!=undefined&&input[key1][index]!=undefined?input[key1][index][name]:''}
        onFocus={onFocus} onBlur={onBlur}
        onChange={(e) =>{
          setInput((prevState) => {
            const clone = JSON.parse(JSON.stringify(prevState));
            const previousArr = clone[key1];
            if(previousArr[index]==undefined){
              previousArr[index]=[]
            }
            previousArr[index][name]=e.target.value;
            clone[key1]=previousArr
            return clone;   
            }
          )
          searchWorkers(e)
          }
        } 
      />
      {focused && Object.keys(docData).length >= 1 &&
        Object.keys(docData).map((objj, index) => {
          {/*console.log(objj)*/}
         return (

            <SearchItem key={index+"floof"} obj={docData[objj]} setFocused={setFocused} onClick={fillInForm} searchLabel={'Part Id'} /> 
          );

        })
      }
    </div>
  );
}

export default Form7a11