import { useState, useEffect, useRef } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
// import useLongPress from './useLongPress.js';
import { Container, Row, Col, Button } from "react-bootstrap";
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import { FaCaretLeft,FaCaretRight} from 'react-icons/fa';
import Form66 from './Form66.js';
import SearchMulti from './SearchMulti.js';
import Labels from './Labels.js';
import { getFirestore, setDoc, collection, query, where, doc, getDocs, orderBy, arrayUnion, onSnapshot } from "firebase/firestore";
import firebase from './firebase.js';
import {Modal, Spinner} from 'react-bootstrap';





const BillOfLading = ({obj,setObj,user,db,auth,openMenu,setOpenMenu,setShowLogin,setPage,currCompany,setCurrCompany,openDrop,setOpenDrop,userData})=> {

  const ref = useRef()
  const labelRef = useRef()
  const [page1,setPage1] = useState(1)
  const [showInput,setShowInput] = useState(false)
  const [multi,setMulti] = useState(false)
  const [showMultiModal,setShowMultiModal] = useState(false)
  // const [obj,setObj] = useState([])
  const [showLabelModal,setShowLabelModal] = useState(false)
  const [labelObj,setLabelObj] = useState({})
  const [searchLabel1, setSearchLabel1] = useState([]);
  const [searchLabel, setSearchLabel] = useState('1');



  const save = ()=>{
    setDoc(doc(db, 'companies', userData['currCompany'], "order", String(obj['Id'])), obj , { merge: true })

  }

  const print = (name)=>{
      console.log('bill')
      setDoc(doc(db, 'companies', userData['currCompany'], "order", String(obj['Id'])), obj , { merge: true })
      const doc1 = new jsPDF();
      // console.log(screenWidth)
      doc1.html(ref.current, {
         callback: function (doc1) {
           doc1.save('BillOfLading_'+name+'.pdf');
         },
         x: 5,
         y: 0,
         width: 200,
         windowWidth: 1200,
         html2canvas : {windowWidth : 1200},
         filename: 'billOfLading'
      }
      );
    };

  const backward = ()=>{
    setPage1((prevState)=>{
      if(prevState>1){
        return prevState-1
      }else{
        return prevState
      }
    })
  }
  
  const forward = ()=>{
    setPage1((prevState)=>{
      if(prevState<obj['toShip'].length){
        return prevState+1
      }else{
        return prevState
      }
    })
  }

useEffect(() => {
  if(obj['Id']!=undefined&&obj['Id']!=''){
    if(obj['toShip'].length>1){
      obj['toShip'].forEach((item,index)=>{
        let value = 0;
        item['Parts'].forEach((item1,index1)=>{
          if(item1['Part Id']!='Shipping & Handling'){
            value+=Number(item1['Price'])
          }
        })
        setObj((prevState)=>{
          const clone = JSON.parse(JSON.stringify(prevState));
          clone['toShip'][index]['Declared Value'] = value
          return clone
        })
      })
    }else{
      let value = 0;
      obj['Parts'].forEach((item1,index1)=>{
        if(item1['Part Id']!='Shipping & Handling'){
          value+=Number(item1['Price'])
        }
      })

      if(obj['toShip'][0]['Parts'][0]['Part Id']==''){

      setObj((prevState)=>{
          const clone = JSON.parse(JSON.stringify(prevState));

          clone['Parts'].forEach((item,index)=>{
            if(clone['Parts'][index]['Part Id'!=undefined]){
              clone['toShip'][0]['Parts'][index]=clone['Parts'][index]
            }
            clone['toShip'][0]['Declared Value'] = value
          })
          return clone
        })
      }
    }
    // if(obj['toShip'][page1-1]['From Contact Person'])
  }
},[])

useEffect(() => {
    const element = ref.current;
    console.log({
      top: element.offsetTop,
      right:
        element.parentElement.offsetWidth -
        (element.offsetLeft + element.offsetWidth),
      bottom:
        element.parentElement.offsetHeight -
        (element.offsetLeft + element.offsetHeight),
      left: element.offsetLeft
    });
  }, [ref]);

const multiClick = ()=>{
  // setMulti(prev=>!prev)
  setShowMultiModal(prev=>!prev)
}

const printLabels = ()=>{
  console.log('print Labels')
      setDoc(doc(db, 'companies', userData['currCompany'], "order", String(obj['Id'])), obj , { merge: true })
      const doc1 = new jsPDF();
      // console.log(screenWidth)
      const name= obj['toShip'].length>1?obj['Id']+'-'+page1:obj['Id']

      doc1.html(labelRef.current, {
         callback: function (doc1) {
           doc1.save('Labels_'+name+'.pdf');
         },
         x: 5,
         y: 0,
         width: 200,
         windowWidth: 1200,
         html2canvas : {windowWidth : 1200},
         filename: 'billOfLading'
      }
      );

}

useEffect(()=>{

  let declaredValue=(obj['toShip'][page1-1]['Delcared Value']!=undefined&&obj['toShip'][page1-1]['Delcared Value']!='')?Number(obj['toShip'][page1-1]['Delcared Value']):Number(obj['Total'])
  console.log(declaredValue)
  if(obj['toShip'][page1-1]['multi']!=undefined){

  obj['toShip'][page1-1]['multi'].forEach((item,index)=>{
    if(obj['toShip'][page1-1]['searchLabel1'][index]!=undefined&&obj['toShip'][page1-1]['searchLabel1'][index]!=''){

      if(item['toShip'][obj['toShip'][page1-1]['searchLabel1'][index]-1]['Declared Value']!=undefined&&item['toShip'][obj['toShip'][page1-1]['searchLabel1'][index]-1]['Declared Value']!=''){
        console.log(item['toShip'][obj['toShip'][page1-1]['searchLabel1'][index]-1]['Declared Value'])
        
        declaredValue+=Number(item['toShip'][obj['toShip'][page1-1]['searchLabel1'][index]-1]['Declared Value'])
      }else{
        console.log(item['Total'])
        declaredValue+=Number(item['Total'])
      }
    }else{
      if(item['toShip'][0]['Declared Value']!=undefined&&item['toShip'][0]['Declared Value']!=''){
        console.log(item['toShip'][0]['Declared Value'])
        declaredValue+=Number(item['toShip'][0]['Declared Value'])
      }else{
        console.log(item['Total'])
        declaredValue+=Number(item['Total'])
      }
    }
  })

  console.log(declaredValue,"********************************************************declaredValue****************************************************************************************")
  if(obj['toShip'][page1-1]['Declared Value1'] != declaredValue){

    setObj((prevState)=>{
      const clone = JSON.parse(JSON.stringify(prevState));
      clone['toShip'][page1-1]['Declared Value1'] = declaredValue
      return clone

    })
  }

  }

},[obj['toShip'][page1-1]['searchLabel1'],obj['toShip'][page1-1]['multi']])

useEffect(()=>{

  const labels=[]

  for(let x=1;x<Number(searchLabel);x++){
    labels.push({})
  }

  obj['toShip'][page1-1]['Parts'].forEach((item,index)=>{
    if(item['Labels']!=undefined){
      item['Labels'].forEach((item1,index1)=>{
        // console.log(item1)
        labels.push(item1)

      })
    }
  })

  if(labels.length>=1&&JSON.stringify(labels)!=JSON.stringify(obj['toShip'][page1-1]['Labels'])){
    // console.log(labels)
    // console.log(obj['toShip'][page1-1]['Labels'])
    // console.log(obj['toShip'][page1-1]['Labels']==labels)
    setObj((prevState)=>{
      const clone = JSON.parse(JSON.stringify(prevState));
      clone['toShip'][page1-1]['Labels'] = labels
      return clone

    })
  }

},[obj['toShip'][page1-1]['Parts']])



  // console.log(name,'fuckkckckck')
  // const obj = {shipperNo:'12345',carrier:'DHL'}
  const emptyArray=[0,1,2,3,4,5,6,7,8,9]
  return (
    <div style={{marginTop:56,maxWidth:1400}}>
      <div>
      {obj['toShip'].length>1&&
        <Row>
          <Col xs="4" lg="4">
          </Col>

          <Col xs="1" lg="1">
            <FaCaretLeft onClick={backward} />
          </Col>
          <Col xs="2" lg="2">
            {page1}
          </Col>
          <Col xs="1" lg="1">
            <FaCaretRight onClick={forward} />
          </Col>
          
          <Col xs="4" lg="4">
          </Col>
        </Row>
      }
      </div>
    <div ref={ref} style={{margin:50}}>
      <Row>
        <Col xs="12" lg="12">
          <div>Straight Bill of Lading</div>
        </Col>
      </Row>
      <Row>
        <Col xs="10" lg="10">
          <div></div>
        </Col>
        <Col xs="12" lg="12">
          <div style={{float:"right"}}>
          <span>
          Shipper No. 
          <input style={{width:150,border:"0px",borderBottom:"1px solid Black", outline:'none', background:"rgba(0,0,0,0)",textAlign:'center'}}
           value={obj['toShip'][page1-1]['Shipper No.']!=undefined?obj['toShip'][page1-1]['Shipper No.']:obj['toShip'].length>1?obj['Id']+'-'+page1:obj['Id']} 
           onChange={(e)=>{
            setObj((prevState)=>{
              const clone = JSON.parse(JSON.stringify(prevState));
              clone['toShip'][page1-1]['Shipper No.'] = e.target.value
              return clone
            })
          }}>
          </input>
          </span>
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs="10" lg="10">
          <div></div>
        </Col>
        <Col xs="12" lg="12">
          <div style={{float:"right"}}>
          <span>
          Carrier No. 
          <input style={{width:150,border:"0px",borderBottom:"1px solid Black", outline:'none', background:"rgba(0,0,0,0)",textAlign:'center'}}
           value={obj['toShip'][page1-1]['Carrier No.']} 
           onChange={(e)=>{
            setObj((prevState)=>{
              const clone = JSON.parse(JSON.stringify(prevState));
              clone['toShip'][page1-1]['Carrier No.'] = e.target.value
              return clone
            })
          }}>
          </input>
          </span>
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs="10" lg="10">
          <div></div>
        </Col>
        <Col xs="12" lg="12">
          <div style={{float:"right"}}>
          <span>
          Date 
          <input style={{width:150,border:"0px",borderBottom:"1px solid Black", outline:'none', background:"rgba(0,0,0,0)",textAlign:'center'}}
           value={obj['toShip'][page1-1]['Ship By Date']} 
           onChange={(e)=>{
            setObj((prevState)=>{
              const clone = JSON.parse(JSON.stringify(prevState));
              clone['toShip'][page1-1]['Ship By Date'] = e.target.value
              return clone
            })
          }}>
          </input>
          </span>
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs="12" lg="12">
          <span>
           
          <input style={{width:200,border:"0px",borderBottom:"1px solid Black", outline:'none', background:"rgba(0,0,0,0)",textAlign:'center'}}
           value={obj['toShip'][page1-1]['Carrier']} 
           onChange={(e)=>{
            setObj((prevState)=>{
              const clone = JSON.parse(JSON.stringify(prevState));
              clone['toShip'][page1-1]['Carrier'] = e.target.value
              return clone
            })
          }}>
          </input>
          </span>
        </Col>
      </Row>
      <Row>
        <Col xs="12" lg="12">
          <div>Name of Carrier</div>
        </Col>
      </Row>
      <Row>
        <Col style={{border:"1px solid black"}} xs="6" lg="6">
          <div style={{float:'left'}}>To: </div>
          <div>{obj['toShip']!=undefined&&obj['toShip'][page1-1]['Contact Person']} </div>
        </Col>
        <Col style={{border:"1px solid black"}} xs="6" lg="6">
          <div style={{float:'left'}}>From: </div>
          <div>{obj['toShip']!=undefined&&obj['toShip'][page1-1]['From Contact Person']} </div>
        </Col>
      </Row>
      <Row>
        <Col style={{border:"1px solid black"}} xs="6" lg="6">
          <div style={{float:'left'}}>Consignee: </div>
          <div>
          <span>
           
          <input style={{width:'75%',border:"none", outline:'none', background:"rgba(0,0,0,0)",textAlign:'center'}}
           value={obj['toShip'][page1-1]['Consignee']} 
           onChange={(e)=>{
            setObj((prevState)=>{
              const clone = JSON.parse(JSON.stringify(prevState));
              clone['toShip'][page1-1]['Consignee'] = e.target.value
              return clone
            })
          }}>
          </input>
          </span>
          </div>
        </Col>
        <Col style={{border:"1px solid black"}} xs="6" lg="6">
          <div style={{float:'left'}}>Shipper: </div>
          <span>
           
          <input style={{width:'75%',border:"none", outline:'none', background:"rgba(0,0,0,0)",textAlign:'center'}}
           value={obj['toShip'][page1-1]['Shipper']} 
           onChange={(e)=>{
            setObj((prevState)=>{
              const clone = JSON.parse(JSON.stringify(prevState));
              clone['toShip'][page1-1]['Shipper'] = e.target.value
              return clone
            })
          }}>
          </input>
          </span>
        </Col>
      </Row>
      
      <Row>
        <Col style={{border:"1px solid black"}} xs="6" lg="6">
          <div style={{float:'left'}}>Address: </div>
          <div>{obj['toShip']!=undefined&&obj['toShip'][page1-1]['Address']} </div>
        </Col>
        <Col style={{border:"1px solid black"}} xs="6" lg="6">
          <div style={{float:'left'}}>Address: </div>
          <div>{obj['toShip']!=undefined&&obj['toShip'][page1-1]['From Address']} </div>
        </Col>
        
      </Row>

      <Row>
        <Col style={{border:"1px solid black"}} xs="6" lg="6">
          <div style={{float:'left'}}>City: </div>
          <div>{obj['toShip']!=undefined&&obj['toShip'][page1-1]['City']}</div>          
        </Col>
        <Col style={{border:"1px solid black"}} xs="6" lg="6">
          <div style={{float:'left'}}>City: </div>
          <div>{obj['toShip']!=undefined&&obj['toShip'][page1-1]['From City']}</div>
        </Col>
        
      </Row>
      <Row>
        <Col style={{border:"1px solid black"}} xs="6" lg="6">
          <div style={{float:'left'}}>State: </div>
          <div>{obj['toShip']!=undefined&&obj['toShip'][page1-1]['State']}</div>
        </Col>
        <Col style={{border:"1px solid black"}} xs="6" lg="6">
          <div style={{float:'left'}}>State: </div>
          <div>{obj['toShip']!=undefined&&obj['toShip'][page1-1]['From State']}</div>
        </Col>
      </Row>
      <Row>
        <Col style={{border:"1px solid black"}} xs="6" lg="6">
          <div style={{float:'left'}}>Zip: </div>
          <div>{obj['toShip']!=undefined&&obj['toShip'][page1-1]['Zip']}</div>
        </Col>
        <Col style={{border:"1px solid black"}} xs="6" lg="6">
          <div style={{float:'left'}}>Zip: </div>
          <div>{obj['toShip']!=undefined&&obj['toShip'][page1-1]['From Zip']}</div>
        </Col>
      </Row>
      <Row>
        <Col style={{border:"1px solid black"}} xs="6" lg="6">
          <div style={{float:'left'}}>Country: </div>
          <div>{obj['toShip']!=undefined&&obj['toShip'][page1-1]['Country']}</div>
        </Col>
        <Col style={{border:"1px solid black"}} xs="6" lg="6">
          <div style={{float:'left'}}>Country: </div>
          <div>{obj['toShip']!=undefined&&obj['toShip'][page1-1]['From Country']}</div>
        </Col>
      </Row>
      
      <Row>
        <Col style={{border:"1px solid black"}} xs="1" lg="1">
          <div style={{}}># Shipping Units</div>          
        </Col>
        <Col style={{border:"1px solid black"}} xs="1" lg="1">
          <div style={{}}>HM</div>
        </Col>
        <Col style={{border:"1px solid black"}} xs="7" lg="7">
          <div style={{}}>Description</div>
        </Col>
        <Col style={{border:"1px solid black"}} xs="1" lg="1">
          <div style={{}}>Weight</div>
        </Col>
        <Col style={{border:"1px solid black"}} xs="1" lg="1">
          <div style={{}}>Rate</div>
        </Col>
        <Col style={{border:"1px solid black"}} xs="1" lg="1">
          <div style={{}}>Charges</div>
        </Col>
        
      </Row>

      {/*first row*/}
      <Form66 first={true} obj={obj} setObj={setObj} index={0} page1={page1} />
      <Form66 second={true} obj={obj} setObj={setObj} index={0} page1={page1} />

      

        {/*obj['toShip'].length>1?*/}
      {
        obj['toShip'][page1-1]['Parts'].map((item,index)=>{
          if(obj['toShip'][page1-1]['Parts'][index]['Part Id']==''){
            return
          }
        return(
        <div>
          <Form66 first={false} obj={obj} setObj={setObj} index={index} page1={page1} />
          <Form66 third={true} obj={obj} setObj={setObj} index={index} page1={page1} />
          {
            obj['toShip'][page1-1]['Parts'][index]['Description2']!=undefined&&obj['toShip'][page1-1]['Parts'][index]['Description2']!=''&&
            <Form66 fourth={true} obj={obj} setObj={setObj} index={index} page1={page1} />
          }
        </div>
            )
  
        })
      }
        {/*:
        obj['Parts'].map((item,index)=>{
          if(obj['Parts'][index]['Part Id']==''){
            return
          }
        return(
        <div>
          {obj['Parts'][index]['Part Id']!='Shipping & Handling'&&
          <Form66 first={false} obj={obj} setObj={setObj} index={index} page1={page1} />
          }
          <Form66 third={true} obj={obj} setObj={setObj} index={index} page1={page1} />
          {
            obj['Parts'][index]['Description2']!=undefined&&obj['Parts'][index]['Description2']!=''&&
            <Form66 fourth={true} obj={obj} setObj={setObj} index={index} page1={page1} />
          }
        </div>
            )
        
        })*/}

      {
        obj['toShip'][page1-1]&&
        obj['toShip'][page1-1]['multi']!=undefined&&
        obj['toShip'][page1-1]['multi'].map((item1,index1)=>{
          console.log(item1)
          return(
            <div key={index1+"obj1234"}>
              <Form66 second={true} obj={item1} setObj={setObj} index={0} page1={page1} />
              {
                item1['toShip'].length>1?
                item1['toShip'][page1-1]['Parts'].map((item,index)=>{
                  if(item['Part Id']==''){
                    return
                  }
                return(
                <div>
                  <Form66 first={false} obj={item1} setObj={setObj} index={index} index1={index1} page1={page1} page={obj['toShip'][page1-1]['searchLabel1'][index1]!=undefined?obj['toShip'][page1-1]['searchLabel1'][index1]:1} />
                  <Form66 third={true} obj={item1} setObj={setObj} index={index} index1={index1} page1={page1} page={obj['toShip'][page1-1]['searchLabel1'][index1]!=undefined?obj['toShip'][page1-1]['searchLabel1'][index1]:1} />
                  {
                    item['Description2']!=undefined&&item['Description2']!=''&&
                    <Form66 fourth={true} obj={item1} setObj={setObj} index={index} index1={index1} page1={page1} page={obj['toShip'][page1-1]['searchLabel1'][index1]!=undefined?obj['toShip'][page1-1]['searchLabel1'][index1]:1} />
                  }
                </div>
                    )
                
                })
                :
                item1['Parts'].map((item,index)=>{
                  if(item1['Parts'][index]['Part Id']==''){
                    return
                  }
                return(
                <div>
                  {item1['Parts'][index]['Part Id']!='Shipping & Handling'&&
                  <Form66 first={false} obj={item1} setObj={setObj} index={index} index1={index1} page1={page1} page={1} />
                  }
                  <Form66 third={true} obj={item1} setObj={setObj} index={index} index1={index1} page1={page1} page={1} />
                  {
                    item1['Parts'][index]['Description2']!=undefined&&item1['Parts'][index]['Description2']!=''&&
                    <Form66 fourth={true} obj={item1} setObj={setObj} index={index} index1={index1} page1={page1} page={1} />
                  }
                </div>
                    )
                
                })
              }
            </div>

            )
        })
      }


      <Row>
        <Col onClick={()=>{setShowInput(true)}} style={{border:"1px solid black"}} xs="6" lg="6">

          <span >Freight - 
            {
              showInput?
            <input onBlur={()=>{setShowInput(false)}} style={{width:'75%',border:"none", outline:'none', background:"rgba(0,0,0,0)"}} 
            value={obj['toShip'][page1-1]['Freight']} 
            onChange={(e)=>{
              setObj((prevState)=>{
                const clone = JSON.parse(JSON.stringify(prevState));
                clone['toShip'][page1-1]['Freight'] = e.target.value

                return clone
              })
            }}
            ></input> 
            : <span>{obj['toShip'][page1-1]['Freight']}</span>
            }
          </span> 
        </Col>
        <Col style={{border:"1px solid black"}} xs="6" lg="6">
          <span>Declared Value : $ 
            <input style={{width:'65%',border:"none", outline:'none', background:"rgba(0,0,0,0)"}} 
            value={obj['toShip'][page1-1]['Declared Value1']!=undefined?obj['toShip'][page1-1]['Declared Value1']:(obj['toShip'][page1-1]['Declared Value']!=undefined&&obj['toShip'][page1-1]['Declared Value']!='')?obj['toShip'][page1-1]['Declared Value']:obj['Total']} 
            onChange={(e)=>{
              setObj((prevState)=>{
                const clone = JSON.parse(JSON.stringify(prevState));
                clone['toShip'][page1-1]['Declared Value1'] = e.target.value

                return clone
              })
            }}
            ></input> 
          </span> 
        </Col>
        
      </Row>

      <Row>
        <Col style={{border:"1px solid black"}} xs="4" lg="4">
          <div style={{float:'left'}}>REMIT C.O.D. </div>
            <Row>
              <Col xs="12" lg="12">
                <div style={{float:'left'}}>
                <span>To: 
                  <input style={{width:100,border:"0px",borderBottom:"1px solid Black", outline:'none', background:"rgba(0,0,0,0)"}} 
                  value={obj['toShip'][page1-1]['C.O.D. To']} 
                  onChange={(e)=>{
                    setObj((prevState)=>{
                      const clone = JSON.parse(JSON.stringify(prevState));
                      clone['toShip'][page1-1]['C.O.D. To'] = e.target.value

                      return clone
                    })
                  }}
                  ></input> 
                </span> 
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs="12" lg="12">
                <div style={{float:'left'}}>
                <span>Address: 
                  <input style={{width:100,border:"0px",borderBottom:"1px solid Black", outline:'none', background:"rgba(0,0,0,0)"}} 
                  value={obj['toShip'][page1-1]['C.O.D. To Address']} 
                  onChange={(e)=>{
                    setObj((prevState)=>{
                      const clone = JSON.parse(JSON.stringify(prevState));
                      clone['toShip'][page1-1]['C.O.D. To Address'] = e.target.value

                      return clone
                    })
                  }}
                  ></input> 
                </span> 
                </div>
              </Col>
            </Row>
          
        </Col>
        <Col style={{border:"1px solid black"}} xs="4" lg="4">
          <div style={{float:'left'}}> 
          <span>C.O.D. AMOUNT: $: 
            <input style={{width:100,border:"0px",borderBottom:"1px solid Black", outline:'none', background:"rgba(0,0,0,0)"}} 
            value={obj['toShip'][page1-1]['C.O.D. AMOUNT: $']} 
            onChange={(e)=>{
              setObj((prevState)=>{
                const clone = JSON.parse(JSON.stringify(prevState));
                clone['toShip'][page1-1]['C.O.D. AMOUNT: $'] = e.target.value

                return clone
              })
            }}
            ></input> 
          </span> 
          </div>
        </Col>
        <Col style={{border:"1px solid black"}} xs="4" lg="4">
          <div style={{width:'100%',textAlign:'left'}}>
          C.O.D. FEE:
          </div>
          <div style={{width:'100%',textAlign:'left'}}>
          <span>Prepaid:
            <input style={{width:50,border:"0px",borderBottom:"1px solid Black", outline:'none', background:"rgba(0,0,0,0)"}} 
            value={obj['toShip'][page1-1]['Prepaid']} 
            onChange={(e)=>{
              setObj((prevState)=>{
                const clone = JSON.parse(JSON.stringify(prevState));
                clone['toShip'][page1-1]['Prepaid'] = e.target.value

                return clone
              })
            }}
            ></input> 
          </span> 
          </div>
        <div style={{width:'100%',textAlign:'left'}}>
          <span>Collect:
            <input style={{width:50,border:"0px",borderBottom:"1px solid Black", outline:'none', background:"rgba(0,0,0,0)"}} 
            value={obj['toShip'][page1-1]['Collect']} 
            onChange={(e)=>{
              setObj((prevState)=>{
                const clone = JSON.parse(JSON.stringify(prevState));
                clone['toShip'][page1-1]['Collect'] = e.target.value

                return clone
              })
            }}
            ></input> 
          </span> 
          </div>
        
        </Col>
      </Row>
      <Row>
        <Col style={{border:"1px solid black"}} xs="3" lg="3">
          <Row>
            <Col xs="12" lg="12">
              <div style={{float:'left', fontSize:12}}>NOTE: Where the rate is dependent on value, shippers are required to state specifically in writing the agreed or declared value of the property. The agreed or declared value of the property is hereby specifically stated by the shipper to be not exceeding</div>
              <div>
              <span>$ 
                <input style={{width:100,border:"0px",borderBottom:"1px solid Black", outline:'none', background:"rgba(0,0,0,0)"}} 
                value={obj['toShip'][page1-1]['RateDependantValue']} 
                onChange={(e)=>{
                  setObj((prevState)=>{
                    const clone = JSON.parse(JSON.stringify(prevState));
                    clone['toShip'][page1-1]['RateDependantValue'] = e.target.value

                    return clone
                  })
                }}
                ></input> 
              </span> 
              <span>
               per 
               <input style={{width:100,border:"0px",borderBottom:"1px solid Black", outline:'none', background:"rgba(0,0,0,0)"}}
                value={obj['toShip'][page1-1]['RateDependantPer']} 
                onChange={(e)=>{
                 setObj((prevState)=>{
                   const clone = JSON.parse(JSON.stringify(prevState));
                   clone['toShip'][page1-1]['RateDependantPer'] = e.target.value

                   return clone
                 })
               }}>
               </input>
               </span>
              </div>
            </Col>
          </Row>
        </Col>
        <Col style={{border:"1px solid black"}} xs="3" lg="3">
          <Row>
            <Col xs="12" lg="12">
              <div style={{float:'left', textAlign:'left', fontSize:12}}>
                This is to certify that the above-named articles are properly classified, described, packaged, marked and labeled, and are in proper condition for transportation according to the applicable regulations of the Department of Transportation
              </div>
              <div style={{textAlign:'left'}}>
              <span>
              Signature:
              <input className='fontfam' style={{width:200,border:"0px",borderBottom:"1px solid Black", outline:'none', background:"rgba(0,0,0,0)", textAlign:'center',fontFamily:"'Apple Chancery', sans-serif"}}
               value={obj['toShip'][page1-1]['certifySigniture']!=undefined?obj['toShip'][page1-1]['certifySigniture']:'Philip C. John'} 
               onChange={(e)=>{
                setObj((prevState)=>{
                  const clone = JSON.parse(JSON.stringify(prevState));
                  clone['toShip'][page1-1]['certifySigniture'] = e.target.value
                  return clone
                })
              }}>
              </input>
              </span>
              </div>
            </Col>
          </Row>
        </Col>
        <Col style={{border:"1px solid black"}} xs="3" lg="3">
            
          <div style={{float:'left', fontSize:12}}>Subject to Section 7 of the conditions, if this shipment is to be delivered to the consignee without recourse on the consignor, the consignor shall sign the following statement: The carrier shall not make delivery of this shipment without payment of freight and all other lawful charges
          </div>
          <div style={{textAlign:'left'}}>
          <span>
          Signature:
          <input style={{width:200,border:"0px",borderBottom:"1px solid Black", outline:'none', background:"rgba(0,0,0,0), textAlign:'center"}}
           value={obj['toShip'][page1-1]['Sec7Signiture']} 
           onChange={(e)=>{
            setObj((prevState)=>{
              const clone = JSON.parse(JSON.stringify(prevState));
              clone['toShip'][page1-1]['Sec7Signiture'] = e.target.value
              return clone
            })
          }}>
          </input>
          </span>
          </div>
          
        </Col>
        <Col style={{border:"1px solid black"}} xs="3" lg="3">
          <Row>
            <Col xs="12" lg="12">
          <div style={{float:'left', paddingBottom:10}}>
          
          <span>
          Total Charges $:
          <input style={{width:100,border:"0px",borderBottom:"1px solid Black", outline:'none', background:"rgba(0,0,0,0)"}}
           value={obj['toShip'][page1-1]['Total Charges']} 
           onChange={(e)=>{
            setObj((prevState)=>{
              const clone = JSON.parse(JSON.stringify(prevState));
              clone['toShip'][page1-1]['Total Charges'] = e.target.value
              return clone
            })
          }}>
          </input>
          </span>
          </div>
          </Col>
          </Row>
          <Row>
            <Col xs="6" lg="6">
              <div style={{width:'100%',textAlign:'left', fontSize:12}}>
              FREIGHT CHARGE
              </div>
              <div style={{width:'100%',textAlign:'left', fontSize:12}}>
              FREIGHT PREPAID 
              </div>
              <div style={{width:'100%',textAlign:'left', fontSize:12}}>
              Except when line at right is marked 
              </div>
            </Col>
            <Col xs="6" lg="6">
            <div style={{fontSize:12}}>
              Check line if charges are to be collect
            </div>
            <div>
              <input style={{width:50,border:"0px",borderBottom:"1px solid Black", outline:'none', background:"rgba(0,0,0,0)",textAlign:'center'}}
               value={obj['toShip'][page1-1]['Freight']=='Collect'?'X':obj['toShip'][page1-1]['ChargesCollect']} 
               onChange={(e)=>{
                setObj((prevState)=>{
                  const clone = JSON.parse(JSON.stringify(prevState));
                  clone['toShip'][page1-1]['ChargesCollect'] = e.target.value
                  return clone
                })
              }}/>
            </div>
            </Col>
          </Row>

        </Col>
      </Row>
      <Row>
        <Col style={{border:"1px solid black", fontSize:12}} lg='12'>
RECEIVED, subject to the classifications and tariffs in effect on the date of the issue of this Bill of Lading, the property described above in apparent good order, except as noted (contents and condition

on contents of packages unknown), marked, consigned, and destined as indicated above which said carrier (the word carrier being understood throughout this contract as meaning any person or

corporation in possession of the property under the contract) agrees to carry to its usual place of delivery at said destination, if on its route, otherwise to delver to another carrier on the route to said

destination. It is mutually agreed as to each carrier of all or any of, said property over all or any portion of said route to destination and as to each party at any time interested in all or any of said

property, that every service to be performed hereunder shall be subject to all the bill of lading terms and conditions in the governing classification on the date of shipment. Shipper hereby certifies that

he is familiar with all the bill of lading terms and conditions in the governing classification and the said terms and conditions are hereby agreed to by the shipper and accepted for himself and his

assigns.
        </Col>
      </Row>
      <Row>
        <Col style={{border:"1px solid black"}} xs="6" lg='6'>
          <div style={{float:'left'}}>
          <span>
          Shipper      
          <input style={{paddingLeft:10,width:300,border:"none", outline:'none', background:"rgba(0,0,0,0),textAlign:'center'"}}
           value={obj['toShip'][page1-1]['From Contact Person']} 
           onChange={(e)=>{
            setObj((prevState)=>{
              const clone = JSON.parse(JSON.stringify(prevState));
              clone['toShip'][page1-1]['From Contact Person'] = e.target.value
              return clone
            })
          }}>
          </input>
          </span>
          </div>
        </Col>
        <Col style={{border:"1px solid black"}} xs="6" lg='6'>
          <div style={{float:'left'}}>
            <span>
            Carrier      
          <input style={{paddingLeft:10,width:300,border:"none", outline:'none', background:"rgba(0,0,0,0),textAlign:'center'"}}
           value={obj['toShip'][page1-1]['Carrier']} 
           onChange={(e)=>{
            setObj((prevState)=>{
              const clone = JSON.parse(JSON.stringify(prevState));
              clone['toShip'][page1-1]['Carrier'] = e.target.value
              return clone
            })
          }}>
          </input>
          </span>
          </div>
        </Col>
      </Row>
      <Row>
        <Col style={{border:"1px solid black"}} xs="6" lg='6'>
          <div style={{float:'left'}}>
            <span>
            Per      
          <input style={{width:200,border:"0px", outline:'none', background:"rgba(0,0,0,0)",textAlign:'center'}}
           value={obj['toShip'][page1-1]['Per1']} 
           onChange={(e)=>{
            setObj((prevState)=>{
              const clone = JSON.parse(JSON.stringify(prevState));
              clone['toShip'][page1-1]['Per1'] = e.target.value
              return clone
            })
          }}>
          </input>
          </span>
          </div>
        </Col>
        <Col style={{border:"1px solid black"}} xs="6" lg='6'>
          <div style={{float:'left'}}>
           <span>
            Per      
          <input style={{width:200,border:"0px", outline:'none', background:"rgba(0,0,0,0)",textAlign:'center'}}
           value={obj['toShip'][page1-1]['Per2']} 
           onChange={(e)=>{
            setObj((prevState)=>{
              const clone = JSON.parse(JSON.stringify(prevState));
              clone['toShip'][page1-1]['Per2'] = e.target.value
              return clone
            })
          }}>
          </input>
          </span>
          </div>
        </Col>
      </Row>
      <Row>
      <Col style={{border:"1px solid black"}} xs="6" lg='6'>
          <div style={{float:'left'}}>
            
          </div>
        </Col>
        <Col style={{border:"1px solid black"}} xs="6" lg='6'>
          <div style={{float:'left'}}>
            <span>
            Date      
          <input style={{width:200,border:"0px", outline:'none', background:"rgba(0,0,0,0)",textAlign:'center'}}
           value={obj['toShip'][page1-1]['Ship By Date']} 
           onChange={(e)=>{
            setObj((prevState)=>{
              const clone = JSON.parse(JSON.stringify(prevState));
              clone['toShip'][page1-1]['Ship By Date'] = e.target.value
              return clone
            })
          }}>
          </input>
          </span>
          </div>
        </Col>
      </Row>
      <Row>
        <Col style={{}} xs="12" lg='12'>
          <div style={{float:'left'}}>
            *Mark with “X” to designate Hazardous Material as defined in Title 49 of the Code of Federal Regulation
            </div>
        </Col>
      </Row>
    </div>
      <Row style={{paddingBottom: 20}}>
      <Col xs="12" lg="3">
        <Button style={{ marginTop: 30}} onClick={save} >Save Order</Button>
      </Col>
      <Col xs="12" lg="3">
        <Button style={{ marginTop: 30}} onClick={multiClick} >Multi</Button>
      </Col>
      <Col xs="12" lg="3">
        <Button style={{ marginTop: 30}} onClick={()=>{setShowLabelModal(true)}} >Labels</Button>
      </Col>
      <Col xs="12" lg="3">
        <Button style={{ marginTop: 30}} onClick={()=>{print(obj['toShip'][page1-1]['Shipper No.']!=undefined?obj['toShip'][page1-1]['Shipper No.']:obj['toShip'].length>1?obj['Id']+'-'+page1:obj['Id'])}} >Print Bill of Lading</Button>
      </Col>

      </Row>
      

      {showMultiModal&&
        <Modal
          show={true}
          onHide={()=>{setShowMultiModal(false)}}
          backdrop="static"
          // keyboard={false}
        >
          <Modal.Header closeButton>
              <Modal.Title>Multi BOL</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            
            <SearchMulti obj={obj} setObj={setObj} userData={userData} db={db} searchLabel1={searchLabel1} setSearchLabel1={setSearchLabel1} page1={page1}/>
            
            
          </Modal.Body>

          <Modal.Footer>
            {multi.length<1?
            <div>
            </div>
            :
              <Button variant="primary" onClick={()=>{setShowMultiModal(false)}} >Confirm Multi BOL</Button>
            }
          </Modal.Footer>

        </Modal>
        }
        {showLabelModal&&
        <Modal
          show={true}
          onHide={()=>{setShowLabelModal(false)}}
          backdrop="static"
          // keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Labels</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Labels obj={obj} setObj={setObj} userData={userData} db={db} page1={page1} setSearchLabel={setSearchLabel} searchLabel={searchLabel} />
          </Modal.Body>
          <Modal.Footer>
              <Button variant="primary" onClick={()=>{setShowLabelModal(false);printLabels()}} >Print</Button>
          </Modal.Footer>
        </Modal>
        }

        <div ref={labelRef} style={{paddingTop:20,display:'flex', flexWrap:'wrap', flexDirection:'row'}}>

          {
            obj['toShip'][page1-1]!=undefined&&obj['toShip'][page1-1]['Labels']!=undefined&&
            obj['toShip'][page1-1]['Labels'].map((item1,index1)=>{
              {console.log(index1%6==0, index1)}
              {/*console.log(obj['toShip'][page1-1]['Labels'])*/}
              return(
                <div style={(index1+1)%6==0?{marginBottom:45,height:580,width:600,display:'flex',justifyContent:'center',alignItems:'center',textAlign:'left'}:{height:580,width:600,display:'flex',justifyContent:'center',alignItems:'center',textAlign:'left'}} >
                <div>
                
                {
                  item1['Address']!=undefined&&
                  item1['Address'].map((item2,index2)=>{
              {/*console.log(item2)*/}

                    return(
                      <div style={{paddingLeft:20}}>{item2}</div>
                      )
                  })
                }
                <div style={{paddingBottom:10}}/>
                {
                  item1['Parts']!=undefined&&
                  item1['Parts'].map((item2,index2)=>{
              {/*console.log(item2)*/}

                    return(
                      <div>{item2}</div>
                      )
                  })
                }
                
                </div>
                </div>
                )

            })
          }
        </div>



          
                  
               
          

       
    </div>

  );
}

export default BillOfLading