import { useState, useEffect } from 'react'
import { Container, Row, Col, Button } from "react-bootstrap";


const Form66 = ({name,setObj,obj,index,index1,first,page1,second,third,fourth,page})=> {
  useEffect(()=>{
    console.log(obj)
    console.log(page1)
    console.log(index)  
    // console.log(obj['toShip'][page1-1]['Parts'][index]['Part Id'])  
    console.log(obj['Parts'][index]['Part Id'])  
  },[])
  const [desc, setDesc] = useState(false)
  const [showInput,setShowInput] = useState(false)


  useEffect(()=>{
    // if(obj['toShip'].length>1){
      if(obj['toShip'][page1-1]!=undefined&&obj['toShip'][page1-1]['Parts'][index]!=undefined){

      if(obj['toShip'][page1-1]['Parts'][index]['Customer Part Id']!=''&&obj['toShip'][page1-1]['Parts'][index]['Job Id']!=undefined&&obj['toShip'][page1-1]['Parts'][index]['Job Id']!=''){
        setDesc('Part # '+obj['toShip'][page1-1]['Parts'][index]['Customer Part Id'] + ', FBIns # '+obj['toShip'][page1-1]['Parts'][index]['Part Id']+', Job # '+obj['toShip'][page1-1]['Parts'][index]['Job Id'])
      }else if(obj['toShip'][page1-1]['Parts'][index]['Customer Part Id']!=''){
        setDesc('Part # '+obj['toShip'][page1-1]['Parts'][index]['Customer Part Id'] + ', FBIns # '+obj['toShip'][page1-1]['Parts'][index]['Part Id'])
      }else if(obj['toShip'][page1-1]['Parts'][index]['Job Id']!=''){
        setDesc('Part # '+obj['toShip'][page1-1]['Parts'][index]['Part Id']+', Job # '+obj['toShip'][page1-1]['Parts'][index]['Job Id'])
      }else{
        setDesc('Part # '+obj['toShip'][page1-1]['Parts'][index]['Part Id'])
      }
    }else{
      if(obj['Parts'][index]['Customer Part Id']!=''&&obj['Parts'][index]['Job Id']!=undefined&&obj['Parts'][index]['Job Id']!=''){
        setDesc('Part # '+obj['Parts'][index]['Customer Part Id'] + ', FBIns # '+obj['Parts'][index]['Part Id']+', Job # '+obj['Parts'][index]['Job Id'])
      }else if(obj['Parts'][index]['Customer Part Id']!=''){
        setDesc('Part # '+obj['Parts'][index]['Customer Part Id'] + ', FBIns # '+obj['Parts'][index]['Part Id'])
      }else if(obj['Parts'][index]['Job Id']!=''){
        setDesc('Part # '+obj['Parts'][index]['Part Id']+', Job # '+obj['Parts'][index]['Job Id'])
      }else{
        setDesc('Part # '+obj['Parts'][index]['Part Id'])
      }
    }
      // }
    
    if(obj['toShip'].length<=1&&obj['toShip'][0]['Parts']['Part Id']==''){

        if(index1!=undefined){
          setObj((prev=>{
           const clone = JSON.parse(JSON.stringify(prev))
              console.log(clone)
              clone['toShip'][page1-1]['multi'][index1]['toShip'][page1]['Parts']=obj['Parts']
              console.log(clone)
            return clone
          }))
        }else{
          setObj((prev=>{
            const clone = JSON.parse(JSON.stringify(prev))
              console.log(clone)
              clone['toShip'][page1]['Parts']=obj['Parts']
              console.log(clone)
            return clone
          }))
        }
      
      }

  },[obj,page1])

  return (
    <div>
      {
        first?
    <Row>
        <Col style={{border:"1px solid black"}} xs="1" lg="1">
          <div style={{height:25}}>
          <input style={{width:'100%',border:"none",outline:'none', background:"rgba(0,0,0,0)",textAlign:'center'}}
           value={obj['toShip'][page1-1]!=undefined&&obj['toShip'][page1-1]['# Units']!=undefined?obj['toShip'][page1-1]['# Units']:''} 
           onChange={(e)=>{
            setObj((prevState)=>{
              const clone = JSON.parse(JSON.stringify(prevState));
              if(index1!=undefined){
                clone['toShip'][page1-1]['multi'][index1]['toShip'][page-1]['# Units'] = e.target.value

              }else{
                clone['toShip'][page1-1]['# Units'] = e.target.value

              }
              return clone
            })
          }}/>
          </div>
        </Col>
        <Col style={{border:"1px solid black"}} xs="1" lg="1">
          <div style={{height:25}}>
          <input style={{width:'100%',border:"none",outline:'none', background:"rgba(0,0,0,0)",textAlign:'center'}}
           value={obj['toShip'][page1-1]!=undefined&&obj['toShip'][page1-1]['HM']!=undefined?obj['toShip'][page1-1]['HM']:''} 
           onChange={(e)=>{
            setObj((prevState)=>{
              const clone = JSON.parse(JSON.stringify(prevState));
              if(index1!=undefined){
                clone['toShip'][page1-1]['multi'][index1]['toShip'][page-1]['HM'] = e.target.value

              }else{
                clone['toShip'][page1-1]['HM'] = e.target.value

              }
              return clone
            })
          }}/>
          </div>
        </Col>
        <Col style={{border:"1px solid black"}} xs="7" lg="7">

          <div>
          <input style={{width:'100%',border:"none",outline:'none', background:"rgba(0,0,0,0)",textAlign:'center'}}
           value={obj['toShip'][page1-1]!=undefined&&obj['toShip'][page1-1]['desc']!=undefined?obj['toShip'][page1-1]['desc']:'Bushing Assembly, Item 104650, Class 60'} 
           onChange={(e)=>{
            setObj((prevState)=>{
              const clone = JSON.parse(JSON.stringify(prevState));
              if(index1!=undefined){
                clone['toShip'][page1-1]['multi'][index1]['toShip'][page-1]['desc'] = e.target.value

              }else{
                clone['toShip'][page1-1]['desc'] = e.target.value

              }
              return clone
            })
          }}/>        
          </div>
        </Col>
        <Col style={{border:"1px solid black"}} xs="1" lg="1">
          <div style={{height:25}}>
          <input style={{width:'100%',border:"none",outline:'none', background:"rgba(0,0,0,0)",textAlign:'center'}}
           value={obj['toShip'][page1-1]!=undefined&&obj['toShip'][page1-1]['Weight']!=undefined?obj['toShip'][page1-1]['Weight']:''} 
           onChange={(e)=>{
            setObj((prevState)=>{
              const clone = JSON.parse(JSON.stringify(prevState));
              if(index1!=undefined){
                clone['toShip'][page1-1]['multi'][index1]['toShip'][page-1]['Weight'] = e.target.value

              }else{
                clone['toShip'][page1-1]['Weight'] = e.target.value

              }
              return clone
            })
          }}/>
          </div>
        </Col>
        <Col style={{border:"1px solid black"}} xs="1" lg="1">
          <div style={{height:25}}>
          <input style={{width:'100%',border:"none",outline:'none', background:"rgba(0,0,0,0)",textAlign:'center'}}
           value={obj['toShip'][page1-1]!=undefined&&obj['toShip'][page1-1]['Rate']!=undefined?obj['toShip'][page1-1]['Rate']:''} 
           onChange={(e)=>{
            setObj((prevState)=>{
              const clone = JSON.parse(JSON.stringify(prevState));
              if(index1!=undefined){
                clone['toShip'][page1-1]['multi'][index1]['toShip'][page-1]['Rate'] = e.target.value

              }else{
                clone['toShip'][page1-1]['Rate'] = e.target.value

              }
              return clone
            })
          }}/>
          </div>
        </Col>
        <Col style={{border:"1px solid black"}} xs="1" lg="1">
          <div style={{height:25}}>
          <input style={{width:'100%',border:"none",outline:'none', background:"rgba(0,0,0,0)",textAlign:'center'}}
           value={obj['toShip'][page1-1]!=undefined&&obj['toShip'][page1-1]['Charges']!=undefined?obj['toShip'][page1-1]['Charges']:''} 
           onChange={(e)=>{
            setObj((prevState)=>{
              const clone = JSON.parse(JSON.stringify(prevState));
              if(index1!=undefined){
                clone['toShip'][page1-1]['multi'][index1]['toShip'][page-1]['Charges'] = e.target.value

              }else{
                clone['toShip'][page1-1]['Charges'] = e.target.value

              }
              return clone
            })
          }}/>
          </div>
        </Col>
      </Row>
      :second?
    <Row>
        <Col style={{border:"1px solid black"}} xs="1" lg="1">
          <div style={{height:25}}>
          <input style={{width:'100%',border:"none",outline:'none', background:"rgba(0,0,0,0)",textAlign:'center'}}
           value={obj['toShip'][page1-1]!=undefined&&obj['toShip'][page1-1]['# Units1']!=undefined?obj['toShip'][page1-1]['# Units1']:''} 
           onChange={(e)=>{
            setObj((prevState)=>{
              const clone = JSON.parse(JSON.stringify(prevState));
              if(index1!=undefined){
                clone['toShip'][page1-1]['multi'][index1]['toShip'][page-1]['# Units1'] = e.target.value

              }else{
                clone['toShip'][page1-1]['# Units1'] = e.target.value

              }
              return clone
            })
          }}/>
          </div>
        </Col>
        <Col style={{border:"1px solid black"}} xs="1" lg="1">
          <div style={{height:25}}>
          <input style={{width:'100%',border:"none",outline:'none', background:"rgba(0,0,0,0)",textAlign:'center'}}
           value={obj['toShip'][page1-1]!=undefined&&obj['toShip'][page1-1]['HM1']!=undefined?obj['toShip'][page1-1]['HM1']:''} 
           onChange={(e)=>{
            setObj((prevState)=>{
              const clone = JSON.parse(JSON.stringify(prevState));
              if(index1!=undefined){
                clone['toShip'][page1-1]['multi'][index1]['toShip'][page-1]['HM1'] = e.target.value

              }else{
                clone['toShip'][page1-1]['HM1'] = e.target.value

              }
              return clone
            })
          }}/>
          </div>
        </Col>
        <Col style={{border:"1px solid black"}} xs="7" lg="7">

          <div>
            PO # {obj['Customer PO Id']}       
          </div>
        </Col>
        <Col style={{border:"1px solid black"}} xs="1" lg="1">
          <div style={{height:25}}>
          <input style={{width:'100%',border:"none",outline:'none', background:"rgba(0,0,0,0)",textAlign:'center'}}
           value={obj['toShip'][page1-1]!=undefined&&obj['toShip'][page1-1]['Weight1']!=undefined?obj['toShip'][page1-1]['Weight1']:''} 
           onChange={(e)=>{
            setObj((prevState)=>{
              const clone = JSON.parse(JSON.stringify(prevState));
              if(index1!=undefined){
                clone['toShip'][page1-1]['multi'][index1]['toShip'][page-1]['Weight1'] = e.target.value

              }else{
                clone['toShip'][page1-1]['Weight1'] = e.target.value

              }
              return clone
            })
          }}/>
          </div>
        </Col>
        <Col style={{border:"1px solid black"}} xs="1" lg="1">
          <div style={{height:25}}>
          <input style={{width:'100%',border:"none",outline:'none', background:"rgba(0,0,0,0)",textAlign:'center'}}
           value={obj['toShip'][page1-1]!=undefined&&obj['toShip'][page1-1]['Rate1']!=undefined?obj['toShip'][page1-1]['Rate1']:''} 
           onChange={(e)=>{
            setObj((prevState)=>{
              const clone = JSON.parse(JSON.stringify(prevState));
              if(index1!=undefined){
                clone['toShip'][page1-1]['multi'][index1]['toShip'][page-1]['Rate1'] = e.target.value

              }else{
                clone['toShip'][page1-1]['Rate1'] = e.target.value

              }
              return clone
            })
          }}/>
          </div>
        </Col>
        <Col style={{border:"1px solid black"}} xs="1" lg="1">
          <div style={{height:25}}>
          <input style={{width:'100%',border:"none",outline:'none', background:"rgba(0,0,0,0)",textAlign:'center'}}
           value={obj['toShip'][page1-1]!=undefined&&obj['toShip'][page1-1]['Charges1']!=undefined?obj['toShip'][page1-1]['Charges1']:''} 
           onChange={(e)=>{
            setObj((prevState)=>{
              const clone = JSON.parse(JSON.stringify(prevState));
              if(index1!=undefined){
                clone['toShip'][page1-1]['multi'][index1]['toShip'][page-1]['Charges1'] = e.target.value

              }else{
                clone['toShip'][page1-1]['Charges1'] = e.target.value

              }
              return clone
            })
          }}/>
          </div>
        </Col>
      </Row>
      :third?
    <Row>
        <Col style={{border:"1px solid black"}} xs="1" lg="1">
          <div style={{height:25}}>
          <input style={{width:'100%',border:"none",outline:'none', background:"rgba(0,0,0,0)",textAlign:'center'}}
           value={obj['toShip'][page1-1]!=undefined&&obj['toShip'][page1-1]['Parts'][index]!=undefined&&obj['toShip'][page1-1]['Parts'][index]['# Units2']!=undefined?obj['toShip'][page1-1]['Parts'][index]['# Units2']:''} 
           onChange={(e)=>{
            setObj((prevState)=>{
              const clone = JSON.parse(JSON.stringify(prevState));
              if(index1!=undefined){
                clone['toShip'][page1-1]['multi'][index1]['toShip'][page-1]['Parts'][index]['# Units2'] = e.target.value

              }else{
                clone['toShip'][page1-1]['Parts'][index]['# Units2'] = e.target.value

              }
              return clone
            })
          }}/>
          </div>
        </Col>
        <Col style={{border:"1px solid black"}} xs="1" lg="1">
          <div style={{height:25}}>
          <input style={{width:'100%',border:"none",outline:'none', background:"rgba(0,0,0,0)",textAlign:'center'}}
           value={obj['toShip'][page1-1]!=undefined&&obj['toShip'][page1-1]['Parts'][index]!=undefined&&obj['toShip'][page1-1]['Parts'][index]['HM2']!=undefined?obj['toShip'][page1-1]['Parts'][index]['HM2']:''} 
           onChange={(e)=>{
            setObj((prevState)=>{
              const clone = JSON.parse(JSON.stringify(prevState));
              if(index1!=undefined){
                clone['toShip'][page1-1]['multi'][index1]['toShip'][page-1]['Parts'][index]['HM2'] = e.target.value

              }else{
                clone['toShip'][page1-1]['Parts'][index]['HM2'] = e.target.value

              }
              return clone
            })
          }}/>
          </div>
        </Col>
        <Col onClick={()=>{setShowInput(true)}} style={{border:"1px solid black"}} xs="7" lg="7">

          <div>
            {
              showInput?
            <input onBlur={()=>{setShowInput(false)}} style={{width:'100%',border:"none",outline:'none', background:"rgba(0,0,0,0)",textAlign:'center'}}
             value={obj['toShip'][page1-1]!=undefined&&obj['toShip'][page1-1]['Parts'][index]!=undefined&&obj['toShip'][page1-1]['Parts'][index]['Description2']!=undefined?obj['toShip'][page1-1]['Parts'][index]['Description2']:''} 
             onChange={(e)=>{
              setObj((prevState)=>{
                const clone = JSON.parse(JSON.stringify(prevState));
                if(index1!=undefined){
                  clone['toShip'][page1-1]['multi'][index1]['toShip'][page-1]['Parts'][index]['Description2'] = e.target.value

                }else{
                  clone['toShip'][page1-1]['Parts'][index]['Description2'] = e.target.value

                }
                return clone
              })
            }}/>
             :  
             <span>{obj['toShip'][page1-1]['Parts'][index]!=undefined&&obj['toShip'][page1-1]['Parts'][index]['Description2']!=undefined?obj['toShip'][page1-1]['Parts'][index]['Description2']:''}</span>    
            }
          </div>
        </Col>
        <Col style={{border:"1px solid black"}} xs="1" lg="1">
          <div style={{height:25}}>
          <input style={{width:'100%',border:"none",outline:'none', background:"rgba(0,0,0,0)",textAlign:'center'}}
           value={obj['toShip'][page1-1]!=undefined&&obj['toShip'][page1-1]['Parts'][index]!=undefined&&obj['toShip'][page1-1]['Parts'][index]['Weight2']!=undefined?obj['toShip'][page1-1]['Parts'][index]['Weight2']:''} 
           onChange={(e)=>{
            setObj((prevState)=>{
              const clone = JSON.parse(JSON.stringify(prevState));
              if(index1!=undefined){
                clone['toShip'][page1-1]['multi'][index1]['toShip'][page-1]['Parts'][index]['Weight2'] = e.target.value

              }else{
                clone['toShip'][page1-1]['Parts'][index]['Weight2'] = e.target.value

              }
              return clone
            })
          }}/>
          </div>
        </Col>
        <Col style={{border:"1px solid black"}} xs="1" lg="1">
          <div style={{height:25}}>
          <input style={{width:'100%',border:"none",outline:'none', background:"rgba(0,0,0,0)",textAlign:'center'}}
           value={obj['toShip'][page1-1]!=undefined&&obj['toShip'][page1-1]['Parts'][index]!=undefined&&obj['toShip'][page1-1]['Parts'][index]['Rate2']!=undefined?obj['toShip'][page1-1]['Parts'][index]['Rate2']:''} 
           onChange={(e)=>{
            setObj((prevState)=>{
              const clone = JSON.parse(JSON.stringify(prevState));
              if(index1!=undefined){
                clone['toShip'][page1-1]['multi'][index1]['toShip'][page-1]['Parts'][index]['Rate2'] = e.target.value

              }else{
                clone['toShip'][page1-1]['Parts'][index]['Rate2'] = e.target.value

              }
              return clone
            })
          }}/>
          </div>
        </Col>
        <Col style={{border:"1px solid black"}} xs="1" lg="1">
          <div style={{height:25}}>
          <input style={{width:'100%',border:"none",outline:'none', background:"rgba(0,0,0,0)",textAlign:'center'}}
           value={obj['toShip'][page1-1]!=undefined&&obj['toShip'][page1-1]['Parts'][index]!=undefined&&obj['toShip'][page1-1]['Parts'][index]['Charges2']!=undefined?obj['toShip'][page1-1]['Parts'][index]['Charges2']:''} 
           onChange={(e)=>{
            setObj((prevState)=>{
              const clone = JSON.parse(JSON.stringify(prevState));
              if(index1!=undefined){
                clone['toShip'][page1-1]['multi'][index1]['toShip'][page-1]['Parts'][index]['Charges2'] = e.target.value

              }else{
                clone['toShip'][page1-1]['Parts'][index]['Charges2'] = e.target.value

              }
              return clone
            })
          }}/>
          </div>
        </Col>
      </Row>
      :fourth?
    <Row>
        <Col style={{border:"1px solid black"}} xs="1" lg="1">
          <div style={{height:25}}>
          <input style={{width:'100%',border:"none",outline:'none', background:"rgba(0,0,0,0)",textAlign:'center'}}
           value={obj['toShip'][page1-1]!=undefined&&obj['toShip'][page1-1]['Parts'][index]!=undefined&&obj['toShip'][page1-1]['Parts'][index]['# Units3']!=undefined?obj['toShip'][page1-1]['Parts'][index]['# Units3']:''} 
           onChange={(e)=>{
            setObj((prevState)=>{
              const clone = JSON.parse(JSON.stringify(prevState));
              if(index1!=undefined){
                clone['toShip'][page1-1]['multi'][index1]['toShip'][page-1]['Parts'][index]['# Units3'] = e.target.value

              }else{
                clone['toShip'][page1-1]['Parts'][index]['# Units3'] = e.target.value

              }
              return clone
            })
          }}/>
          </div>
        </Col>
        <Col style={{border:"1px solid black"}} xs="1" lg="1">
          <div style={{height:25}}>
          <input style={{width:'100%',border:"none",outline:'none', background:"rgba(0,0,0,0)",textAlign:'center'}}
           value={obj['toShip'][page1-1]!=undefined&&obj['toShip'][page1-1]['Parts'][index]!=undefined&&obj['toShip'][page1-1]['Parts'][index]['HM3']!=undefined?obj['toShip'][page1-1]['Parts'][index]['HM3']:''} 
           onChange={(e)=>{
            setObj((prevState)=>{
              const clone = JSON.parse(JSON.stringify(prevState));
              if(index1!=undefined){
                clone['toShip'][page1-1]['multi'][index1]['toShip'][page-1]['Parts'][index]['HM3'] = e.target.value

              }else{
                clone['toShip'][page1-1]['Parts'][index]['HM3'] = e.target.value

              }
              return clone
            })
          }}/>
          </div>
        </Col>
        <Col onClick={()=>{setShowInput(true)}} style={{border:"1px solid black"}} xs="7" lg="7">

          <div>
            {
              showInput?
            <input onBlur={()=>{setShowInput(false)}} style={{width:'100%',border:"none",outline:'none', background:"rgba(0,0,0,0)",textAlign:'center'}}
             value={obj['toShip'][page1-1]!=undefined&&obj['toShip'][page1-1]['Parts'][index]!=undefined&&obj['toShip'][page1-1]['Parts'][index]['Description3']!=undefined?obj['toShip'][page1-1]['Parts'][index]['Description3']:''} 
             onChange={(e)=>{
              setObj((prevState)=>{
                const clone = JSON.parse(JSON.stringify(prevState));
                if(index1!=undefined){
                  clone['toShip'][page1-1]['multi'][index1]['toShip'][page-1]['Parts'][index]['Description3'] = e.target.value

                }else{
                  clone['toShip'][page1-1]['Parts'][index]['Description3'] = e.target.value

                }
                return clone
              })
            }}/>
             :  
             <span>{obj['toShip'][page1-1]['Parts'][index]!=undefined&&obj['toShip'][page1-1]['Parts'][index]['Description3']!=undefined?obj['toShip'][page1-1]['Parts'][index]['Description3']:''}</span>    
            }
          </div>
        </Col>
        <Col style={{border:"1px solid black"}} xs="1" lg="1">
          <div style={{height:25}}>
          <input style={{width:'100%',border:"none",outline:'none', background:"rgba(0,0,0,0)",textAlign:'center'}}
           value={obj['toShip'][page1-1]!=undefined&&obj['toShip'][page1-1]['Parts'][index]!=undefined&&obj['toShip'][page1-1]['Parts'][index]['Weight3']!=undefined?obj['toShip'][page1-1]['Parts'][index]['Weight3']:''} 
           onChange={(e)=>{
            setObj((prevState)=>{
              const clone = JSON.parse(JSON.stringify(prevState));
              if(index1!=undefined){
                clone['toShip'][page1-1]['multi'][index1]['toShip'][page-1]['Parts'][index]['Weight3'] = e.target.value

              }else{
                clone['toShip'][page1-1]['Parts'][index]['Weight3'] = e.target.value

              }
              return clone
            })
          }}/>
          </div>
        </Col>
        <Col style={{border:"1px solid black"}} xs="1" lg="1">
          <div style={{height:25}}>
          <input style={{width:'100%',border:"none",outline:'none', background:"rgba(0,0,0,0)",textAlign:'center'}}
           value={obj['toShip'][page1-1]!=undefined&&obj['toShip'][page1-1]['Parts'][index]!=undefined&&obj['toShip'][page1-1]['Parts'][index]['Rate3']!=undefined?obj['toShip'][page1-1]['Parts'][index]['Rate3']:''} 
           onChange={(e)=>{
            setObj((prevState)=>{
              const clone = JSON.parse(JSON.stringify(prevState));
              if(index1!=undefined){
                clone['toShip'][page1-1]['multi'][index1]['toShip'][page-1]['Parts'][index]['Rate3'] = e.target.value

              }else{
                clone['toShip'][page1-1]['Parts'][index]['Rate3'] = e.target.value

              }
              return clone
            })
          }}/>
          </div>
        </Col>
        <Col style={{border:"1px solid black"}} xs="1" lg="1">
          <div style={{height:25}}>
          <input style={{width:'100%',border:"none",outline:'none', background:"rgba(0,0,0,0)",textAlign:'center'}}
           value={obj['toShip'][page1-1]!=undefined&&obj['toShip'][page1-1]['Parts'][index]!=undefined&&obj['toShip'][page1-1]['Parts'][index]['Charges3']!=undefined?obj['toShip'][page1-1]['Parts'][index]['Charges3']:''} 
           onChange={(e)=>{
            setObj((prevState)=>{
              const clone = JSON.parse(JSON.stringify(prevState));
              if(index1!=undefined){
                clone['toShip'][page1-1]['multi'][index1]['toShip'][page-1]['Parts'][index]['Charges3'] = e.target.value

              }else{
                clone['toShip'][page1-1]['Parts'][index]['Charges3'] = e.target.value

              }
              return clone
            })
          }}/>
          </div>
        </Col>
      </Row>
      :
        obj['Parts'][index]!=undefined&&obj['Parts'][index]['Part Id']!=''&&
      <div>
        <Row>
          <Col style={{border:"1px solid black"}} xs="1" lg="1">
          <div style={{height:25}}>
          <input style={{width:'100%',border:"none",outline:'none', background:"rgba(0,0,0,0)",textAlign:'center'}}
           value={obj['toShip'][page1-1]!=undefined&&obj['toShip'][page1-1]['Parts'][index]!=undefined&&obj['toShip'][page1-1]['Parts'][index]!=undefined&&obj['toShip'][page1-1]['Parts'][index]['# Units1']!=undefined?obj['toShip'][page1-1]['Parts'][index]['# Units1']:''} 
           onChange={(e)=>{
            setObj((prevState)=>{
              const clone = JSON.parse(JSON.stringify(prevState));
              if(index1!=undefined){
                clone['toShip'][page1-1]['multi'][index1]['toShip'][page-1]['Parts'][index]['# Units1'] = e.target.value

              }else{
                clone['toShip'][page1-1]['Parts'][index]['# Units1'] = e.target.value

              }
              return clone
            })
          }}/>
          </div>
        </Col>
        <Col style={{border:"1px solid black"}} xs="1" lg="1">
          <div style={{height:25}}>
          <input style={{width:'100%',border:"none",outline:'none', background:"rgba(0,0,0,0)",textAlign:'center'}}
           value={obj['toShip'][page1-1]!=undefined&&obj['toShip'][page1-1]['Parts'][index]!=undefined&&obj['toShip'][page1-1]['Parts'][index]!=undefined&&obj['toShip'][page1-1]['Parts'][index]['HM1']!=undefined?obj['toShip'][page1-1]['Parts'][index]['HM1']:''} 
           onChange={(e)=>{
            setObj((prevState)=>{
              const clone = JSON.parse(JSON.stringify(prevState));
              if(index1!=undefined){
                clone['toShip'][page1-1]['multi'][index1]['toShip'][page-1]['Parts'][index]['HM1'] = e.target.value

              }else{
                clone['toShip'][page1-1]['Parts'][index]['HM1'] = e.target.value

              }
              return clone
            })
          }}/>
          </div>
        </Col>
        <Col style={{border:"1px solid black"}} xs="7" lg="7">

            
            <div style={{height:25, overflowX:'hidden', overflowY:'hidden'}}>{desc}</div>
           
            
          </Col>
          <Col style={{border:"1px solid black"}} xs="1" lg="1">
          <div style={{height:25}}>
          <input style={{width:'100%',border:"none",outline:'none', background:"rgba(0,0,0,0)",textAlign:'center'}}
           value={obj['toShip'][page1-1]!=undefined&&obj['toShip'][page1-1]['Parts'][index]!=undefined&&obj['toShip'][page1-1]['Parts'][index]!=undefined&&obj['toShip'][page1-1]['Parts'][index]['Weight1']!=undefined?obj['toShip'][page1-1]['Parts'][index]['Weight1']:''} 
           onChange={(e)=>{
            setObj((prevState)=>{
              const clone = JSON.parse(JSON.stringify(prevState));
              if(index1!=undefined){
                clone['toShip'][page1-1]['multi'][index1]['toShip'][page-1]['Parts'][index]['Weight1'] = e.target.value

              }else{
                clone['toShip'][page1-1]['Parts'][index]['Weight1'] = e.target.value

              }
              return clone
            })
          }}/>
          </div>
        </Col>
        <Col style={{border:"1px solid black"}} xs="1" lg="1">
          <div style={{height:25}}>
          <input style={{width:'100%',border:"none",outline:'none', background:"rgba(0,0,0,0)",textAlign:'center'}}
           value={obj['toShip'][page1-1]!=undefined&&obj['toShip'][page1-1]['Parts'][index]!=undefined&&obj['toShip'][page1-1]['Parts'][index]!=undefined&&obj['toShip'][page1-1]['Parts'][index]['Rate1']!=undefined?obj['toShip'][page1-1]['Parts'][index]['Rate1']:''} 
           onChange={(e)=>{
            setObj((prevState)=>{
              const clone = JSON.parse(JSON.stringify(prevState));
              if(index1!=undefined){
                clone['toShip'][page1-1]['multi'][index1]['toShip'][page-1]['Parts'][index]['Rate1'] = e.target.value

              }else{
                clone['toShip'][page1-1]['Parts'][index]['Rate1'] = e.target.value

              }
              return clone
            })
          }}/>
          </div>
        </Col>
        <Col style={{border:"1px solid black"}} xs="1" lg="1">
          <div style={{height:25}}>
          <input style={{width:'100%',border:"none",outline:'none', background:"rgba(0,0,0,0)",textAlign:'center'}}
           value={obj['toShip'][page1-1]!=undefined&&obj['toShip'][page1-1]['Parts'][index]!=undefined&&obj['toShip'][page1-1]['Parts'][index]!=undefined&&obj['toShip'][page1-1]['Parts'][index]['Charges1']!=undefined?obj['toShip'][page1-1]['Parts'][index]['Charges1']:''} 
           onChange={(e)=>{
            setObj((prevState)=>{
              const clone = JSON.parse(JSON.stringify(prevState));
              if(index1!=undefined){
                clone['toShip'][page1-1]['multi'][index1]['toShip'][page-1]['Parts'][index]['Charges1'] = e.target.value

              }else{
                clone['toShip'][page1-1]['Parts'][index]['Charges1'] = e.target.value

              }
              return clone
            })
          }}/>
          </div>
        </Col>
        </Row>
      <Row>
        <Col style={{border:"1px solid black"}} xs="1" lg="1">
          <div style={{}}>
          <input style={{width:'100%',border:"none",outline:'none', background:"rgba(0,0,0,0)",textAlign:'center'}}
           value={obj['toShip'][page1-1]!=undefined&&obj['toShip'][page1-1]['Parts'][index]!=undefined&&obj['toShip'][page1-1]['Parts'][index]!=undefined&&obj['toShip'][page1-1]['Parts'][index]['# Units']!=undefined?obj['toShip'][page1-1]['Parts'][index]['# Units']:''} 
           onChange={(e)=>{
            setObj((prevState)=>{
              const clone = JSON.parse(JSON.stringify(prevState));
              if(index1!=undefined){
                clone['toShip'][page1-1]['multi'][index1]['toShip'][page-1]['Parts'][index]['# Units'] = e.target.value

              }else{
                clone['toShip'][page1-1]['Parts'][index]['# Units'] = e.target.value

              }
              return clone
            })
          }}/>
          </div>
        </Col>
        <Col style={{border:"1px solid black"}} xs="1" lg="1">
          <div style={{}}>
          <input style={{width:'100%',border:"none",outline:'none', background:"rgba(0,0,0,0)",textAlign:'center'}}
           value={obj['toShip'][page1-1]!=undefined&&obj['toShip'][page1-1]['Parts'][index]!=undefined&&obj['toShip'][page1-1]['Parts'][index]!=undefined&&obj['toShip'][page1-1]['Parts'][index]['HM']!=undefined?obj['toShip'][page1-1]['Parts'][index]['HM']:''} 
           onChange={(e)=>{
            setObj((prevState)=>{
              const clone = JSON.parse(JSON.stringify(prevState));
              if(index1!=undefined){
                clone['toShip'][page1-1]['multi'][index1]['toShip'][page-1]['Parts'][index]['HM'] = e.target.value

              }else{
                clone['toShip'][page1-1]['Parts'][index]['HM'] = e.target.value

              }
              return clone
            })
          }}/>
          </div>
        </Col>
        <Col style={{border:"1px solid black"}} xs="7" lg="7">

          <div>
          {
            obj['toShip'].length>1?obj['toShip'][page1-1]['Parts'][index]['Part Id']!='Shipping & Handling'&&obj['toShip'][page1-1]['Parts'][index]['Part Id']!=''?
          <div style={{overflowX:'hidden',}}>{'Qty. '+obj['toShip'][page1-1]['Parts'][index]['Quantity']+' , '+obj['toShip'][page1-1]['Parts'][index]['Description']}</div>
          :''
          :obj['Parts'][index]['Part Id']!='Shipping & Handling'&&obj['Parts'][index]['Part Id']!='' ?
          <div style={{overflowX:'hidden'}}>{'Qty. '+obj['Parts'][index]['Quantity']+' , '+obj['Parts'][index]['Description']}</div>
          :''
          }
        
          </div>
        </Col>
        <Col style={{border:"1px solid black"}} xs="1" lg="1">
          <div style={{}}>
          <input style={{width:'100%',border:"none",outline:'none', background:"rgba(0,0,0,0)",textAlign:'center'}}
           value={obj['toShip'][page1-1]!=undefined&&obj['toShip'][page1-1]['Parts'][index]!=undefined&&obj['toShip'][page1-1]['Parts'][index]!=undefined&&obj['toShip'][page1-1]['Parts'][index]['Weight']!=undefined?obj['toShip'][page1-1]['Parts'][index]['Weight']:''} 
           onChange={(e)=>{
            setObj((prevState)=>{
              const clone = JSON.parse(JSON.stringify(prevState));
              if(index1!=undefined){
                clone['toShip'][page1-1]['multi'][index1]['toShip'][page-1]['Parts'][index]['Weight'] = e.target.value

              }else{
                clone['toShip'][page1-1]['Parts'][index]['Weight'] = e.target.value

              }
              return clone
            })
          }}/>
          </div>
        </Col>
        <Col style={{border:"1px solid black"}} xs="1" lg="1">
          <div style={{}}>
          <input style={{width:'100%',border:"none",outline:'none', background:"rgba(0,0,0,0)",textAlign:'center'}}
           value={obj['toShip'][page1-1]!=undefined&&obj['toShip'][page1-1]['Parts'][index]!=undefined&&obj['toShip'][page1-1]['Parts'][index]!=undefined&&obj['toShip'][page1-1]['Parts'][index]['Rate']!=undefined?obj['toShip'][page1-1]['Parts'][index]['Rate']:''} 
           onChange={(e)=>{
            setObj((prevState)=>{
              const clone = JSON.parse(JSON.stringify(prevState));
              if(index1!=undefined){
                clone['toShip'][page1-1]['multi'][index1]['toShip'][page-1]['Parts'][index]['Rate'] = e.target.value

              }else{
                clone['toShip'][page1-1]['Parts'][index]['Rate'] = e.target.value

              }
              return clone
            })
          }}/>
          </div>
        </Col>
        <Col style={{border:"1px solid black"}} xs="1" lg="1">
          <div style={{}}>
          <input style={{width:'100%',border:"none",outline:'none', background:"rgba(0,0,0,0)",textAlign:'center'}}
           value={obj['toShip'][page1-1]!=undefined&&obj['toShip'][page1-1]['Parts'][index]!=undefined&&obj['toShip'][page1-1]['Parts'][index]!=undefined&&obj['toShip'][page1-1]['Parts'][index]['Charges']!=undefined?obj['toShip'][page1-1]['Parts'][index]['Charges']:''} 
           onChange={(e)=>{
            setObj((prevState)=>{
              const clone = JSON.parse(JSON.stringify(prevState));
              if(index1!=undefined){
                clone['toShip'][page1-1]['multi'][index1]['toShip'][page-1]['Parts'][index]['Charges'] = e.target.value

              }else{
                clone['toShip'][page1-1]['Parts'][index]['Charges'] = e.target.value

              }
              return clone
            })
          }}/>
          </div>
        </Col></Row>
      </div>
      }
    </div>
  );
}

export default Form66
      // :
      //   <Row>
      //   <Col style={{border:"1px solid black"}} xs="1" lg="1">
      //     <div style={{height:25}}></div>
      //   </Col>
      //   <Col style={{border:"1px solid black"}} xs="1" lg="1">
      //     <div style={{height:25}}></div>
      //   </Col>
      //   <Col style={{border:"1px solid black"}} xs="7" lg="7">
      //     <div style={{height:25}}>
      //       {
      //         index==emptyArray.length-1?
      //         obj['toShip'].length>1?
      //         'Declared Value: $'+obj['toShip'][page1-1]['Declared Value']:'Declared Value: $'+obj['Declared Value']:index==emptyArray.length-2?'PO # '+obj['Customer PO Id']:''
      //     }
      //     </div>
      //   </Col>
      //   <Col style={{border:"1px solid black"}} xs="1" lg="1">
      //     <div style={{height:25}}></div>
      //   </Col>
      //   <Col style={{border:"1px solid black"}} xs="1" lg="1">
      //     <div style={{height:25}}></div>
      //   </Col>
      //   <Col style={{border:"1px solid black"}} xs="1" lg="1">
      //     <div style={{height:25}}></div>
      //   </Col>
      // </Row>