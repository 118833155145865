import '../App.css';
import Form from './Form.js';
import Form1 from './Form1.js';
import Form4 from './Form4.js';
import Form6 from './Form6.js';
import Form7 from './Form7.js';
import PartMenu from './PartMenu.js';
import NavMenu from './NavMenu.js';
import UploadFile from './UploadFile.js';
import { useState, useEffect, useRef } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col, Button,Modal,Spinner } from "react-bootstrap";
import { getFunctions, httpsCallable } from "firebase/functions";
import { getStorage,ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { getFirestore,deleteDoc, setDoc, collection, query, where, doc, getDoc, getDocs, orderBy, arrayUnion, onSnapshot } from "firebase/firestore";
import { getAuth, createUserWithEmailAndPassword, onAuthStateChanged, signInWithEmailAndPassword, signOut, sendEmailVerification, sendPasswordResetEmail } from "firebase/auth";
import firebase from './firebase.js';
import LoginModal from './LoginModal.js'
import costEstimate from '../jsons/costEstimateData.json'
import parts from '../jsons/PARTS.csv'
import XLSX from 'xlsx';
import { csv } from 'd3-request';
import Dropdown from 'react-dropdown';
import { Outlet,useNavigate } from "react-router-dom"
import { Document, Page } from 'react-pdf';




import { FaCaretDown,FaCaretUp,FaSearch,FaRedo,FaBars } from 'react-icons/fa';



function Shop({user,db,auth,openMenu,setOpenMenu,setShowLogin,page,setPage,openNavMenu,setOpenNavMenu,userData,number,setNumber,obj,setObj,obj1,setObj1,laborObj,setLaborObj,showCost,setShowCost,currCompany,setCurrCompany,openDrop,setOpenDrop,setShowOutlet,showOutlet}) {
  
  const navigate = useNavigate()
  const storage = getStorage(firebase);
  const [percent,setPercent] = useState({});

  const [showLoading, setShowLoading] = useState(false);
  const [newNew, setNewNew] = useState(true);
  const [docData, setDocData] = useState({})
  const [nextEstNum,setNextEstNum] = useState("")
  const [estDate,setEstDate] = useState("")
  const [searchQ,setSearchQ] = useState("")
  const [docDataMenu, setDocDataMenu] = useState({})
  const [selectedFile, setSelectedFile] = useState([]);
  const [selectedFile1, setSelectedFile1] = useState([]);
  const [productObj, setProductObj] = useState([]);
  const [num, setNum] = useState(0);

  const inputRef = useRef();
  const inputRef1 = useRef();
  const queryRef = useRef();
  const dateQueryRef = useRef();
  const dragItem = useRef();
  const dragOverItem = useRef();
  const productRef = useRef({});
  const companiesList = useRef([]);

  const [list, setList] = useState(["Id","Description","Inventory"])
  const partList1 = ["Id","Description","Inventory"]; 
  const partList2 = ["Id","Description","Inventory","Base Cost","Labor","Overhead","Material","Total"]; 
  const [list1, setList1] = useState(['Id','Description','Qty.'])
  const compList1 = ['Id','Description','Qty.']
  const compList2 = ['Id','Description','Qty.',"Base Cost","Labor","Overhead","Material","Total"]
  const emptyCompObj = {'Id':'','Description':'','Qty.':'',"Base Cost":'',"Labor":'',"Overhead":'',"Material":'',"Total":''};
  const emptyPartObj = {"Id":"","Approved":false,"Description":"","Inventory":"","Base Cost":"","Labor":"","Overhead":"","Material":"","Total":"","components":[{'Id':'','Description':'','Qty.':'',"Base Cost":'',"Labor":'',"Overhead":'',"Material":'',"Total":''}],"componentIds":[],"images":[],"instructions":[]};
  const [imageRef,setImageRef] = useState([]);

  useEffect(()=>{
    // console.log(user)
    // getProducts()
    getProducts1()
  },[user])

  const getProducts1 = async ()=>{
    console.log('getting products')
    const q = doc(db,'products','prods');
    
    try{
      setDocData({})
      const docc = await getDoc(q);
      const data = docc.data()
      console.log(data)
      if(data.products!=undefined){
      console.log(data.products)
        setDocData(data.products)


      // Object.keys(data.products).forEach(async(item)=>{
      //   const q1 = doc(db,'companies',data.products[item]['company'],'parts',item);
      //   const doc1 = await getDoc(q1);
      //   const data1 = doc1.data()
      //   console.log(data1)
      //   // data1['company']=data.products[item]['company'];

      //   // setDocData(prev=>{
      //   //   const clone = JSON.parse(JSON.stringify(prev))
      //   //   clone[data1['Id']]=data1
      //   //   return clone
      //   // })

      // })
      }

    }
    catch(err){
      console.log(err)
    }
      
    
  }

  useEffect(()=>{
    // console.log(showOutlet, docData)

    // let pdf = false;
    //         if(docData[item][0]['images'].length>0){
    //           pdf = docData[item][0]['images'][0].name.includes('.pdf')
    //           const downRef = ref(storage,'/images/'+docData[item][0]['images'][0].storeId)
    //           getDownloadURL(downRef).then((url) => {
    //             // Insert url into an <img> tag to "download"
    //             imageRef.current[docData[item][0]['Id']] = url
    //             {/*console.log(imageRef.current)*/}
    //           })
    //         }



    setImageRef([])
      Object.keys(docData).forEach((item,index)=>{
        // console.log(item,docData[item])

    if(docData[item].length>=1&&docData[item][0]['photos']!=undefined&&docData[item][0]['photos'].length>0){
          // console.log('yup1')
        const downRef = ref(storage,'/photos/'+docData[item][0]['photos'][0].storeId)
        getDownloadURL(downRef).then((url) => {
          // console.log('yup2')
          // Insert url into an <img> tag to "download"
          setImageRef((prev)=>{
            const clone = JSON.parse(JSON.stringify(prev))
            clone[item] = {url:url,pdf:docData[item][0]['photos'][0].name.includes('.pdf')}
            // [...prev,{url:url,pdf:item.name.includes('.pdf')}]
            return clone
          })
          // console.log(imageRef)
        })
    }
      })

  },[docData])

  const getProducts = async ()=>{
    const q = doc(db,'companiesList','list');
    try{
      productRef.current ={}
      setNum(0)
      const docList = await getDoc(q);
      companiesList.current = docList.data()
      console.log(companiesList.current)

      companiesList.current.companies.forEach(async(item)=>{

      console.log(item,'fuck')
      const q1 = query(collection(db,'companies', item, "parts"), where("Total", "!=",''));
      
      const querySnapshot = await getDocs(q1);

        querySnapshot.forEach((doc) => {
          const data = doc.data()
      console.log(data)
            console.log(productRef.current)

          if(data['Id']!=undefined&&Number(data['Total'])>0){
            const newobj = {'Id':data['Id'],'company':item,'price':data['Total'],description:data['Description']}
            if(data['photos']!=undefined){
              newobj['photos'] = data['photos'];
            }else{
              newobj['photos'] = [];
            }
            if(productRef.current[newobj['Id']]==undefined){
              productRef.current[newobj['Id']] = [newobj]
            }else{
              productRef.current[newobj['Id']].push(newobj)
            }
          }
        });
        setNum(prevCount => prevCount + 1)
      })

    }
    catch(err){
      console.log(err)
    }
  }

  useEffect(()=>{

    if(num>0&&companiesList.current.companies!=undefined&&num==companiesList.current.companies.length){
      console.log(num,companiesList.current.companies.length)
      console.log(productRef.current)
      setDoc(doc(db,'products','prods'), {products:productRef.current} , { merge: false })

    }
  },[num])

  const dragStart = (e, position) => {
    dragItem.current = position;
    // console.log(e.target.innerHTML);
  };
 
  const dragEnter = (e, position) => {
    dragOverItem.current = position;
    // console.log(e.target.innerHTML);
  };

  const drop = (e,objj,setObjj) => {
      setObjj((objjj)=>{

      const objj1 = JSON.parse(JSON.stringify(objjj));
      const copyListItems = objj1['components'];
      console.log(copyListItems)
      const dragItemContent = copyListItems[dragItem.current];
      copyListItems.splice(dragItem.current, 1);
      console.log(copyListItems)
      copyListItems.splice(dragOverItem.current, 0, dragItemContent);
      dragItem.current = null;
      dragOverItem.current = null;
      console.log(copyListItems)
      objj1['components'] = copyListItems;
      console.log(objj1)

      return objj1;
      });
    };

  useEffect(()=>{

    // make array of all part numbers and query that and do searches off of that
    return ()=>{
      if(dateQueryRef.current!=null){
        dateQueryRef.current()
        dateQueryRef.current=null
      }
    }
  },[userData])

  // useEffect(()=>{
  //   if(userData['currCompany']!=undefined&&userData['currCompany']!=''){
  //     if(number!=''){
  //       const q = query(collection(db,'companies', userData['currCompany'], "parts"), where("Id", "==", number));
  //       if(queryRef.current!=null){
  //         queryRef.current()
  //         queryRef.current=null
  //       }
  //       queryRef.current = onSnapshot(q, (snapshot) => {
  //         snapshot.docChanges().forEach((change) => {
  //           // console.log(change)
  //           const data = change.doc.data()
  //             data['Approved'] = data['Approved'] !=undefined?data['Approved']:false
  //           if (change.type === "added") {
  //             setNewNew(false)
  //             // console.log("New : ", change.doc.data());
  //             // setDocData(docData =>[...docData, change.doc.data()])
  //             setObj(data)
                
  //           }
  //           if (change.type === "modified") {
  //             setNewNew(false)
  //             // console.log("Modified : ", change.doc.data());
  //             setObj(data)
  //           }
            
  //         });
  //       });
  //     }
  //   }
  //   return ()=>{
  //     if(queryRef.current!=null){
  //       queryRef.current()
  //       queryRef.current=null
  //     }
  //   }
  // },[number,userData])


  // function chunk(array, size) {
  //   return array.reduce((chunks, item, i) => {
  //     if (i % size === 0) {
  //       chunks.push([item]);
  //     } else {  
  //       chunks[chunks.length - 1].push(item);
  //     }
  //     return chunks;
  //   }, []);
  // }


  const uuidv4 = ()=> {
    return ([1e7]+1e3+4e3+8e3+1e11).replace(/[018]/g, c =>
      (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    );
  }

  useEffect(()=>{
    console.log(percent)
    let showLoading=false
    Object.keys(percent).forEach((per,index)=>{
        console.log(per)
      if(percent[per]<100){
        showLoading=true;
        console.log('hey1')

      }
    })
    console.log(showLoading)
    setShowLoading(showLoading)

  },[percent])
  useEffect(()=>{
    console.log(percent,showLoading)
    
  },[showLoading])

  const addToCart = async()=>{
    // const obj2 = JSON.parse(JSON.stringify(obj))
    
    // console.log(obj2)
    // if(userData['currCompany']!=undefined&&userData['currCompany']!=''){
    //   setNewNew(false)

    //   // should download part from db to make sure..............
    //   setObj(obj2)      
    // }

  }

  



  const reset = ()=>{
    // setNewNew(true)
    // setObj(emptyPartObj)
    // setObj1([{'COMPONENT':'','DESCRIPTION':'','QTY.':'','COST':''}])
    // setLaborObj([{'Description':'','Rate':'','Parts/hr':'','Labor':'','Overhead':'','Total':''}])

  }

  


  return (
    <Container style={{height:'100%','marginTop':56}} >
    {openMenu&&
      <div style={{height:'100%',width:'100%',zIndex:'1',position:'absolute',top:0,right:0,opactiy:100}} onClick={()=>{if(openMenu){setOpenMenu(false)};if(openNavMenu){setOpenNavMenu(false)}}}/>
    }

    {
      !showOutlet&&
      <div style={{'zIndex':'101',display: 'flex',flex:1,flexDirection:'row',flexWrap:'wrap',justifyContent:'center',alignItems:'center',padding:5}}  >
      
      {
        Object.keys(docData).length>=1&&
        Object.keys(docData).map((item,index)=>{
          {/*console.log(item, docData[item])*/}
            

          return(
            <div key={docData[item][0]['Id']+'indy'+index} style={{height:500,width:300,border:'1px solid black',borderRadius:25,margin:5}}>
              <div onClick={()=>{setProductObj(docData[item]);navigate('./'+docData[item][0]['Id'])}} style={{display:'flex',flex:1,flexDirection:'column',justifyContent:'center',alignItems:'center',height:'100%',width:'100%'}}>
                
                {
                  imageRef[docData[item][0]['Id']]!=undefined&&!imageRef[docData[item][0]['Id']]['pdf']?
                  <img style={{height:300,width:300,objectFit:'contain'}} src={imageRef[docData[item][0]['Id']]['url']}></img>
                  :
                  imageRef[docData[item][0]['Id']]!=undefined&&
                  <object data={imageRef[docData[item][0]['Id']]['url']} type="application/pdf" height='300px' width='300px'>
                  </object>
                }
                <div style={{}}>{docData[item][0]['Id']}</div>
                <div style={{}}>{docData[item][0]['description']}</div>
                <div style={{}}>{docData[item][0]['company']}</div>
                <div style={{}}>{'$'+(Number(docData[item][0]['price'])*1.4).toFixed(2)}</div>

              </div>
            </div>
          )
        })
      }

                  {/*<Document file={imageRef.current[docData[item][0]['Id']]}>
                    <Page pageNumber={1} />
                  </Document>*/}
     


    </div>
    }
    {
      showOutlet&&
      <Outlet context={[productObj,setProductObj]} />
    }
  {showLoading&&
  <Modal
    show={true}
    onHide={()=>{setShowLoading(false)}}
    backdrop="static"
    // keyboard={false}
  >
  <Modal.Header closeButton>
          <Modal.Title>Uploading</Modal.Title>
        </Modal.Header>
    <Modal.Body>
      <Spinner animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    </Modal.Body>
  </Modal>
  }
  <div className='nav1' style={{height:'100%','zIndex':101}}>
    <PartMenu 
        open={openMenu} 
        setOpen={setOpenMenu} 
        db={db}
        list={list}
        setObj={setObj}
        setObj1={setObj1}
        setNewNew={setNewNew}
        docData={docDataMenu}
        setDocData={setDocDataMenu}
        userData={userData}
        dbSection="parts"
        laborObj={laborObj}
        setLaborObj={setLaborObj}
        setSelectedFile={setSelectedFile}
        setSelectedFile1={setSelectedFile1}
      />
      {/*<NavMenu 
        open={openNavMenu} 
        setOpen={setOpenNavMenu} 
        db={db}  
        user={user} 
        auth={auth}     
        setShowLogin={setShowLogin}     
        page={page}  
        setPage={setPage}  
        userData={userData}
        showCost={showCost}
        setShowCost={setShowCost}   
      />*/}
  </div>

  </Container>
  );
}

export default Shop;
