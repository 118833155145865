import React from 'react';
import { StyledMenu } from './Menu.styled';
import SearchItem from './SearchItem.js';
import { useState, useEffect, useRef } from "react";
import { FaCaretDown, FaCaretUp  } from 'react-icons/fa';
import Dropdown from 'react-dropdown';
import { getFirestore, setDoc, collection, query, where, doc,getDoc,getDocs, orderBy, arrayUnion, onSnapshot } from "firebase/firestore";



const FlowMenu = ({open,setOpen,db,obj,setObj,setNewNew,docData,setDocData,userData,queryRef,queryRef1}) => {
    
  const [list, setList] = useState(["Date","Id","Customer PO Id","Customer","Part"]);
  const [list1, setList1] = useState(['Open','Closed','Returns','All']);
  const [searchLabel, setSearchLabel] = useState('Date');
  const [searchLabel1, setSearchLabel1] = useState('Open');
  const searchInputRef = useRef('');
  const searchInput1Ref = useRef('');
  const [searchInput,setSearchInput]= useState('');
  const [searchInput1,setSearchInput1] = useState('');
  const [openDrop, setOpenDrop] = useState(false);
  const [openDrop1, setOpenDrop1] = useState(false);
  const [done, setDone] = useState(false);
  
  const queryRef11 = useRef();
  const [dateIds, setDateIds] = useState({'size':0,ids:[]});

  
  useEffect(()=>{
    return ()=>{
      if(queryRef1.current!=null){
        queryRef1.current()
        queryRef1.current=null
      }
    }
  },[])
  // useEffect(()=>{
  //   const q = query(collection(db,'companies', userData['currCompany'], "order"), where('Id', "!=", ''));

  //   if(queryRef11.current!=null){
  //     queryRef11.current()
  //   }

  //   queryRef11.current = onSnapshot(q, (snapshot) => {
  //     snapshot.docChanges().forEach((change) => {
  //       // console.log(change)
  //       if (change.type === "added") {
  //           const data = change.doc.data()
  //           console.log("New : ", data);
  //           // setDocData(docData =>[...docData, change.doc.data()])

            // data['toShip'].forEach((item,index)=>{
            //   if(item['Ship By Date']!=''){
            //     const newObj={}
            //     newObj['shipDate']=String(+ new Date(item['Ship By Date']))
            //     newObj['Id']=data['Id']
            //     newObj['toShipIndy']=index

            //     setDoc(doc(db, 'companies', userData['currCompany'], "shipDates", String(+ new Date(item['Ship By Date']))), newObj , { merge: false })
            //   }
              
            //   data['toShip'][index]['shipDate'] = item['Ship By Date']!=undefined?String(+ new Date(item['Ship By Date'])):'0'
              
            // })

            // setDoc(doc(db, 'companies', userData['currCompany'], "order", String(data['Id'])), data , { merge: true })

  //       }

  //     })
  //   })

  // },[])

  useEffect(()=>{
    if(dateIds['ids'].length>=1&&searchLabel=='Date'){
      setObj((prevState)=>{
        const clone = JSON.parse(JSON.stringify(prevState));
        clone['Orders']=[]
        return clone
      })

      // console.log(obj['Orders'])

      dateIds['ids'].forEach((item,index)=>{
        getIds(item)
      })
    }
  },[dateIds])

  const getIds = async (id)=>{
    console.log(id)
    const ref = doc(db,'companies',userData['currCompany'],'order',id)
    // console.log(ref)
    const doc1 = await getDoc(ref) 

    // console.log()
    const data = doc1.data()
    console.log(data)
    if(data!=undefined){

    setObj((prevState)=>{
      const clone = JSON.parse(JSON.stringify(prevState));
      
        if(searchLabel1=='All'){
          clone['Orders'].push(data)
        } else if(searchLabel1=='Open'&&data['Open']){
          clone['Orders'].push(data)
        } else if(searchLabel1=='Closed'&&!data['Open']){
          clone['Orders'].push(data)
        } else if(searchLabel1=='Returns'&&data['Return']){
          clone['Orders'].push(data)
        }
      

      return clone
    })
    }
  }

  const twoWeeks = async ()=>{

    if(queryRef.current!=null){
        queryRef.current()
        queryRef.current=null
      }
    const date=new Date();
    const year = date.getFullYear();
      // console.log(year)
    const year2 = (year-2000)
    let month = date.getMonth()+1;
    let day = date.getDate();

    const date1=month+"/"+day+"/"+year2

    const date2 = String(+ new Date(date1))
    const date3 = String(+ new Date(Date.now() + 12096e5))
    console.log(date1, date2,date3)
    const q = query(collection(db,'companies', userData['currCompany'], "shipDates"), where('shipDate', ">=", date2), where('shipDate', "<=", date3)); 
    if(queryRef1.current!=null){
      queryRef1.current()
    }
    setObj((prevState)=>({
          ...prevState,
          ['Orders']:[],
        }))
      setDateIds({ids:[]})
    queryRef1.current = onSnapshot(q, (snapshot) => {
      console.log(snapshot.size)
      snapshot.docChanges().forEach((change,index) => {
        console.log(change)
            const data = change.doc.data()
        if (change.type === "added") {
            console.log("New : ", data);
            // setDocData(docData =>[...docData, change.doc.data()])
            setDateIds((prev)=>{
              const clone = JSON.parse(JSON.stringify(prev));
              let good = true;
              clone['ids'].forEach((item,index)=>{
                console.log(item)
                if(item==data['Id']){
                  good=false;
                  console.log(item['Id']==data['Id'])
                }
              })
              console.log(good)
              if(good){
              console.log('inside')
                clone['ids'].push(data['Id'])
              } 
              return clone;
            })
        }
        if (change.type == "modified") {
          console.log('modified')
            setDateIds((prev)=>{
              const clone = JSON.parse(JSON.stringify(prev));
              clone['ids'].forEach((item,index)=>{
                if(item==data['Id']){
                  if(data['shipDate']>=date2&&data['shipDate']<=date3){
                    clone['ids'].splice(index,1)
                    clone['ids'].push(data['Id'])
                  }else{
                  clone['ids'].splice(index,1)
                  }
                }
              })
              return clone;
            })
        }

        if (change.type == "deleted" ||change.type == "removed") {
          console.log('removed')
            setDateIds((prev)=>{
              const clone = JSON.parse(JSON.stringify(prev));
              console.log(clone)
              clone['ids'].forEach((item,index)=>{
                console.log(item,data['Id'])
                if(item==data['Id']){
                console.log('inside ',index)
                  clone['ids'].splice(index,1)
                }
              })
              return clone;
            })
        }

      })
    })
  }

  const search = async (e)=>{

    // if(e.target.value.length>2){
      setDocData({})
      if(userData['currCompany']!=undefined&&userData['currCompany']!=''){
        let q = ''
        switch(searchLabel){
          case 'Id': q = query(collection(db,'companies', userData['currCompany'], "order"), where('Id', ">=",searchInputRef.Current), where('Id', "<=", searchInputRef.Current+'\uf8ff'));
            break;
          case 'Customer PO Id': q = query(collection(db,'companies', userData['currCompany'], "order"), where('Customer PO Id', ">=",searchInputRef.Current), where('Customer PO Id', "<=", searchInputRef.Current+'\uf8ff'));
            break;
          case 'Date' : 
            const date = searchInput1Ref.Current!=''?String(+ new Date(searchInputRef.Current)):'0'
            const date1 = searchInput1Ref.Current!=''? String(+ new Date(searchInput1Ref.Current)):String(Date.now())
            console.log(date,date1)
            q = query(collection(db,'companies', userData['currCompany'], "shipDates"), where('shipDate', ">=", date), where('shipDate', "<=", date1)); 
            break;
          case 'Customer': q = query(collection(db,'companies', userData['currCompany'], "order"), where('Customer', ">=",searchInputRef.Current), where('Customer', "<=", searchInputRef.Current+'\uf8ff'));
            break;
          case 'Part' : q = query(collection(db,'companies', userData['currCompany'], "order"), where('PartIds', "array-contains", searchInputRef.Current));
            break;
          
        }
        if(queryRef.current!=null){
          queryRef.current()
          queryRef.current=null
        }
        if(queryRef1.current!=null){
          queryRef1.current()
        }
        setObj((prevState)=>({
          ...prevState,
          ['Orders']:[],
        }))
        queryRef1.current = onSnapshot(q, (snapshot) => {
          // console.log(snapshot.size)
          setDateIds({'size':snapshot.size,ids:[]})
          snapshot.docChanges().forEach((change,index) => {
            // console.log(change)
            if (change.type === "added") {
                const data = change.doc.data()
                // console.log("New : ", data);
                // setDocData(docData =>[...docData, change.doc.data()])
                if(searchLabel=='Date'){
                    setDateIds((prev)=>{
                      const clone = JSON.parse(JSON.stringify(prev));
                      clone['ids'].push(data['Id'])
                      return clone;
                    })
                }else{

                  setObj((prevState) => {
                    const clone = JSON.parse(JSON.stringify(prevState));
                    if(searchLabel1=='All'){
                      clone['Orders'].push(data)
                    } else if(searchLabel1=='Open'&&data['Open']){
                      clone['Orders'].push(data)
                    } else if(searchLabel1=='Closed'&&!data['Open']){
                      clone['Orders'].push(data)
                    } else if(searchLabel1=='Returns'&&data['Return']){
                      clone['Orders'].push(data)
                    }

                    return clone
                  })
                }
            }
            if (change.type === "modified") {
                // console.log("Modified : ", change.doc.data());
                const data = change.doc.data()
                setObj((prevState) => {
                  const clone = JSON.parse(JSON.stringify(prevState));
                  let indy=0
                  clone['Orders'].forEach((item,index)=>{
                    if(item['Id']==data['Id']){
                      indy=index
                    }
                  })
                  clone['Orders'].splice(indy, 1);

                  return clone
                }
              )
            }
            if (change.type === "deleted" ||change.type === "removed") {
                setObj((prevData) => {
                  const newData = {...prevData}
                  delete newData[change.doc.id]
                  return newData;
                })
            }
          });
        });
      }
    // }
  }
  const saveTemplateAsFile = (filename, dataObjToWrite) => {
    var fields = ["Company","Id","PO#","Ship Date","Req\'d By","Description","Part No","Qty","Added","Shipping","Note"]
    var fields1 = ['Customer','Id','Customer PO Id','Ship By Date','Req\'d By','Description','Part Id','Qty','Date','Carrier','Note']
    // console.log(fields)

    var replacer = function(key, value) { return (value === null || value==undefined) ? '' : value } 
    var replacer1 = function(key, value) { 
        if(value!=undefined){

        let rere=value.replace(/,/g,'\,')
        rere=rere.replace(/"/g,"'")
        return (rere === null || rere==undefined) ? '' : rere
        }else{
          return ''
        }
      } 
      const toWrite={}
      console.log(dataObjToWrite)
    Object.keys(dataObjToWrite).forEach((item,index)=>{
      
      if(dataObjToWrite[item]['toShip'].length<=1){

          const partObj={}
          const clone = JSON.parse(JSON.stringify(dataObjToWrite[item]))
          clone['Parts'].forEach((item3,index)=>{
            if(partObj[item3['Part Id']]==undefined){
              partObj[item3['Part Id']]=item3
              console.log(item3)
            }else{
              partObj[item3['Part Id']]['Quantity']=Number(partObj[item3['Part Id']]['Quantity'])+Number(item3['Quantity'])
              // partObj[item3['Part Id']]['Price']=Number(partObj[item3['Part Id']]['Price'])+Number(item3['Price'])
              console.log(item3)
            }
          })
        
        

        Object.keys(partObj).forEach((item1,index1)=>{
          if(partObj[item1]['Part Id']!=''&&partObj[item1]['Part Id']!='Shipping & Handling'){

            toWrite[String(item)+String(index1)] = {}
            toWrite[String(item)+String(index1)]['Customer'] = dataObjToWrite[item]['Customer']
            toWrite[String(item)+String(index1)]['Id'] = dataObjToWrite[item]['Id']
            toWrite[String(item)+String(index1)]['Customer PO Id'] = dataObjToWrite[item]['Customer PO Id']
            toWrite[String(item)+String(index1)]['Date'] = dataObjToWrite[item]['Date']
            toWrite[String(item)+String(index1)]['Ship By Date'] = dataObjToWrite[item]['toShip'][0]['Ship By Date']
            toWrite[String(item)+String(index1)]['Carrier'] = replacer1(',',dataObjToWrite[item]['toShip'][0]['Carrier'])
            toWrite[String(item)+String(index1)]['Part Id'] = partObj[item1]['Part Id']
            toWrite[String(item)+String(index1)]['Description'] = replacer1(',',partObj[item1]['Description 2'])
            toWrite[String(item)+String(index1)]['Qty'] = partObj[item1]['Quantity']
            console.log(item)
            console.log(partObj[item1])
            console.log(index1) 
          }
        })


      }else{
          // setPartsObj1(partObj1)
        dataObjToWrite[item]['toShip'].forEach((item1,index1)=>{
        const partObj1={}
          // console.log(partObj1)

          const clone = JSON.parse(JSON.stringify(item1))
            item1['Parts'].forEach((item3,index1)=>{
              if(partObj1[item3['Part Id']]==undefined){
                partObj1[item3['Part Id']]=item3
              }else{
                partObj1[item3['Part Id']]['Quantity']=Number(partObj1[item3['Part Id']]['Quantity'])+Number(item3['Quantity'])
                // partObj1[item1['Part Id']]['Price']=Number(partObj1[item1['Part Id']]['Price'])+Number(item1['Price'])
              }
            })
          
          Object.keys(partObj1).forEach((item2,index2)=>{
            if(partObj1[item2]['Part Id']!=''){

            toWrite[String(item)+String(index1)+String(index2)] = {}
            toWrite[String(item)+String(index1)+String(index2)]['Customer'] = dataObjToWrite[item]['Customer']
            toWrite[String(item)+String(index1)+String(index2)]['Id'] = dataObjToWrite[item]['Id']
            toWrite[String(item)+String(index1)+String(index2)]['Customer PO Id'] = dataObjToWrite[item]['Customer PO Id']
            toWrite[String(item)+String(index1)+String(index2)]['Date'] = dataObjToWrite[item]['Date']
            toWrite[String(item)+String(index1)+String(index2)]['Ship By Date'] = item1['Ship By Date']
            toWrite[String(item)+String(index1)+String(index2)]['Carrier'] = replacer1(',',item1['Carrier'])
            toWrite[String(item)+String(index1)+String(index2)]['Part Id'] = partObj1[item2]['Part Id']
            toWrite[String(item)+String(index1)+String(index2)]['Description'] = replacer1(',',partObj1[item2]['Description 2'])
            toWrite[String(item)+String(index1)+String(index2)]['Qty'] = partObj1[item2]['Quantity']
            console.log(item)
            console.log(item1)
            console.log(partObj1[item2])
            console.log(index1)
            }
          })
        })
      }

    })
    var csv = Object.keys(toWrite).map(function(row){

      return fields1.map(function(fieldName){
        // console.log(fieldName,row,dataObjToWrite[row][fieldName])

        return JSON.stringify(toWrite[row][fieldName], replacer)
      }).join(',')
    })
    let headers = fields.map((item,index)=>{
      return JSON.stringify(item, replacer)
    }).join(',')
    console.log(headers)
    headers+=',' // add header column
    csv.unshift(headers) // add header column
    csv = csv.join('\r\n');
    console.log(csv)


    const blob = new Blob(["\ufeff",csv], { type:"text/csv;charset=utf-8"});
    const link = document.createElement("a");

    link.download = filename;
    link.href = window.URL.createObjectURL(blob);
    link.dataset.downloadurl = ["text/csv", link.download, link.href].join(":");

    const evt = new MouseEvent("click", {
        view: window,
        bubbles: true,
        cancelable: true,
    });

    link.dispatchEvent(evt);
    link.remove()
};

const download = ()=>{
  console.log(docData) 
  const date=new Date();
    const year = date.getFullYear();
      // console.log(year)
    const year2 = (year-2000)
    let month = date.getMonth()+1;
    let day = date.getDate();

    const date1=month+"/"+day+"/"+year2
    const len=Object.keys(docData) 
  saveTemplateAsFile("Customer_Shipping_Schedule"+date1+".csv",len.length>0?docData:obj['Orders'])
}

  const fillInForm = (objj)=>{
    // fill in the cost estimate form
    // console.log(objj)
         // console.log(docData) 
    // saveTemplateAsFile("component_Data.json",docData)

    // window.history.pushState({}, document.title, window.location.pathname);
    list.forEach((listItem)=>{
      // console.log(listItem)
      setObj((prevState)=>({
        ...prevState,
        ['Orders']:[objj],
      }))
    })
  }

  return (
    <StyledMenu open={open} left={true}>
      <div style={{float:'top'}}>
        <span style={{ float: 'right', cursor: 'pointer', padding: '10px'}} onClick={()=>{setOpen(false)}}>
          X
        </span>
      </div>
      <div style={{float:'top'}}>
        <button style={{ float: 'center', cursor: 'pointer', padding: '10px'}} onClick={download}>
          Download result file
        </button>
      </div>
      
        <div>
          <Dropdown 
            className="dropdown"
            placeholderClassName="fuck"
            arrowClosed={<FaCaretDown className="arrow-closed" />}
            arrowOpen={<FaCaretUp className="arrow-open" />}
            controlClassName="dropdownC" open={openDrop} options={list} onChange={(e)=>{setSearchLabel(e.value)}} value={searchLabel}>
            
          </Dropdown>
        </div>
        <div>
          <Dropdown 
            className="dropdown"
            placeholderClassName="fuck"
            arrowClosed={<FaCaretDown className="arrow-closed" />}
            arrowOpen={<FaCaretUp className="arrow-open" />}
            controlClassName="dropdownC" open={openDrop1} options={list1} onChange={(e)=>{setSearchLabel1(e.value)}} value={searchLabel1}>
            
          </Dropdown>
        </div>

      <div>
          {searchLabel=='Date'&&<div>Begin</div>}
          <input
            type="text" 
            value={searchInput}
            placeholder={searchLabel=='Date'?'07/04/2022':'case sensitive'}
            // onChange={(e) => {setSearchInput(e.target.value);}}
            onChange={(e) => {
              searchInputRef.Current=e.target.value;
              setSearchInput(e.target.value);
            }}
          />
      </div>
      {searchLabel=='Date'&&

        <div>
          <div>End</div>
          <input
            type="text" 
            value={searchInput1}
            placeholder={searchLabel=='Date'?'10/31/2022':'case sensitive'}
            // onChange={(e) => {setSearchInput(e.target.value);}}
            onChange={(e) => {
              console.log(e.target.value);
              console.log(searchInput1Ref.Current);
              searchInput1Ref.Current=e.target.value;
              setSearchInput1(e.target.value);
              console.log(searchInput1Ref.Current);
            }}
          />
        </div>
      }

      <div style={{float:'top',paddingTop:10}}>
        <button style={{ float: 'center', cursor: 'pointer', padding: '10px'}} onClick={search}>
          Search
        </button>
      </div>
      
      <div style={{float:'top',paddingTop:10}}>
        <button style={{ float: 'center', cursor: 'pointer', padding: '10px'}} onClick={twoWeeks}>
          Next 2 Weeks
        </button>
      </div>


    {/*map of search output*/}
    {Object.keys(docData).length >= 1 &&
      Object.keys(docData).map((objj, index) => {
       return (

          <SearchItem key={index+"floof"} obj={docData[objj]} onClick={fillInForm} searchLabel={searchLabel} /> 
        );

      })
    }
      
    </StyledMenu>
  )
}
export default FlowMenu;