import React, {useState,useRef,useEffect} from 'react';
import { FaMinusCircle,FaFileDownload } from 'react-icons/fa';
import { getStorage,ref, uploadBytesResumable, getDownloadURL,deleteObject } from "firebase/storage";
import { getFirestore,deleteDoc, setDoc, collection, query, where, doc, getDocs, orderBy, arrayUnion, onSnapshot } from "firebase/firestore";



const UploadFile = ({setSelectedFile,selectedFile,label,obj,setObj,type,inputRef,storage,userData,db})=>{
	const [isFilePicked, setIsFilePicked] = useState(false);
	const [isSelected, setIsSelected] = useState(false);
	

	const changeHandler = (event) => {
		console.log(event.target.files[0])
		setSelectedFile((prev)=>[...prev, event.target.files[0]]);
		setIsSelected(true);
	};
	const downloadFile = (objj)=>{
		console.log(objj)
		const downRef = ref(storage,'/'+type+'/'+objj.storeId)
		getDownloadURL(downRef).then((url) => {
    // Insert url into an <img> tag to "download"
    window.open(url,'_blank');

  })
  .catch((error) => {
    // A full list of error codes is available at
    // https://firebase.google.com/docs/storage/web/handle-errors
    switch (error.code) {
      case 'storage/object-not-found':
        // File doesn't exist
        alert('file not found')
        break;
      case 'storage/unauthorized':
        // User doesn't have permission to access the object
        alert('user not authorized')
        break;
      case 'storage/canceled':
        // User canceled the upload
        // alert('user ')
        break;

      // ...

      case 'storage/unknown':
        alert('unknown error')
        // Unknown error occurred, inspect the server response
        break;
    }
  });
	}
	const deleteFile1 = (objj,x,type)=>{
		const deleteRef = ref(storage,'/'+type+'/'+objj.storeId)
		deleteObject(deleteRef)
		setObj((prev)=>{
			const clone = JSON.parse(JSON.stringify(prev))
			clone[type].splice(x,1)
			console.log(clone[type],type,clone)
			if(userData['currCompany']!=undefined&&userData['currCompany']!=''){
			  setDoc(doc(db,'companies', userData['currCompany'], "parts", clone['Id']), clone , { merge: true })
			}
			return clone
		})


	}

	const deleteFile = (x)=>{
		// console.log(inputRef.current,inputRef.current.value)
		if(selectedFile.length-1==x && inputRef.current.value!=null){
			inputRef.current.value = ''
		}
		setSelectedFile((previousArr) => {
	      // console.log(newArray,' 1', x)
	      if(previousArr.length>1){

	      const first = previousArr.slice(0, x)
	      // console.log(first)
	      
	      // console.log(newArray,' 2')
	      const second =previousArr.slice(x+1)
	      // console.log(second)
	      // newArray.concat(second)
	      // console.log(newArray,' 3')
	      return first.concat(second);
	      }else{
	        return [];
	      }
	    })
	}

	const handleSubmission = () => {
		console.log('submit')
	};

	// useEffect(()=>{
	// 	console.log(type,obj[type],obj)
	// })

	return(
   <div style={{ marginTop: 10}}>
   		{obj[type]!=undefined&&
			(obj[type]).map((objj,index)=>{
				{/*{console.log(index,obj)}*/}
				if(objj!=undefined){

				return(
					<div style={{paddingBottom:"8px"}}>

				{/*need to make a download image or file button function maybe preview*/}

				<FaFileDownload onClick={()=>{downloadFile(objj)}} />
				<span style={{paddingLeft:"5px",paddingRight:"5px"}}>{objj.name}</span>	
				<FaMinusCircle onClick={()=>{deleteFile1(objj,index,type)}} />
					</div>
					)
				}
   			})
   		}
   		{   
			selectedFile.map((obj,index)=>{
				{/*{console.log(index,obj)}*/}
				if(obj!=undefined){

				return(
					<div style={{paddingBottom:"8px", backgroundColor:"#c0f5b5"}}>

				{/*need to make a download image or file button function maybe preview*/}

				<span style={{paddingLeft:"5px",paddingRight:"5px"}}>{obj.name}</span>	
				<FaMinusCircle onClick={()=>{deleteFile(index)}} />
					</div>
					)
				}
   			})
   		}
   		{/*<label style={{paddingRight:"5px"}}>{label}</label>*/}
		<input ref={inputRef} type="file" onChange={changeHandler} />
			{/*{isSelected ? (
				<div>
					<p>Filename: {selectedFile.name}</p>
					<p>Filetype: {selectedFile.type}</p>
					<p>Size in bytes: {selectedFile.size}</p>
					<p>
						lastModifiedDate:{' '}
						{selectedFile.lastModifiedDate.toLocaleDateString()}
					</p>
				</div>
			) : (
				<p>Select a file to show details</p>
			)}*/}
			{/*<div>
				<button onClick={handleSubmission}>Submit</button>
			</div>*/}
		</div>
	)
}

export default UploadFile