import '../App.css';
import Form2 from './Form2.js';
import Form1 from './Form1.js';
import Form5 from './Form5.js';
import Form6 from './Form6.js';
import Form7 from './Form7.js';
import WorkerMenu from './WorkerMenu.js';
import NavMenu from './NavMenu.js';
import { useState, useEffect, useRef } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col, Button } from "react-bootstrap";
import { getFunctions, httpsCallable } from "firebase/functions";
import {  } from "firebase/firestore";
import { getFirestore, setDoc, collection, query, where, doc, getDocs, orderBy, arrayUnion, onSnapshot } from "firebase/firestore";
import { getAuth, createUserWithEmailAndPassword, onAuthStateChanged, signInWithEmailAndPassword, signOut, sendEmailVerification, sendPasswordResetEmail } from "firebase/auth";
import firebase from './firebase.js';
import LoginModal from './LoginModal.js'
import { FaCaretDown,FaCaretUp,FaSearch,FaRedo,FaBars } from 'react-icons/fa';
import inventory from '../jsons/inventoryData.json'
import {Modal, Spinner} from 'react-bootstrap';
import Dropdown from 'react-dropdown';



function Worker({user,db,auth,openMenu,setOpenMenu,setShowLogin,setPage,openNavMenu,setOpenNavMenu,userData,workerId,workerTierId,obj,setObj,currCompany,setCurrCompany,openDrop,setOpenDrop}) {
  
  const [newNew, setNewNew] = useState(true);
  const [docData, setDocData] = useState([])
  const [nextEstNum,setNextEstNum] = useState("")
  const [estDate,setEstDate] = useState("")
  const [searchQ,setSearchQ] = useState("")
  const [docDataMenu, setDocDataMenu] = useState({})
  const [docData1,setDocData1] = useState({})
  const [red, setRed] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [search, setSearch] = useState(false)



  const queryRef = useRef();
  const dateQueryRef = useRef();
  const dragItem = useRef();
  const dragOverItem = useRef();

  // const [obj, setObj] = useState({"Description":"","Cost":"","Description":"","Quantity":""});
  const [obj1, setObj1] = useState({'Id':'','Name':'','Tier':'','Rate':''})

  const [list, setList] = useState(['Id','Description','Rate'])
  const [list1, setList1] = useState(['Id','Name','Tier','Rate'])
  // const emptyCompObj = {'COMPONENT':'','DESCRIPTION':'','QTY.':'','COST':''};
  const [keyListBy2,setKeyListBy2]=useState([])

  // useEffect(()=>{
  //   let num=0
  //     console.log(inventory)
  //   Object.keys(inventory).forEach((item)=>{
  //     // console.log(item)
  //      const reitem = item.replace(/\//g,' slash ')
  //     if(num<10){
  //     console.log(inventory[item])
  //     // if(item.includes('/')){
  //     // }
  //     //   setObj1(inventory[item]['labor'])
  //     //     inventory[item]['laborList']=[]
  //     //   inventory[item]['labor'].forEach((listItem)=>{
  //     //     if(listItem['COMPONENT'] != '' && listItem['COMPONENT'] !== '*'  && listItem['COMPONENT'] != '**'){
  //     //       inventory[item]['laborList'].push(listItem['COMPONENT'])
  //       //   }
  //       // })

  //       // list.forEach((listItem)=>{
  //       //   console.log(listItem)
  //       //   setObj((prevState) => ({
  //       //       ...prevState,
  //       //         [listItem]: inventory[item][listItem],
  //       //       }
  //       //     ))
  //       // })
  //       // delete inventory[item]['labor']
  //     // console.log(inventory[item])


  //     }
  //       num++
  //       const temp = {}
  //       temp['Description'] = inventory[item]['Description'].toString()
  //       // temp['Description'] = inventory[item]['Description']
  //       // temp['Cost']=inventory[item]['labor'].length>0?inventory[item]['labor'][inventory[item]['labor'].length-1]['PRICE']!=undefined?inventory[item]['labor'][inventory[item]['labor'].length-1]['PRICE']:0:0
  //       if(inventory[item]['Qty']!=undefined){
  //         temp['Quantity']=inventory[item]['Qty']
  //       }
  //       temp['Pending Qty'] = inventory[item]['Pending Qty'] !=undefined? inventory[item]['Pending Qty']:0;
  //     setDoc(doc(db, 'companies', 'Fostoria Bushings', 'labor', reitem), temp, { merge: true })
  //     // deleteDoc(doc(db,'companies','fbins','costEstimate',reitem))

  //   })
  //   console.log(num)

  // },[])

  // useEffect(()=>{
  //   if(userData['currCompany']!=undefined&&userData['currCompany']!=''){
  //     if(number!=''){
  //       const q = query(collection(db,'companies', userData['currCompany'], "labor"), where("Description", "==", number));
        
  //       queryRef.current = onSnapshot(q, (snapshot) => {
  //           console.log(snapshot,snapshot.size)
  //           if(snapshot.size==0){
  //             setObj((prevState) => ({
  //                 ...prevState,
  //                   ['Description']: number,
  //                 }
  //               ))
  //           }
  //         snapshot.docChanges().forEach((change) => {
  //           // console.log(change)
  //           const data = change.doc.data()
  //           // console.log(data)
  //           if (change.type === "added") {
  //           setNewNew(false)
  //               console.log("New : ", change.doc.data());
  //               // setDocData(docData =>[...docData, change.doc.data()])
  //               list.forEach((listItem)=>{
  //                     // console.log(listItem)
  //                     setObj((prevState) => ({
  //                         ...prevState,
  //                           [listItem]: data[listItem],
  //                         }
  //                       ))
  //                   })
  //           }
  //           if (change.type === "modified") {
  //           setNewNew(false)
  //               // console.log("Modified : ", change.doc.data());
  //               list.forEach((listItem)=>{
  //                     // console.log(listItem)
  //                     setObj((prevState) => ({
  //                         ...prevState,
  //                           [listItem]: data[listItem],
  //                         }
  //                       ))
  //                   })
  //           }
            
  //         });
  //       });
  //     }
  //   }
  //   return ()=>{
  //     if(queryRef.current!=null){
  //       queryRef.current()
  //       queryRef.current=null
  //     }
  //   }
  // },[number,userData])

  


  
  // useEffect(()=>{

  // },[obj['Id']])

  // need real time updates....and searches on the inputs

  const editWorker = ()=>{
    setDoc(doc(db, 'companies', userData['currCompany'], "workerTier", obj['Id']), {'Id':obj['Id'],'Description':obj['Description']} , { merge: true })
  }
  const editWorker1 = async()=>{
    if(userData['currCompany']!=undefined&&userData['currCompany']!=''){
      
      setDoc(doc(db, 'companies', userData['currCompany'], "worker", obj1['Id']), obj1 , { merge: false })
      
      const q = query(collection(db,'companies', userData['currCompany'], "worker"), where("Tier", "==", obj1['Tier']));

      const querySnapshot = await getDocs(q);
      const rates = []
      querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        console.log(doc.id, " => ", doc.data());
        const data = doc.data()
        if(data['Rate']!=undefined&&data['Rate']!=''){
          rates.push(data['Rate'])
        }
      });
      let add = 0;
      rates.forEach((r)=>{
        add+=Number(r)
      })
      let tierRate = 0
      if(rates.length>0){
        tierRate = (add/rates.length).toFixed(2)
      }


      // this should be on the backend...................................

      setDoc(doc(db, 'companies', userData['currCompany'], "workerTier", obj1['Tier']), {Rate:tierRate} , { merge: true })

    }

  }
  

  useEffect(()=>{
    if(userData['currCompany']!=undefined&&userData['currCompany']!=''){
      if(workerId!=''){
        const q = query(collection(db,'companies', userData['currCompany'], "worker"), where("Id", "==", workerId));
        if(queryRef.current!=undefined){
          queryRef.current()
        }
        queryRef.current = onSnapshot(q, (snapshot) => {
          snapshot.docChanges().forEach((change) => {
            // console.log(change)
            const data = change.doc.data()
            if (change.type === "added") {
                console.log("New : ", change.doc.data());
                // setDocData(docData =>[...docData, change.doc.data()])
                list.forEach((listItem)=>{
                      // console.log(listItem)
                      setObj1((prevState) => ({
                          ...prevState,
                            [listItem]: data[listItem],
                          }
                        ))
                    })
            }
            if (change.type === "modified") {
                // console.log("Modified : ", change.doc.data());
                list.forEach((listItem)=>{
                      // console.log(listItem)
                      setObj1((prevState) => ({
                          ...prevState,
                            [listItem]: data[listItem],
                          }
                        ))
                    })
            }
            
          });
        });
      }
    }
    return ()=>{
      if(queryRef.current!=null){
        queryRef.current()
        queryRef.current=null
      }
    }
  },[workerId,userData])

useEffect(()=>{
    if(userData['currCompany']!=undefined&&userData['currCompany']!=''){
      if(workerTierId!=''){
        const q = query(collection(db,'companies', userData['currCompany'], "workerTier"), where("Id", "==", workerTierId));
        if(queryRef.current!=undefined){
          queryRef.current()
        }
        queryRef.current = onSnapshot(q, (snapshot) => {
          snapshot.docChanges().forEach((change) => {
            const data = change.doc.data()
            console.log(data)
            if (change.type === "added") {
                console.log("New : ", change.doc.data());
                // setDocData(docData =>[...docData, change.doc.data()])
                setObj(data)
            }
            // if (change.type === "modified") {
            //     // console.log("Modified : ", change.doc.data());
            //     // console.log(listItem)
            //   setObj(data)
            // }
            
          });
        });
      }
    }
    return ()=>{
      if(queryRef.current!=null){
        queryRef.current()
        queryRef.current=null
      }
    }
  },[workerTierId,userData])

  
  // useEffect(()=>{
  //   console.log(obj)
    
  //   if(obj['Rate']!=''&&obj['Parts/hr']!=''){
  //     const lab =Number((Number(obj['Parts/hr'])/Number(obj['Rate'])).toFixed(2))
  //     const overhead = obj['Overhead']!=''?Number(obj['Overhead']):0
  //     const material = obj['Material']!=''?Number(obj['Material']):0
  //    setObj((prevState) => ({
  //       ...prevState,
  //         ['Labor']: lab,
  //         ['Total']: Number((lab+(lab*overhead)+material).toFixed(2)),
  //       }
  //     )) 

  //   }

  // },[obj['Rate'],obj['Overhead'],obj['Material'],obj['Parts/hr']])

  const reset = ()=>{
    setNewNew(true)
    setObj({'Id':'','Description':'','Rate':''})
  }

  const reset1 = ()=>{
    setNewNew(true)
    setObj1({'Id':'','Name':'','Tier':'','Rate':''})
  }

  return (
    <Container style={{height:'100%','marginTop':56}}>
      <Row onClick={()=>{if(openMenu){setOpenMenu(false)};if(openNavMenu){setOpenNavMenu(false)}}} >
      <Row>
        <div style={{ marginBottom: 20}} >
        {userData['currCompany']!=undefined?
          userData['companies'].length>1?
          <span className="fuck1">
            <Dropdown 
              className="dropdown"
              placeholderClassName="fuck"
              arrowClosed={<FaCaretDown className="arrow-closed" />}
              arrowOpen={<FaCaretUp className="arrow-open" />}
              controlClassName="dropdownC" open={openDrop} options={Object.keys(userData['companiesMap'])} onChange={(e)=>{setCurrCompany(e.value)}} value={currCompany}>
              
            </Dropdown>
          </span>      
          :
          <span>{userData['currCompany']}</span>
          :''}
        </div>
      </Row>
      <Row>
        <div style={{ marginBottom: 20}} >
          <span style={{cursor: 'pointer'}} onClick={reset}>
            <FaRedo />  Worker Tier
            {/*<FaCaretDown />*/}
          </span>
        </div>
      </Row>


        <Row> 
      <Col xs="4" lg="4">
        <div>Id</div>
      </Col>
      <Col xs="4" lg="4">
        <div>Description</div>
      </Col>
      <Col xs="4" lg="4">
        <div>Avg. Rate</div>
      </Col>
      
    </Row>
      
          <Row 
          > 
            <Col xs="4" lg="4" style={{paddingRight:0, paddingLeft:0,}}>
              <Form7 name={'Id'} input={obj} setInput={setObj}  db={db} userData={userData} setSearch={setSearch} searchName={'workerTier'} />
            </Col>
            <Col xs="4" lg="4" style={{paddingRight:0, paddingLeft:0,}}>
              <Form7 name={'Description'} input={obj} setInput={setObj}  db={db} userData={userData} setSearch={setSearch} searchName={'workerTier'} />
            </Col>
            <Col xs="4" lg="4" style={{paddingRight:0, paddingLeft:0,}}>
              <div style={{height:'30px',width:'100%', border:'1px solid black',borderRadius:3,textAlign:'left',paddingLeft: '3px'}}>{obj['Rate']==undefined?0:obj['Rate']==''?0:obj['Rate']}</div>
            </Col>
            
          </Row>
        
    

    <Row style={{paddingBottom:"10px",borderBottom:"1px solid #000"}}>
      <Col xs="0" lg="3">
      </Col>
      <Col xs="12" lg="6">
        <Button style={{ marginTop: 30}} onClick={editWorker} >Save Worker Tier</Button>
      </Col>
      
      {/*}*/}
      <Col xs="0" lg="3">
      </Col>
    </Row>

    <Row>
        <div style={{ marginBottom: 20, paddingTop:"20px"}} >
          <span style={{cursor: 'pointer'}} onClick={reset1}>
            <FaRedo /> Individual Worker
            {/*<FaCaretDown />*/}
          </span>
        </div>
      </Row>


        <Row> 
      <Col xs="3" lg="3">
        <div>Id</div>
      </Col>
      <Col xs="3" lg="3">
        <div>Name</div>
      </Col>
      <Col xs="3" lg="3">
        <div>Tier</div>
      </Col>
      <Col xs="3" lg="3">
        <div>Rate</div>
      </Col>
      
    </Row>
      
          <Row 
          > 
            <Col xs="3" lg="3" style={{paddingRight:0, paddingLeft:0,}}>
              <Form7 name={'Id'} input={obj1} setInput={setObj1} db={db} userData={userData} setSearch={setSearch} searchName={'worker'} />
            </Col>
            <Col xs="3" lg="3" style={{paddingRight:0, paddingLeft:0,}}>
              <Form7 name={'Name'} input={obj1} setInput={setObj1} db={db} userData={userData} setSearch={setSearch} searchName={'worker'} />
            </Col>
            <Col xs="3" lg="3" style={{paddingRight:0, paddingLeft:0,}}>
              <Form7 name={'Tier'} input={obj1} setInput={setObj1} db={db} userData={userData} setSearch={setSearch} searchName={'worker'} />
            </Col>
            <Col xs="3" lg="3" style={{paddingRight:0, paddingLeft:0,}}>
              <Form7 name={'Rate'} input={obj1} setInput={setObj1} db={db} userData={userData} setSearch={setSearch} searchName={'worker'} />
            </Col>
            
          </Row>
        
    

    <Row style={{paddingBottom:"10px",borderBottom:"1px solid #000"}}>
      <Col xs="0" lg="3">
      </Col>
      <Col xs="12" lg="6">
        <Button style={{ marginTop: 30}} onClick={editWorker1} >Save Individual Worker</Button>
      </Col>
      
      {/*}*/}
      <Col xs="0" lg="3">
      </Col>
    </Row>

  </Row>
  {/*{showModal&&
    <Modal
      show={true}
      onHide={()=>{setShowModal(false)}}
      backdrop="static"
      // keyboard={false}
    >
      <Modal.Header closeButton>
        {obj['Description']==''?
          <Modal.Title>Description</Modal.Title>
          :
          <Modal.Title>Worker</Modal.Title>
        }
      </Modal.Header>

      <Modal.Body>
        {obj['Description']==''?
        <div>
          The Description field can not be blank.
        </div>
        :
        <div>
          Are you sure you want to over-write the workers rate?  This will change the rate for all Labor with this worker.
        </div>
        
        }
      </Modal.Body>

      <Modal.Footer>
        {obj['Description']==''?
        <div>
        </div>
        :
          <Button variant="primary" onClick={handleOverWrite} >Over-Write</Button>
        }
      </Modal.Footer>

    </Modal>
  }*/}
    <div className='nav1'>

      <WorkerMenu 
          open={openMenu} 
          setOpen={setOpenMenu} 
          db={db}
          list={list}
          list1={list1}
          setObj={setObj}
          setObj1={setObj1}
          setNewNew={setNewNew}
          docData={docDataMenu}
          setDocData={setDocDataMenu}
          userData={userData}
          dbSection="worker"
        />
      
      {/*<NavMenu 
          open={openNavMenu} 
          setOpen={setOpenNavMenu} 
          db={db}  
          user={user} 
          auth={auth}     
          setShowLogin={setShowLogin}     
          setPage={setPage}     
          userData={userData}

        />*/}
    </div>

  </Container>
  );
}

export default Worker;
