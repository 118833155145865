import { useState, useEffect } from 'react'
import useLongPress from './useLongPress.js';


const Form1a = ({name, setInput, input, index, key1 })=> {
  useEffect(()=>{
    // console.log(input['components'][index])
    
  })
const onLongPress = () => {
      // console.log('longpress is triggered');
      if(name=="Part Id"){

        window.history.pushState({}, document.title, window.location.pathname);
        let loc = String(window.location);
        let locSplit = loc.split('/')
        window.open(String(locSplit[0]+'/Parts'+"?number="+input[key1][index]['Part Id']),'_blank');
      }
  };

  const onClick = () => {
      // console.log('click is triggered')
  }

  const defaultOptions = {
      shouldPreventDefault: true,
      delay: 500,
  };
  const useLongPressEvent = useLongPress(onLongPress,onClick,defaultOptions)

  return (
    <div>
      <input
        {...useLongPressEvent}
      style={{float:"left",width:'100%'}}
      type="text" 
      value={input[key1][index]!=undefined?input[key1][index][name]:''}
      onChange={(e) =>{

        console.log(index)
        console.log(key1)
        console.log(input[key1])
        console.log(input[key1][index])
        setInput((prevState) => {
            const clone = JSON.parse(JSON.stringify(prevState));
            const previousArr = clone[key1];
            if(previousArr[index]==undefined){
              previousArr[index]=[]
            }

            previousArr[index][name]=e.target.value;
            if(previousArr[index]['Per Cost']==undefined){
              previousArr[index]['Per Cost']=''
            }
            if(previousArr[index]['Per Price']==undefined){
              previousArr[index]['Per Price']=''
            }
            
            if(name=="Mark Up" && previousArr[index]['Mark Up']!=undefined){
              if(Number(previousArr[index]['Mark Up'])&&Number(previousArr[index]['Per Cost'])){

              console.log(Number(previousArr[index]['Per Cost']),(Number(previousArr[index]['Mark Up']) * 0.01) * Number(previousArr[index]['Per Cost']),(((Number(previousArr[index]['Mark Up']) * 0.01) * Number(previousArr[index]['Per Cost']))+Number(previousArr[index]['Per Cost'])),((((Number(previousArr[index]['Mark Up']) * 0.01) * Number(previousArr[index]['Per Cost']))+Number(previousArr[index]['Per Cost']))*(Number(previousArr[index]['Quantity']))).toFixed(2))
              previousArr[index]['Per Price'] = ((((Number(previousArr[index]['Mark Up']) * 0.01) * Number(previousArr[index]['Per Cost']))+Number(previousArr[index]['Per Cost']))).toFixed(2)
              previousArr[index]['Price'] = (Number(previousArr[index]['Per Price'])*(Number(previousArr[index]['Quantity']))).toFixed(2)
              }
            }else if(name=="Price"){
              previousArr[index]['Per Price'] = (Number(previousArr[index]['Price'])/Number(previousArr[index]['Quantity'])).toFixed(2)
              if(Number(previousArr[index]['Per Cost'])){
                previousArr[index]['Mark Up'] = (((Number(previousArr[index]['Per Price'])-(Number(previousArr[index]['Per Cost'])))/Number(previousArr[index]['Per Cost']))*100).toFixed(2)
              }
            }else if(name=="Per Price"){
              previousArr[index]['Price'] = (Number(previousArr[index]['Per Price'])*Number(previousArr[index]['Quantity'])).toFixed(2)
              if(Number(previousArr[index]['Per Cost'])){
                previousArr[index]['Mark Up'] = (((Number(previousArr[index]['Per Price'])-(Number(previousArr[index]['Per Cost'])))/Number(previousArr[index]['Per Cost']))*100).toFixed(2)
              }
            }else if(name=='Quantity'){
              let totalQuantity=0
              previousArr[index]['Cost'] = (Number(previousArr[index]['Per Cost'])*(Number(previousArr[index]['Quantity']))).toFixed(2)
              previousArr.forEach((objj,index1)=>{
                totalQuantity+=Number(objj['Quantity'])>0?Number(objj['Quantity']):0
                clone['totalQuantity']=totalQuantity
                if(objj['Mark Up']==undefined){
                  console.log(objj['Cost'],objj['Quantity'])
                  objj['Price']=Number(objj['Cost'])*Number(objj['Quantity'])
                }
              })
                if(previousArr[index]['Mark Up']!=undefined){
                  if(previousArr[index]['Per Price']!=undefined&&previousArr[index]['Per Price']!=''){
                    previousArr[index]['Price'] = (Number(previousArr[index]['Per Price'])*Number(previousArr[index]['Quantity'])).toFixed(2)                  
                  }

                }
              console.log(totalQuantity)
            }else if(name=='Per Cost'){
              previousArr[index]['Cost'] = (Number(previousArr[index]['Per Cost'])*Number(previousArr[index]['Quantity'])).toFixed(2)
              previousArr[index]['Per Price'] = ((((Number(previousArr[index]['Mark Up']) * 0.01) * Number(previousArr[index]['Per Cost']))+Number(previousArr[index]['Per Cost']))).toFixed(2)
              previousArr[index]['Price'] = (Number(previousArr[index]['Per Price'])*(Number(previousArr[index]['Quantity']))).toFixed(2)
              
            }else if(name=='Cost'&&previousArr[index]['Quantity']!=''){
              previousArr[index]['Per Cost'] = (Number(previousArr[index]['Cost'])/Number(previousArr[index]['Quantity'])).toFixed(2)
              previousArr[index]['Per Price'] = ((((Number(previousArr[index]['Mark Up']) * 0.01) * Number(previousArr[index]['Per Cost']))+Number(previousArr[index]['Per Cost']))).toFixed(2)
              previousArr[index]['Price'] = (Number(previousArr[index]['Per Price'])*(Number(previousArr[index]['Quantity']))).toFixed(2)
              
            }

            clone[key1]=previousArr

            return clone;   
          })
        // console.log('fuckkkk')
        // const clone = [...input];
        // clone[index][name] = e.target.value;
        // setInput(clone)
        }
      } 
    />
    </div>
  );
}

export default Form1a