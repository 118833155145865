import '../App.css';
import Form from './Form.js';
import Form1 from './Form1.js';
import Form4 from './Form4.js';
import Form6 from './Form6.js';
import Form7 from './Form7.js';
import PartMenu from './PartMenu.js';
import NavMenu from './NavMenu.js';
import UploadFile from './UploadFile.js';
import { useState, useEffect, useRef } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col, Button,Modal,Spinner } from "react-bootstrap";
import { getFunctions, httpsCallable } from "firebase/functions";
import { getStorage,ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { getFirestore,deleteDoc, setDoc, collection, query, where, doc, getDocs, orderBy, arrayUnion, onSnapshot } from "firebase/firestore";
import { getAuth, createUserWithEmailAndPassword, onAuthStateChanged, signInWithEmailAndPassword, signOut, sendEmailVerification, sendPasswordResetEmail } from "firebase/auth";
import firebase from './firebase.js';
import LoginModal from './LoginModal.js'
import costEstimate from '../jsons/costEstimateData.json'
import parts from '../jsons/PARTS.csv'
import XLSX from 'xlsx';
import { csv } from 'd3-request';
import Dropdown from 'react-dropdown';


import { FaCaretDown,FaCaretUp,FaSearch,FaRedo,FaBars } from 'react-icons/fa';



function PartNum({user,db,auth,openMenu,setOpenMenu,setShowLogin,page,setPage,openNavMenu,setOpenNavMenu,userData,number,setNumber,obj,setObj,obj1,setObj1,laborObj,setLaborObj,showCost,setShowCost,currCompany,setCurrCompany,openDrop,setOpenDrop}) {
  
  const storage = getStorage(firebase);
  const [percent,setPercent] = useState({});

  const [showWebModal, setShowWebModal] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [newNew, setNewNew] = useState(true);
  const [docData, setDocData] = useState([])
  const [nextEstNum,setNextEstNum] = useState("")
  const [estDate,setEstDate] = useState("")
  const [searchQ,setSearchQ] = useState("")
  const [docDataMenu, setDocDataMenu] = useState({})
  const [selectedFile, setSelectedFile] = useState([]);
  const [selectedFile1, setSelectedFile1] = useState([]);
  const [selectedFile2, setSelectedFile2] = useState([]);

  const inputRef = useRef();
  const inputRef1 = useRef();
  const inputRef2 = useRef();
  const queryRef = useRef();
  const dateQueryRef = useRef();
  const dragItem = useRef();
  const dragOverItem = useRef();

  const [list, setList] = useState(["Id","Description","Inventory"])
  const [list2, setList2] = useState(['Connector Type', 'Connector Thickness', 'Stud Size & Thread', 'Current Rating','Dimensions'])
  const partList1 = ["Id","Description","Inventory"]; 
  const partList2 = ["Id","Description","Inventory","Base Cost","Labor","Overhead","Material","Total"]; 
  const [list1, setList1] = useState(['Id','Description','Qty.'])
  const compList1 = ['Id','Description','Qty.']
  const compList2 = ['Id','Description','Qty.',"Base Cost","Labor","Overhead","Material","Total"]
  const emptyCompObj = {'Id':'','Description':'','Qty.':'',"Base Cost":'',"Labor":'',"Overhead":'',"Material":'',"Total":''};
  const emptyPartObj = {"Id":"","Approved":false,"Description":"","web":"","Mark Up":"","List Price":"","Inventory":"","Base Cost":"","Labor":"","Overhead":"","Material":"","Total":"","components":[{'Id':'','Description':'','Qty.':'',"Base Cost":'',"Labor":'',"Overhead":'',"Material":'',"Total":''}],"componentIds":[],"images":[],"instructions":[]};

  // useEffect(()=>{
  //   csv(parts, function(err, data) {
  //     console.log(data);
  //     data.forEach((objj,index)=>{
  //       const clone = JSON.parse(JSON.stringify(emptyPartObj))

  //       if(objj['Item']!=undefined&&objj['Item']!=''){
  //         const rere = objj['Item'].replace(/\//g,'-')
  //         clone['Id']=rere
  //         clone['Description']=objj['Description']
  //         console.log(clone)
  //         setDoc(doc(db, 'companies',userData['currCompany'], 'parts', clone['Id']), clone , { merge: true })
  //       }
  //     })
  //   })

  // },[])

  const dragStart = (e, position) => {
    dragItem.current = position;
    // console.log(e.target.innerHTML);
  };
 
  const dragEnter = (e, position) => {
    dragOverItem.current = position;
    // console.log(e.target.innerHTML);
  };

  const drop = (e,objj,setObjj) => {
      setObjj((objjj)=>{

      const objj1 = JSON.parse(JSON.stringify(objjj));
      const copyListItems = objj1['components'];
      console.log(copyListItems)
      const dragItemContent = copyListItems[dragItem.current];
      copyListItems.splice(dragItem.current, 1);
      console.log(copyListItems)
      copyListItems.splice(dragOverItem.current, 0, dragItemContent);
      dragItem.current = null;
      dragOverItem.current = null;
      console.log(copyListItems)
      objj1['components'] = copyListItems;
      console.log(objj1)

      return objj1;
      });
    };

  useEffect(()=>{

    // make array of all part numbers and query that and do searches off of that
    return ()=>{
      if(dateQueryRef.current!=null){
        dateQueryRef.current()
        dateQueryRef.current=null
      }
    }
  },[userData])

  useEffect(()=>{
    if(userData['currCompany']!=undefined&&userData['currCompany']!=''){
      if(number!=''){
        const q = query(collection(db,'companies', userData['currCompany'], "parts"), where("Id", "==", number));
        
        queryRef.current = onSnapshot(q, (snapshot) => {
          snapshot.docChanges().forEach((change) => {
            // console.log(change)
            const data = change.doc.data()
              data['Approved'] = data['Approved'] !=undefined?data['Approved']:false
            if (change.type === "added") {
              setNewNew(false)
              // console.log("New : ", change.doc.data());
              // setDocData(docData =>[...docData, change.doc.data()])
              setObj(data)
                
            }
            if (change.type === "modified") {
              setNewNew(false)
              // console.log("Modified : ", change.doc.data());
              setObj(data)
            }
            
          });
        });
      }
    }
    return ()=>{
      if(queryRef.current!=null){
        queryRef.current()
        queryRef.current=null
      }
    }
  },[number,userData])


  // function chunk(array, size) {
  //   return array.reduce((chunks, item, i) => {
  //     if (i % size === 0) {
  //       chunks.push([item]);
  //     } else {  
  //       chunks[chunks.length - 1].push(item);
  //     }
  //     return chunks;
  //   }, []);
  // }

  const enter = async()=>{
    // const obj2 = JSON.parse(JSON.stringify(obj))
    // obj2['components'] = obj1
    // console.log(obj2)
    if(userData['currCompany']!=undefined&&userData['currCompany']!=''){
      setDoc(doc(db,'companies', userData['currCompany'], "parts", obj['Id']), obj , { merge: true })
      setNewNew(false)
    }
  }

  const uuidv4 = ()=> {
    return ([1e7]+1e3+4e3+8e3+1e11).replace(/[018]/g, c =>
      (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    );
  }

  useEffect(()=>{
    console.log(percent)
    let showLoading=false
    Object.keys(percent).forEach((per,index)=>{
        console.log(per)
      if(percent[per]<100){
        showLoading=true;
        console.log('hey')

      }
    })
    console.log(showLoading)
    setShowLoading(showLoading)

  },[percent])

  useEffect(()=>{
    console.log(percent,showLoading)
    
  },[showLoading])

  const edit = async()=>{
    const obj2 = JSON.parse(JSON.stringify(obj))
    if(obj2['Id']!=undefined&&obj2['Id']!=''){
      const componentIds=[]
      obj2['components'].forEach((objj,index)=>{
        if(objj['Id']!=''){
          componentIds.push(objj['Id'])
        }
      })
      obj2['componentIds']=componentIds
      

    const typeObj = {images:{obj:selectedFile,set:setSelectedFile,ref:inputRef},photos:{obj:selectedFile2,set:setSelectedFile2,ref:inputRef2},instructions:{obj:selectedFile1,set:setSelectedFile1,ref:inputRef1}}

    Object.keys(typeObj).forEach((type)=>{
      setPercent({})

    if(typeObj[type]['obj']!=undefined){
      // need to make this for an array and both images and instructions
      typeObj[type]['obj'].forEach((fileObj,index)=>{
        
        const storeId = uuidv4()
        console.log(storeId)
        if(obj2[type]!=undefined){
          obj2[type].push({storeId:storeId,'name':fileObj.name})
        }else{
          obj2[type]=[]
          obj2[type].push({storeId:storeId,'name':fileObj.name})
        }
          
        const storageRef = ref(storage, `/${type}/${storeId}`);
        const meta = {};
        // progress can be paused and resumed. It also exposes progress updates.
        // Receives the storage reference and the selectedFile to upload.
        const uploadTask = uploadBytesResumable(storageRef, fileObj,meta);

        uploadTask.on(
          "state_changed",
          (snapshot) => {
              const percent = Math.round(
                  (snapshot.bytesTransferred / snapshot.totalBytes) * 100
              );

              // update progress
              setPercent((prevState) => ({
                ...prevState,
                [(type+index)]:percent,
              }));
          },
          (err) => alert(err),
          () => {
           
              typeObj[type]['set']((previousArr) => {
                if(previousArr.length<2){
                  return []
                }else {
                  for(let pa=0;previousArr.length-1;pa++){
                    if(fileObj.name==previousArr[pa].name){
                      const first = previousArr.slice(0, pa)
                      // console.log(first)
                      // console.log(newArray,' 2')
                      const second =previousArr.slice(pa+1)
                      // console.log(second)
                      // newArray.concat(second)
                      // console.log(newArray,' 3')
                      return first.concat(second);
                    }
                  }
                }
              })
              typeObj[type]['ref'].current.value=''
           
            // download url
            getDownloadURL(uploadTask.snapshot.ref).then((url) => {
                console.log(url);
            });
          }
        );
      })
    }
    })

      obj2['partApproved']=false

      console.log(obj2)
      if(userData['currCompany']!=undefined&&userData['currCompany']!=''){
        setDoc(doc(db,'companies', userData['currCompany'], "parts", obj2['Id']), obj2 , { merge: true })
        setNewNew(false)

        // should download part from db to make sure..............
        setObj(obj2)      
      }


  }

  }
  const addRow = (name,thingToAdd)=>{
    console.log('addd',name)
    setObj((prevState) => {
      const clone = JSON.parse(JSON.stringify(prevState));
      if(clone[name]==undefined){
        clone[name] = [thingToAdd]
      }else{
        clone[name].push(thingToAdd)
      }
      return clone;

    })
    // setObjj([...objj, emptyCompObj])
  }
  const removeRow = (x,name)=>{
    console.log('remove', x)
    setObj((prevState) => {
      const clone = JSON.parse(JSON.stringify(prevState));

      const previousArr = clone[name];
        if(previousArr.length>1){

        const first = previousArr.slice(0, x)
        console.log(first)
        
        // console.log(newArray,' 2')
        const second =previousArr.slice(x+1)
        console.log(second)
        // newArray.concat(second)
        // console.log(newArray,' 3')
        clone[name]=first.concat(second)
        return clone;
        }else{
          return clone;
        }
      }
    )
    // objj((previousArr) => {
    //   // console.log(newArray,' 1', x)
    //   if(previousArr.length>1){

    //   const first = previousArr.slice(0, x)
    //   console.log(first)
      
    //   // console.log(newArray,' 2')
    //   const second =previousArr.slice(x+1)
    //   console.log(second)
    //   // newArray.concat(second)
    //   // console.log(newArray,' 3')
    //   return first.concat(second);
    //   }else{
    //     return previousArr;
    //   }
    // })
  }

  useEffect(()=>{
    let add=false
      if(obj['tags']!=undefined){

      if(obj['tags'].length-1>=0&&obj['tags'][obj['tags'].length-1]!==""){
        add=true
      }
      
      for(let x=0;x<=obj['tags'].length-2;x++){
        let remove=0
        list1.forEach((item, index)=>{
          if(obj['tags'][x][item]==""){
            remove+=1
          }
        })
        if(remove==list1.length){
          removeRow(x,'tags')
        }
      }
      if(add){
        console.log('add')
        addRow('tags','')
      }
    }else{
      addRow('tags','')
    }
    console.log(obj['tags'])
  },[obj['tags']])
  
  useEffect(()=>{
    console.log(obj['components'])
    console.log('***************************************')
    let add=false
      if(obj['components']!=undefined){

      list1.forEach((item, index)=>{
        if(obj['components'].length-1>=0&&obj['components'][obj['components'].length-1][item]!==""){
          add=true
        }
      })
      for(let x=0;x<=obj['components'].length-2;x++){
        let remove=0
        list1.forEach((item, index)=>{
          if(obj['components'][x][item]==""){
            remove+=1
          }
        })
        if(remove==list1.length){
          removeRow(x,'components')
        }
      }
      if(add){
        console.log('add')
        addRow('components',emptyCompObj)
      }

      const tempObj={'Conversion1':0,'Labor1':0,'Overhead1':0,'Material1':0,'Total1':0}
      obj['components'].forEach((objj,index)=>{

        tempObj['Conversion1'] = Number(tempObj['Conversion1']) + (objj['Conversion1']!=undefined&&objj['Conversion1']!=''?Number(objj['Conversion1']):0)
        tempObj['Labor1'] = Number(tempObj['Labor1']) + (objj['Labor1']!=undefined&&objj['Labor1']!=''? Number(objj['Labor1']):0)
        tempObj['Overhead1'] = Number(tempObj['Overhead1']) + (objj['Overhead1']!=undefined&&objj['Overhead1']!=''? Number(objj['Overhead1']):0)
        tempObj['Material1'] = Number(tempObj['Material1']) + (objj['Material1']!=undefined&&objj['Material1']!=''? Number(objj['Material1']):0)
        tempObj['Total1'] = Number(tempObj['Total1']) + (objj['Total1']!=undefined&&objj['Total1']!=''? Number(objj['Total1']):0)
      })

      setObj((prevState)=>({
        ...prevState,
      'Conversion': tempObj['Conversion1'].toFixed(2),
      'Labor': tempObj['Labor1'].toFixed(2),
      'Overhead': tempObj['Overhead1'].toFixed(2),
      'Material': tempObj['Material1'].toFixed(2),
      'Total': tempObj['Total1'].toFixed(2),
      }))

      console.log(obj)
    
      }
    
  },[obj['components']]) 

  const reset = ()=>{
    setNewNew(true)
    setObj(emptyPartObj)
    // setObj1([{'COMPONENT':'','DESCRIPTION':'','QTY.':'','COST':''}])
    // setLaborObj([{'Description':'','Rate':'','Parts/hr':'','Labor':'','Overhead':'','Total':''}])

  }

  const next = async ()=>{
    console.log("go to Quote Page")
    // need to make on App.js a componentObj, partNumObj, and costEstimateObj 
    window.history.pushState({}, document.title, window.location.pathname);
    await edit()
    setPage('Quote')
    setNumber(obj['Id'])
    // if ('URLSearchParams' in window) {
    //     var searchParams = new URLSearchParams(window.location.search);
    //     searchParams.set('page','Cost Estimate');
    //     searchParams.set('next','true');
    //     searchParams.set('number',obj['Id']);
    //     window.location.search = searchParams.toString();
    // }
  }

  const approve = async()=>{
      try{

        const clone = JSON.parse(JSON.stringify(obj));
        clone['partApproved']=true
        await setDoc(doc(db, 'companies', userData['currCompany'], "parts", String(clone['Id'])), clone , { merge: true })
        console.log('saved')
        setObj((prev)=>{
          const clone = JSON.parse(JSON.stringify(prev));
          clone['partApproved']=true
          return clone
        })
        console.log('set')

      }
      catch(err){
        alert(err)
      }
    }
  
  const web =()=>{
    setShowWebModal(true)
  }


  return (
    <Container style={{height:'100%','marginTop':56}} >
    {openMenu&&
      <div style={{height:'100%',width:'100%',zIndex:'1',position:'absolute',top:0,right:0,opactiy:100}} onClick={()=>{if(openMenu){setOpenMenu(false)};if(openNavMenu){setOpenNavMenu(false)}}}/>
    }
      <div style={{'zIndex':'101'}}  >
      <Row>
        <div style={{ marginBottom: 20}} >
        {userData['currCompany']!=undefined?
          userData['companies'].length>1?
          <span className="fuck1">
            <Dropdown 
              className="dropdown"
              placeholderClassName="fuck"
              arrowClosed={<FaCaretDown className="arrow-closed" />}
              arrowOpen={<FaCaretUp className="arrow-open" />}
              controlClassName="dropdownC" open={openDrop} options={Object.keys(userData['companiesMap'])} onChange={(e)=>{setCurrCompany(e.value)}} value={currCompany}>
              
            </Dropdown>
          </span>      
          :
          <span>{userData['currCompany']}</span>
          :''}
        </div>
      </Row>
      <Row>
        <div style={{ marginBottom: 20}} >
          <span style={{cursor: 'pointer'}} onClick={reset}>
            <FaRedo />  Product
            {/*<FaCaretDown />*/}
          </span>
        </div>
      </Row>
      <span>
    {showCost?

    <Row> 
      <Col xs="2" lg="2">
        <div style={{overflowX:"hidden"}}>Product Id</div>
      </Col>
      <Col xs="4" lg="4">
        <div style={{overflowX:"hidden"}}>Description</div>
      </Col>
      <Col xs="1" lg="1">
        <div style={{overflowX:"hidden"}}>Inventory</div>
      </Col>
      <Col xs="1" lg="1">
        <div style={{overflowX:"hidden"}}>Labor</div>
      </Col>
      <Col xs="1" lg="1">
        <div style={{overflowX:"hidden"}}>Overhead</div>
      </Col>
      <Col xs="1" lg="1">
        <div style={{overflowX:"hidden"}}>Converison</div>
      </Col>
      <Col xs="1" lg="1">
        <div style={{overflowX:"hidden"}}>Material</div>
      </Col>
      <Col xs="1" lg="1">
        <div style={{overflowX:"hidden"}}>Total</div>
      </Col>      
    </Row>
    :
    <Row> 
      <Col xs="4" lg="4">
        <div style={{overflowX:"hidden"}}>Product Id</div>
      </Col>
      <Col xs="4" lg="4">
        <div style={{overflowX:"hidden"}}>Description</div>
      </Col>
      <Col xs="4" lg="4">
        <div style={{overflowX:"hidden"}}>Inventory</div>
      </Col>
      
    </Row>
  }
  </span>

    <span>
    {showCost?
    <Row > 
      <Col xs="2" lg="2" style={{paddingRight:0, paddingLeft:0,}}>
        <Form7 name={'Id'} input={obj} setInput={setObj} db={db} userData={userData} searchName={'parts'} />
      </Col>
      <Col xs="4" lg="4" style={{paddingRight:0, paddingLeft:0,}}>
        <Form name={'Description'} input={obj} setInput={setObj} />
      </Col>
      <Col xs="1" lg="1" style={{paddingRight:0, paddingLeft:0,}}>
        <Form name={'Inventory'} input={obj} setInput={setObj} />
      </Col>
      <Col xs="1" lg="1" style={{paddingRight:0, paddingLeft:0,}}>
        <Form name={'Labor'} input={obj} setInput={setObj} />
      </Col>
      <Col xs="1" lg="1" style={{paddingRight:0, paddingLeft:0,}}>
        <Form name={'Overhead'} input={obj} setInput={setObj} />
      </Col>
      <Col xs="1" lg="1" style={{paddingRight:0, paddingLeft:0,}}>
        <Form name={'Conversion'} input={obj} setInput={setObj} />
      </Col>
      <Col xs="1" lg="1" style={{paddingRight:0, paddingLeft:0,}}>
        <Form name={'Material'} input={obj} setInput={setObj} />
      </Col>
      <Col xs="1" lg="1" style={{paddingRight:0, paddingLeft:0,}}>
        <Form name={'Total'} input={obj} setInput={setObj} />
      </Col>
      
    </Row>
    :
    <Row > 
      <Col xs="4" lg="4" style={{paddingRight:0, paddingLeft:0,}}>
        <Form7 name={'Id'} input={obj} setInput={setObj} db={db} userData={userData} searchName={'parts'} />
      </Col>
      <Col xs="4" lg="4" style={{paddingRight:0, paddingLeft:0,}}>
        <Form name={'Description'} input={obj} setInput={setObj} />
      </Col>
      <Col xs="4" lg="4" style={{paddingRight:0, paddingLeft:0,}}>
        <Form name={'Inventory'} input={obj} setInput={setObj} />
      </Col>
    </Row>

    }
    </span>

    <Row style={{paddingTop:20,justifyContent:'center',flex:1,alignItems:'center'}} > 
      <Col xs="3" lg="3" style={{paddingRight:0, paddingLeft:0,overflowX:"hidden"}}>
          <span>
          Add product to web
          </span>
        
      </Col>
      <Col xs="3" lg="3" style={{paddingRight:0, paddingLeft:0,overflowX:"hidden"}}>
          <span>
          Requires Quote
          </span>
        
      </Col>
      <Col xs="3" lg="3" style={{paddingRight:0, paddingLeft:0,overflowX:"hidden"}}>
      Mark Up
      </Col>
      <Col xs="3" lg="3" style={{paddingRight:0, paddingLeft:0,overflowX:"hidden"}}>
      List Price
      </Col>
    </Row>

    <Row style={{paddingTop:20,justifyContent:'center',flex:1,alignItems:'center'}} > 
      <Col xs="3" lg="3" style={{paddingRight:0, paddingLeft:0,overflowX:"hidden"}}>
        <label>
          <input style={{textAlign:'left'}} type='radio' 
            value={obj['web']} 
            name={'web'}
            checked={obj['web']}
            onClick={()=>{
                setObj((prev)=>{
                  const clone = JSON.parse(JSON.stringify(prev))
                  console.log(clone['web'])
                  clone['web']=clone['web']!=undefined?clone['web']?false:true:true
                  return clone
                })
            }}
            >
          </input>
        </label>
      </Col>
      <Col xs="3" lg="3" style={{paddingRight:0, paddingLeft:0,overflowX:"hidden"}}>
        <label>
          <input style={{textAlign:'left'}} type='radio' 
            value={obj['requiresQuote']} 
            name={'requiresQuote'}
            checked={obj['requiresQuote']}
            onClick={()=>{
                setObj((prev)=>{
                  const clone = JSON.parse(JSON.stringify(prev))
                  console.log(clone['requiresQuote'])
                  clone['requiresQuote']=clone['requiresQuote']!=undefined?clone['requiresQuote']?false:true:true
                  return clone
                })
            }}
            >
          </input>
        </label>
      </Col>
      
      <Col xs="3" lg="3" style={{paddingRight:0, paddingLeft:0,overflowX:"hidden"}}>
        <Form name={'Mark Up'} input={obj} setInput={setObj}/>
      </Col>
      <Col xs="3" lg="3" style={{paddingRight:0, paddingLeft:0,overflowX:"hidden"}}>
        <Form name={'List Price'} input={obj} setInput={setObj}/>
      </Col>
      
    </Row>
    
    {/*<Row style={{paddingTop:20,justifyContent:'center',flex:1,alignItems:'center'}} > 
      <Col xs="12" lg="12" style={{paddingRight:0, paddingLeft:0,overflowX:"hidden"}}>
        Tags
      </Col>
    </Row>
    <Row style={{paddingTop:20,justifyContent:'center',flex:1,alignItems:'center'}} > 
      {
        obj['tags']!=undefined&&
        obj['tags'].map((item,index)=>{
          console.log(item)
          return(
              <Col key={item+index} xs="3" lg="3" style={{paddingRight:0, paddingLeft:0,overflowX:"hidden"}}>
                <Form name={'tags'} input={obj['tags'][index]} setInput={setObj}/>
              </Col>
            )
        }) 
      }
    </Row>*/}
    

{/*
    <Row style={{paddingTop:20,justifyContent:'center',flex:1,alignItems:'center'}} > 
    {
      list2.map((item,index)=>{

        if(item!='Dimensions'){
          return(            
              <Col xs="2" lg="2" style={{paddingRight:0, paddingLeft:0,overflowX:"hidden"}}>
                {item}
              </Col>
              )
        }else{
          return(
            <Col xs="4" lg="4" style={{paddingRight:0, paddingLeft:0,overflowX:"hidden"}}>
              <Row>
                <Col xs="12" lg="12" style={{paddingRight:0, paddingLeft:0,}}>
                  Dimensions
                </Col>
              </Row>
              <Row>
                <Col xs="6" lg="6" style={{paddingRight:0, paddingLeft:0,}}>
                  L
                </Col>
                <Col xs="6" lg="6" style={{paddingRight:0, paddingLeft:0,}}>
                  B
                </Col>
              </Row>
            </Col>
          )
        }
      })
    }
    </Row>

    <Row style={{paddingTop:20}} > 
    {
      list2.map((item,index)=>{

        if(item!='Dimensions'){
          return(            
              <Col xs="2" lg="2" style={{paddingRight:0, paddingLeft:0,}}>
                <Form name={item} input={obj} setInput={setObj}/>
              </Col>
              )
        }else{
          return(
            <Col xs="4" lg="4" style={{paddingRight:0, paddingLeft:0,}}>
              <Row>
                <Col xs="6" lg="6" style={{paddingRight:0, paddingLeft:0,}}>
                  <Form name={'L'} input={obj} setInput={setObj}/>
                </Col>
                <Col xs="6" lg="6" style={{paddingRight:0, paddingLeft:0,}}>
                  <Form name={'B'} input={obj} setInput={setObj}/>
                </Col>
              </Row>
            </Col>
          )
        }
      })
    }
    </Row>*/}

    


    
    <Row style={{paddingTop:10,paddingBottom:10}}> 
      <Col xs="12" lg="12">
        <span style={{borderBottom:"1px solid #000"}}>
        Components
        </span>
      </Col> 
    </Row> 

    <span>
    {showCost?
    <Row> 
      <Col xs="2" lg="2">
        <div style={{overflowX:"hidden"}}>Component Id</div>
      </Col>
      <Col xs="4" lg="4">
        <div style={{overflowX:"hidden"}}>Description</div>
      </Col>
      <Col xs="1" lg="1">
        <div style={{overflowX:"hidden"}}>Qty.</div>
      </Col>
      <Col xs="1" lg="1">
        <div style={{overflowX:"hidden"}}>Labor</div>
      </Col>
      <Col xs="1" lg="1">
        <div style={{overflowX:"hidden"}}>Overhead</div>
      </Col>
      <Col xs="1" lg="1">
        <div style={{overflowX:"hidden"}}>Conversion</div>
      </Col>
      <Col xs="1" lg="1">
        <div style={{overflowX:"hidden"}}>Material</div>
      </Col>
      <Col xs="1" lg="1">
        <div style={{overflowX:"hidden"}}>Total</div>
      </Col>
      
    </Row>
    :
    <Row> 
      <Col xs="4" lg="4">
        <div style={{overflowX:"hidden"}}>Component Id</div>
      </Col>
      <Col xs="4" lg="4">
        <div style={{overflowX:"hidden"}}>Description</div>
      </Col>
      <Col xs="4" lg="4">
        <div style={{overflowX:"hidden"}}>Qty.</div>
      </Col>
      
    </Row>
    }
    </span>
      
    {obj['components']!=undefined ?obj['components'].map((objj1, index1) => {
      {/*console.log('kkkkkkkkk')*/}
      return (
          <span>
          {showCost?
          <Row 
            // onDragEnter={(e) => dragEnter(e, index1)}
            // onDragStart={(e) => dragStart(e, index1)}
            // onDragEnd={(e) => drop(e,obj,setObj)}
            key={index1}
            // draggable
          > 

            <Col xs="2" lg="2" style={{paddingRight:0, paddingLeft:0,}}>
              <Form4 index={index1} name={'Id'} input={obj} setInput={setObj} db={db} userData={userData} />
            </Col>
            <Col xs="4" lg="4" style={{paddingRight:0, paddingLeft:0,}}>
              <Form1 index={index1} index={index1} name={'Description'} input={obj} setInput={setObj} />
            </Col>
            <Col xs="1" lg="1" style={{paddingRight:0, paddingLeft:0,}}>
              <Form1 index={index1} name={'Qty.'} input={obj} setInput={setObj} />
            </Col>
            <Col xs="1" lg="1" style={{paddingRight:0, paddingLeft:0,}}>
              <div style={{height:'30px',width:'100%', border:'1px solid black',borderRadius:3,textAlign:'left',paddingLeft: '3px'}} >{obj['components'][index1]['Labor1']!=undefined?obj['components'][index1]['Labor1']:''}</div>
            </Col>
            <Col xs="1" lg="1" style={{paddingRight:0, paddingLeft:0,}}>
              <div style={{height:'30px',width:'100%', border:'1px solid black',borderRadius:3,textAlign:'left',paddingLeft: '3px'}} >{obj['components'][index1]['Overhead1']!=undefined?obj['components'][index1]['Overhead1']:''}</div>
            </Col>
            <Col xs="1" lg="1" style={{paddingRight:0, paddingLeft:0,}}>
              <div style={{height:'30px',width:'100%', border:'1px solid black',borderRadius:3,textAlign:'left',paddingLeft: '3px'}} >{obj['components'][index1]['Conversion1']!=undefined?obj['components'][index1]['Conversion1']:''}</div>
            </Col>
            <Col xs="1" lg="1" style={{paddingRight:0, paddingLeft:0,}}>
              <div style={{height:'30px',width:'100%', border:'1px solid black',borderRadius:3,textAlign:'left',paddingLeft: '3px'}} >{obj['components'][index1]['Material1']!=undefined?obj['components'][index1]['Material1']:''}</div>
            </Col>
            <Col xs="1" lg="1" style={{paddingRight:0, paddingLeft:0,}}>
              <div style={{height:'30px',width:'100%', border:'1px solid black',borderRadius:3,textAlign:'left',paddingLeft: '3px'}} >{obj['components'][index1]['Total1']!=undefined?obj['components'][index1]['Total1']:''}</div>
            </Col>

          </Row>
          :
          <Row 
            // onDragEnter={(e) => dragEnter(e, index1)}
            // onDragStart={(e) => dragStart(e, index1)}
            // onDragEnd={(e) => drop(e,obj,setObj)}
            key={index1}
            // draggable
          > 

            <Col xs="4" lg="4" style={{paddingRight:0, paddingLeft:0,}}>
              <Form4 index={index1} name={'Id'} input={obj} setInput={setObj} db={db} userData={userData} />
            </Col>
            <Col xs="4" lg="4" style={{paddingRight:0, paddingLeft:0,}}>
              <Form1 index={index1} name={'Description'} input={obj} setInput={setObj} />
            </Col>
            <Col xs="4" lg="4" style={{paddingRight:0, paddingLeft:0,}}>
              <Form1 index={index1} name={'Qty.'} input={obj} setInput={setObj} />
            </Col>
           

          </Row>
          }
          </span>
        );
      }):<span>Whoops</span>}    
    <div style={{paddingBottom:"10px",borderBottom:"1px solid #000"}}/>

    <Row style={{paddingBottom:"10px",paddingTop:"10px",borderBottom:"1px solid #000"}}>
      <Col xs="12" lg="12">
        <span >
        <span style={{borderBottom:"1px solid #000"}}>
          Photos
          </span>
          <UploadFile setSelectedFile={setSelectedFile2} selectedFile={selectedFile2} obj={obj} setObj={setObj} type={'photos'} inputRef={inputRef2} storage={storage} userData={userData} db={db} />
        </span>
      </Col>
    </Row>

    <Row style={{paddingBottom:"10px",paddingTop:"10px",borderBottom:"1px solid #000"}}>
      <Col xs="12" lg="12">
        <span >
        <span style={{borderBottom:"1px solid #000"}}>
          Drawing
          </span>
          <UploadFile setSelectedFile={setSelectedFile} selectedFile={selectedFile} obj={obj} setObj={setObj} type={'images'} inputRef={inputRef} storage={storage} userData={userData} db={db} />
        </span>
      </Col>
    </Row>

    <Row style={{paddingBottom:"10px",paddingTop:"10px",borderBottom:"1px solid #000"}}>
      <Col xs="12" lg="12">
        <span style={{borderBottom:"1px solid #000"}}>
        Instructions
        </span>
        <UploadFile setSelectedFile={setSelectedFile1} selectedFile={selectedFile1} obj={obj} setObj={setObj} type={'instructions'} inputRef={inputRef1} storage={storage} userData={userData} db={db} />
      </Col>
    </Row>

      {
        (obj['Part Id']!=''&&userData['companiesMap']!=undefined&&userData['companiesMap'][userData['currCompany']]!=undefined&&userData['companiesMap'][userData['currCompany']]['admin'])&&(obj['partApproved']==undefined||!obj['partApproved'])?
        <Row style={{paddingBottom:"10px",borderBottom:"1px solid #000"}}>
          <Col xs="6" lg="6">
            <Button style={{ marginTop: 30}} onClick={edit} >Save</Button>
          </Col>
          {/*<Col xs="6" lg="6">
            <Button style={{ marginTop: 30}} onClick={web} >Web</Button>
          </Col>*/}
        
          <Col xs="6" lg="6">
            <Button style={{ marginTop: 30}} onClick={approve} >Approve Product</Button>
          </Col>
        </Row>
        :
        (obj['Part Id']!=''&&userData['companiesMap']!=undefined&&userData['companiesMap'][userData['currCompany']]!=undefined&&userData['companiesMap'][userData['currCompany']]['admin'])?
        <Row style={{paddingBottom:"10px",borderBottom:"1px solid #000"}}>
          <Col xs="12" lg="12">
            <Button style={{ marginTop: 30}} onClick={edit} >Save</Button>
          </Col>
          {/*<Col xs="6" lg="6">
            <Button style={{ marginTop: 30}} onClick={web} >Web</Button>
          </Col>*/}
        </Row>
        :
        <span>
        </span>
      }



  </div>
  {showWebModal&&
  <Modal
    show={true}
    onHide={()=>{setShowWebModal(false)}}
    backdrop="static"
    // keyboard={false}
  >
  <Modal.Header closeButton>
          <Modal.Title>Web</Modal.Title>
        </Modal.Header>
    <Modal.Body>
      
        <div>
        <label>
          <input style={{textAlign:'left'}} type='radio' 
            value={obj['web']} 
            name={'web'}
            checked={obj['web']}
            onClick={()=>{
                setObj((prev)=>{
                  const clone = JSON.parse(JSON.stringify(prev))
                  console.log(clone['web'])
                  clone['web']=clone['web']!=undefined?clone['web']?false:true:true
                  return clone
                })
            }}
            >
          </input>
          <span style={{padding:5}}>
          Add product to web
          </span>
        </label>
        </div>
    </Modal.Body>
  </Modal>
  }

  {showLoading&&
  <Modal
    show={true}
    onHide={()=>{setShowLoading(false)}}
    backdrop="static"
    // keyboard={false}
  >
  <Modal.Header closeButton>
          <Modal.Title>Uploading</Modal.Title>
        </Modal.Header>
    <Modal.Body>
      <Spinner animation="border" role="status">
      </Spinner>
        <span style={{padding:5}}>Percent... {
          Object.keys(percent).map((per,index)=>{
              {/*console.log(per)*/}
            if(percent[per]!=undefined){

            return (
              <div>
              {percent[per]}
              </div>
              )
            }else{
              return
            }
          })
        }</span>
    </Modal.Body>
  </Modal>
  }
  
  <div className='nav1' style={{height:'100%','zIndex':101}}>
    <PartMenu 
        open={openMenu} 
        setOpen={setOpenMenu} 
        db={db}
        list={list}
        setObj={setObj}
        setObj1={setObj1}
        setNewNew={setNewNew}
        docData={docDataMenu}
        setDocData={setDocDataMenu}
        userData={userData}
        dbSection="parts"
        laborObj={laborObj}
        setLaborObj={setLaborObj}
        setSelectedFile={setSelectedFile}
        setSelectedFile1={setSelectedFile1}
      />
      {/*<NavMenu 
        open={openNavMenu} 
        setOpen={setOpenNavMenu} 
        db={db}  
        user={user} 
        auth={auth}     
        setShowLogin={setShowLogin}     
        page={page}  
        setPage={setPage}  
        userData={userData}
        showCost={showCost}
        setShowCost={setShowCost}   
      />*/}
  </div>

  </Container>
  );
}

export default PartNum;
