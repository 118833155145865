import { useState, useEffect,useRef } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import useLongPress from './useLongPress.js';
import { collection, query, where, getDoc, doc, orderBy, onSnapshot } from "firebase/firestore";
import SearchItem from './SearchItem.js';
import { BrowserRouter as useNavigate,useLocation } from "react-router-dom";




const Form7 = ({name, setInput, input, index, userData, db ,setObj1,setSearch,isArray,searchName,emptyObj,custObj,setCustObj})=> {
  // useEffect(()=>{
  //   console.log(name)
  //   console.log(input)
  //   console.log(index)  
  // },[])
  // useEffect(()=>{
  //   console.log(input)
  // },[input])
  const location = useLocation()
  const [focused, setFocused] = useState(false)
  const [docData, setDocData] = useState({})
  const onFocus = () => setFocused(true)
  const onBlur = () => startTimer()
  const queryRef = useRef();

  const [counter, setCounter] = useState(0);
  const timer = useRef(null); // we can save timer in useRef and pass it to child

  const startTimer = ()=>{
     timer.current = setTimeout(() => {setFocused(false);}, 250);
  }

    useEffect(
    () => {

      // this will clear Timeout
      // when component unmount like in willComponentUnmount
      // and show will not change to true
      return () => {
        if(timer.current!=null){
          clearTimeout(timer.current);
        }
      };
    },
    // useEffect will run only one time with empty []
    // if you pass a value to array,
    // like this - [data]
    // than clearTimeout will run every time
    // this value changes (useEffect re-run)
    []
  );

  
  useEffect(()=>{
    return ()=>{
      if(queryRef.current!=null){
        queryRef.current()
        queryRef.current=null
      }

    }
  },[])


  const searchWorkers = async (e)=>{
    console.log(e.target.value)
    console.log(docData)
    console.log(name)
    // const name1 = name=="Quote Id"?'Id':name
    const name1 = name
    // if(e.target.value.length>2){
      setDocData({})
      if(userData['currCompany']!=undefined&&userData['currCompany']!=''){
        const q = query(collection(db,'companies', userData['currCompany'], searchName), where(name1, ">=", e.target.value), where(name1, "<=", e.target.value+'\uf8ff'));
      // console.log(q)
        if(queryRef.current!=null){
          queryRef.current()
        }
        
        queryRef.current = onSnapshot(q, (snapshot) => {
          snapshot.docChanges().forEach((change) => {
            console.log(change)
            if (change.type === "added") {
                console.log("New : ", change.doc.data(), change.doc.id);
                // setDocData(docData =>[...docData, change.doc.data()])
                setDocData((prevState) => ({
                ...prevState,
                  [change.doc.id]: change.doc.data()
                }
              ))
            }
            if (change.type === "modified") {
                console.log("Modified : ", change.doc.data());
                const data = change.doc.data()
                setInput((prevState)=>{
                  const clone = JSON.parse(JSON.stringify(prevState));
                console.log(clone['Id'],data['Id']);
                console.log(clone,data);
                  
                  if(name=='Id'){

                    if(clone['Id']==data['Id']&&searchName!='parts'){
                              console.log('into not parts......................')
                      // setInput(data)
                        return data
                    }else if(searchName=='parts'){
                              console.log('into parts......................')
                        // const data = change.doc.data()
                        data['components'].forEach((objj,int)=>{
                          clone['components'].forEach((objjj,int1)=>{
                            if(objj['Id']==objjj['Id'] ){
                              console.log('should be doing it',objj,objjj)
                              clone['components'][int1] = objj
                            }
                          })
                        })

                        return clone
                    }
                  }
                  return prevState
                })
                setDocData((prevState) => ({
                ...prevState,
                  [change.doc.id]: change.doc.data()
                }
              ))
            }
            if (change.type === "removed") {
                setDocData((prevData) => {
                  const newData = {...prevData}
                  delete newData[change.doc.id]
                  return newData;
                })
            }
          });
        });
      }
    // }
  }

  const fillInForm = async (obj,key1)=>{
    console.log(obj)
    if(obj['Id']==undefined){
      obj['Id']=key1
    }
    if(location.pathname=='/Components' && name=='Customer'){
      setInput((prevState)=>{
        const clone = JSON.parse(JSON.stringify(prevState));

        if(clone['ApprovedVendors']==undefined){
          clone['ApprovedVendors']={}
        }
        const approvedVendor = {"Customer":obj['Customer'],"Customer Id":obj['Id']}
        console.log(approvedVendor)
        clone['ApprovedVendors'][obj['Id']] = approvedVendor
        clone['Customer'] = ''

        // clone['ApprovedVendors'].push(approvedVendor)

        return clone
      })
    }else if(location.pathname!='/Customers'&& searchName=="Customer"){
      console.log('turd')
      setCustObj(obj)
      setInput((prevState)=>{
        let clone = JSON.parse(JSON.stringify(prevState));
        
        clone["Customer"]= obj['Customer']
        if(clone["Contact Person"]!=undefined){
          clone["Contact Person"]= obj['contactPerson'][0]["Contact Person"]
          clone["Customer Id"]= obj['Id']

        }else{
          clone = obj
        }
        return clone
      })
    }
    else if( (location.pathname=='/Quotes'||location.pathname=='/PurchaseOrders')&&(name=='Id'||name=='Customer PO Id') ){
      setInput((prevState)=>{

      const clone = JSON.parse(JSON.stringify(emptyObj));
      // console.log(clone)
      Object.keys(clone).forEach((item,index)=>{
      // console.log(obj[item],clone[item])
        if(obj[item]!=undefined){
          clone[item]=obj[item]
        }
      })
      let totalQuantity=0
      obj['Parts'].forEach((objj,index)=>{
        totalQuantity+=Number(objj['Quantity'])>0?Number(objj['Quantity']):0
        if(objj['Per Cost']!=undefined){
          objj['Per Cost']=''
        }
        if(objj['Per Price']!=undefined){
          objj['Per Price']=''
        }
      })
      clone['totalQuantity']=totalQuantity
      
      return clone
      })

    }
    else{
      setInput(obj)
    }
    if(setSearch!=undefined){
      setSearch(true)
    }
  }
const onLongPress = () => {
      console.log(input,name, input[name], 'longpress is triggered');
      if(name=="Customer"){

        // need to set up worker page

        // setObj1({Worker:input['Worker'],Rate:input['Rate']})
        window.history.pushState({}, document.title, window.location.pathname);
        let loc = String(window.location);
        let locSplit = loc.split('/')
        window.open(String(locSplit[0]+'/Customers'+"?CustomerId="+input['Customer Id']),'_blank');
        
      }
  };

  const onClick = () => {
      // console.log('click is triggered')
  }

  const defaultOptions = {
      shouldPreventDefault: true,
      delay: 500,
  };
  const useLongPressEvent = useLongPress(onLongPress,onClick,defaultOptions)

  return (
    <div>
      <input
        {...useLongPressEvent}
        style={{float:"left",width:'100%'}}
        type="text" 
        value={input[name]}
        onFocus={onFocus} onBlur={onBlur}
        onChange={(e) =>{
          setInput((prevState) => ({
            ...prevState,
              [name]: e.target.value,
            }
          ))
          searchWorkers(e)
          }
        } 
      />
      {focused && Object.keys(docData).length >= 1 &&
        Object.keys(docData).map((objj, index) => {
          {/*console.log(objj)*/}
         return (

            // <SearchItem key={index+"floof"} key1={objj} obj={docData[objj]} setFocused={setFocused} onClick={fillInForm} searchLabel={name=="Quote Id"?'Id':name} /> 
            <SearchItem key={index+"floof"} key1={objj} obj={docData[objj]} setFocused={setFocused} onClick={fillInForm} searchLabel={name} /> 
          );

        })
      }
    </div>
  );
}

export default Form7