import { useState, useEffect,useRef } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import useLongPress from './useLongPress.js';
import { collection, query, where, getDoc, doc, orderBy, onSnapshot } from "firebase/firestore";
import SearchItem from './SearchItem.js';



const Form7c = ({name, setInput, input, index, userData, db ,setObj1,setSearch,isArray,searchName,emptyObj,custObj,setCustObj})=> {
  // useEffect(()=>{
  //   console.log(name)
  //   console.log(input)
  //   console.log(index)  
  // },[])
  // useEffect(()=>{
  //   console.log(input)
  // },[input])

  const [focused, setFocused] = useState(false)
  const [docData, setDocData] = useState([])
  const onFocus = () => setFocused(true)
  const onBlur = () => startTimer()
  const queryRef = useRef();

  const [counter, setCounter] = useState(0);
  const timer = useRef(null); // we can save timer in useRef and pass it to child

  const startTimer = ()=>{
     timer.current = setTimeout(() => {setFocused(false);}, 250);
  }

    useEffect(
    () => {

      // this will clear Timeout
      // when component unmount like in willComponentUnmount
      // and show will not change to true
      return () => {
        if(timer.current!=null){
          clearTimeout(timer.current);
        }
      };
    },
    // useEffect will run only one time with empty []
    // if you pass a value to array,
    // like this - [data]
    // than clearTimeout will run every time
    // this value changes (useEffect re-run)
    []
  );

  
  useEffect(()=>{
    return ()=>{
      if(queryRef.current!=null){
        queryRef.current()
        queryRef.current=null
      }

    }
  },[])


  const searchCusty = async (e)=>{
    console.log(e.target.value)
    console.log(docData)
    console.log(name)

      setDocData([])


      // setDocData((prevState) => {
      //   let clone = JSON.parse(JSON.stringify(prevState));
      //   custObj['contactPerson'].forEach((item)=>{
      //     clone['Contact Person'].push(item['Contact Person'])
      //   })
      //   // need to filter this list by  e.target.value
      // })
      if(input['Customer Id']!=undefined&&input['Customer Id'] == custObj['Id']){
        setDocData(custObj['contactPerson'])
      }else if(userData['currCompany']!=undefined&&userData['currCompany']!=''&& input['Customer Id']!=''){
          const q = doc(db,'companies', userData['currCompany'], 'Customer',input['Customer Id']);
          const docSnap = await getDoc(q);
          const data = docSnap.data()
          
          setDocData(data['contactPerson'])
   
        
      }
    
    
  }

  const fillInForm = async (obj,key1)=>{
    console.log(obj)
    setInput((prevState)=>({
      ...prevState,
      ['Contact Person']:obj['Contact Person']
    }))
    
    if(setSearch!=undefined){
      setSearch(true)
    }
  }

  return (
    <div>
      <input
        style={{float:"left",width:'100%'}}
        type="text" 
        value={input[name]}
        onFocus={onFocus} onBlur={onBlur}
        onChange={(e) =>{
          setInput((prevState) => ({
            ...prevState,
              [name]: e.target.value,
            }
          ))
          searchCusty(e)
          }
        } 
      />
      {focused && Object.keys(docData).length >= 1 &&
        Object.keys(docData).map((objj, index) => {
          {/*console.log(objj)*/}
         return (

            // <SearchItem key={index+"floof"} key1={objj} obj={docData[objj]} setFocused={setFocused} onClick={fillInForm} searchLabel={name=="Quote Id"?'Id':name} /> 
            <SearchItem key={index+"floof"} key1={objj} obj={docData[objj]} setFocused={setFocused} onClick={fillInForm} searchLabel={name} /> 
          );

        })
      }
    </div>
  );
}

export default Form7c