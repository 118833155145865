// firebase deploy --only hosting:inventory101
import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import Header from './Components/Header.js'

import Form from './Components/Form.js';
import Form1 from './Components/Form1.js';
import Menu from './Components/Menu.js';
import NavMenu from './Components/NavMenu.js';
import CostEstimate from './Components/CostEstimate.js';
import PartNum from './Components/PartNum.js';
import Component from './Components/Component.js';
import Worker from './Components/Worker.js';
import Quote from './Components/Quote.js';
import Shop from './Components/Shop.js';
import Product from './Components/Product.js';
import Cart from './Components/Cart.js';
import PurchaseOrder from './Components/PurchaseOrder.js';
import Returns from './Components/Returns.js';
import Customer from './Components/Customer.js';
import BillOfLading from './Components/BillOfLading.js';
import Invoice from './Components/Invoice.js';
import Flow from './Components/Flow.js';
import Checkout from './Components/Checkout.js';
import { useState, useEffect, useRef } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col, Button } from "react-bootstrap";
import { getFunctions, httpsCallable } from "firebase/functions";
import {  } from "firebase/firestore";
import { getFirestore, setDoc, collection, query, where, doc, getDocs, orderBy, arrayUnion, onSnapshot } from "firebase/firestore";
import { getAuth, createUserWithEmailAndPassword, onAuthStateChanged, signInWithEmailAndPassword, signOut, sendEmailVerification, sendPasswordResetEmail } from "firebase/auth";
import firebase from './Components/firebase.js';
import LoginModal from './Components/LoginModal.js'
import costEstimate from './jsons/costEstimateData.json'
import { FaCaretDown,FaCaretUp,FaSearch,FaRedo,FaBars } from 'react-icons/fa';



function App() {

  const auth = getAuth()
  const functions = getFunctions(firebase);
  const db = getFirestore();

  const [user, setUser] = useState(null); 
  const [expanded, setExpanded] = useState(false); 

  const [openMenu, setOpenMenu] = useState(false); 
  const [openNavMenu, setOpenNavMenu] = useState(false); 
  const [showLogin, setShowLogin] = useState(false);
  const [currCompany, setCurrCompany] = useState('')
  const [userData, setUserData] = useState({})
  
  const [componentObj, setComponentObj] = useState({'Id':'','Description':'','Conversion':0,'Worker':'','Rate':0,'Parts/hr':0,'Labor':0,'Overhead':0,'Fixed Overhead':0,'Material':0,'Total':0})
  const [partObj, setPartObj] = useState({"Id":"","Approved":false,"Description":"","Inventory":"","Base":"","Labor":"","Overhead":"","Material":"","Total":"","components":[{'Id':'','Description':'','Qty.':'',"Base":'',"Labor":'',"Overhead":'',"Material":'',"Total":''}]})
  const [partObj1, setPartObj1] = useState([])
  const [laborObj, setLaborObj] = useState([{'Description':'','Worker':'','Rate':'','Parts/hr':'','Labor':'','Overhead':'','Material':'','Total':''}])
  const [laborObj1, setLaborObj1] = useState({'Description':'','Worker':'','Rate':'','Parts/hr':'','Labor':'','Overhead':'','Material':'','Total':''})
  const [workerObj, setWorkerObj] = useState({'Id':'','Description':'','Rate':''})
  const [showCost, setShowCost] = useState(false);
  const [openDrop, setOpenDrop] = useState(false);
  const [showOutlet, setShowOutlet] = useState(false);
  const [flowlist, flowsetList] = useState(["Id","Date","Customer","Parts","Total"])
  const [cartObj, setCartObj] = useState({});


  const [customerObj, setCustomerObj] = useState({"Id":'',"Customer":"",contactPerson:[{"Contact Person":"","Phone Number":"","Email":""}],"contactPeople":[],"phoneNumbers":[],"emails":[],"addresses":[],"addresses2":[],"cities":[],"zips":[],"states":[],"countries":[],"Addresses":[{'Address':'','Address 2':'','City':'','Zip':'','State':'','Country':''}]})
  const [orderObj, setOrderObj] = useState({"Id":"","Open":true,"Status":"Quote",'splitOrder':false,'totalQuantity':0,"Customer PO Id":"","Customer":"","Customer Id":"","Date":"","Contact Person":"","Total":"","PartIds":[],"Parts":[{"Part Id":"","Customer Part Id":"","Job Id":"","Description":"","Description 2":"","Quantity":"",'Price':'','Mark Up':'','Per Price':'','Per Cost':'','Cost':''}],"CustomerPartIds":[],'BillingInfo':{'Contact Person':'','Address':'','Address 2':'','City':'','Zip':'','State':'','Country':''},'toShip':[{"Parts":[{"Part Id":"","Customer Part Id":"","Job Id":"","Description":"","Quantity":"",'Price':'','Per Price':''}],'Contact Person':'','Address':'','Address 2':'','City':'','Zip':'','State':'','Country':'','From Customer Id':'','From Contact Person':'','From Address':'','From Address 2':'','From City':'','From Zip':'','From State':'','From Country':'','Ship By Date':'','Carrier':'','Tracking':'','# Units':'','Weight':'','Freight':'','Status':''}]})
  const emptyObj = {"Id":"","Open":true,"Status":"Quote",'splitOrder':false,'totalQuantity':0,"Customer PO Id":"","Customer":"","Customer Id":"","Date":"","Contact Person":"","Total":"","PartIds":[],"Parts":[{"Part Id":"","Customer Part Id":"","Job Id":"","Description":"","Description 2":"","Quantity":"",'Price':'','Mark Up':'','Per Price':'','Per Cost':'','Cost':''}],"CustomerPartIds":[],'BillingInfo':{'Contact Person':'','Address':'','Address 2':'','City':'','Zip':'','State':'','Country':''},'toShip':[{"Parts":[{"Part Id":"","Customer Part Id":"","Job Id":"","Description":"","Description 2":"","Quantity":"",'Price':'','Per Price':''}],'Contact Person':'','Address':'','Address 2':'','City':'','Zip':'','State':'','Country':'','From Customer Id':'','From Contact Person':'','From Address':'','From Address 2':'','From City':'','From Zip':'','From State':'','From Country':'','Ship By Date':'','Carrier':'','Tracking':'','# Units':'','Weight':'','Freight':'','Status':''}]}
  const emptyToShipObj = {"Parts":[{"Part Id":"","Customer Part Id":"","Job Id":"","Description":"","Description 2":"","Quantity":"",'Price':'','Per Price':''}],'Contact Person':'','Address':'','Address 2':'','City':'','Zip':'','State':'','Country':'','From Customer Id':'','From Contact Person':'','From Address':'','From Address 2':'','From City':'','From Zip':'','From State':'','From Country':'','Ship By Date':'','Carrier':'','Tracking':'','# Units':'','Weight':'','Freight':'','Status':''}
  const [flowObj, setFlowObj] = useState({"Workers":[{"Id":""}],"Components":[{"Id":""}],"Parts":[{"Id":"","Approved":false,"Description":"","Inventory":"","Base":"","Labor":"","Overhead":"","Material":"","Total":""}],"Orders":[]})

  
  const [next, setNext] = useState('')
  const [number, setNumber] = useState('')
  const [workerId, setWorkerId] = useState('')
  const [CustomerId, setCustomerId] = useState('')
  const [workerTierId, setWorkerTierId] = useState('')
  const [estNum, setEstNum] = useState('')
  const [page, setPage] = useState('Parts')
  const [quoteId, setQuoteId] = useState('')
  const conRef = useRef()
  const userDataRef = useRef()
  const header = useRef()
  const navbarRef = useRef()

  useEffect(()=>{
    if(userData!=null&&userData!=undefined&&userData['companiesMap']!=undefined&&currCompany!=''&&userData['currCompany']!=currCompany){

      console.log(Object.keys(userData['companiesMap']))
      setDoc(doc(db,'users', user.uid), {
        currCompany: currCompany
      } , { merge: true })

    }
  },[currCompany])

  useEffect(()=>{

    if(userData['companiesMap']!=undefined&&currCompany==''){
      setCurrCompany(userData['currCompany'])
    }    
  },[userData])

  // useEffect(()=>{
  //   if(conRef.current!=null){
  //     conRef.current.style.width = "99vw";
  //     conRef.current.style.width = "100vw";
  //   }
  // },[conRef])

  const getQueryVariable = (variable)=>{
    let query = window.location.search.substring(1);
    // console.log(query)//"app=article&act=news_content&aid=160990"
    let replace = query.replace("%20"," ")
    let vars = replace.split("&");
    // console.log(vars) //[ 'app=article', 'act=news_content', 'aid=160990' ]
    for (let i=0;i<vars.length;i++) {
      let pair = vars[i].split("=");
      if(pair[1]=='Cost+Estimate'||pair[1]=='Purchase+Order'){
        pair[1]=pair[1].replace('+',' ')
      }
      // console.log(pair)//[ 'app', 'article' ][ 'act', 'news_content' ][ 'aid', '160990' ] 
      if(pair[0] == variable){return pair[1];}
    }
    return(false);
  }

useEffect(()=>{
  onAuthStateChanged(auth, (user1) => {
    if (user1) {
      // User is signed in, see docs for a list of available properties
      // const uid = user.uid;
      setUser(user1)
      console.log('fuck',user1)
      const q = doc(db,'users', user1.uid);
      const getD = async(q)=>{
        // setUserData({})

        userDataRef.current = onSnapshot(q, (snapshot) => {
            console.log(snapshot)
            setUserData(snapshot.data())
          
            if (snapshot.type === "added") {
                console.log("New : ", snapshot.data());
                  
            }
            if (snapshot.type === "modified") {
                console.log("Modified : ", snapshot.data());
              setUserData(snapshot.data())
            }
            // if (snapshot.type === "removed") {
            //     console.log("Removed : ", snapshot.doc.data());
            // }
        
        });
        
      }
      getD(q)
    } else {
      setUser(null)
      console.log(user)
      // User is signed out
    }
  });
  return ()=>{
      if(userDataRef.current!=null){
        userDataRef.current()
        userDataRef.current=null
      }
    }
},[])

useEffect(()=>{
    setNumber(()=>getQueryVariable('number')?getQueryVariable('number'):'')
    setWorkerId(()=>getQueryVariable('workerId')?getQueryVariable('workerId'):'')
    setWorkerTierId(()=>getQueryVariable('workerTierId')?getQueryVariable('workerTierId'):'')
    setEstNum(()=>getQueryVariable('estnum')?getQueryVariable('estnum'):'')
    setNext(()=>getQueryVariable('next')?getQueryVariable('next'):'')
    setQuoteId(()=>getQueryVariable('QuoteId')?getQueryVariable('QuoteId'):'')
    setCustomerId(()=>getQueryVariable('CustomerId')?getQueryVariable('CustomerId'):'')
  // console.log(userData)

},[page])

  return (
    <Router>
      <div className="stickPos" ref={navbarRef}>
        
        <Header
          expanded={expanded}
          setExpanded={setExpanded}
          header={header}
          user={user}
          db={db}       
          auth={auth}
          page={page}
          setPage={setPage}
          userData={userData}
          setShowLogin={setShowLogin}
          showCost={showCost}
          setShowCost={setShowCost}
          openMenu={openMenu}
          setOpenMenu={setOpenMenu}
          setShowOutlet={setShowOutlet}
          cartObj={cartObj}
        />
        
        </div>
      {/*<div className="App">*/}
        {/*<Container ref={conRef} className="yup" style={{overflowX: 'hidden'}}>
          <Row className="yup">*/}
              {/*<div style={{position:'relative',zIndex:900,backgroundColor:'white'}}>
             
                <span onClick={()=>{setOpenMenu(!openMenu)}} style={{ marginBottom: 10, float: 'left', cursor: 'pointer'}} ><FaSearch /></span>
              
              <span onClick={()=>{setOpenNavMenu(!openNavMenu)}} style={{ marginBottom: 10, float: 'right', cursor: 'pointer'}}><FaBars /></span>
                
              </div>*/}
          {/*</Row>*/}
          {/*</Container>*/}
        {/*<Header
          signedIn={signedIn}
          setSignedIn={setSignedIn}
          user={user}
          setUser={setUser}
          expanded={expanded}
          setExpanded={setExpanded}
          header={header}
          signIn={signIn}
          doc={doc}
          setDoc={setDoc}
          online={online}
          sentenceRef={sentenceRef}

        />*/}

        
        
        {/*</div>*/}
        <Routes>
        <Route path='/' element={ 
            <PartNum 
              user={user}
              db={db}       
              auth={auth}
              openMenu={openMenu}
              setOpenMenu={setOpenMenu}
              openNavMenu={openNavMenu}
              setOpenNavMenu={setOpenNavMenu} 
              setShowLogin={setShowLogin}     
              page={page}
              setPage={setPage}
              userData={userData}
              number={number}
              setNumber={setNumber}
              obj={partObj}
              setObj={setPartObj}
              obj1={partObj1}
              setObj1={setPartObj1}
              laborObj={laborObj}
              setLaborObj={setLaborObj}
              showCost={showCost}
              setShowCost={setShowCost}
              currCompany={currCompany}
              setCurrCompany={setCurrCompany}
              openDrop={openDrop}
              setOpenDrop={setOpenDrop}
              
            />
        } />
        <Route path='/Products' element={ 
            <PartNum 
              user={user}
              db={db}       
              auth={auth}
              openMenu={openMenu}
              setOpenMenu={setOpenMenu}
              openNavMenu={openNavMenu}
              setOpenNavMenu={setOpenNavMenu} 
              setShowLogin={setShowLogin}     
              page={page}
              setPage={setPage}
              userData={userData}
              number={number}
              setNumber={setNumber}
              obj={partObj}
              setObj={setPartObj}
              obj1={partObj1}
              setObj1={setPartObj1}
              laborObj={laborObj}
              setLaborObj={setLaborObj}
              showCost={showCost}
              setShowCost={setShowCost}
              currCompany={currCompany}
              setCurrCompany={setCurrCompany}
              
            />
        } />

        <Route path='/Components' element={ 
            <Component 
            user={user}
            db={db}       
            auth={auth}
            openMenu={openMenu}
            setOpenMenu={setOpenMenu}
            openNavMenu={openNavMenu}
            setOpenNavMenu={setOpenNavMenu} 
            setShowLogin={setShowLogin}     
            setPage={setPage}
            userData={userData}
            number={number}
            obj={componentObj}
            setObj={setComponentObj}
            currCompany={currCompany}
            setCurrCompany={setCurrCompany}
            openDrop={openDrop}
            setOpenDrop={setOpenDrop}
          />
        } />
        <Route path='/Workers' element={ 
            <Worker 
            user={user}
            db={db}       
            auth={auth}
            openMenu={openMenu}
            setOpenMenu={setOpenMenu}
            openNavMenu={openNavMenu}
            setOpenNavMenu={setOpenNavMenu} 
            setShowLogin={setShowLogin}     
            setPage={setPage}
            userData={userData}
            workerId={workerId}
            workerTierId={workerTierId}
            obj={workerObj}
            setObj={setWorkerObj}
            currCompany={currCompany}
            setCurrCompany={setCurrCompany}
            openDrop={openDrop}
            setOpenDrop={setOpenDrop}
          />
        } />
        <Route path='/Customers' element={ 
            <Customer 
            user={user}
            db={db}       
            auth={auth}
            openMenu={openMenu}
            setOpenMenu={setOpenMenu}
            openNavMenu={openNavMenu}
            setOpenNavMenu={setOpenNavMenu} 
            setShowLogin={setShowLogin}     
            setPage={setPage}
            userData={userData}
            workerId={workerId}
            workerTierId={workerTierId}
            obj={customerObj}
            setObj={setCustomerObj}
            CustomerId={CustomerId}
            currCompany={currCompany}
            setCurrCompany={setCurrCompany}
            openDrop={openDrop}
            setOpenDrop={setOpenDrop}
          /> 
        } />
        <Route path='/Quotes' element={ 
            <Quote 
            user={user}
            db={db}       
            auth={auth}
            openMenu={openMenu}
            setOpenMenu={setOpenMenu}
            openNavMenu={openNavMenu}
            setOpenNavMenu={setOpenNavMenu} 
            setShowLogin={setShowLogin}     
            setPage={setPage}
            userData={userData}
            workerId={workerId}
            workerTierId={workerTierId}
            obj={orderObj}
            setObj={setOrderObj}
            custObj={customerObj}
            setCustObj={setCustomerObj}
            emptyObj={emptyObj}
            currCompany={currCompany}
            setCurrCompany={setCurrCompany}
            openDrop={openDrop}
            setOpenDrop={setOpenDrop}
          />
        } />
        <Route path='/PurchaseOrders' element={ 
            <PurchaseOrder 
            user={user}
            db={db}       
            auth={auth}
            openMenu={openMenu}
            setOpenMenu={setOpenMenu}
            openNavMenu={openNavMenu}
            setOpenNavMenu={setOpenNavMenu} 
            setShowLogin={setShowLogin}     
            setPage={setPage}
            userData={userData}
            workerId={workerId}
            workerTierId={workerTierId}
            obj={orderObj}
            setObj={setOrderObj}
            quoteId={quoteId}
            emptyObj={emptyObj}
            emptyToShipObj={emptyToShipObj}
            currCompany={currCompany}
            setCurrCompany={setCurrCompany}
            openDrop={openDrop}
            setOpenDrop={setOpenDrop}
          />
        } />
        <Route path='/Returns' element={ 
            <Returns 
            user={user}
            db={db}       
            auth={auth}
            openMenu={openMenu}
            setOpenMenu={setOpenMenu}
            openNavMenu={openNavMenu}
            setOpenNavMenu={setOpenNavMenu} 
            setShowLogin={setShowLogin}     
            setPage={setPage}
            userData={userData}
            workerId={workerId}
            workerTierId={workerTierId}
            obj={orderObj}
            setObj={setOrderObj}
            quoteId={quoteId}
            emptyObj={emptyObj}
            emptyToShipObj={emptyToShipObj}
            currCompany={currCompany}
            setCurrCompany={setCurrCompany}
            openDrop={openDrop}
            setOpenDrop={setOpenDrop}
          />
        } />
        
        <Route path='/BillOfLading' element={ 
            <BillOfLading 
            user={user}
            db={db}       
            auth={auth}
            openMenu={openMenu}
            setOpenMenu={setOpenMenu}
            openNavMenu={openNavMenu}
            setOpenNavMenu={setOpenNavMenu} 
            setShowLogin={setShowLogin}     
            setPage={setPage}
            userData={userData}
            workerId={workerId}
            workerTierId={workerTierId}
            obj={orderObj}
            setObj={setOrderObj}
            quoteId={quoteId}
            currCompany={currCompany}
            setCurrCompany={setCurrCompany}
            openDrop={openDrop}
            setOpenDrop={setOpenDrop}
          />
        } />
        <Route path='/Invoice' element={ 
            <Invoice 
            user={user}
            db={db}       
            auth={auth}
            openMenu={openMenu}
            setOpenMenu={setOpenMenu}
            openNavMenu={openNavMenu}
            setOpenNavMenu={setOpenNavMenu} 
            setShowLogin={setShowLogin}     
            setPage={setPage}
            userData={userData}
            workerId={workerId}
            workerTierId={workerTierId}
            obj={orderObj}
            setObj={setOrderObj}
            quoteId={quoteId}
            currCompany={currCompany}
            setCurrCompany={setCurrCompany}
            openDrop={openDrop}
            setOpenDrop={setOpenDrop}
          />
        } />
        
        <Route path='/Flow' element={ 
            <Flow 
            user={user}
            db={db}       
            auth={auth}
            openMenu={openMenu}
            setOpenMenu={setOpenMenu}
            openNavMenu={openNavMenu}
            setOpenNavMenu={setOpenNavMenu} 
            setShowLogin={setShowLogin}     
            setPage={setPage}
            userData={userData}
            obj={flowObj}
            setObj={setFlowObj}
            orderObj={orderObj}
            setOrderObj={setOrderObj}
            currCompany={currCompany}
            setCurrCompany={setCurrCompany}
            openDrop={openDrop}
            setOpenDrop={setOpenDrop}
            list={flowlist}
            setList={flowsetList}
          />
        } />
          
        {/*<Route exact path='/CurrentDream/:dreamId' element={ <CurrentDream dreamRef={dreamRef} buttonsRef={buttonsRef} dreamBody={dreamBody} user={user} doc={doc} setDoc={setDoc} setPlaying={setPlaying} playing={playing} setFinishedSentences={setFinishedSentences} online={online} setOnline={setOnline} setShowLogo={setShowLogo} sentenceRef={sentenceRef} {...props} /> } />*/}
        
        </Routes>
      <div>
      {(user == null || showLogin) &&
        <LoginModal
          user={user}
          showLogin={showLogin} 
          setShowLogin={setShowLogin}
          auth={auth}
          createUserWithEmailAndPassword={createUserWithEmailAndPassword}
          signInWithEmailAndPassword={signInWithEmailAndPassword}
          db={db}
          setDoc={setDoc}
          doc={doc}
          sendEmailVerification={sendEmailVerification}
          sendPasswordResetEmail={sendPasswordResetEmail}
        />
      }
       
      </div>
    </Router>

  );
}

export default App;
