import '../App.css';
import Form2 from './Form2.js';
import Form1 from './Form1.js';
import Form1a from './Form1a.js';
import Form5 from './Form5.js';
import Form6 from './Form6.js';
import Form7 from './Form7.js';
import Form7a from './Form7a.js';
import WorkerMenu from './WorkerMenu.js';
import NavMenu from './NavMenu.js';
import { useState, useEffect, useRef } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col, Button } from "react-bootstrap";
import { getFunctions, httpsCallable } from "firebase/functions";
import {  } from "firebase/firestore";
import { getFirestore, setDoc, collection, query, where, doc, getDocs, orderBy, arrayUnion, onSnapshot } from "firebase/firestore";
import { getAuth, createUserWithEmailAndPassword, onAuthStateChanged, signInWithEmailAndPassword, signOut, sendEmailVerification, sendPasswordResetEmail } from "firebase/auth";
import firebase from './firebase.js';
import LoginModal from './LoginModal.js'
import { FaCaretDown,FaCaretUp,FaSearch,FaRedo,FaBars } from 'react-icons/fa';
import inventory from '../jsons/inventoryData.json'
import {Modal, Spinner} from 'react-bootstrap';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import Dropdown from 'react-dropdown';



function Customer({user,db,auth,openMenu,setOpenMenu,setShowLogin,setPage,openNavMenu,setOpenNavMenu,userData,obj,setObj,CustomerId,currCompany,setCurrCompany,openDrop,setOpenDrop}) {
  

  const [list, setList] = useState(["Contact Person","Phone Number","Email"])
  const [list1, setList1] = useState(['Address','Address 2','City','Zip','State','Country'])

  // const [list1, setList1] = useState(["Part Id","Customer Part Id","Description","Quantity",'Cost','Mark Up','Price'])
  
  const emptyObj = {"Id":'',"Customer":"",contactPerson:[{"Contact Person":"","Phone Number":"","Email":""}],"contactPeople":[],"phoneNumbers":[],"emails":[],"addresses":[],"addresses2":[],"cities":[],"zips":[],"states":[],"countries":[],"Addresses":[{'Address':'','Address 2':'','City':'','Zip':'','State':'','Country':''}]}
  const emptyAddressObj = {'Address':'','Address 2':'','City':'','Zip':'','State':'','Country':''}
  const emptyContactObj = {"Contact Person":"","Phone Number":"","Email":""}
  
  const [showModal1, setShowModal1] = useState(false)
  const [search, setSearch] = useState(false)
  const [docData, setDocData] = useState([])
  const [docData1,setDocData1] = useState({})
  const [screenWidth,setScreenWidth] = useState(0)
  const ref = useRef()
  const queryRef = useRef()

  useEffect(()=>{
    console.log('fuck')
    if(userData['currCompany']!=undefined&&userData['currCompany']!=''){
      if(CustomerId!=''){
        const q = query(collection(db,'companies', userData['currCompany'], "Customer"), where("Id", "==", CustomerId));
        
        queryRef.current = onSnapshot(q, (snapshot) => {
          snapshot.docChanges().forEach((change) => {
            const data = change.doc.data()
            console.log(data)
            if (change.type === "added") {
                console.log("New : ", data);
                // setDocData(docData =>[...docData, data])
                
              setObj(data)
            }
            if (change.type === "modified") {
                // console.log("Modified : ", data);
                
              setObj(data)
            }
            
          });
        });
      }
    }
    return ()=>{
      if(queryRef.current!=null){
        queryRef.current()
        queryRef.current=null
      }
    }
  },[CustomerId,userData])

  useEffect(()=>{
    if(ref.current!=null){
      console.log(ref.current.offsetWidth)
      setScreenWidth(window.innerWidth);

    }
    window.addEventListener("resize", setScreenWidth(window.innerWidth));

    return () => {
      window.removeEventListener("resize", setScreenWidth(window.innerWidth));
    };
  },[ref])

  const addRow = (item,obj)=>{
    console.log('addd')
    setObj((prevState) => {
      const clone = JSON.parse(JSON.stringify(prevState));
      clone[item].push(obj)
      return clone;

    })
    // setObjj([...objj, emptyPartObj])
  }
  const removeRow = (x,item)=>{
    console.log('remove', x)
    setObj((prevState) => {
      const clone = JSON.parse(JSON.stringify(prevState));

      const previousArr = clone[item];
        if(previousArr.length>1){

        const first = previousArr.slice(0, x)
        console.log(first)
        
        // console.log(newArray,' 2')
        const second =previousArr.slice(x+1)
        console.log(second)
        // newArray.concat(second)
        // console.log(newArray,' 3')
        clone[item]=first.concat(second)
        return clone;
        }else{
          return clone;
        }
      }
    )
  }


  useEffect(()=>{
    console.log(obj)
    console.log('***************************************')

    const things = ['Addresses','contactPerson']
    things.forEach((item1,index)=>{
      const things2 = item1=='contactPerson'?list:list1
      let add=false
      if(obj[item1]!=undefined){

        things2.forEach((item, index)=>{
          // if(item = 'Address 2')
          if(obj[item1].length-1>=0&&obj[item1][obj[item1].length-1][item]!==""){
            console.log(item)
            add=true
          }
        })
        for(let x=0;x<=obj[item1].length-2;x++){
          let remove=0
          things2.forEach((item, index)=>{
            if(obj[item1][x][item]==""){
              remove+=1
            }
          })
          if(remove==things2.length){
            removeRow(x,item1)
          }
        }
        if(add){
          console.log('add')
          addRow(item1,item1=='contactPerson'?emptyContactObj:emptyAddressObj)
        }
      }
    })
    
  },[obj['Addresses'],obj['contactPerson']]) 

  const uuidv4 = ()=> {
    return ([1e7]+1e3+4e3+8e3+1e11).replace(/[018]/g, c =>
      (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    );
  }
  
  const edit = async()=>{
    console.log('edit')
    // if(obj['Id']==''){
    //   setShowModal(true)
    // }else{
    const id = uuidv4()
    const clone = JSON.parse(JSON.stringify(obj));
    if(clone['Id']==''){
      clone['Id']=id
    }
    const things = ['Addresses','contactPerson']
    clone['addresses']=[]
    clone['addresses2']=[]
    clone['cities']=[]
    clone['zips']=[]
    clone['states']=[]
    clone['countries']=[]
    clone['contactPeople']=[]
    clone['phoneNumbers']=[]
    clone['emails']=[]
    obj['Addresses'].forEach((item1,index1)=>{
        if(item1["Address"]!=""){
          clone['addresses'].push(item1["Address"])
        }
       if(item1["Address 2"]!=""){
          clone['addresses2'].push(item1["Address 2"])
        }
        if(item1["City"]!=""){
          clone['cities'].push(item1["City"])
        }
        if(item1["Zip"]!=""){
          clone['zips'].push(item1["Zip"])
        }
        if(item1["State"]!=""){
          clone['states'].push(item1["State"])
        }
        if(item1["Country"]!=""){
          clone['countries'].push(item1["Country"])
        }
      // "contactPeople":[],"phoneNumbers":[],"emails":[],
    })
    obj['contactPerson'].forEach((item1,index1)=>{
        if(item1["Contact Person"]!=""){
          clone['contactPeople'].push(item1["Contact Person"])
        }
        if(item1["Phone Number"]!=""){
          clone['phoneNumbers'].push(item1["Phone Number"])
        } 
        if(item1["Email"]!=""){
          clone['emails'].push(item1["Email"])
        }
      // "contactPeople":[],"phoneNumbers":[],"emails":[],
    })
    
console.log(clone)



// ...................................................................................................................
      // loop through and put the things into the searchable arrays..........................................
        // and add key1 to all Form1a, i think just Quote and purchase order
      

    try{

      await setDoc(doc(db, 'companies', userData['currCompany'], "Customer", clone['Id']), clone , { merge: true })
      setObj(clone)

    }
    catch(err){
      alert('Error Saving Customer')
    }

    // }
  }

  const next = ()=>{
    console.log('next')
    
  };

  const reset = ()=>{
    setObj(emptyObj)
  }

  return(
    <Container style={{height:'100%','marginTop':56}}>
      <div style={{zIndex:9,position: 'relative',backgroundColor:'white',height:'97vh'}}>
      <Row onClick={()=>{if(openMenu){setOpenMenu(false)};if(openNavMenu){setOpenNavMenu(false)}}} >
      <Row>
        <div style={{ marginBottom: 20}} >
        {userData['currCompany']!=undefined?
          userData['companies'].length>1?
          <span className="fuck1">
            <Dropdown 
              className="dropdown"
              placeholderClassName="fuck"
              arrowClosed={<FaCaretDown className="arrow-closed" />}
              arrowOpen={<FaCaretUp className="arrow-open" />}
              controlClassName="dropdownC" open={openDrop} options={Object.keys(userData['companiesMap'])} onChange={(e)=>{setCurrCompany(e.value)}} value={currCompany}>
              
            </Dropdown>
          </span>      
          :
          <span>{userData['currCompany']}</span>
          :''}
        </div>
      </Row>
      <Row>
        <div style={{ marginBottom: 20}} >
          <span style={{cursor: 'pointer'}} onClick={reset}>
            <FaRedo />  Customers
            {/*<FaCaretDown />*/}
          </span>
        </div>
      </Row>
    
      <Row>
        <Col xs={12} lg={12}>
          <div style={{overflowX:"hidden"}}>Customer</div>
        </Col> 
      </Row>
      <Row>
        <Col xs={12} lg={12}>
          <Form7 name={'Customer'} input={obj}  setInput={setObj} db={db} userData={userData} setSearch={setSearch} searchName={'Customer'} />
        </Col> 
      </Row>

      <Row style={{paddingTop:10,paddingBottom:10}}> 
        <Col xs="12" lg="12">
          <span style={{borderBottom:"1px solid #000"}}>
          Contacts
          </span>
        </Col> 
      </Row>
      
      <Row> 
      {
        list.map((item,index)=>{
          let size = "4";
          {/*switch(item){
            case "Contact Person": size=3;
              break;
            case "Customer": size=3;
              break;
            default:size=2;
          }*/}
          
          return (
            <Col xs={size} lg={size}>
              <div style={{overflowX:"hidden"}}>{item}</div>
            </Col>

            )
        })
      }
      
      </Row>
      
      {

        obj['contactPerson'].map((item,index)=>{
          return(
          <Row 
          > 
        
            <Col xs="4" lg="4" style={{paddingRight:0, paddingLeft:0,}}>
              <Form1a index={index} name={'Contact Person'} input={obj} setInput={setObj} key1={'contactPerson'}  />
            </Col>            
            <Col xs="4" lg="4" style={{paddingRight:0, paddingLeft:0,}}>
              <Form1a index={index} name={'Phone Number'} input={obj} setInput={setObj} key1={'contactPerson'}  />
            </Col>
            <Col xs="4" lg="4" style={{paddingRight:0, paddingLeft:0,}}>
              <Form1a index={index} name={'Email'} input={obj} setInput={setObj} key1={'contactPerson'} />
            </Col>
            
          </Row>
            )
        })
      }

          <Row style={{paddingTop:10,paddingBottom:10}}> 
            <Col xs="12" lg="12">
              <span style={{borderBottom:"1px solid #000"}}>
              Addresses
              </span>
            </Col> 
          </Row> 

      <Row> 
      {
        list1.map((item,index)=>{
          let size = "2";
          switch(item){
            case "Address": size=3;
              break;
            case "Zip": size=1;
              break;
            
            default:size=2;
          }
          
          return (
            <Col xs={size} lg={size}>
              <div style={{overflowX:"hidden"}}>{item}</div>
            </Col>

            )
        })
      }
      
      </Row>
      
    
        {/*no forward slashes, need to program*/}
        {/*["Id","Customer","Date","Contact Person","Part Id","Customer Part Id","Description","Quantity",'Price']*/}
            {
              obj['Addresses'].map((item,index)=>{
                                
                return (
                  <Row > 
                    <Col xs="3" lg="3" style={{paddingRight:0, paddingLeft:0,}}>
                      <Form1a index={index} name={'Address'} input={obj} setInput={setObj} key1={'Addresses'} />
                    </Col>
                    <Col xs="2" lg="2" style={{paddingRight:0, paddingLeft:0,}}>
                      <Form1a index={index} name={'Address 2'} input={obj} setInput={setObj} key1={'Addresses'} />
                    </Col>
                    <Col xs="2" lg="2" style={{paddingRight:0, paddingLeft:0,}}>
                      <Form1a index={index} name={'City'} input={obj} setInput={setObj} key1={'Addresses'} />
                    </Col>
                    <Col xs="1" lg="1" style={{paddingRight:0, paddingLeft:0,}}>
                      <Form1a index={index} name={'Zip'} input={obj} setInput={setObj} key1={'Addresses'} />
                    </Col>
                    <Col xs="2" lg="2" style={{paddingRight:0, paddingLeft:0,}}>
                      <Form1a index={index} name={'State'} input={obj} setInput={setObj} key1={'Addresses'} />
                    </Col>
                    <Col xs="2" lg="2" style={{paddingRight:0, paddingLeft:0,}}>
                      <Form1a index={index} name={'Country'} input={obj} setInput={setObj} key1={'Addresses'} />
                    </Col>
                    
                  </Row>
                  )
              })
            }
            

          

    <div style={{paddingBottom:"10px",borderBottom:"1px solid #000"}}/>

    <Row style={{paddingBottom:"10px",borderBottom:"1px solid #000"}}>
      
      <Col xs="12" lg="12">
        <Button style={{ marginTop: 30}} onClick={edit} >Save Customer</Button>
      </Col>
      {/*<Col xs="12" lg="6">
        <Button style={{ marginTop: 30}} onClick={next} >Next</Button>
      </Col>*/}
      
    </Row>

  </Row>
  </div>

  {showModal1&&
    <Modal
      show={true}
      onHide={()=>{setShowModal1(false)}}
      backdrop="static"
      // keyboard={false}
    >
      <Modal.Header closeButton>
        
        <Modal.Title>Saving</Modal.Title>
          
      </Modal.Header>

      <Modal.Body>
        Saving...
      </Modal.Body>

    </Modal>
  }

    {/*<div className='nav1'>*/}
  

{/*      <WorkerMenu 
          open={openMenu} 
          setOpen={setOpenMenu} 
          db={db}
          list={list}
          list1={list1}
          setObj={setObj}
          setObj1={setObj1}
          setNewNew={setNewNew}
          docData={docDataMenu}
          setDocData={setDocDataMenu}
          userData={userData}
          dbSection="worker"
        />*/}
      
      {/*<NavMenu 
          open={openNavMenu} 
          setOpen={setOpenNavMenu} 
          db={db}  
          user={user} 
          auth={auth}     
          setShowLogin={setShowLogin}     
          setPage={setPage}     
          userData={userData}

        />*/}
    {/*</div>*/}

  </Container>
  );
}

export default Customer;
