import { useState, useEffect } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';


const SearchItem = ({obj,onClick,searchLabel,setFocused,key1,indy })=> {

  return (
    <div className='searchItems' onClick={()=>{
      onClick(obj,key1,indy);
      if(setFocused!=null){
        setFocused(false)
      }
    }}>
      <span style={{float:"center"}} >
      {obj[searchLabel]}
      </span>
    </div>
  );
}

export default SearchItem