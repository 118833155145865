import { useState, useEffect } from 'react'
import useLongPress from './useLongPress.js';


const Form1a3 = ({item,obj })=> {
  useEffect(()=>{
    // console.log(input['components'][index])
    
  })
const onLongPress = () => {
      // console.log('longpress is triggered');
      if(obj[item]['Customer Id']!=undefined){

        window.history.pushState({}, document.title, window.location.pathname);
        let loc = String(window.location);
        let locSplit = loc.split('/')
        window.open(String(locSplit[0]+'/Customers'+"?CustomerId="+obj[item]['Customer Id']),'_blank');
      }
      
  };

  const onClick = () => {
      // console.log('click is triggered')
  }

  const defaultOptions = {
      shouldPreventDefault: true,
      delay: 500,
  };
  const useLongPressEvent = useLongPress(onLongPress,onClick,defaultOptions)

  return (
    <span>
    <li>
    {
      <span  {...useLongPressEvent} >
      {
        obj[item]['Customer']!=undefined?obj[item]['Customer']:''
      }
      </span>
    }
    </li>
    </span>
  );
}

export default Form1a3