import { useState, useEffect,useRef } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import useLongPress from './useLongPress.js';
import { collection, query, where, getDoc,doc, orderBy, onSnapshot } from "firebase/firestore";
import SearchItem from './SearchItem.js';
import Dropdown from 'react-dropdown';
import { FaCaretDown,FaCaretUp } from 'react-icons/fa';




const Form7b4 = ({name, setInput, input, index, userData, db ,setObj1,setSearch,isArray,searchName,key2,indy})=> {
  // useEffect(()=>{
  //   console.log(name)
  //   console.log(input)
  //   console.log(index)  
  // },[])
  // useEffect(()=>{
  //   console.log(input)
  // },[input])

  const [openDrop, setOpenDrop] = useState(false)
  const [focused, setFocused] = useState(false)
  const [docData, setDocData] = useState({})
  const onFocus = () => setFocused(true)
  const onBlur = () => startTimer()
  const queryRef = useRef();
  const [approvedVendorsList, setApprovedVendorsList] = useState({})

  const [counter, setCounter] = useState(0);
  const timer = useRef(null); // we can save timer in useRef and pass it to child

  const startTimer = ()=>{
     timer.current = setTimeout(() => {setFocused(false);}, 250);
  }

    useEffect(
    () => {

      // this will clear Timeout
      // when component unmount like in willComponentUnmount
      // and show will not change to true
      return () => {
        if(timer.current!=null){
          clearTimeout(timer.current);
        }
      };
    },
    // useEffect will run only one time with empty []
    // if you pass a value to array,
    // like this - [data]
    // than clearTimeout will run every time
    // this value changes (useEffect re-run)
    []
  );

  
  useEffect(()=>{
    // console.log('fuck')
    return ()=>{
      if(queryRef.current!=null){
        queryRef.current()
        queryRef.current=null
      }

    }
  },[])


  

  const fillInForm = (obj,key1)=>{
    console.log(obj)
    if(obj['Id']==undefined){
      obj['Id']=key1
    }
      setInput((prevState)=>{
        const clone = JSON.parse(JSON.stringify(prevState));
        clone[key2][indy][name]=obj['Customer']
        clone[key2][indy]['Customer Id']=obj['Id']
        console.log(clone)
        console.log(key2,indy,name)

        return clone
      })
    
  }

  const onLongPress = () => {
      console.log(input,name, input[name], 'longpress is triggered');
      if(name=="Customer"&&input[key2][indy]['Customer Id']!=undefined){

        // need to set up worker page
        console.log(input)
        // setObj1({Worker:input['Worker'],Rate:input['Rate']})
        window.history.pushState({}, document.title, window.location.pathname);
        let loc = String(window.location);
        let locSplit = loc.split('/')
        if(input[key2]!=undefined&&input[key2][indy]){
          window.open(String(locSplit[0]+'/Customers'+"?CustomerId="+input[key2][indy]['Customer Id']),'_blank');
        }
        
      }
  };

  const onClick = () => {
      // console.log('click is triggered')
  }

  const defaultOptions = {
      shouldPreventDefault: true,
      delay: 500,
  };
  const useLongPressEvent = useLongPress(onLongPress,onClick,defaultOptions)

  const changeCompany=(e)=>{
    console.log(e)
    setInput((prevState)=>{
      const clone = JSON.parse(JSON.stringify(prevState));
      clone[key2][indy][name]=approvedVendorsList[e]['Customer']
      clone[key2][indy]['Customer Id']=approvedVendorsList[e]['Customer Id']
      // console.log(clone)
      // console.log(key2,indy,name)

      return clone
    })
  }

  useEffect(()=>{
    if(input['ApprovedVendors']!=undefined){

    Object.keys(input['ApprovedVendors']).forEach((item,index)=>{
      // console.log(input['ApprovedVendors'][item]['Customer'])
      setApprovedVendorsList((prevState)=>{
        const clone = JSON.parse(JSON.stringify(prevState));
        // const previousArr = clone['PurchaseHistory'];

        clone[input['ApprovedVendors'][item]['Customer']] = input['ApprovedVendors'][item]
        return clone

      })
    })

    }
  },[input['ApprovedVendors']])

  return (
    <span className="fuck1" style={{border:'1px solid black',width:'100%',height:'100%'}}>
      {
        approvedVendorsList!=undefined?
      <Dropdown 
        className="dropdown"
        placeholderClassName="fuck"
        arrowClosed={<FaCaretDown className="arrow-closed" />}
        arrowOpen={<FaCaretUp className="arrow-open" />}
        controlClassName="dropdownC" open={openDrop} 
        options={Object.keys(approvedVendorsList)} 
        onChange={(e)=>{changeCompany(e.value)}} 
        value={input[key2][indy]!=undefined&&input[key2][indy]['Customer']!=undefined?input[key2][indy]['Customer']:''}>
      </Dropdown>
      :
      <div style={{width:'100%',height:'100%'}}>
      </div>
      }
    </span>

  );
}

export default Form7b4