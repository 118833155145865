import '../App.css';
import Form2 from './Form2.js';
import Form1 from './Form1.js';
import Form1a from './Form1a.js';
import Form5 from './Form5.js';
import Form6 from './Form6.js';
import Form7 from './Form7.js';
import Form7a from './Form7a.js';
import Form7c from './Form7c.js';
import WorkerMenu from './WorkerMenu.js';
import NavMenu from './NavMenu.js';
import { useState, useEffect, useRef } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col, Button } from "react-bootstrap";
import { getFunctions, httpsCallable } from "firebase/functions";
import {  } from "firebase/firestore";
import { getStorage,ref,uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { getFirestore, setDoc, collection, query, where, doc, getDocs, orderBy, arrayUnion, onSnapshot } from "firebase/firestore";
import { getAuth, createUserWithEmailAndPassword, onAuthStateChanged, signInWithEmailAndPassword, signOut, sendEmailVerification, sendPasswordResetEmail } from "firebase/auth";
import firebase from './firebase.js';
import LoginModal from './LoginModal.js'
import { FaCaretDown,FaCaretUp,FaSearch,FaRedo,FaBars } from 'react-icons/fa';
import inventory from '../jsons/inventoryData.json'
import {Modal, Spinner} from 'react-bootstrap';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import { BrowserRouter as Router, Route, Link, useNavigate } from "react-router-dom";
import Dropdown from 'react-dropdown';




function Quote({user,db,auth,openMenu,setOpenMenu,setShowLogin,setPage,openNavMenu,setOpenNavMenu,userData,obj,setObj,custObj,setCustObj,emptyObj,currCompany,setCurrCompany,openDrop,setOpenDrop}) {
  
  const storage = getStorage(firebase);
  const [list, setList] = useState(["Id","Date","Customer","Contact Person","Total"])
  const [list1, setList1] = useState(["Part Id","Customer Part Id","Job Id","Description","Description 2","Quantity",'Per Cost','Cost','Mark Up','Per Price','Price'])
  const [list2, setList2] = useState(["Part Id","Customer Part Id","Job Id","Description","Quantity",'Per Price','Price'])
  
  // const emptyObj = {"Id":"","Customer":"","Customer Id":"","Date":"","Contact Person":"","Total":"","PartIds":[],"CustomerPartIds":[],"Parts":[{"Part Id":"","Customer Part Id":"","Description":"","Quantity":"",'Cost':"",'Mark Up':"",'Price':''}]}
  const emptyPartObj = {"Part Id":"","Customer Part Id":"","Job Id":"","Description":"","Description 2":"","Quantity":"",'Per Cost':"",'Cost':"",'Mark Up':"",'Per Price':'','Price':''}
  
  const [search, setSearch] = useState(false)
  const [docData, setDocData] = useState([])
  const [docData1,setDocData1] = useState({})
  const [screenWidth,setScreenWidth] = useState(0)
  const [percent,setPercent] = useState(0)
  const ref2 = useRef()
  const ref1 = useRef()
  const firstRef = useRef(true)
  const navigate = useNavigate()

  useEffect(()=>{
    // firstRef.current=true
    if(obj['Id']==''){
      const clone = JSON.parse(JSON.stringify(emptyObj));
      clone['Id']=String(Date.now())
      const date=new Date();
      const year = date.getFullYear();
        // console.log(year)
      const year2 = (year-2000)
      let month = date.getMonth()+1;
      let day = date.getDate();

      clone['Date']=month+"/"+day+"/"+year2
      setObj(clone)
    }
    // if(userData['companiesMap']!=undefined){

    // console.log(userData['companiesMap'][currCompany]['admin'])
    // }
  },[userData])

  useEffect(()=>{
    if(ref2.current!=null){
      console.log(ref2.current.offsetWidth)
      setScreenWidth(window.innerWidth);

    }
    window.addEventListener("resize", setScreenWidth(window.innerWidth));

    return () => {
      window.removeEventListener("resize", setScreenWidth(window.innerWidth));
    };
  },[ref2])

  const addRow = ()=>{
    console.log('addd')
    setObj((prevState) => {
      const clone = JSON.parse(JSON.stringify(prevState));
      clone['Parts'].push(emptyPartObj)
      return clone;

    })
    // setObjj([...objj, emptyPartObj])
  }
  const removeRow = (x)=>{
    console.log('remove', x)
    setObj((prevState) => {
      const clone = JSON.parse(JSON.stringify(prevState));

      const previousArr = clone['Parts'];
        if(previousArr.length>1){

        const first = previousArr.slice(0, x)
        console.log(first)
        
        // console.log(newArray,' 2')
        const second =previousArr.slice(x+1)
        console.log(second)
        // newArray.concat(second)
        // console.log(newArray,' 3')
        clone['Parts']=first.concat(second)
        return clone;
        }else{
          return clone;
        }
      }
    )
  }


  useEffect(()=>{
    // console.log(obj['Parts'])
    // console.log('***************************************')
    let add=false
      if(obj['Parts']!=undefined){

        list1.forEach((item, index)=>{
          if(obj['Parts'].length-1>=0&&obj['Parts'][obj['Parts'].length-1][item]!==""){
            console.log(item,obj['Parts'][obj['Parts'].length-1][item])
            add=true
          }
        })
        for(let x=0;x<=obj['Parts'].length-2;x++){
          let remove=0
          list1.forEach((item, index)=>{
            if(obj['Parts'][x][item]==""){
              remove+=1
            }
          })
          if(remove==list1.length){
            removeRow(x)
          }
        }
        if(add){
          console.log('add')
          addRow()
        }



        let total = 0
        let totalQuantity=0
        obj['Parts'].forEach((objj,index)=>{
          if(objj['Price']!=''){
            total+=Number(objj['Price'])
          }
          totalQuantity+=Number(objj['Quantity'])>0?Number(objj['Quantity']):0
        })

        console.log('totalQuantity',totalQuantity)
        setObj((prevState)=>({
          ...prevState,
        'Total': total.toFixed(2),
        'totalQuantity':totalQuantity,
        }))

      // console.log(obj)
    
      }
    
  },[obj['Parts']]) 


  const edit = ()=>{
    console.log('edit')
    if(obj['Id']==''){
      // setShowModal(true)
    }else{
      //need to add part ids and customer ids to searchable array
      const clone = JSON.parse(JSON.stringify(obj));
      if(clone['Customer Id']==''){
        alert(" Need To add Customer to Database")
        return 
      }
      clone['toShip'].forEach((item1,index1)=>{
        if(!item1['StatusOverride']){
          clone['toShip'][0]['Status'] = 'Quote'
        }
      })
      clone['PartIds']=[]
      clone['CustomerPartIds']=[]
      
      clone['Parts'].forEach((item1,index1)=>{
        console.log(item1)
        console.log(item1["Part Id"])
        console.log(item1["Customer Part Id"])
          if(item1["Part Id"]!=""){
            clone['PartIds'].push(item1["Part Id"])
          }
          if(item1["Customer Part Id"]!=""){
            clone['CustomerPartIds'].push(item1["Customer Part Id"])
          }

        })
      console.log(clone)

      setDoc(doc(db, 'companies', userData['currCompany'], "order", String(clone['Id'])), clone , { merge: true })
    }
  }

  const next = ()=>{
    console.log('next')
    const id = obj['Id']
    window.history.pushState({}, document.title, window.location.pathname);
    let loc = String(window.location);
    let locSplit = loc.split('/')
    window.open(String(locSplit[0]+'/PurchaseOrders'+"?QuoteId="+id),'_blank');
    // navigate('/PurchaseOrders',{QuoteId:id})
    
  };

  const reset = ()=>{
    const clone = JSON.parse(JSON.stringify(emptyObj));
    clone['Id']=String(Date.now())
    const date=new Date();
    const year = date.getFullYear();
      // console.log(year)
    const year2 = (year-2000)
    let month = date.getMonth()+1;
    let day = date.getDate();

    clone['Date']=month+"/"+day+"/"+year2
    setObj(clone)
  }

  const saveToStorage = (btyes,obj1)=>{
    const storeId = uuidv4()
        console.log(storeId)
        // if(obj2[type]!=undefined){
        //   obj2[type].push({storeId:storeId,'name':fileObj.name})
        // }else{
        //   obj2[type]=[]
        //   obj2[type].push({storeId:storeId,'name':fileObj.name})
        // }
        obj1['storeId'] = storeId  
        const storageRef = ref(storage, `/Quotes/${storeId}`);
        const meta = {};
        // progress can be paused and resumed. It also exposes progress updates.
        // Receives the storage reference and the selectedFile to upload.
        const uploadTask = uploadBytesResumable(storageRef,btyes,meta);

        uploadTask.on(
          "state_changed",
          (snapshot) => {
              const percent = Math.round(
                  (snapshot.bytesTransferred / snapshot.totalBytes) * 100
              );

              // update progress
              setPercent(percent);
          },
          (err) => alert(err),
          (obj1) => {
           
            // download url
            getDownloadURL(uploadTask.snapshot.ref).then((url) => {
                console.log(url);
            });

            setDoc(doc(db, 'toEmail', storeId), obj1 , { merge: true })


          }
        );
  }

    const uuidv4 = ()=> {
    return ([1e7]+1e3+4e3+8e3+1e11).replace(/[018]/g, c =>
      (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    );
  }

  const approve =async ()=>{
    if(obj['Total']!=0){
      try{

        const clone = JSON.parse(JSON.stringify(obj));
        clone['partApproved']=true
        await setDoc(doc(db, 'companies', userData['currCompany'], "order", String(clone['Id'])), clone , { merge: true })
        setObj((prev)=>{
          const clone = JSON.parse(JSON.stringify(prev));
          clone['partApproved']=true
          return clone
        })

      }
      catch(err){
        alert(err)
      }
    }
  }

  const print = ()=>{
      console.log('print')
      const doc = new jsPDF();
      // console.log(screenWidth)
      edit()
      doc.html(ref1.current, {
         callback: function (doc) {
            // const blob = doc.output('blob');
            // // const formData = new FormData();
            // // formData.append('pdf', blob);
            // const obj1 = {currCompany:currCompany,companySendTo:obj['Customer'],id:obj['Id'],date:obj['date']}

            // saveToStorage(blob,obj1)
          doc.save('Quote_'+obj['Id']+'.pdf');

         },
         x: 5,
         y: 20,
         width: 200,
         windowWidth: 1200,
         html2canvas : {windowWidth : 1200},
         filename: 'quote'
      }
      );
    };

  return(
    <Container style={{height:'100%','paddingTop':56}}>
      <Row style={{backgroundColor:'white'}} onClick={()=>{if(openMenu){setOpenMenu(false)};if(openNavMenu){setOpenNavMenu(false)}}} >
      <Row>
        <div style={{ marginBottom: 20}} >
        {userData['currCompany']!=undefined?
          userData['companies'].length>1?
          <span className="fuck1">
            <Dropdown 
              className="dropdown"
              placeholderClassName="fuck"
              arrowClosed={<FaCaretDown className="arrow-closed" />}
              arrowOpen={<FaCaretUp className="arrow-open" />}
              controlClassName="dropdownC" open={openDrop} options={Object.keys(userData['companiesMap'])} onChange={(e)=>{setCurrCompany(e.value)}} value={currCompany}>
              
            </Dropdown>
          </span>      
          :
          <span>{userData['currCompany']}</span>
          :''}
        </div>
      </Row>
      <Row>
        <div style={{ marginBottom: 20}} >
          <span style={{cursor: 'pointer'}} onClick={reset}>
            <FaRedo />  Quote
            {/*<FaCaretDown />*/}
          </span>
        </div>
      </Row>
    
      <Row> 
      {
        list.map((item,index)=>{
          let size = "2";
          switch(item){
            case "Contact Person": size=3;
              break;
            case "Customer": size=3;
              break;
            default:size=2;
          }
          
          return (
            <Col key={item+index+'yo'} xs={size} lg={size}>
              <div style={{overflowX:"hidden"}}>{item}</div>
            </Col>

            )
        })
      }
      
      </Row>
      
    
          <Row 
          > 
        {/*no forward slashes, need to program*/}
        {/*["Id","Customer","Date","Contact Person","Part Id","Customer Part Id","Description","Quantity",'Price']*/}
            <Col xs="2" lg="2" style={{paddingRight:0, paddingLeft:0,}}>
              <Form7 name={'Id'} emptyObj={emptyObj} input={obj} setInput={setObj} db={db} userData={userData} setSearch={setSearch} searchName={'order'} />
            </Col>
            <Col xs="2" lg="2" style={{paddingRight:0, paddingLeft:0,}}>
              {/*<Form6 name={'Rate'} input={obj} setInput={setObj} docData={docData1} red={red} setRed={setRed} />*/}
              <Form6 name={'Date'} input={obj} setInput={setObj} db={db} userData={userData} docData={docData1}setDocData={setDocData1} search={search} setSearch={setSearch} />
            </Col>
            <Col xs="3" lg="3" style={{paddingRight:0, paddingLeft:0,}}>
              <Form7 name={'Customer'} emptyObj={emptyObj} input={obj} setInput={setObj} db={db} userData={userData} docData={docData1}setDocData={setDocData1} search={search} setSearch={setSearch} searchName={'Customer'} custObj={custObj} setCustObj={setCustObj}  />
            </Col>
            <Col xs="3" lg="3" style={{paddingRight:0, paddingLeft:0,}}>
              <Form7c name={'Contact Person'} input={obj} setInput={setObj} custObj={custObj} db={db} userData={userData} />
            </Col>
            <Col xs="2" lg="2" style={{paddingRight:0, paddingLeft:0,}}>
              <Form6 name={'Total'} input={obj} setInput={setObj} />
            </Col>
            
          </Row>

          <Row style={{paddingTop:10,paddingBottom:10}}> 
            <Col xs="12" lg="12">
              <span style={{borderBottom:"1px solid #000"}}>
              Parts
              </span>
            </Col> 
          </Row> 

      <Row> 
      {
        list1.map((item,index)=>{
          let size = "1";
          switch(item){
            case "Description": size=2;
              break;
            {/*case "Description 2": size=2;
              break;*/}
           {/* case "Part Id": size=2;
              break;
            */}
            default:size=1;
          }
          
          return (
            <Col key={index+item} xs={size} lg={size}>
              <div style={{overflowX:"hidden"}}>{item}</div>
            </Col>

            )
        })
      }
      
      </Row>
    
           {
              obj['Parts'].map((item,index)=>{
                {/*console.log(obj)   */}
                {/*console.log(item)   */}
                return (
                  <Row key={index+item} > 
                    <Col xs="1" lg="1" style={{paddingRight:0, paddingLeft:0,}}>
                      <Form7a name={'Part Id'} input={obj} index={index}  setInput={setObj} db={db} userData={userData} setSearch={setSearch} searchName={'parts'} key1={'Parts'} />
                    </Col>
                    <Col xs="1" lg="1" style={{paddingRight:0, paddingLeft:0,}}>
                      <Form1a name={'Customer Part Id'} input={obj} index={index}  setInput={setObj} key1={'Parts'} />
                    </Col> 
                    <Col xs="1" lg="1" style={{paddingRight:0, paddingLeft:0,}}>
                      <Form1a name={'Job Id'} input={obj} index={index}  setInput={setObj} key1={'Parts'} />
                    </Col>
                    <Col xs="2" lg="2" style={{paddingRight:0, paddingLeft:0,}}>
                      <Form1a name={'Description'} input={obj} index={index}  setInput={setObj} key1={'Parts'} />
                    </Col>
                    <Col xs="1" lg="1" style={{paddingRight:0, paddingLeft:0,}}>
                      <Form1a name={'Description 2'} input={obj} index={index}  setInput={setObj} key1={'Parts'} />
                    </Col>
                    
                    <Col xs="1" lg="1" style={{paddingRight:0, paddingLeft:0,}}>
                      <Form1a name={'Quantity'} input={obj} index={index}  setInput={setObj} key1={'Parts'} />
                    </Col>
                    <Col xs="1" lg="1" style={{paddingRight:0, paddingLeft:0,}}>
                      <Form1a name={'Per Cost'} input={obj} index={index}  setInput={setObj} key1={'Parts'} />
                    </Col> 
                    <Col xs="1" lg="1" style={{paddingRight:0, paddingLeft:0,}}>
                      <Form1a name={'Cost'} input={obj} index={index}  setInput={setObj} key1={'Parts'} />
                    </Col>
                    <Col xs="1" lg="1" style={{paddingRight:0, paddingLeft:0,}}>
                      <Form1a name={'Mark Up'} input={obj} index={index}  setInput={setObj} key1={'Parts'} />
                    </Col>
                    <Col xs="1" lg="1" style={{paddingRight:0, paddingLeft:0,}}>
                      <Form1a name={'Per Price'} input={obj} index={index}  setInput={setObj} key1={'Parts'} />
                    </Col>
                    <Col xs="1" lg="1" style={{paddingRight:0, paddingLeft:0,}}>
                      <Form1a name={'Price'} input={obj} index={index}  setInput={setObj} key1={'Parts'} />
                    </Col>
                    
                  </Row>
                  )
              })
            }
            

          

    <div style={{paddingBottom:"10px",borderBottom:"1px solid #000"}}/>

      {
        userData['companiesMap']!=undefined&&currCompany!=undefined&&userData['companiesMap'][currCompany]!=undefined&&userData['companiesMap'][currCompany]['admin']&&(obj['quoteApproved']==undefined||!obj['quoteApproved'])&&obj['Total']!=0?
        
    <Row style={{paddingBottom:"10px",borderBottom:"1px solid #000"}}>

      <Col xs="4" lg="4">
        <Button style={{ marginTop: 30}} onClick={edit} >Save Quote</Button>
      </Col>
      <Col xs="4" lg="4">
        <Button style={{ marginTop: 30}} onClick={print} >Print Quote</Button>
      </Col>
      <Col xs="4" lg="4">
        <Button style={{ marginTop: 30}} onClick={approve} >Approve Quote</Button>
      </Col>
    </Row>
    :
     <Row style={{paddingBottom:"10px",borderBottom:"1px solid #000"}}>

      <Col xs="6" lg="6">
        <Button style={{ marginTop: 30}} onClick={edit} >Save Quote</Button>
      </Col>
      <Col xs="6" lg="6">
        <Button style={{ marginTop: 30}} onClick={print} >Print Quote</Button>
      </Col>
    </Row>
      }

  </Row>


{/**************************************************************************************************/}
  <Row style={{maxWidth:1200,position:'fixed',zIndex:-1,top:65, display:'none'}}>
    <Row ref={ref1}>
      <Row>
        <div style={{ marginBottom: 20}} >
          <span style={{cursor: 'pointer'}} onClick={reset}>
            Quote
            {/*<FaCaretDown />*/}
          </span>
        </div>
      </Row>

      <Row> 
      {
        list.map((item,index)=>{
          let size = "2";
          switch(item){
            case "Contact Person": size=3;
              break;
            case "Customer": size=3;
              break;
            default:size=2;
          }
          
          return (
            <Col key={item+index+'yo'} xs={size} lg={size}>
              <div style={{overflowX:"hidden"}}>{item}</div>
            </Col>

            )
        })
      }
      
      </Row>
      
    
          <Row 
          > 
        {/*no forward slashes, need to program*/}
        {/*["Id","Customer","Date","Contact Person","Part Id","Customer Part Id","Description","Quantity",'Price']*/}
            <Col xs="2" lg="2" style={{paddingRight:0, paddingLeft:0,}}>
              <Form7 name={'Id'} emptyObj={emptyObj} input={obj} setInput={setObj} db={db} userData={userData} setSearch={setSearch} searchName={'order'} />
            </Col>
            <Col xs="2" lg="2" style={{paddingRight:0, paddingLeft:0,}}>
              {/*<Form6 name={'Rate'} input={obj} setInput={setObj} docData={docData1} red={red} setRed={setRed} />*/}
              <Form6 name={'Date'} input={obj} setInput={setObj} db={db} userData={userData} docData={docData1}setDocData={setDocData1} search={search} setSearch={setSearch} />
            </Col>
            <Col xs="3" lg="3" style={{paddingRight:0, paddingLeft:0,}}>
              <Form7 name={'Customer'} emptyObj={emptyObj} input={obj} setInput={setObj} db={db} userData={userData} docData={docData1}setDocData={setDocData1} search={search} setSearch={setSearch} searchName={'Customer'} custObj={custObj} setCustObj={setCustObj}  />
            </Col>
            <Col xs="3" lg="3" style={{paddingRight:0, paddingLeft:0,}}>
              <Form7c name={'Contact Person'} input={obj} setInput={setObj} custObj={custObj} db={db} userData={userData} />
            </Col>
            <Col xs="2" lg="2" style={{paddingRight:0, paddingLeft:0,}}>
              <Form6 name={'Total'} input={obj} setInput={setObj} />
            </Col>
            
          </Row>

          <Row style={{paddingTop:10,paddingBottom:10}}> 
            <Col xs="12" lg="12">
              <span style={{borderBottom:"1px solid #000"}}>
              Parts
              </span>
            </Col> 
          </Row> 

      <Row> 
      {
        list2.map((item,index)=>{
          let size = "1";
          switch(item){
            case "Description": size=4;
              break;
            case "Customer Part Id": size=2;
              break;
            case "Part Id": size=2;
              break;
            
            default:size=1;
          }
          
          return (
            <Col key={index+item} xs={size} lg={size}>
              <div style={{overflowX:"hidden"}}>{item}</div>
            </Col>

            )
        })
      }
      
      </Row>
    
           {
              obj['Parts'].map((item,index)=>{

                return (
                  <Row key={index+item} > 
                    <Col xs="2" lg="2" style={{paddingRight:0, paddingLeft:0,}}>
                      <Form7a name={'Part Id'} input={obj} index={index}  setInput={setObj} db={db} userData={userData} setSearch={setSearch} searchName={'parts'} key1={'Parts'} />
                    </Col>
                    <Col xs="2" lg="2" style={{paddingRight:0, paddingLeft:0,}}>
                      <Form1a name={'Customer Part Id'} input={obj} index={index}  setInput={setObj} key1={'Parts'} />
                    </Col> 
                    <Col xs="1" lg="1" style={{paddingRight:0, paddingLeft:0,}}>
                      <Form1a name={'Job Id'} input={obj} index={index}  setInput={setObj} key1={'Parts'} />
                    </Col>
                    <Col xs="4" lg="4" style={{paddingRight:0, paddingLeft:0,}}>
                      <Form1a name={'Description'} input={obj} index={index}  setInput={setObj} key1={'Parts'} />
                    </Col>
                    <Col xs="1" lg="1" style={{paddingRight:0, paddingLeft:0,}}>
                      <Form1a name={'Quantity'} input={obj} index={index}  setInput={setObj} key1={'Parts'} />
                    </Col>
                    <Col xs="1" lg="1" style={{paddingRight:0, paddingLeft:0,}}>
                      <Form1a name={'Per Price'} input={obj} index={index}  setInput={setObj} key1={'Parts'} />
                    </Col>
                    <Col xs="1" lg="1" style={{paddingRight:0, paddingLeft:0,}}>
                      <Form1a name={'Price'} input={obj} index={index}  setInput={setObj} key1={'Parts'} />
                    </Col>
                    
                  </Row>
                  )
              })
            }
            

          

    <div style={{paddingBottom:"10px",borderBottom:"1px solid #000"}}/>      
      
    </Row>
  </Row>

  </Container>
  );
}

export default Quote;
