import React from 'react';
import { StyledMenu } from './Menu.styled';
import SearchItem from './SearchItem.js';
import { useState, useEffect, useRef } from "react";
import { collection, query, where, getDocs, orderBy, onSnapshot } from "firebase/firestore";
import { FaCaretDown, FaCaretUp  } from 'react-icons/fa';
import Dropdown from 'react-dropdown';



const PartMenu = ({open, setOpen, db, list, setObj, setObj1, setNewNew, docData, setDocData,userData,laborObj, setLaborObj,setSelectedFile,setSelectedFile1}) => {
    
  const [searchLabel, setSearchLabel] = useState('Id');
  const [searchInput, setSearchInput] = useState('');
  const [openDrop, setOpenDrop] = useState(false);
  const queryRef = useRef();

  
  useEffect(()=>{
    return ()=>{
      if(queryRef.current!=null){
        queryRef.current()
        queryRef.current=null
      }
    }
  },[])

  const search = async (e)=>{

    if(e.target.value.length>0){
      setDocData([])
      if(userData['currCompany']!=undefined&&userData['currCompany']!=''){
        const q = query(collection(db,'companies', userData['currCompany'], "parts"), where(searchLabel, ">=", e.target.value), where(searchLabel, "<=", e.target.value+'\uf8ff'));

        queryRef.current = onSnapshot(q, (snapshot) => {
          snapshot.docChanges().forEach((change) => {
            // console.log(change)
            if (change.type === "added") {
                // console.log("New : ", change.doc.data());
                // setDocData(docData =>[...docData, change.doc.data()])
                setDocData((prevState) => ({
                ...prevState,
                  [change.doc.id]: change.doc.data()
                }
              ))
            }
            if (change.type === "modified") {
                // console.log("Modified : ", change.doc.data());
                setDocData((prevState) => ({
                ...prevState,
                  [change.doc.id]: change.doc.data()
                }
              ))
            }
            if (change.type === "removed") {
                setDocData((prevData) => {
                  const newData = {...prevData}
                  delete newData[change.doc.id]
                  return newData;
                })
            }
          });
        });
      }
    }
  }
  const fillInForm = (obj)=>{
    // fill in the cost estimate form
    console.log(obj)
    window.history.pushState({}, document.title, window.location.pathname);
    setNewNew(false)
    setSelectedFile([])
    setSelectedFile1([])
    
    setObj(obj)

  }

  return (
    <StyledMenu open={open} left={true}>
      <div style={{float:'top'}}>
        <span style={{ float: 'right', cursor: 'pointer', padding: '10px'}} onClick={()=>{setOpen(false)}}>
          X
        </span>
      </div>
        <div>
          {/*<span style={{display:'flex'}}>*/}
            {/*<FaCaretDown style={{'flex':2}} />*/}
          <Dropdown 
            className="dropdown"
            placeholderClassName="fuck"
            arrowClosed={<FaCaretDown className="arrow-closed" />}
            arrowOpen={<FaCaretUp className="arrow-open" />}
            controlClassName="dropdownC" open={openDrop} options={list} onChange={(e)=>{setSearchLabel(e.value)}} value={searchLabel}>
            
          </Dropdown>
      </div>
      <div>
          {/*</span>*/}
          
          <input
            // style={{float:"right"}}
            type="text" 
            value={searchInput}
            onChange={(e) => {setSearchInput(e.target.value);setDocData([]);search(e)}}
          />
      </div>


    {/*map of search output*/}
    {Object.keys(docData).length >= 1 &&
      Object.keys(docData).map((objj, index) => {
        {/*console.log(objj)*/}
       return (

          <SearchItem key={index+"floof"} obj={docData[objj]} onClick={fillInForm} searchLabel={searchLabel} /> 
        );

      })
    }
      
    </StyledMenu>
  )
}
export default PartMenu;