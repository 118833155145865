import {initializeApp} from 'firebase/app';
import { getAnalytics } from "firebase/analytics";

// Initialize Firebase

const firebaseConfig = {
  apiKey: "AIzaSyACKWQN-BNiZDZgM9wHoGQyin0O6RO9wGo",
  authDomain: "consultajent.firebaseapp.com",
  projectId: "consultajent",
  storageBucket: "consultajent.appspot.com",
  messagingSenderId: "364016585304",
  appId: "1:364016585304:web:16d4c877ff39e607e6982d",
  measurementId: "G-4ZK9B7GWSK"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
// if (!firebase.apps.length) {
// 	initializeApp(firebaseConfig);
// }else {
//    firebase.app(); // if already initialized, use that one
// }

export default app