import { useState, useEffect } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
// import useLongPress from './useLongPress.js';


const Form = ({name, setInput, input, useLongPressEvent })=> {

  // console.log(name,'fuckkckckck')

  return (
      <div>
        {
        name=='Id'?
        <input
          {...useLongPressEvent}
          style={{width:"100%"}}
          type="text" 
          value={input[name]}
          // onClick={}
          onChange={(e) => 
            setInput((prevState) => ({
              ...prevState,
                [name]: e.target.value,
              }
            ))
          }
        />
        :
        name!=undefined?
        <input
          style={{width:"100%"}}
          type="text" 
          value={input[name]}
          onChange={(e) => 
            setInput((prevState) => ({
              ...prevState,
                [name]: e.target.value,
              }
            ))
          }
        />
        :
        <span></span>
        }
    </div>
  );
}

export default Form