import { useState, useEffect } from 'react'

const Form6 = ({name, setInput, input, index,red,setRed })=> {
  // useEffect(()=>{
  //   console.log(name)
  //   console.log(input)
  //   console.log(index)  
  // },[])
  // const [red, setRed] = useState(false)


  return (
    <div>
  {
    name=='Labor'||name=='Total'?

    <input
      style={{width:'100%'}}
      type="text" 
      value={input[name]}
      onChange={(e) =>{}}
    />
    :
    name=='Rate'?
    <input
      style={{width:'100%'}}
      type="text" 
      className={red!=undefined&&red?'red':''}
      value={input[name]}
      onChange={(e) =>{

        setInput((prevState) => ({
            ...prevState,
              [name]: e.target.value,
            }
          ))
        }
      } 
    />
    :<input
      style={{width:'100%'}}
      type="text" 
      value={input[name]}
      onChange={(e) =>{

        setInput((prevState) => ({
            ...prevState,
              [name]: e.target.value,
            }
          ))
        }
      } 
    />
  }
    </div>
  );
}

export default Form6