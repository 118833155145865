import { useState, useEffect, useRef } from 'react'

import 'bootstrap/dist/css/bootstrap.min.css';
import { Nav, Navbar } from "react-bootstrap";
import logo from '../logo.svg'
import { setDoc, doc, collection, query, where, getDocs, orderBy, onSnapshot } from "firebase/firestore";
import { signOut } from "firebase/auth";
import Menu from "./Menu.js";
import NavMenu from "./NavMenu.js";
import PartMenu from "./PartMenu.js";
import WorkerMenu from "./WorkerMenu.js";
import Shop from "./Shop.js";
import Cart from "./Cart.js";
import { BrowserRouter as Router, Route, Link, useNavigate,useLocation } from "react-router-dom";



const Header = ({expanded,setExpanded,header,db,user,auth,setShowLogin,page,setPage,userData,setShowCost,showCost,openMenu,setOpenMenu,setShowOutlet,cartObj}) => {

  const navigate = useNavigate();
  const location = useLocation();
  const [show, setShow] = useState(false);
  const [qty, setQty] = useState(0);
  const loggedOutNonAdmin = ['Login']
  


  const [searchLabel, setSearchLabel] = useState('');

  const [showCost1, setShowCost1] = useState('Show Cost');
  // const loggedInNonAdmin = ['Workers','Components','Parts','Customers','Quotes','Purchase Orders','Bill of Lading','Invoice','Flow','Logout']
  // const loggedInAdmin = ['Workers','Components','Parts',showCost1,'Customers','Quotes','Purchase Orders','Bill of Lading','Invoice','Flow','Logout']
  const loggedInNonAdmin = ['Workers','Components','Products','Customers','Quotes','Purchase Orders','Bill of Lading','Invoice','Flow','Logout']
  const loggedInAdmin = ['Workers','Components','Products',showCost1,'Customers','Quotes','Purchase Orders','Bill of Lading','Invoice','Flow','Logout']
  const [list, setList] = useState(loggedOutNonAdmin);
  
  useEffect(()=>{
    if(userData!=null&&userData!=undefined&&userData['companiesMap']!=undefined){
      console.log(Object.keys(userData['companiesMap']))
      if(userData['currCompany']==''&&userData['companies'].length>0){
        setSearchLabel(userData['companies'][0])
      }else{
        setSearchLabel(userData['currCompany'])

      }
      // $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$############
      // cannot read undefined of admin
      if(userData['companiesMap'][userData['currCompany']]!=undefined){
        setList(userData['companiesMap'][userData['currCompany']]['admin']?loggedInAdmin:loggedInNonAdmin )
      }else{
        setList(loggedInNonAdmin)
      }
    }

  },[userData])

  useEffect(()=>{
    if(cartObj!=undefined){
      setQty(0)
      Object.keys(cartObj).forEach((item)=>{
        console.log(cartObj[item])
        setQty((prev)=>{
          let clone = JSON.parse(JSON.stringify(prev))
          clone += Number(cartObj[item]['qty'])
          return clone
        })

      })

    }

  },[cartObj])

  useEffect(()=>{
    console.log(location.pathname)
    const split = location.pathname.split('/')
    // console.log(split)
    if(split.length>=3){
      setShowOutlet(true)
    }else{
      setShowOutlet(false)
    }

  },[location])

  useEffect(()=>{
    if(userData!=null&&userData!=undefined&&userData['companiesMap']!=undefined&&searchLabel!=''&&userData['currCompany']!=searchLabel){

      console.log(Object.keys(userData['companiesMap']))
      setDoc(doc(db,'users', user.uid), {
        currCompany: searchLabel
      } , { merge: true })

    }
  },[searchLabel])

  useEffect(()=>{
    if(userData['companiesMap']!=undefined){
      if(userData['companiesMap'][userData['currCompany']]!=undefined){
        setList(userData['companiesMap'][userData['currCompany']]['admin']?loggedInAdmin:loggedInNonAdmin)
      }else{
        setList(loggedInNonAdmin)
      }
    }
  },[showCost1])

  useEffect(()=>{
    if(user!=null){
      // setList()
    }else {
      setList(loggedOutNonAdmin)
    }
  },[user])    


  const close = () =>{
    console.log("close ")
    setExpanded(false)
  } 
  const openSearch = () =>{
    console.log("close ")
    switch(location.pathname){
      case '/Products': ;
      break;
    }
    setOpenMenu(openMenu?false:true)
  } 

  const onClick = (page) => {
    let page1=page;
    setExpanded(false)
    switch(page){
      case 'Purchase Orders': page1='PurchaseOrders';
      break;
      case 'Bill of Lading': page1='BillOfLading';
      break;
    }

    if(page=='Logout'){
      signOut(auth)
    }else if(page=='Login'){
      console.log("fuck",page)
      setShowLogin(true)
    }else if(page==showCost1){
      console.log('show me the money')
      setShowCost(page=='Show Cost'?true:false)
      setShowCost1((prev)=>{return prev=='Hide Cost'?'Show Cost':'Hide Cost'})
    }else{
      navigate('/'+page1)
      setPage(page1)
    }
    // if (parallax.current) {
    // console.log(parallax.current)
    //   parallax.current.scrollTo(to)
    // }
  }

  return (
    <div ref={header} >
    {/*<Navbar expanded={expanded} className='navbar' collapseOnSelect expand={false} variant="light">*/}
    <Navbar expanded={expanded} className='navbar' collapseOnSelect expand="lg" variant="light">
      <Nav.Link onClick={()=>{openSearch()}} style={{ textDecoration: 'none' }} to="/">
        <img
          // style rounded corners
          style={{marginLeft:20,marginRight:20}}
          src={logo}
          alt="Logo"
          width="30"
          height="30"
          className="d-inline-block align-top"
        />
        <span className="link"> Ajent Orders</span>
      </Nav.Link>
      <Navbar.Toggle onClick={() => setExpanded(expanded ? false : true)} aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse onClick={close} id="responsive-navbar-nav">

        <Nav className="mr-auto">
          {list.map((item,index)=>{
            return (
              <div key={index+item+"head"}>
              {location.pathname!='/Products'&&item==showCost1?
                <span/>
              : item=='Cart'?
                <Nav.Link key={index+item+"head1"}  onClick={()=>{onClick(item)}}><span className="link">{item}</span><span className="cartQty">{qty}</span></Nav.Link>
              : 
              <Nav.Link key={index+item+"head1"}  onClick={()=>{onClick(item)}}><span className="link">{item}</span></Nav.Link>
              }
              </div>
              )
          })

          }
        </Nav>

      </Navbar.Collapse>
    </Navbar>

    {/*<Menu
      open={openMenu} 
      setOpen={setOpenMenu} 
      db={db}
      list={list}
      setObj={setObj}
      setObj1={setObj1}
      setNewNew={setNewNew}
      docData={docDataMenu}
      setDocData={setDocDataMenu}
      userData={userData}
      dbSection="parts"  
    />*/}

    </div>

  );
}



export default Header