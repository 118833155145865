import { useState, useEffect,useRef } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import useLongPress from './useLongPress.js';
import { collection, query, where, getDoc, doc, orderBy, onSnapshot } from "firebase/firestore";
import SearchItem from './SearchItem.js';
import { BrowserRouter as useNavigate,useLocation } from "react-router-dom";
import { Container, Row, Col, Button } from "react-bootstrap";
import Dropdown from 'react-dropdown';
import { FaCaretDown, FaCaretUp  } from 'react-icons/fa';




const SearchMulti = ({setObj,obj,userData,db,searchLabel1, setSearchLabel1,page1})=> {
  // useEffect(()=>{
  //   console.log(name)
  //   console.log(input)
  //   console.log(index)  
  // },[])
  // useEffect(()=>{
  //   console.log(input)
  // },[input])
  const location = useLocation()
  const [focused,setFocused] = useState(false)
  const [docData,setDocData] = useState({})
  const onFocus = () => setFocused(true)
  const onBlur = () => startTimer()
  const queryRef = useRef();

  const [list, setList] = useState(["Id","Customer PO Id","Customer"]);
  const [searchLabel, setSearchLabel] = useState('Id');
  const searchInputRef = useRef('');
  const [searchInput,setSearchInput]= useState('');
  const [list1, setList1] = useState([]);
  const [openDrop1, setOpenDrop1] = useState([]);
  
  const [input,setInput]= useState('');
  const [openDrop, setOpenDrop] = useState(false);
  

  const [counter, setCounter] = useState(0);
  const timer = useRef(null); // we can save timer in useRef and pass it to child

  const startTimer = ()=>{
     timer.current = setTimeout(() => {setFocused(false);}, 250);
  }

    useEffect(
    () => {

      // if(obj.length>=1){
      //   obj['toShip'][page1-1]['multi'].forEach((item,index)=>{
      //     const listy = []
      //     if(item['toShip'].length>=1){
      //       item['toShip'].forEach((item,index)=>{
      //         listy.push((index+1))
      //       })
      //     }
      //     console.log(listy)
      //   // set up for array vs obj
      //       setList1((prev)=>{

      //         const clone = JSON.parse(JSON.stringify(prev))

      //         clone.push(listy)
      //         return clone
      //         }
      //         )
      //       setOpenDrop1((prev)=>[...prev,false])
      //   })
      // }

      return () => {
        if(timer.current!=null){
          clearTimeout(timer.current);
        }
      };
    },[]);

  
  useEffect(()=>{
    return ()=>{
      if(queryRef.current!=null){
        queryRef.current()
        queryRef.current=null
      }

    }
  },[])


  const searchWorkers = async (e)=>{
    console.log(e.target.value)
    console.log(docData)
    console.log(searchLabel)
    // const name1 = name=="Quote Id"?'Id':name
    const name1 = searchLabel
    const orderBy1 = searchLabel == 'Customer'?'Id':searchLabel
    // if(e.target.value.length>2){
      setDocData({})
      if(userData['currCompany']!=undefined&&userData['currCompany']!=''){
        const q = query(collection(db,'companies', userData['currCompany'], 'order'), where(name1, ">=", e.target.value), where(name1, "<=", e.target.value+'\uf8ff'), orderBy(searchLabel, "desc"));
      // console.log(q)
        if(queryRef.current!=null){
          queryRef.current()
        }
        
        queryRef.current = onSnapshot(q, (snapshot) => {
          snapshot.docChanges().forEach((change) => {
            console.log(change)
            if (change.type === "added") {
                console.log("New : ", change.doc.data(), change.doc.id);
                // setDocData(docData =>[...docData, change.doc.data()])
                setDocData((prevState) => ({
                ...prevState,
                  [change.doc.id]: change.doc.data()
                }
              ))
            }
            if (change.type === "modified") {
                console.log("Modified : ", change.doc.data());
                const data = change.doc.data()
                // setMultiObj((prevState)=>{
                //   const clone = JSON.parse(JSON.stringify(prevState));
                // console.log(clone['Id'],data['Id']);
                // console.log(clone,data);
                  
                //   if(name=='Id'){

                //     if(clone['Id']==data['Id']){
                //               console.log('into not parts......................')
                //       // setMultiObj(data)
                //         return data
                //     }
                //   }
                //   return prevState
                // })
                setDocData((prevState) => ({
                ...prevState,
                  [change.doc.id]: change.doc.data()
                }
              ))
            }
            if (change.type === "removed") {
                setDocData((prevData) => {
                  const newData = {...prevData}
                  delete newData[change.doc.id]
                  return newData;
                })
            }
          });
        });
      }
    // }
  }

  // useEffect(()=>{
  //   console.log(searchLabel1)
  // },[searchLabel1])

  const fillInForm = async (objj)=>{
    // console.log(obj)

      const listy = []
      if(objj['toShip'].length>=1){
        objj['toShip'].forEach((item,index)=>{
          listy.push((index+1))
        })
      }


    // set up for array vs obj
        // setList1((prev)=>{

        //   const clone = JSON.parse(JSON.stringify(prev))

        //   clone.push(listy)
        //   return clone
        //   }
        //   )
        // setOpenDrop1((prev)=>[...prev,false])
        // setSearchLabel1((prev)=>[...prev,''])
    
      setObj((prev)=>{
        const clone = JSON.parse(JSON.stringify(prev))
        if(clone['toShip'][page1-1]['multi']==undefined){
          clone['toShip'][page1-1]['multi']=[]
        }
        if(clone['toShip'][page1-1]['searchLabel1']==undefined){
          clone['toShip'][page1-1]['searchLabel1']=[]
        }
        if(clone['toShip'][page1-1]['openDrop1']==undefined){
          clone['toShip'][page1-1]['openDrop1']=[]
        }
        if(clone['toShip'][page1-1]['list1']==undefined){
          clone['toShip'][page1-1]['list1']=[]
        }
        
        clone['toShip'][page1-1]['multi'].push(objj) 
        clone['toShip'][page1-1]['searchLabel1'].push('') 
        clone['toShip'][page1-1]['openDrop1'].push(false) 
        clone['toShip'][page1-1]['list1'].push({listy:listy}) 
        return clone;
      })

      console.log('listy',listy)
  }

  const remove = (index) => {
    setObj((prev)=>{
        const clone = JSON.parse(JSON.stringify(prev))
        clone['toShip'][page1-1]['multi'].splice(index,1)
        clone['toShip'][page1-1]['searchLabel1'].splice(index,1)
        clone['toShip'][page1-1]['openDrop1'].splice(index,1)
        clone['toShip'][page1-1]['list1'].splice(index,1)
        return clone;
      })

    // setMultiObj((prev=>{
    //   const clone = JSON.parse(JSON.stringify(prev))
    //   console.log(clone)
    //     clone.splice(index,1)
    //   console.log(clone)
    //   return clone
    // }))
    // setList1((prev=>{
    //   const clone = JSON.parse(JSON.stringify(prev))
    //   console.log(clone)
    //     clone.splice(index,1)
    //   console.log(clone)
    //   return clone
    // }))
    // setOpenDrop1((prev=>{
    //   const clone = JSON.parse(JSON.stringify(prev))
    //   console.log(clone)
    //     clone.splice(index,1)
    //   console.log(clone)
    //   return clone
    // }))
    // setSearchLabel1((prev=>{
    //   const clone = JSON.parse(JSON.stringify(prev))
    //   console.log(clone)
    //     clone.splice(index,1)
    //   console.log(clone)
    //   return clone
    // }))


  }

const onLongPress = () => {
      console.log('longpress is triggered');
      // if(name=="Customer"){

      //   // need to set up worker page

      //   // setObj1({Worker:input['Worker'],Rate:input['Rate']})
      //   window.history.pushState({}, document.title, window.location.pathname);
      //   let loc = String(window.location);
      //   let locSplit = loc.split('/')
      //   window.open(String(locSplit[0]+'/Customers'+"?CustomerId="+input['Customer Id']),'_blank');
        
      // }
  };

  const onClick = () => {
      // console.log('click is triggered')
  }

  const defaultOptions = {
      shouldPreventDefault: true,
      delay: 500,
  };
  const useLongPressEvent = useLongPress(onLongPress,onClick,defaultOptions)

  return (
    <div>
      <Row>
        <Col xs="6" lg="6" >
          <span>
            Search By
          </span>
        </Col>
        <Col xs="6" lg="6" >
          <Dropdown 
            className="dropdown"
            placeholderClassName="fuck"
            arrowClosed={<FaCaretDown className="arrow-closed" />}
            arrowOpen={<FaCaretUp className="arrow-open" />}
            controlClassName="dropdownC" open={openDrop} options={list} onChange={(e)=>{setSearchLabel(e.value)}} value={searchLabel}>
            
          </Dropdown>
        </Col>

      <Col xs="12" lg="12" >
      <input
        {...useLongPressEvent}
        style={{float:"left",width:'100%'}}
        type="text" 
        value={input}
        onFocus={onFocus} onBlur={onBlur}
        onChange={(e) =>{
          setInput(e.target.value)
          // setMultiObj((prevState) => ({
          //   ...prevState,
          //     [name]: e.target.value,
          //   }
          // ))
          searchWorkers(e)
          }
        } 
      />
        </Col>
      {focused && Object.keys(docData).length >= 1 &&
        Object.keys(docData).map((objj, index) => {
          {/*console.log(objj)*/}
         return (
            <Row key={index+"floof1234"} onClick={()=>{fillInForm(docData[objj])}}>
              <Col xs="3" lg="3" style={{padding:5}}>
                {docData[objj]['Id']}
              </Col>
              <Col xs="3" lg="3" style={{padding:5}}>
                {docData[objj]['Customer PO Id']}
              </Col>
              <Col xs="3" lg="3" style={{padding:5}}>
                {docData[objj]['Date']}
              </Col>
              <Col xs="3" lg="3" style={{padding:5}}>
                {docData[objj]['Customer']}
              </Col>
              
            </Row>

            // <SearchItem key={index+"floof"} key1={objj} obj={docData[objj]} setFocused={setFocused} onClick={fillInForm} searchLabel={name=="Quote Id"?'Id':name} /> 
            //<SearchItem key={index+"floof"} key1={objj} obj={docData[objj]} setFocused={setFocused} onClick={fillInForm} searchLabel={searchLabel} /> 
          );

        })
      }
      {
        obj['toShip'][page1-1]!=undefined&&
        obj['toShip'][page1-1]['multi']!=undefined&&
        obj['toShip'][page1-1]['multi'].map((item,index)=>{
          console.log(obj['toShip'][page1-1]['list1'][index])
          return(
          <div> 
            <Row key={index+'multiObj123'}>
              <Col xs="1" lg="1" style={{padding:5}} onClick={()=>remove(index)}>
                {'X'}
              </Col>
              <Col xs="3" lg="3" style={{padding:5}}>
                {item['Id']}
              </Col>
              <Col xs="3" lg="3" style={{padding:5}}>
                {item['Customer PO Id']}
              </Col>
              <Col xs="2" lg="2" style={{padding:5}}>
                {item['Date']}
              </Col>
              <Col xs="3" lg="3" style={{padding:5}}>
                {item['Customer']}
              </Col>
            </Row>
            {
              item['toShip'].length>1&&
              <Row>
                <Col xs="6" lg="6" style={{padding:5}}>
                Choose Split Order
                </Col>
                <Col xs="6" lg="6" style={{padding:5}}>
                {
                  obj['toShip'][page1-1]['list1'][index]!=undefined&&
                <Dropdown 
                  className="dropdown"
                  placeholderClassName="fuck"
                  arrowClosed={<FaCaretDown className="arrow-closed" />}
                  arrowOpen={<FaCaretUp className="arrow-open" />}
                  controlClassName="dropdownC" open={obj['toShip'][page1-1]['openDrop1'][index]!=undefined?obj['toShip'][page1-1]['openDrop1'][index]:false} 
                  options={obj['toShip'][page1-1]['list1']!=undefined&&obj['toShip'][page1-1]['list1'][index]!=undefined&&obj['toShip'][page1-1]['list1'][index]['listy']!=undefined?obj['toShip'][page1-1]['list1'][index]['listy']:['']} 
                  onChange={(e)=>{setObj((prev)=>{const clone = JSON.parse(JSON.stringify(prev)); if(clone['toShip'][page1-1]['searchLabel1']==undefined){clone['toShip'][page1-1]['searchLabel1']=[]} clone['toShip'][page1-1]['searchLabel1'][index]=e.value; console.log(clone['toShip'][page1-1]['searchLabel1'][index]); return clone;} )}} 
                  value={obj['toShip'][page1-1]['searchLabel1'][index]!=undefined?String(obj['toShip'][page1-1]['searchLabel1'][index]):'fuck'}>
                </Dropdown>
                }
                </Col>

              </Row>
            }

          </div> 
          )
        })
      }

      </Row>
    </div>
  );
}

export default SearchMulti