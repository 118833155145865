import { useState, useEffect,useRef } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import useLongPress from './useLongPress.js';
import { BrowserRouter as Router, Route, Link, useNavigate,useLocation } from "react-router-dom";
import {Modal, Spinner} from 'react-bootstrap';
import { Container, Row, Col, Button } from "react-bootstrap";
import { getFirestore, setDoc, collection, query, where, doc, getDocs, orderBy, arrayUnion, onSnapshot } from "firebase/firestore";


const Form99 = ({item,name,setOrderObj,setPage,status,userData,db,indy})=> {

  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false)
  const [Quote, setQuote] = useState(false)
  const [PurchaseOrder, setPurchaseOrder] = useState(false)
  const [BillOfLading, setBillOfLading] = useState(false)
  const [Invoice, setInvoice] = useState(false)
  const [status1,setStatus1] = useState(item['toShip'][indy]['Status'])
  const [status2,setStatus2] = useState({'Quote':false,'Purchase Order':false,'Bill Of Lading':false,'Invoice':false,'Close':false,'Open':false, })
  const [approved,setApproved] = useState(true)
  useEffect(()=>{
    switch(item['toShip'][indy]['Status']){
      case 'Quote': setStatus2((prevState)=>({
                      ...prevState,
                      ['Quote']:true,
                    }));
                    if(name=='Quote'&&item['quoteApproved']){
                      setApproved(true)
                    }else if(name=='Quote'){
                      console.log(name)
                      setApproved(false)
                    }

        break;
      case 'Purchase Order': setStatus2((prevState)=>({
                      ...prevState,
                      ['Purchase Order']:true,
                    }));
                    if(name=='Purchase Order'&&item['purchaseOrderApproved']){
                      setApproved(true)
                    }else if(name=='Purchase Order'){
                      console.log(name)
                      setApproved(false)
                    }
        break;
      case 'Bill Of Lading': setStatus2((prevState)=>({
                      ...prevState,
                      ['Bill Of Lading']:true,
                    }));
                    if(name=='Bill Of Lading'&&item['billOfLadingApproved']){
                      setApproved(true)
                    }else if(name=='Bill Of Lading'){
                      console.log(name)
                      setApproved(false)
                    }
        break;
      case 'Invoice': setStatus2((prevState)=>({
                      ...prevState,
                      ['Invoice']:true,
                    }));
                    if(name=='Invoice'&&item['invoiceApproved']){
                      setApproved(true)
                    }else if(name=='Invoice'){
                      console.log(name)
                      setApproved(false)
                    }
        break;
      case 'Close': setStatus2((prevState)=>({
                      ...prevState,
                      ['Close']:true,
                    }));
        break;
      case 'Open': setStatus2((prevState)=>({
                      ...prevState,
                      ['Open']:true,
                    }));
        break;
      
    }
  },[])

  const gotTo=(item)=>{
    let page1='Orders';
    switch(item['toShip'][indy]['Status']){
      case 'Purchase Order': page1='PurchaseOrders';
      break;
      case 'Bill Of Lading': page1='PurchaseOrders';
      break;
      case 'Invoice': page1='Invoice';
      break;
      case 'Quote': page1='Quotes';
      break;
      
    }
    setOrderObj(item)
    navigate('/'+page1)
    setPage(page1)
  }




  const onLongPress = () => {
      console.log('longpress is triggered');
      setShowModal(true)
      // if(name=="Id"){

      //   const paartNum = input['components'][index][name].replace('/',' , ')
      //   window.history.pushState({}, document.title, window.location.pathname);
      //   let loc = String(window.location);
      //   let locSplit = loc.split('/')
      //   window.open(String(locSplit[0]+'/Components'+"?number="+paartNum),'_blank');
      // }
  };

  const onClick = () => {
      console.log('click is triggered')
      gotTo(item)
  }

  const defaultOptions = {
      shouldPreventDefault: true,
      delay: 500,
  };
  const useLongPressEvent = useLongPress(onLongPress,onClick,defaultOptions)

  const changeStatus = ()=>{
    console.log('changeStatus')
    if(status1=='Close'){
      item['Open']=false
      item['toShip'][indy]['StatusOverride']=true
    }else if(status1=='Open'){
      item['Open']=true
      item['toShip'][indy]['StatusOverride']=true
    }else{
      item['toShip'][indy]['Status'] = status1
      item['toShip'][indy]['StatusOverride']=true
    }
    setDoc(doc(db, 'companies', userData['currCompany'], "order", String(item['Id'])), item , { merge: true })
    setShowModal(false)
  }

  const handleCheckChange=(status)=>{
    switch(status){
      case 'Quote': setStatus2((prevState)=>({
                      ...prevState,
                      ['Quote']:true,
                      ['Purchase Order']:false,
                      ['Bill Of Lading']:false,
                      ['Invoice']:false,
                      ['Close']:false,
                      ['Open']:false,
                    })); setStatus1('Quote');
        break;
      case 'Purchase Order': setStatus2((prevState)=>({
                      ...prevState,
                      ['Purchase Order']:true,
                      ['Quote']:false,
                      ['Bill Of Lading']:false,
                      ['Invoice']:false,
                      ['Close']:false,
                      ['Open']:false,
                    })); setStatus1('Purchase Order');
        break;
      case 'Bill Of Lading': setStatus2((prevState)=>({
                      ...prevState,
                      ['Bill Of Lading']:true,
                      ['Quote']:false,
                      ['Purchase Order']:false,
                      ['Invoice']:false,
                      ['Close']:false,
                      ['Open']:false,
                    })); setStatus1('Bill Of Lading');
        break;
      case 'Invoice': setStatus2((prevState)=>({
                      ...prevState,
                      ['Invoice']:true,
                      ['Quote']:false,
                      ['Purchase Order']:false,
                      ['Bill Of Lading']:false,
                      ['Close']:false,
                      ['Open']:false,
                    })); setStatus1('Invoice');
        break;
      case 'Close': setStatus2((prevState)=>({
                      ...prevState,
                      ['Invoice']:false,
                      ['Quote']:false,
                      ['Purchase Order']:false,
                      ['Bill Of Lading']:false,
                      ['Close']:true,
                    })); setStatus1('Close');
        break;
      case 'Open': setStatus2((prevState)=>({
                      ...prevState,
                      ['Invoice']:false,
                      ['Quote']:false,
                      ['Purchase Order']:false,
                      ['Bill Of Lading']:false,
                      ['Close']:false,
                      ['Open']:true,
                    })); setStatus1('Open');
        break;
      
    }
  }

  return (
      <div>
    <div style={{color:'black'}} {...useLongPressEvent} >
      {!status?
      item[name]
      :
      !approved?
      <span style={{color:'black'}}>{name+' !'}</span> 
      :
      <span style={{color:'black'}}>{name}</span>
      }
      </div>
      {showModal&&
          <Modal
            show={true}
            onHide={()=>{setShowModal(false)}}
            backdrop="static"
            // keyboard={false}
          >
            <Modal.Header closeButton>
                <Modal.Title>Change Status</Modal.Title>
            </Modal.Header>

            <Modal.Body>
              <div>{item['Customer PO Id']!=''?item['Id']+" : "+item['Customer PO Id']:item['Id']}</div>
              <div>
              <label>

                <input
                  name="Quote"
                  type="checkbox"
                  checked={status2['Quote']}
                  onChange={()=>{handleCheckChange("Quote")}} />
                  <span> </span>
                Quote 
              </label>
              </div>
              <div>
              <label>
                <input
                  name="Purchase Order"
                  type="checkbox"
                  checked={status2['Purchase Order']}
                  onChange={()=>{handleCheckChange("Purchase Order")}} />
                <span> </span>
                Purchase Order 
              </label>
              </div>
              <div>
              <label>
                <input
                  name="Bill Of Lading"
                  type="checkbox"
                  checked={status2['Bill Of Lading']}
                  onChange={()=>{handleCheckChange("Bill Of Lading")}} />
                <span> </span>
                Bill Of Lading 
              </label>
              </div>
              <div>
              <label>
                <input
                  name="Invoice"
                  type="checkbox"
                  checked={status2['Invoice']}
                  onChange={()=>{handleCheckChange("Invoice")}} />
                <span> </span>
                Invoice 
              </label>
              </div>
              {
                item['Open']?
              <div>
              <label>
                <input
                  name="Close"
                  type="checkbox"
                  checked={status2['Close']}
                  onChange={()=>{handleCheckChange("Close")}} />
                <span> </span>
                Close 
              </label>
              </div>
              :
              <div>
              <label>
                <input
                  name="Open"
                  type="checkbox"
                  checked={status2['Open']}
                  onChange={()=>{handleCheckChange("Open")}} />
                <span> </span>
                Open 
              </label>
              </div>
              
              }
              
              
              
            </Modal.Body>

            <Modal.Footer>
              {item['toShip'][indy]['Status']==status1?
              <div>
              </div>
              :
                <Button variant="primary" onClick={changeStatus} >Confirm Change Status</Button>
              }
            </Modal.Footer>

          </Modal>
          }
    </div>
  );
}

export default Form99