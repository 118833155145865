import { useState, useEffect } from 'react'

const Form1 = ({name, setInput, input, index })=> {
  useEffect(()=>{
    // console.log(input['components'][index])
    
  })

  return (
    <div>
    <input
      style={{float:"left",width:'100%'}}
      type="text" 
      value={input['components'][index]!=undefined?input['components'][index][name]:''}
      onChange={(e) =>{
        // console.log(input['components'][index])
        setInput((prevState) => {
            const clone = JSON.parse(JSON.stringify(prevState));
            const previousArr = clone['components'];
            if(previousArr[index]==undefined){
              previousArr[index]=[]
            }
            previousArr[index][name]=e.target.value;
            if(name=='Qty.'){
              // console.log(Number(previousArr[index]['Qty.']),previousArr[index]['Qty.'])
              // console.log(String(Number(previousArr[index]['COST1'])*Number(previousArr[index]['Qty.'])),Number(previousArr[index]['COST1'])*Number(previousArr[index]['Qty.']))
              // previousArr[index]['Conversion1'] = previousArr[index]['Conversion']!=undefined&&previousArr[index]['Conversion']!=''?(Number(previousArr[index]['Conversion'])*Number(previousArr[index]['Qty.'])).toFixed(2):0
              // previousArr[index]['Labor1'] =previousArr[index]['Labor']!=undefined&&previousArr[index]['Labor']!=''? (Number(previousArr[index]['Labor'])*Number(previousArr[index]['Qty.'])).toFixed(2):0
              // previousArr[index]['Overhead1'] =previousArr[index]['Overhead']!=undefined&&previousArr[index]['Overhead']!=''? ((Number(previousArr[index]['Overhead'])*Number(previousArr[index]['Labor']))*Number(previousArr[index]['Qty.'])).toFixed(2):0
              const materialOverhead = previousArr[index]['MaterialOverhead']!=undefined&&previousArr[index]['MaterialOverhead']!=''?Number(previousArr[index]['MaterialOverhead']):0 
              const laborOverhead = previousArr[index]['LaborOverhead']!=undefined&&previousArr[index]['LaborOverhead']!=''? Number(previousArr[index]['LaborOverhead']):0
              const laborCost = previousArr[index]['Labor']!=undefined&&previousArr[index]['Labor']!=''? Number(previousArr[index]['Labor']):0
              console.log(laborOverhead,materialOverhead)
              
              previousArr[index]['Overhead1'] =((materialOverhead+laborOverhead)*Number(previousArr[index]['Qty.'])).toFixed(2)
              previousArr[index]['Labor1'] =((laborCost)*Number(previousArr[index]['Qty.'])).toFixed(2)
              previousArr[index]['Conversion1'] = ((materialOverhead+laborOverhead+laborCost)*Number(previousArr[index]['Qty.'])).toFixed(2)
              

              previousArr[index]['Material1'] =previousArr[index]['Material']!=undefined&&previousArr[index]['Material']!=''? (Number(previousArr[index]['Material'])*Number(previousArr[index]['Qty.'])).toFixed(2):0
              previousArr[index]['Total1'] =previousArr[index]['Total']!=undefined&&previousArr[index]['Total']!=''? (Number(previousArr[index]['Total'])*Number(previousArr[index]['Qty.'])).toFixed(2):0
              
              // previousArr[index]['Cost'] = previousArr[index]['Total']!=undefined? String((Number(previousArr[index]['Total'])*Number(previousArr[index]['Qty.'])).toFixed(2)):previousArr[index]['Cost'];
            }
            clone['components']=previousArr
            return clone;   
          })
        // console.log('fuckkkk')
        // const clone = [...input];
        // clone[index][name] = e.target.value;
        // setInput(clone)
        }
      } 
    />
    </div>
  );
}

export default Form1