import { useState, useEffect,useRef } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import useLongPress from './useLongPress.js';
import { collection, query, where, getDocs, orderBy, onSnapshot } from "firebase/firestore";
import SearchItem from './SearchItem.js';



const Form5 = ({name, setInput, input, index, userData, db,setObj1,docData,setDocData,red,setRed,search,setSearch})=> {
  // useEffect(()=>{
  //   console.log(name)
  //   console.log(input)
  //   console.log(index)  
  // },[])
  const [focused, setFocused] = useState(false)
  const onFocus = () => setFocused(true)
  const onBlur = () => startTimer()
  const queryRef = useRef();

  const [counter, setCounter] = useState(0);
  const timer = useRef(null); // we can save timer in useRef and pass it to child

  const startTimer = ()=>{
     timer.current = setTimeout(() => {setFocused(false);}, 250);
  }
    useEffect(
    () => {

      // this will clear Timeout
      // when component unmount like in willComponentUnmount
      // and show will not change to true
      return () => {
        if(timer.current!=null){
          clearTimeout(timer.current);
        }
      };
    },
    // useEffect will run only one time with empty []
    // if you pass a value to array,
    // like this - [data]
    // than clearTimeout will run every time
    // this value changes (useEffect re-run)
    []
  );

  // useEffect(()=>{
  //   if(Object.keys(docData).length>0){

  //     if(input['Worker']!=''&&docData[input['Worker']]==undefined&&!focused){
  //       // setRed(true)
  //     }else if(input['Worker']!=''&&docData[input['Worker']]!=undefined&&input['Rate']!=docData[input['Worker']]['Rate']&&!focused){
  //       // setRed(true)
  //     }else{setRed(false)}

  //   }

  // },[input['Worker'],input['Rate'],focused,docData])  

  useEffect(()=>{
    if(search){
      searchWorkers({target:{value:input['Worker']}})
      setSearch(false)
    }
  },[search])
  
  useEffect(()=>{
    return ()=>{
      if(queryRef.current!=null){
        queryRef.current()
        queryRef.current=null
      }

    }
  },[])


  const searchWorkers = async (e)=>{
    console.log(docData)
    // if(e.target.value.length>2){
      setDocData([])
      if(userData['currCompany']!=undefined&&userData['currCompany']!=''){
        const q = query(collection(db,'companies', userData['currCompany'], "workerTier"), where('Id', ">=", e.target.value), where('Id', "<=", e.target.value+'\uf8ff'));
      // console.log(q)
        if(queryRef.current!=null){
          queryRef.current()
        }
        
        queryRef.current = onSnapshot(q, (snapshot) => {
          snapshot.docChanges().forEach((change) => {
            // console.log(change)
            if (change.type === "added") {
                console.log("New : ", change.doc.data(), change.doc.id);
                // setDocData(docData =>[...docData, change.doc.data()])
                setDocData((prevState) => ({
                ...prevState,
                  [change.doc.id]: change.doc.data()
                }
              ))
            }
            if (change.type === "modified") {
                // console.log("Modified : ", change.doc.data());
                if(change.doc.id==input['Worker']){
                  
                }
                setDocData((prevState) => ({
                ...prevState,
                  [change.doc.id]: change.doc.data()
                }
              ))
            }
            if (change.type === "removed") {
                setDocData((prevData) => {
                  const newData = {...prevData}
                  delete newData[change.doc.id]
                  return newData;
                })
            }
          });
        });
      }
    // }
  }

  const fillInForm = (obj)=>{
    console.log(obj)
    setInput((prevState) => ({
                ...prevState,
                  ['Worker']: obj['Id'],
                  ['Rate']: obj['Rate']!=undefined&&obj['Rate']!=''?obj['Rate']:0,
                }
              ))
  }

  const onLongPress = () => {
      console.log(input,name, input[name], 'longpress is triggered');
      if(name=="Worker"){

        // need to set up worker page

        // setObj1({Worker:input['Worker'],Rate:input['Rate']})
        window.history.pushState({}, document.title, window.location.pathname);
        let loc = String(window.location);
        let locSplit = loc.split('/')
        window.open(String(locSplit[0]+'/Workers'+"?workerTierId="+input['Worker']),'_blank');
        
      }
  };

  const onClick = () => {
      // console.log('click is triggered')
  }

  const defaultOptions = {
      shouldPreventDefault: true,
      delay: 500,
  };
  const useLongPressEvent = useLongPress(onLongPress,onClick,defaultOptions)

  return (
    <div>
      <input
        {...useLongPressEvent}
        style={{float:"left",width:'100%'}}
        className={red?'red':''}
        type="text" 
        value={input[name]}
        onFocus={onFocus} onBlur={onBlur}
        onChange={(e) =>{
          setInput((prevState) => ({
            ...prevState,
              [name]: e.target.value,
            }
          ))
          searchWorkers(e)
          }
        } 
      />
      {focused && Object.keys(docData).length >= 1 &&
        Object.keys(docData).map((objj, index) => {
          {/*console.log(objj)*/}
         return (

            <SearchItem key={index+"floof"} obj={docData[objj]} setFocused={setFocused} onClick={fillInForm} searchLabel={'Id'} /> 
          );

        })
      }
    </div>
  );
}

export default Form5