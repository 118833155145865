import { useState, useEffect } from 'react'

const Form6a = ({name, setInput, input, index,red,setRed })=> {
  // useEffect(()=>{
  //   console.log(name)
  //   console.log(input)
  //   console.log(index)  
  // },[])
  // const [red, setRed] = useState(false)

  

  return (
    <div>
  
    <input
      style={{width:'100%'}}
      type="text" 
      value={input[name]}
      onChange={(e) =>{

        setInput((prevState) => {
              console.log(name)
            if(name!='Total'){

              const clone = JSON.parse(JSON.stringify(prevState))
              clone[name] = e.target.value
              let lab=0;
              if(name=='Parts/hr'){
                clone['laborOverRide']=false;
              }
              if(name!='Labor'&&!clone['laborOverRide']){
              lab =clone['Parts/hr']!=0?Number(((Number(clone['Rate'])/60)*(60/Number(clone['Parts/hr']))).toFixed(2)):0
              }else{
                clone['laborOverRide']=true;
                lab = clone['Labor']!=''?Number(clone['Labor']):0
              }
              console.log(lab)
              const overhead = clone['Overhead%']!=''&&clone['Overhead%']!=undefined?Number(clone['Overhead%']):0
              const laborOverhead = name=='LaborOverhead'?Number(clone['LaborOverhead']): Number(Number(lab*Number(overhead/100)).toFixed(0))
              console.log(clone['Overhead%'], laborOverhead)
              const material = clone['Material']!=''?Number(clone['Material']):0
              // const fixedOverhead = clone['Fixed Overhead']!=''?Number(clone['Fixed Overhead']):0
              // const conversion = fixedOverhead>0? Number(fixedOverhead): laborOverhead+Number(lab)
              const conversion = laborOverhead+Number(lab)
              console.log(conversion,material)
              
              clone['Labor']= lab;
              clone['LaborOverhead']= laborOverhead;
              clone['Conversion']= (conversion).toFixed(2);
              let lastPrice=0;
              let lastShipping=0;
              let lastTotal=0;
              for(let x=clone['PurchaseHistory'].length-1;x>=0;x--){
                console.log(Number(clone['PurchaseHistory'][x]['Per Total']))
                if(Number(clone['PurchaseHistory'][x]['Per Total'])>0){
                console.log(Number(clone['PurchaseHistory'][x]['Per Total']))
                lastPrice = Number(clone['PurchaseHistory'][x]['Per Price'])
                lastShipping = Number(clone['PurchaseHistory'][x]['Per Shipping'])!=='NaN'?Number(clone['PurchaseHistory'][x]['Per Shipping']):0;
                lastTotal = Number(clone['PurchaseHistory'][x]['Per Total'])!=='NaN'?Number(clone['PurchaseHistory'][x]['Per Total']):0;
                break;  
                }
              }   
              console.log('last Total',lastTotal)
              clone['Total']= Number((conversion+lastTotal).toFixed(2));

              return clone;
            }else{
              const clone = JSON.parse(JSON.stringify(prevState))
              clone[name] = e.target.value
              console.log(clone)
              const fixedOverhead = clone['Fixed Overhead']!=''?Number(clone['Fixed Overhead']):0
              if(fixedOverhead>0){
                clone['Material']= Number(Number(clone['Total'])-Number(fixedOverhead)).toFixed(2)  
              }
              return clone;

            }
          })
        }
      } 
    />
  
    </div>
  );
}

export default Form6a