import '../App.css';
import Form2 from './Form2.js';
import Form1 from './Form1.js';
import Form1a from './Form1a.js';
import Form5 from './Form5.js';
import Form6 from './Form6.js';
import Form7 from './Form7.js';
import Form7a from './Form7a.js';
import Form7c from './Form7c.js';
import WorkerMenu from './WorkerMenu.js';
import NavMenu from './NavMenu.js';
import { useState, useEffect, useRef } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col, Button } from "react-bootstrap";
import { getFunctions, httpsCallable } from "firebase/functions";
import {  } from "firebase/firestore";
import { getFirestore, setDoc, collection, query, where, doc, getDocs, orderBy, arrayUnion, onSnapshot } from "firebase/firestore";
import { getAuth, createUserWithEmailAndPassword, onAuthStateChanged, signInWithEmailAndPassword, signOut, sendEmailVerification, sendPasswordResetEmail } from "firebase/auth";
import firebase from './firebase.js';
import LoginModal from './LoginModal.js'
import { FaCaretDown,FaCaretUp,FaSearch,FaRedo,FaBars } from 'react-icons/fa';
import inventory from '../jsons/inventoryData.json'
import {Modal, Spinner} from 'react-bootstrap';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import { BrowserRouter as Router, Route, Link, useNavigate } from "react-router-dom";
import Dropdown from 'react-dropdown';
import { FaCaretLeft,FaCaretRight} from 'react-icons/fa';




function Invoice({user,db,auth,openMenu,setOpenMenu,setShowLogin,setPage,openNavMenu,setOpenNavMenu,userData,obj,setObj,custObj,setCustObj,currCompany,setCurrCompany,openDrop,setOpenDrop}) {
  

  const [list, setList] = useState(["Id","Date","Customer","Contact Person","Total"])
  const [list1, setList1] = useState(["Part Id","Customer Part Id","Description","Quantity",'Cost','Mark Up','Price'])
  
  const emptyObj = {"Id":"","Customer":"","Customer Id":"","Date":"","Contact Person":"","Total":"","PartIds":[],"CustomerPartIds":[],"Parts":[{"Part Id":"","Customer Part Id":"","Description":"","Quantity":"",'Cost':"",'Mark Up':"",'Price':''}]}
  const emptyPartObj = {"Part Id":"","Customer Part Id":"","Description":"","Quantity":"",'Cost':"",'Mark Up':"",'Price':''}
  
  const [partsObj,setPartsObj] = useState({})
  const [partsObj1,setPartsObj1] = useState([])
  const [search, setSearch] = useState(false)
  const [docData, setDocData] = useState([])
  const [docData1,setDocData1] = useState({})
  const [screenWidth,setScreenWidth] = useState(0)
  const [date,setDate] = useState()
  const ref = useRef()
  const navigate = useNavigate()
  const [currCompany1,setCurrCompany1]=useState({name:currCompany})
  const queryRef = useRef();
  const [page1,setPage1] = useState(1)
  const [showInput,setShowInput] = useState(false)


  const backward = ()=>{
    setPage1((prevState)=>{
      if(prevState>1){
        return prevState-1
      }else{
        return prevState
      }
    })
  }
  
  const forward = ()=>{
    setPage1((prevState)=>{
      if(prevState<obj['toShip'].length){
        return prevState+1
      }else{
        return prevState
      }
    })
  }



  useEffect(()=>{
    // console.log(obj)
    return ()=>{
      if(queryRef.current!=null){
        queryRef.current()
        queryRef.current=null
      }

    }
  },[])


  useEffect(()=>{
    if(userData['currCompany']!=undefined&&userData['currCompany']!=''){
      // const date=new Date();
      // const year = date.getFullYear();
      //   // console.log(year)
      // const year2 = (year-2000)
      // let month = date.getMonth();
      // let day = date.getDate();
      // setDate(month+'/'+day+'/'+year)
      // if(obj['Invoice']==undefined){

      //     setObj((prevState)=>{
      //       const clone = JSON.parse(JSON.stringify(prevState));
      //       clone['Invoice']={};
      //       clone['Invoice']['Date'] = month+'/'+day+'/'+year;

      //       return clone
      //     })
      // }

      if(queryRef.current!=null){
        queryRef.current()
      }
      const name = currCompany=='Fostoria Bushings'?'Fostoria Bushings & Insulators Corp.':currCompany;
      const q = query(collection(db,'companies', userData['currCompany'], 'Customer'), where('Customer', "==", name));

      queryRef.current = onSnapshot(q, (snapshot) => {
          // console.log(snapshot.size)
        snapshot.docChanges().forEach((change) => {
          // console.log(change)
          const data = change.doc.data()
          if (change.type === "added") {
              // console.log("New Custy info: ", change.doc.data(), change.doc.id);
              // setDocData(docData =>[...docData, change.doc.data()])
              setCurrCompany1((prevState) => {
                const yup = {}
                yup['name']  = data['Customer']
                yup['phone']  = data['contactPerson'][0]!=undefined ? data['contactPerson'][0]['Phone Number'] :''
                yup['Address']  = data['Addresses'][0]!=undefined ? data['Addresses'][0] :{}
                // yup['Address 2']  = data['Addresses'][0]!=undefined ? data['Addresses'][0] :{}
                return yup
              }
            )
          }
        });
      });
      
    }
  },[])

  useEffect(()=>{
    const partObj={}
    const partObj1=[]
    if(obj['toShip'].length<=1){
      const clone = JSON.parse(JSON.stringify(obj))
      clone['Parts'].forEach((item,index)=>{
        const id = item['Customer Part Id'] != ''? item['Customer Part Id']: item['Part Id']
        if(partObj[id]==undefined){
          partObj[id]=item
          // console.log(item)
           partObj[id]['Line Item1'] = [];
           partObj[id]['Line Item1'].push((index+1))
        }else{
           partObj[id]['LineDouble'] = true;
           partObj[id]['Line Item1'].push((index+1))
        
          partObj[id]['Quantity']=Number(partObj[id]['Quantity'])+Number(item['Quantity'])
          partObj[id]['Price']=Number(partObj[id]['Price'])+Number(item['Price'])

          // console.log(item)
        }
      })
      setPartsObj(partObj)
    }else{
      const clone = JSON.parse(JSON.stringify(obj))
      clone['toShip'].forEach((item,index)=>{
        item['Parts'].forEach((item1,index1)=>{
        const id = item1['Customer Part Id'] != ''? item1['Customer Part Id']: item1['Part Id']
          if(partObj1[index]==undefined){
            partObj1[index]=[]
          }
          if(partObj1[index][id]==undefined){
            partObj1[index][id]=item1
            partObj1[index][id]['Line Item1'] = [];
            partObj1[index][id]['Line Item1'].push((item1['Line Item']+1))
          }else{
            partObj1[index][id]['LineDouble'] = true;
            partObj1[index][id]['Line Item1'].push((item1['Line Item']+1))
            console.log(partObj1[index][id]['LineDouble'],partObj1[index][id]['Line Item1'])


            // partObj1[index][id]['QtyLeft']=Number(item1['QtyLeft'])
            partObj1[index][id]['Quantity']=Number(partObj1[index][id]['Quantity'])+Number(item1['Quantity'])
            partObj1[index][id]['Price']=Number(partObj1[index][id]['Price'])+Number(item1['Price'])
          }
        })
      })
      console.log(partObj1)
      setPartsObj1(partObj1)
    }
  },[obj])
  
    

    
  
  const edit = ()=>{
    console.log('edit')
    if(obj['Id']==''){
      // setShowModal(true)
    }else{
      
      // setDoc(doc(db, 'companies', userData['currCompany'], "Quote", String(clone['Id'])), clone , { merge: true })
    }
  }

  const save=()=>{
    console.log('save')
    if(userData['currCompany']!=undefined&&userData['currCompany']!=''){
      setDoc(doc(db, 'companies', userData['currCompany'], "order", String(obj['Id'])), obj , { merge: true })
    }
  }
  
  const print = (name)=>{
      console.log('print')
      const doc = new jsPDF();
      // console.log(screenWidth)
      doc.html(ref.current, {
         callback: function (doc) {
           doc.save('Invoice_'+name+'.pdf');
         },
         x: 5,
         y: 10,
         width: 200,
         windowWidth: 1200,
         html2canvas : {windowWidth : 1200},
         filename: 'invoice'+String(obj['Id']),
      }
      );
    };
  

  const reset = ()=>{
    const clone = JSON.parse(JSON.stringify(emptyObj));
    clone['Id']=String(Date.now())
    const date=new Date();
    const year = date.getFullYear();
      // console.log(year)
    const year2 = (year-2000)
    let month = date.getMonth();
    let day = date.getDate();

    clone['Date']=month+"/"+day+"/"+year2
    clone['Invoice Date']=month+"/"+day+"/"+year2
    setObj(clone)
  }

  return(
    <Container style={{height:'100%','marginTop':56}}>
      <Row onClick={()=>{if(openMenu){setOpenMenu(false)};if(openNavMenu){setOpenNavMenu(false)}}} >
      <Row>
        <div style={{ marginBottom: 20}} >
        {userData['currCompany']!=undefined?
          userData['companies'].length>1?
          <span className="fuck1">
            <Dropdown 
              className="dropdown"
              placeholderClassName="fuck"
              arrowClosed={<FaCaretDown className="arrow-closed" />}
              arrowOpen={<FaCaretUp className="arrow-open" />}
              controlClassName="dropdownC" open={openDrop} options={Object.keys(userData['companiesMap'])} onChange={(e)=>{setCurrCompany(e.value)}} value={currCompany}>
              
            </Dropdown>
          </span>      
          :
          <span>{userData['currCompany']}</span>
          :''}
        </div>
      </Row>
      <Row>
        <div style={{ marginBottom: 20}} >
          <span style={{cursor: 'pointer'}} onClick={reset}>
            <FaRedo />  Invoice
          </span>
        </div>
      </Row>
    <Row>
      <div>
      {obj['toShip'].length>1&&
        <Row>
          <Col xs="4" lg="4">
          </Col>

          <Col xs="1" lg="1">
            <FaCaretLeft onClick={backward} />
          </Col>
          <Col xs="2" lg="2">
            {page1}
          </Col>
          <Col xs="1" lg="1">
            <FaCaretRight onClick={forward} />
          </Col>
          
          <Col xs="4" lg="4">
          </Col>
        </Row>
      }
      </div>
      </Row>
    <Row ref={ref} style={{paddingRight:20,paddingLeft:20,}}>
      <Row>
        <Col xs="6" lg="6" style={{paddingRight:0,textAlign:'left'}}>
          <Row>
            <Col xs="12" lg="12" style={{paddingRight:0}}>
              {currCompany1['name']}
            </Col>
          </Row>
          <Row>
            <Col xs="12" lg="12" style={{paddingRight:0,textAlign:'left'}}>
              {currCompany1['phone']}
            </Col>
          </Row>
          <Row>
            <Col xs="12" lg="12" style={{paddingRight:0,textAlign:'left'}}>
              {currCompany1['Address']!=undefined&&currCompany1['Address']['Address']}
            </Col>
          </Row>
          <Row>
            <Col xs="12" lg="12" style={{paddingRight:0,textAlign:'left'}}>
              {currCompany1['Address']!=undefined&&currCompany1['Address']['Address 2']}
            </Col>
          </Row>
          <Row>
            <Col xs="12" lg="12" style={{paddingRight:0,textAlign:'left'}}>
              {currCompany1['Address']!=undefined&&currCompany1['Address']['City']+', ' +currCompany1['Address']['State']+' ' +currCompany1['Address']['Zip']}
            </Col>
          </Row>



        </Col>
        <Col xs="6" lg="6" style={{paddingRight:0, paddingLeft:18,}}>
          <Row>
            <Col xs="12" lg="12" style={{paddingRight:0,}}>
              <h3>Invoice</h3>
            </Col>
          </Row>
          <Row>
            <Col xs="6" lg="6" style={{paddingRight:0, paddingLeft:0,border:'2px solid black',borderWidth:'2px 0px 2px 2px',}}>
              Date:
            </Col>
            <Col xs="6" lg="6" style={{paddingRight:0,paddingLeft:0,border:'2px solid black',borderWidth:'2px 2px 2px 2px',}}>
              Invoice #
            </Col>
          </Row>
          <Row >
            <Col xs="6" lg="6" style={{paddingRight:0, paddingLeft:0,border:'2px solid black',borderWidth:'0px 0px 2px 2px',}}>
          {/*make long click to change date*/}
              <input 
                style={{borderWidth:'0px',width:'100%',textAlign:'center'}}
                value={
                  obj['toShip'][page1-1]['Invoice Date']!=undefined?
                  obj['toShip'][page1-1]['Invoice Date']:
                  obj['toShip'][page1-1]['Ship By Date']
                }
                type="text" 
                onChange={(e) =>{
                  setObj((prevState)=>{
                    const clone = JSON.parse(JSON.stringify(prevState));
                    clone['toShip'][page1-1]['Invoice Date']=e.target.value
                    return clone
                  })
                  }
                } 

              />
              
            </Col>
            <Col xs="6" lg="6" style={{paddingRight:0, paddingLeft:0,border:'2px solid black',borderWidth:'0px 2px 2px 2px',}}>
              {obj['toShip'].length>1?obj['Id']+'-'+page1 :obj['Id']}
            </Col>

          </Row>
        </Col>
        
      </Row>


      <Row style={{paddingTop:110,paddingBottom:110}}>
        <Col xs="6" lg="6" style={{paddingRight:4,textAlign:'left'}}>
          <Row>
            <Col xs="12" lg="12" style={{border:'2px solid black',borderWidth:'2px 0px 2px 2px'}}>
              Bill To
            </Col>
          </Row>
          <Row style={{border:'2px solid black',borderWidth:'0px 2px 2px 2px'}}>
          <Row>
            <Col xs="12" lg="12" style={{paddingRight:0,textAlign:'left',minHeight:24}}>
              {obj['BillingInfo']!=undefined&&obj['BillingInfo']['Contact Person']}
            </Col>
          </Row>
          <Row>
            <Col xs="12" lg="12" style={{paddingRight:0,textAlign:'left',minHeight:24}}>
              {obj['BillingInfo']!=undefined&&obj['BillingInfo']['Address']}
            </Col>
          </Row>
          <Row>
            <Col xs="12" lg="12" style={{paddingRight:0,textAlign:'left'}}>
              {obj['BillingInfo']!=undefined&&obj['BillingInfo']['Address 2']}
            </Col>
          </Row>
          <Row>
            <Col xs="12" lg="12" style={{paddingRight:0,textAlign:'left',minHeight:24}}>
              {(obj['BillingInfo']!=undefined&&obj['BillingInfo']['City'])!=''?obj['BillingInfo']['City']+', ' +obj['BillingInfo']['State']+' '+obj['BillingInfo']['Zip']:''}
            </Col>
          </Row>
          {obj['BillingInfo']!=undefined&&obj['BillingInfo']['Address 2']==''&&
            <Row>
            <Col xs="12" lg="12" style={{paddingRight:0,textAlign:'left',minHeight:24}}>
              
            </Col>
          </Row>}
          </Row>



        </Col>
        <Col xs="6" lg="6" style={{paddingRight:0, paddingLeft:18,}}>
          <Row>
            <Col xs="12" lg="12" style={{paddingRight:0,border:'2px solid black'}}>
              Ship To
            </Col>
          </Row>
          <Row style={{border:'2px solid black',borderWidth:'0px 2px 2px 2px'}}>
          <Row>
            <Col xs="12" lg="12" style={{paddingRight:0,textAlign:'left',minHeight:24}}>
              {obj['toShip'][page1-1]!=undefined&&obj['toShip'][page1-1]['Contact Person']}
            </Col>
          </Row>
          <Row>
            <Col xs="12" lg="12" style={{paddingRight:0,textAlign:'left',minHeight:24}}>
              {obj['toShip'][page1-1]!=undefined&&obj['toShip'][page1-1]['Address']}
            </Col>
          </Row>
          <Row>
            <Col xs="12" lg="12" style={{paddingRight:0,textAlign:'left'}}>
              {obj['toShip'][page1-1]!=undefined&&obj['toShip'][page1-1]['Address 2']}
            </Col>
          </Row>
          <Row>
            <Col xs="12" lg="12" style={{paddingRight:0,textAlign:'left',minHeight:24}}>
              {obj['toShip'][page1-1]!=undefined&&obj['toShip'][page1-1]['City']!=''?obj['toShip'][page1-1]['City']+', ' +obj['toShip'][page1-1]['State']+' '+obj['toShip'][page1-1]['Zip']:''}
            </Col>
          </Row>
          {obj['toShip'][page1-1]!=undefined&&obj['toShip'][page1-1]['Address 2']==''&&
            <Row>
            <Col xs="12" lg="12" style={{paddingRight:0,textAlign:'left',minHeight:24}}>
              
            </Col>
          </Row>}
          </Row>
        </Col>
        
      </Row>

      <Row style={{paddingRight:0,paddingTop:20}}>
        <Col xs="2" lg="2" style={{paddingRight:0, paddingLeft:0, border:'2px solid black',borderWidth:'2px 0px 2px 2px'}}>
          P.O. No.
        </Col>
        <Col xs="2" lg="2" style={{paddingRight:0, paddingLeft:0, border:'2px solid black',borderWidth:'2px 0px 2px 2px'}}>
          Terms
        </Col>
        <Col xs="2" lg="2" style={{paddingRight:0, paddingLeft:0, border:'2px solid black',borderWidth:'2px 0px 2px 2px'}}>
          Rep
        </Col>
        <Col xs="2" lg="2" style={{paddingRight:0, paddingLeft:0, border:'2px solid black',borderWidth:'2px 0px 2px 2px'}}>
          Ship Date
        </Col>
        <Col xs="2" lg="2" style={{paddingRight:0, paddingLeft:0, border:'2px solid black',borderWidth:'2px 0px 2px 2px'}}>
          Ship Via
        </Col>
        <Col xs="1" lg="1" style={{paddingRight:0, paddingLeft:0, border:'2px solid black',borderWidth:'2px 0px 2px 2px'}}>
          FOB
        </Col>
        <Col xs="1" lg="1" style={{paddingRight:0, paddingLeft:0, border:'2px solid black',borderWidth:'2px 2px 2px 2px'}}>
          Project
        </Col>
      </Row>
      <Row style={{paddingRight:0}}>
        <Col xs="2" lg="2" style={{paddingRight:0, paddingLeft:0, border:'2px solid black',borderWidth:'0px 0px 2px 2px'}}>
          {obj['Customer PO Id']}
        </Col>
        <Col xs="2" lg="2" style={{paddingRight:0, paddingLeft:0, border:'2px solid black',borderWidth:'0px 0px 2px 2px'}}>
          {
            <input 
                style={{borderWidth:'0px',width:'100%',textAlign:'center'}}
                value={
                  (obj['Terms']!=undefined)?
                  obj['Terms']:
                  '30 Days'
                }
                type="text" 
                onChange={(e) =>{
                  setObj((prevState)=>{
                    const clone = JSON.parse(JSON.stringify(prevState));
                    clone['Terms']=e.target.value
                    return clone
                  })
                  }
                } 
              />
          }
        </Col>
        <Col xs="2" lg="2" style={{paddingRight:0, paddingLeft:0, border:'2px solid black',borderWidth:'0px 0px 2px 2px'}}>
          {obj['Sales Person']!=undefined&&obj['Sales Person']!=''?obj['Sales Person']:'HA'}
        </Col>
        <Col xs="2" lg="2" style={{paddingRight:0, paddingLeft:0, border:'2px solid black',borderWidth:'0px 0px 2px 2px'}}>
          {obj['toShip'][page1-1]['Ship By Date']}
        </Col>
        <Col xs="2" lg="2" style={{paddingRight:0, paddingLeft:0, border:'2px solid black',borderWidth:'0px 0px 2px 2px'}}>
          {obj['toShip'][page1-1]['Carrier']}
        </Col>
        <Col xs="1" lg="1" style={{paddingRight:0, paddingLeft:0, border:'2px solid black',borderWidth:'0px 0px 2px 2px'}}>
          {
            <input 
                style={{borderWidth:'0px',width:'100%',textAlign:'center'}}
                value={
                  (obj['toShip'][page1-1]!=undefined&&obj['toShip'][page1-1]['FOB']!=undefined)?
                  obj['toShip'][page1-1]['FOB']:
                  ''
                }
                type="text" 
                onChange={(e) =>{
                  setObj((prevState)=>{
                    const clone = JSON.parse(JSON.stringify(prevState));
                    clone['toShip'][page1-1]['FOB']=e.target.value
                    return clone
                  })
                  }
                } 
              />
          }
        </Col>
        <Col xs="1" lg="1" style={{paddingRight:0, paddingLeft:0, border:'2px solid black',borderWidth:'0px 2px 2px 2px'}}>
          {
            <input 
                style={{borderWidth:'0px',width:'100%',textAlign:'center'}}
                value={
                  (obj['toShip'][page1-1]!=undefined&&obj['toShip'][page1-1]['Project']!=undefined)?
                  obj['toShip'][page1-1]['Project']:
                  ''
                }
                type="text" 
                onChange={(e) =>{
                  setObj((prevState)=>{
                    const clone = JSON.parse(JSON.stringify(prevState));
                    clone['toShip'][page1-1]['Project']=e.target.value
                    return clone
                  })
                  }
                } 
              />
          }
        </Col>
      </Row>
      <Row style={{paddingRight:0,paddingTop:20}}>
        <Col xs="2" lg="2" style={{paddingRight:0, paddingLeft:0, border:'2px solid black',borderWidth:'2px 0px 2px 2px'}}>
          Cust Part #
        </Col> 
        <Col xs="2" lg="2" style={{paddingRight:0, paddingLeft:0, border:'2px solid black',borderWidth:'2px 0px 2px 2px'}}>
          FBIns Part #
        </Col>
        <Col xs="5" lg="5" style={{paddingRight:0, paddingLeft:0, border:'2px solid black',borderWidth:'2px 0px 2px 2px'}}>
          Description
        </Col>
        <Col xs="1" lg="1" style={{paddingRight:0, paddingLeft:0, border:'2px solid black',borderWidth:'2px 0px 2px 2px'}}>
          Qty
        </Col>
        <Col xs="1" lg="1" style={{paddingRight:0, paddingLeft:0, border:'2px solid black',borderWidth:'2px 0px 2px 2px'}}>
          Rate
        </Col>
        <Col xs="1" lg="1" style={{paddingRight:0, paddingLeft:0, border:'2px solid black',borderWidth:'2px 2px 2px 2px'}}>
          Amount
        </Col>
      </Row>
      {
        obj['toShip'].length>1&&partsObj1[page1-1]!=undefined?
        Object.keys(partsObj1[page1-1]).map((item,index)=>{
          if(partsObj1[page1-1][item]['Part Id']==''){
            return
          }
          const verb = obj['Customer'] == 'Normandy Machine Company, Inc.' ? 'Entry #': 'Line Item #'

          console.log('qtyLeft : ',partsObj1[page1-1][item]['QtyLeft'])
          return(
            <Row style={{paddingRight:0}}>
              <Col xs="2" lg="2" style={{paddingRight:0, paddingLeft:0, border:'2px solid black',borderWidth:'0px 0px 2px 2px'}}>
                {partsObj1[page1-1][item]['Customer Part Id']!=''?partsObj1[page1-1][item]['Customer Part Id']:partsObj1[page1-1][item]['Part Id'] }
              </Col>
              <Col xs="2" lg="2" style={{paddingRight:0, paddingLeft:0, border:'2px solid black',borderWidth:'0px 0px 2px 2px'}}>
                {partsObj1[page1-1][item]['Part Id']}
              </Col>

              <Col xs="5" lg="5" style={{paddingRight:0, paddingLeft:0, border:'2px solid black',borderWidth:'0px 0px 2px 2px'}}>
                <div style={{width:'100%'}}>{partsObj1[page1-1][item]['Part Id']!=''? verb+' '+(partsObj1[page1-1][item]['LineDouble']?partsObj1[page1-1][item]['Line Item1'].join(' , '): partsObj1[page1-1][item]['Line Item']+1)+" - "+ partsObj1[page1-1][item]['Description']:partsObj1[page1-1][item]['Description']}</div>
                {
                  partsObj1[page1-1][item]['QtyLeft']!=undefined&&partsObj1[page1-1][item]['QtyLeft']!=0&&
                  <div style={{width:'100%'}}>{'B/O : '+ partsObj1[page1-1][item]['QtyLeft']+' pc' }</div>
                }
              </Col>
              <Col xs="1" lg="1" style={{paddingRight:0, paddingLeft:0, border:'2px solid black',borderWidth:'0px 0px 2px 2px'}}>
                {partsObj1[page1-1][item]['Quantity']}
              </Col>
              <Col xs="1" lg="1" style={{paddingRight:0, paddingLeft:0, border:'2px solid black',borderWidth:'0px 0px 2px 2px'}}>
                {partsObj1[page1-1][item]['Rate']!=undefined?'$'+Number(partsObj1[page1-1][item]['Rate']).toFixed(2):'$'+(Number(partsObj1[page1-1][item]['Price'])/Number(partsObj1[page1-1][item]['Quantity'])).toFixed(2)}
              </Col>
              <Col xs="1" lg="1" style={{paddingRight:0, paddingLeft:0, border:'2px solid black',borderWidth:'0px 2px 2px 2px'}}>
                {'$'+Number(partsObj1[page1-1][item]['Price']).toFixed(2)}
              </Col>
            </Row>
            )

        })
        :
        Object.keys(partsObj).map((item,index)=>{
          if(partsObj[item]['Part Id']==''){
            return
          }
          const verb = obj['Customer'] == 'Normandy Machine Company, Inc.' ? 'Entry #': 'Line Item #'

          return(
            <Row style={{paddingRight:0}}>
              <Col xs="2" lg="2" style={{paddingRight:0, paddingLeft:0, border:'2px solid black',borderWidth:'0px 0px 2px 2px'}}>
                {partsObj[item]['Customer Part Id']!=''?partsObj[item]['Customer Part Id']:partsObj[item]['Part Id']}
              </Col>
              <Col xs="2" lg="2" style={{paddingRight:0, paddingLeft:0, border:'2px solid black',borderWidth:'0px 0px 2px 2px'}}>
                {partsObj[item]['Part Id']}
              </Col>
              <Col xs="5" lg="5" style={{paddingRight:0, paddingLeft:0, border:'2px solid black',borderWidth:'0px 0px 2px 2px'}}>
                <div style={{width:'100%'}}>{verb+' '+partsObj[item]['Line Item1'].join(' , ') +" - "+ partsObj[item]['Description']}</div>
              </Col>
              <Col xs="1" lg="1" style={{paddingRight:0, paddingLeft:0, border:'2px solid black',borderWidth:'0px 0px 2px 2px'}}>
                {partsObj[item]['Quantity']}
              </Col>
              <Col xs="1" lg="1" style={{paddingRight:0, paddingLeft:0, border:'2px solid black',borderWidth:'0px 0px 2px 2px'}}>
                {partsObj[item]['Rate']!=undefined?'$'+Number(partsObj[item]['Rate']).toFixed(2):'$'+(Number(partsObj[item]['Price'])/Number(partsObj[item]['Quantity'])).toFixed(2)}
              </Col>
              <Col xs="1" lg="1" style={{paddingRight:0, paddingLeft:0, border:'2px solid black',borderWidth:'0px 2px 2px 2px'}}>
                {'$'+Number(partsObj[item]['Price']).toFixed(2)}
              </Col>
            </Row>
            )

        })
      }
      <Row style={{paddingRight:0,paddingTop:20}}>
        <Col xs="2" lg="2" style={{paddingRight:0, paddingLeft:0, border:'2px solid black',borderWidth:'2px 0px 2px 2px'}}>
          Shipping
        </Col>
        <Col xs="2" lg="2" style={{paddingRight:0, paddingLeft:0, border:'2px solid black',borderWidth:'2px 0px 2px 2px'}}>
          Carrier
        </Col>
        <Col xs="2" lg="2" style={{paddingRight:0, paddingLeft:0, border:'2px solid black',borderWidth:'2px 0px 2px 2px'}}>
          Tracking
        </Col>
        <Col xs="1" lg="1" style={{paddingRight:0, paddingLeft:0, border:'2px solid black',borderWidth:'2px 0px 2px 2px'}}>
          # Units
        </Col>
        <Col xs="1" lg="1" style={{paddingRight:0, paddingLeft:0, border:'2px solid black',borderWidth:'2px 0px 2px 2px'}}>
          Weight
        </Col>
        <Col xs="2" lg="2" style={{paddingRight:0, paddingLeft:0, border:'2px solid black',borderWidth:'2px 0px 2px 2px'}}>
          Bol
        </Col>
        <Col xs="2" lg="2" style={{paddingRight:0, paddingLeft:0, border:'2px solid black',borderWidth:'2px 2px 2px 2px'}}>
          P/L 
        </Col>
      </Row>
      <Row style={{paddingRight:0}}>
        <Col xs="2" lg="2" style={{paddingRight:0, paddingLeft:0, border:'2px solid black',borderWidth:'0px 0px 2px 2px'}}>
          {obj['toShip'][page1-1]['Freight']!=undefined?obj['toShip'][page1-1]['Freight']:'Frieght Collect'}
        </Col>
        <Col onClick={()=>{setShowInput(true)}} xs="2" lg="2" style={{paddingRight:0, paddingLeft:0, border:'2px solid black',borderWidth:'0px 0px 2px 2px'}}>
          {
            showInput?
          <input onBlur={()=>{setShowInput(false)}} style={{width:'75%',border:"none", outline:'none', background:"rgba(0,0,0,0)"}} 
          value={obj['toShip'][page1-1]['Carrier']} 
          onChange={(e)=>{
            setObj((prevState)=>{
              const clone = JSON.parse(JSON.stringify(prevState));
              clone['toShip'][page1-1]['Carrier'] = e.target.value

              return clone
            })
          }}
          ></input> 
          : <span>{obj['toShip'][page1-1]['Carrier']}</span>
          }
        </Col>
        <Col xs="2" lg="2" style={{paddingRight:0, paddingLeft:0, border:'2px solid black',borderWidth:'0px 0px 2px 2px'}}>
          {obj['toShip'][page1-1]['Tracking']}
        </Col>
        <Col xs="1" lg="1" style={{paddingRight:0, paddingLeft:0, border:'2px solid black',borderWidth:'0px 0px 2px 2px'}}>
          {obj['toShip'][page1-1]['# Units']}
        </Col>
        <Col xs="1" lg="1" style={{paddingRight:0, paddingLeft:0, border:'2px solid black',borderWidth:'0px 0px 2px 2px'}}>
          {obj['toShip'][page1-1]['Weight']}
        </Col>
        <Col onClick={()=>{setShowInput(true)}} xs="2" lg="2" style={{paddingRight:0, paddingLeft:0, border:'2px solid black',borderWidth:'0px 0px 2px 2px'}}>
          {
            showInput?
          <input onBlur={()=>{setShowInput(false)}} style={{width:'75%',border:"none", outline:'none', background:"rgba(0,0,0,0)"}} 
          value={obj['toShip'][page1-1]['Shipper No.']!=undefined?obj['toShip'][page1-1]['Shipper No.']:obj['toShip'].length>1?obj['Id']+'-'+page1:obj['Id']} 
          onChange={(e)=>{
            setObj((prevState)=>{
              const clone = JSON.parse(JSON.stringify(prevState));
              clone['toShip'][page1-1]['Shipper No.'] = e.target.value

              return clone
            })
          }}
          ></input> 
          : <span>{obj['toShip'][page1-1]['Shipper No.']!=undefined?obj['toShip'][page1-1]['Shipper No.']:obj['toShip'].length>1?obj['Id']+'-'+page1:obj['Id']}</span>
          }
        </Col>
        <Col onClick={()=>{setShowInput(true)}} xs="2" lg="2" style={{paddingRight:0, paddingLeft:0, border:'2px solid black',borderWidth:'0px 2px 2px 2px'}}>
          {
            showInput?
          <input onBlur={()=>{setShowInput(false)}} style={{width:'75%',border:"none", outline:'none', background:"rgba(0,0,0,0)"}} 
          value={obj['toShip'][page1-1]['P/L']!=undefined?obj['toShip'][page1-1]['P/L']:''} 
          onChange={(e)=>{
            // console.log(e.target.value)
            setObj((prevState)=>{
              const clone = JSON.parse(JSON.stringify(prevState));
            // console.log(clone['toShip'][page1-1]['P/L'])
              clone['toShip'][page1-1]['P/L'] = e.target.value
            // console.log(clone['toShip'][page1-1]['P/L'])

              return clone
            })
          }}
          ></input> 
          : <span>{obj['toShip'][page1-1]['P/L']}</span>
          }
        </Col>
      </Row>

      <Row style={{paddingRight:0,paddingTop:20}}>
        <Col xs="6" lg="6" style={{paddingRight:0, paddingLeft:0, border:'2px solid black',borderWidth:'2px 0px 2px 2px'}}>
          Your Partnership is Invaluable.
        </Col>
        <Col xs="6" lg="6" style={{paddingRight:0, paddingLeft:10, border:'2px solid black',borderWidth:'2px 2px 2px 2px'}}>
          <Row style={{paddingRight:10}}>
            <Col xs="6" lg="6" style={{paddingRight:0, paddingLeft:0, border:'2px solid black',borderWidth:'0px 2px 2px 0px'}}>
              Total
            </Col>
            <Col xs="6" lg="6" style={{paddingRight:0, paddingLeft:0,border:'2px solid black',borderWidth:'0px 0px 2px 0px'}}>
              {obj['toShip'].length>1?
                obj['toShip'][page1-1]['Total']
                :
                obj['Total']

              }
            </Col>
            
          </Row>
          <Row style={{paddingRight:10}}>
            <Col xs="6" lg="6" style={{paddingRight:0, paddingLeft:0, border:'2px solid black',borderWidth:'0px 2px 2px 0px'}}>
              Payments / Credits
            </Col>
            <Col xs="6" lg="6" style={{paddingRight:0, paddingLeft:0,border:'2px solid black',borderWidth:'0px 0px 2px 0px'}}>
              
              {
                <input 
                    style={{borderColor:'black',borderWidth:'0px 2px 0px 0px',width:'100%',textAlign:'center'}}
                    value={
                      obj['toShip'].length>1?
                      obj['toShip'][page1-1]['Payments / Credits']!=undefined?obj['toShip'][page1-1]['Payments / Credits']:0
                      :
                      obj['Payments / Credits']!=undefined?obj['Payments / Credits']:0
                    }
                    type="text" 
                    onChange={(e) =>{
                      setObj((prevState)=>{
                        const clone = JSON.parse(JSON.stringify(prevState));
                        if(clone['toShip'].length>1){
                          clone['toShip'][page1-1]['Payments / Credits']=e.target.value
                          clone['toShip'][page1-1]['Balance Due']=(Number(clone['toShip'][page1-1]['Total'])-Number(clone['toShip'][page1-1]['Payments / Credits'])).toFixed(2)

                        }else{
                          clone['Payments / Credits'] = e.target.value
                          clone['Balance Due']=(Number(clone['Total'])-Number(clone['Payments / Credits'])).toFixed(2)
                        }
                        return clone
                      })
                      }
                    } 

                  />
                
              }

            </Col>
            
          </Row>
          <Row style={{paddingRight:10}}>
            <Col xs="6" lg="6" style={{paddingRight:0, paddingLeft:0, border:'2px solid black',borderWidth:'0px 2px 0px 0px'}}>
              Balance Due
            </Col>
            <Col xs="6" lg="6" style={{paddingRight:0, paddingLeft:0}}>
              {obj['toShip'].length>1?
                obj['toShip'][page1-1]['Balance Due']!=undefined?obj['toShip'][page1-1]['Balance Due']:Number(obj['toShip'][page1-1]['Total']).toFixed(2)
                :
                obj['Balance Due']!=undefined?obj['Balance Due']:Number(obj['Total']).toFixed(2)

              }
            </Col>
            
          </Row>
          
        </Col>
        
      </Row>


      
    </Row>
    <Row style={{paddingTop:20}}>
      <Col xs="6" lg="6" style={{paddingRight:0, paddingLeft:0}}>
        <Button onClick={save}>Save Invoice</Button>
      </Col>
      <Col xs="6" lg="6" style={{paddingRight:0, paddingLeft:0}}>
        <Button onClick={()=>{print(obj['toShip'].length>1?obj['Id']+'-'+page1 :obj['Id'])}}>Print Invoice</Button>
      </Col>
      
    </Row>
    </Row>
    
  </Container>
  );
}

export default Invoice;
