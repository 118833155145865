import { useState, useEffect,useRef } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import useLongPress from './useLongPress.js';
import { collection, query, where, getDocs, orderBy, onSnapshot } from "firebase/firestore";
import SearchItem from './SearchItem.js';



const Form4 = ({name, setInput, input, index, userData, db})=> {
  // useEffect(()=>{
  //   console.log(name)
  //   console.log(input)
  //   console.log(index)  
  // },[])
  const [focused, setFocused] = useState(false)
  const onFocus = () => setFocused(true)
  const onBlur = () => startTimer()
  const [docData,setDocData] = useState({})
  const queryRef = useRef();

  const [counter, setCounter] = useState(0);
  const timer = useRef(null); // we can save timer in useRef and pass it to child

  const startTimer = ()=>{
     timer.current = setTimeout(() => {setFocused(false);}, 250);
  }
    useEffect(
    () => {

      // this will clear Timeout
      // when component unmount like in willComponentUnmount
      // and show will not change to true
      return () => {
        if(timer.current!=null){
          clearTimeout(timer.current);
        }
      };
    },
    // useEffect will run only one time with empty []
    // if you pass a value to array,
    // like this - [data]
    // than clearTimeout will run every time
    // this value changes (useEffect re-run)
    []
  );


  // useEffect(()=>{
  //   console.log(focused)
  // },[focused])
  
  useEffect(()=>{
    return ()=>{
      if(queryRef.current!=null){
        queryRef.current()
        queryRef.current=null
      }

    }
  },[])


  const searchComponents = async (e)=>{

    // if(e.target.value.length>2){
      setDocData([])
      if(userData['currCompany']!=undefined&&userData['currCompany']!=''){
        const q = query(collection(db,'companies', userData['currCompany'], "components"), where('Id', ">=", e.target.value), where('Id', "<=", e.target.value+'\uf8ff'));
      // console.log(q)
        if(queryRef.current!=null){
          queryRef.current()
        }
        
        queryRef.current = onSnapshot(q, (snapshot) => {
          snapshot.docChanges().forEach((change) => {
            // console.log(change)
            if (change.type === "added") {
                // console.log("New : ", change.doc.data());
                // setDocData(docData =>[...docData, change.doc.data()])
                setDocData((prevState) => ({
                ...prevState,
                  [change.doc.id]: change.doc.data()
                }
              ))
            }
            if (change.type === "modified") {
                // console.log("Modified : ", change.doc.data());
                const obj = change.doc.data();
                setInput((prevState) => {
                  const clone = JSON.parse(JSON.stringify(prevState));
                  clone['components'].forEach((objj,int)=>{

                    if(clone['components'][int]['Id']==obj['Id']){
                      // setInput(change.doc.data())

                        const previousArr = clone['components']; 
                        const previousQty = previousArr[index]['Qty.']!=undefined?previousArr[index]['Qty.']:'1'
                        previousArr[index] = obj
                        console.log(Number(obj['Total']),obj['Total'])
                        console.log(Number(previousArr[index]['Qty.']),previousArr[index]['Qty.'])
                        console.log(String(Number(obj['Total'])*Number(previousArr[index]['Qty.'])),Number(obj['Total'])*Number(previousArr[index]['Qty.']))
                        previousArr[index]['Qty.'] = previousQty!=""?previousQty:"1";

                        const materialOverhead = obj['MaterialOverhead']!=undefined&&obj['MaterialOverhead']!=''?Number(obj['MaterialOverhead']):0 
                        const laborOverhead = obj['Overhead']!=undefined&&obj['Overhead']!=''? Number(obj['Overhead']):0
                        const laborCost = obj['Labor']!=undefined&&obj['Labor']!=''? Number(obj['Labor']):0
                        console.log(laborCost,materialOverhead)
                        
                        previousArr[index]['Overhead1'] =((materialOverhead+laborOverhead)*Number(previousArr[index]['Qty.'])).toFixed(2)
                        previousArr[index]['Labor1'] =((laborCost)*Number(previousArr[index]['Qty.'])).toFixed(2)
                        previousArr[index]['Conversion1'] = ((materialOverhead+laborOverhead+laborCost)*Number(previousArr[index]['Qty.'])).toFixed(2)
                        
                        previousArr[index]['Material1'] =obj['Material']!=undefined&&obj['Material']!=''? (Number(obj['Material'])*Number(previousArr[index]['Qty.'])).toFixed(2):0
                        previousArr[index]['Total1'] =obj['Total']!=undefined&&obj['Total']!=''? (Number(obj['Total'])*Number(previousArr[index]['Qty.'])).toFixed(2):0
                        clone['components']=previousArr
                        
                    }
                  })
                  return clone;
                })
                setDocData((prevState) => ({
                ...prevState,
                  [change.doc.id]: change.doc.data()
                }
              ))
            }
            if (change.type === "removed") {
                setDocData((prevData) => {
                  const newData = {...prevData}
                  delete newData[change.doc.id]
                  return newData;
                })
            }
          });
        });
      }
    // }
  }

  const fillInForm = (obj)=>{
    console.log(obj)
    // setInput((prevState) => {
    //         const previousArr = [...prevState['components']];
    //         previousArr[index][name]=e.target.value;
    //         prevState['components']=previousArr
    //         return prevState;
    //       })
    setInput((prevState) => {

      const clone = JSON.parse(JSON.stringify(prevState));
      const previousArr = clone['components']; 
      const previousQty = previousArr[index]['Qty.']!=undefined?previousArr[index]['Qty.']:'1'
      previousArr[index] = obj
      console.log(Number(obj['Total']),obj['Total'])
      console.log(Number(previousArr[index]['Qty.']),previousArr[index]['Qty.'])
      console.log(String(Number(obj['Total'])*Number(previousArr[index]['Qty.'])),Number(obj['Total'])*Number(previousArr[index]['Qty.']))
      previousArr[index]['Qty.'] = previousQty!=""?previousQty:"1";
      
      const materialOverhead = obj['MaterialOverhead']!=undefined&&obj['MaterialOverhead']!=''?Number(obj['MaterialOverhead']):0 
      const laborOverhead = obj['LaborOverhead']!=undefined&&obj['LaborOverhead']!=''? Number(obj['LaborOverhead']):0
      const laborCost = obj['Labor']!=undefined&&obj['Labor']!=''? Number(obj['Labor']):0
      console.log(clone['MaterialOverhead'],materialOverhead)
      
      previousArr[index]['Overhead1'] =((materialOverhead+laborOverhead)*Number(previousArr[index]['Qty.'])).toFixed(2)
      previousArr[index]['Labor1'] =((laborCost)*Number(previousArr[index]['Qty.'])).toFixed(2)
      previousArr[index]['Conversion1'] = ((materialOverhead+laborOverhead+laborCost)*Number(previousArr[index]['Qty.'])).toFixed(2)
                        
      previousArr[index]['Material1'] =obj['Material']!=undefined&&obj['Material']!=''? (Number(obj['Material'])*Number(previousArr[index]['Qty.'])).toFixed(2):0
      previousArr[index]['Total1'] =obj['Total']!=undefined&&obj['Total']!=''? (Number(obj['Total'])*Number(previousArr[index]['Qty.'])).toFixed(2):0
      clone['components']=previousArr
      return clone;
      
    })
  }

  const onLongPress = () => {
      // console.log('longpress is triggered');
      if(name=="Id"){

        const paartNum = input['components'][index][name].replace('/',' , ')
        window.history.pushState({}, document.title, window.location.pathname);
        let loc = String(window.location);
        let locSplit = loc.split('/')
        window.open(String(locSplit[0]+'/Components'+"?number="+paartNum),'_blank');
      }
  };

  const onClick = () => {
      // console.log('click is triggered')
  }

  const defaultOptions = {
      shouldPreventDefault: true,
      delay: 500,
  };
  const useLongPressEvent = useLongPress(onLongPress,onClick,defaultOptions)

  return (
    <div>
      <input
        {...useLongPressEvent}
        style={{float:"left",width:'100%'}}
        type="text" 
        value={input['components'][index]!=undefined?input['components'][index][name]:''}
        onFocus={onFocus} onBlur={onBlur}
        onChange={(e) =>{
          console.log(input)
          // const clone = [...input];
          setInput((prevState) => {
            const clone = JSON.parse(JSON.stringify(prevState));
            const previousArr = clone['components'];
            if(previousArr[index]==undefined){
              previousArr[index]=[]
            }
            previousArr[index][name]=e.target.value;
            clone['components']=previousArr
            return clone;   
          })
          // clone[index][name] = e.target.value;
          // setInput(clone)
          searchComponents(e)
          }
        } 
      />
      {focused && Object.keys(docData).length >= 1 &&
        Object.keys(docData).map((objj, index) => {
          {/*console.log(objj)*/}
         return (

            <SearchItem key={index+"floof"} obj={docData[objj]} setFocused={setFocused} onClick={fillInForm} searchLabel={'Id'} /> 
          );

        })
      }
    </div>
  );
}

export default Form4