// Menu -> index.js
// export { default } from './Menu';

// Menu.styled.js
import styled from 'styled-components';

  // display: ${({ open }) => open ? 'flex' : 'none'};
  // visibility: ${({ open }) => open ? 'show' : 'hidden'};
export const StyledMenu = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-color: #ebe9e6;
  color: #020303;
  height: 100vh;
  max-height:100vh;
  overflow: hidden;
  overflow-y: scroll;
  // overflow-x: scroll;
  text-align: left;
  padding: 2rem;
  position: absolute;
  top: 0;
  right: ${({ left }) => left ? null : 0};
  left: ${({ left }) => left ? 0 : null};
  transition: transform 0.3s ease-in-out;
  transform: ${({ open, left }) => left? open ? 'translateX(0)' : 'translateX(-100%)': open ? 'translateX(0)' : 'translateX(100%)'};
  z-index:99;
  @media (max-width: 500) {
    width: '90%';
  }

  .searchItems {
    padding: 2px 5px;
    transition: color 0.3s linear;
    
    &:hover {
      background-color: #efefef;
      border-radius: 25px;
    }

    @media (max-width: 500) {
      
    }

  }
`;