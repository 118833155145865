import { useState, useEffect } from 'react'
import useLongPress from './useLongPress.js';


const Form1a2 = ({name,setInput,input,index,key1,key2,indy })=> {
  useEffect(()=>{
    // console.log(input['components'][index])
    
  })
const onLongPress = () => {
      // console.log('longpress is triggered');
      if(name=="Part Id"){

        window.history.pushState({}, document.title, window.location.pathname);
        let loc = String(window.location);
        let locSplit = loc.split('/')
        window.open(String(locSplit[0]+'/Parts'+"?number="+input[key2][indy][key1][index]['Part Id']),'_blank');
      
      }
  };

  const onClick = () => {
      // console.log('click is triggered')
  }

  const defaultOptions = {
      shouldPreventDefault: true,
      delay: 500,
  };
  const useLongPressEvent = useLongPress(onLongPress,onClick,defaultOptions)

  return (
    <span>
      <input
        {...useLongPressEvent}
      style={{float:"left",width:'100%',height:'100%'}}
      type="text" 
      value={input[key2]!=undefined&&input[key2][indy]!=undefined?input[key2][indy][name]:''}
      onChange={(e) =>{

        // console.log(index)
        // console.log(key1)
        // console.log(input[key1])
        // console.log(input[key1][index])
        setInput((prevState) => {
            const clone = JSON.parse(JSON.stringify(prevState));
            const previousArr = clone[key2];
            console.log(previousArr)
            if(previousArr[indy]==undefined){
              previousArr[indy]=[]
            }

            previousArr[indy][name]=e.target.value;
            let Price=0;
            let PerPrice=0;
            let Shipping=0
            let PerShipping=0
            console.log(name)

            const qty = previousArr[indy]['Qty']!=''?Number(previousArr[indy]['Qty']):1;
            if(Number(previousArr[indy]['Per Price'])!='NaN'&&Number(previousArr[indy]['Per Price'])>0&&name!='Price'){
              console.log('1',qty)
              Price=qty!='NaN'?(qty*Number(previousArr[indy]['Per Price'])).toFixed(2):Number(previousArr[indy]['Per Price']).toFixed(2)
              PerPrice = Number(previousArr[indy]['Per Price'])!='NaN'?previousArr[indy]['Per Price']:0
              console.log(PerPrice)
            }else{
            console.log('2')
              if(name=='Per Price'&&previousArr[indy]['Per Price']==''){
                PerPrice = 0
                Price = 0
              }else{
                Price = Number(previousArr[indy]['Price'])!='NaN'?previousArr[indy]['Price']:0
                PerPrice=qty!='NaN'?(Number(previousArr[indy]['Price'])/qty).toFixed(2):Number(previousArr[indy]['Price']).toFixed(2)
              }
            }
            if(Number(previousArr[indy]['Per Shipping'])!='NaN'&&Number(previousArr[indy]['Per Shipping'])>0&&name!='Shipping'){
            console.log('3')
              Shipping=qty!='NaN'?(qty*Number(previousArr[indy]['Per Shipping'])).toFixed(2):Number(previousArr[indy]['Per Shipping']).toFixed(2)
              PerShipping = Number(previousArr[indy]['Per Shipping'])!='NaN'?previousArr[indy]['Per Shipping']:0
            }else{
            console.log('4')
              if(name=='Per Shipping'&&previousArr[indy]['Per Shipping']==''){
                PerShipping = 0
                Shipping = 0
              }else{
                PerShipping=qty!='NaN'?(Number(previousArr[indy]['Shipping'])/qty).toFixed(2):Number(previousArr[indy]['Shipping']).toFixed(2)
                Shipping = Number(previousArr[indy]['Shipping'])!='NaN'?previousArr[indy]['Shipping']:0
              }
            }

            console.log(Price,Shipping)
            console.log(Number(PerPrice),Number(PerShipping),(Number(PerPrice)+Number(PerShipping)))

            if(Price!=''||Shipping!=''){

            if(name!='Per Price'){
               previousArr[indy]['Per Price'] = PerPrice;
            }
            if(name!='Per Shipping'){
              previousArr[indy]['Per Shipping'] = PerShipping;
            }

            // console.log(Price)


              previousArr[indy]['Price'] = Price;
              previousArr[indy]['Shipping'] = Shipping;
              previousArr[indy]['Total'] = Math.round( (Number(Price)+Number(Shipping)) * 100 + Number.EPSILON ) / 100 ;
              previousArr[indy]['Per Total'] = Math.round( (Number(PerPrice)+Number(PerShipping)) * 100 + Number.EPSILON ) / 100 ;
            }else{
               previousArr[indy]['Per Price'] = '';
              previousArr[indy]['Per Shipping'] = '';
               previousArr[indy]['Price'] = ''
              previousArr[indy]['Shipping'] = ''
              previousArr[indy]['Total'] = ''
              previousArr[indy]['Per Total'] = ''
           
            }

            let lastPrice=0;
            let lastShipping=0;
            let lastTotal=0;
            for(let x=previousArr.length-1;x>=0;x--){
              console.log(Number(previousArr[x]['Per Total']))
              if(Number(previousArr[x]['Per Total'])>0&&previousArr[x]['Date1']!=''){
              console.log(Number(previousArr[x]['Per Total']))
              lastPrice = Number(previousArr[x]['Per Price'])
              lastShipping = Number(previousArr[x]['Per Shipping'])!=='NaN'?Number(previousArr[x]['Per Shipping']):0;
              lastTotal = Number(previousArr[x]['Per Total'])!=='NaN'?Number(previousArr[x]['Per Total']):0;
              break;  
              }
            } 
            if(lastTotal<=0){
              for(let x=previousArr.length-1;x>=0;x--){
                console.log(Number(previousArr[x]['Per Total']))
                if(Number(previousArr[x]['Per Total'])>0){
                console.log(Number(previousArr[x]['Per Total']))
                lastPrice = Number(previousArr[x]['Per Price'])
                lastShipping = Number(previousArr[x]['Per Shipping'])!=='NaN'?Number(previousArr[x]['Per Shipping']):0;
                lastTotal = Number(previousArr[x]['Per Total'])!=='NaN'?Number(previousArr[x]['Per Total']):0;
                break;  
                }
              } 
            }  
            const lab = Number(clone['Labor'])!=='NaN'?Number(clone['Labor']):0
            const labO = Number(clone['LaborOverhead'])!=='NaN'?Number(clone['LaborOverhead']):0
            const labC = Number(clone['Conversion'])!=='NaN'?Number(clone['Conversion']):0
            
            clone['Material'] = lastPrice
            clone['MaterialOverhead'] = lastShipping
            clone['Total'] = labC+lastTotal




            // if(name==""){
            //   console.log('quant change')
            //   let indyPrice=0;
            //   console.log(clone['Parts'])
            //   console.log(previousArr)
            //   clone['Parts'].forEach((item,index)=>{
            //     previousArr.forEach((item1,index1)=>{

            //       if(previousArr[index1]['Part Id']==item['Part Id'] && item['Part Id']!=''){
            //         console.log(item['Price'],item['Quantity'])
            //         indyPrice=Number(item['Price'])/Number(item['Quantity'])
            //       }

            //     })
            //   })
            //   console.log(indyPrice)
            //   previousArr[index]['Price'] = ((Number(previousArr[index]['Quantity'])*indyPrice)).toFixed(2)
            //   let total=0
            //   previousArr.forEach((item,index)=>{
            //     total+=Number(item['Price'])
            //   })
            //   clone[key2][indy]['Total'] = Number(total).toFixed(2)

            // }
            // else if(name=="Price"){
            //   previousArr[index]['Mark Up'] = (((Number(previousArr[index]['Price'])-Number(previousArr[index]['Cost']))/Number(previousArr[index]['Cost']))*100).toFixed(2)
            // }
            
            clone[key2]=previousArr
            console.log(clone[key2][indy])
            return clone;   
          })
        // console.log('fuckkkk')
        // const clone = [...input];
        // clone[index][name] = e.target.value;
        // setInput(clone)
        }
      } 
    />
    </span>
  );
}

export default Form1a2