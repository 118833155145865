import { useState, useEffect } from 'react'
import {Modal, Button, Spinner} from 'react-bootstrap';
import { FaEyeSlash, FaEye } from 'react-icons/fa';



const LoginModal = ({user, showLogin, setShowLoginLogin, auth, createUserWithEmailAndPassword, signInWithEmailAndPassword, db, setDoc, doc, sendEmailVerification,sendPasswordResetEmail})=> {
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [secondPassword, setSecondPassword] = useState("")
  const [creating, setCreating] = useState(false)
  const [created, setCreated] = useState(false)
  const [hidden, setHidden] = useState(true)
  const [secondHidden, setSecondHidden] = useState(true)
  const [show, setShowLogin] = useState(false)
  const [reseting, setReseting] = useState(false)
  const [reset, setReset] = useState(false)
  const [resetError, setResetError] = useState(false)

  useEffect(()=>{
    let timeout;
    if(reset){
      timeout = setTimeout(()=>{setReset(false)},3000)
    }
    return timeout!=undefined?()=>{clearTimeout(timeout)}:()=>{}
  },[reset])

  useEffect(()=>{
    if(user!=null){
      setShowLogin(false)
    }else{
      setShowLogin(true)
    }
  },[user])
  const handleClose = () => setShowLogin(false);
  // const handleShow = () => setShowLogin(true);
  
  const handleLogin = () => {
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Signed in 
        setShowLogin(false)
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
      });
  };
  
  const handleCreate = () => {
    if(!created){

    setCreated(true)
    if(password.length <8){
      setCreated(false)
      alert("Password must be atleast 8 characters.")
      return
    }
    if(password==secondPassword){
      createUserWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
          // Signed in 
          const user = userCredential.user;
          console.log(user)
          console.log(auth.currentUser)

          // enter email into db
          setDoc(doc(db, 'users', user.uid, ), {
            email: email,
            token: "",
            time: new Date(),
            verified: false,
            companies: [],
            companiesMap:{},
            currCompany:"",
          } , { merge: true })
          
          sendEmailVerification(auth.currentUser).then(()=>{
            console.log("verifcation sent")
            setCreated(false)
            setShowLogin(false)
          }).catch((error)=>{
            console.log(error)
            setCreated(false)
            setShowLogin(false)
          })
          
        })
        .catch((error) => {
          setCreated(false)
          const errorCode = error.code;
          const errorMessage = error.message;
          alert("There was an error while creating your email with message:  "+errorMessage)
          console.log(errorCode,errorMessage)
          // ..
        });

    } else{
      setCreated(false)
      alert("Password's need to match");
    }

    }
  };
  
  const handleReset=async()=>{
    setReseting(true)
    setResetError(false)
    setReset(false)
    try{
      const send = await sendPasswordResetEmail(auth,email)
      setReset(true)
      setReseting(false)

    }
    catch(errr){
      setReseting(false)
      setResetError(true)
      console.log(errr)

    }
  }

  return (
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        // keyboard={false}
        style={{textAlign:'left'}}
      >
        <Modal.Header>
          <Modal.Title>Login</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{marginBottom:'4px'}}>
            <input  type="text"  placeholder="Enter email" onChange={(event)=>{setEmail(event.target.value)}}/>
          </div>
          <div style={{marginBottom:'4px'}}>
            <input  type={hidden ? "password":"text"}  placeholder="Enter password" onChange={(event)=>{setPassword(event.target.value)}}/>
          &nbsp;
            {hidden ? <FaEye onClick={()=>{setHidden(prev => !prev)}}/> : <FaEyeSlash onClick={()=>{setHidden(prev => !prev)}}/>}
          </div>
          {creating &&
          <div style={{marginBottom:'4px'}}>
            <input  type={secondHidden ? "password":"text"}  placeholder="Re-enter password" onChange={(event)=>{setSecondPassword(event.target.value)}}/>
            &nbsp;
            {secondHidden ? <FaEye onClick={()=>{setSecondHidden(prev => !prev)}}/> : <FaEyeSlash onClick={()=>{setSecondHidden(prev => !prev)}}/>}
          </div>
          }
          {created &&
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          }
        </Modal.Body>
        <Modal.Footer style={{justifyContent:'center'}}>
        {!creating&&<div>

          <Button variant="secondary" onClick={()=>{setCreating(true)}} >Create New Login</Button>
          &nbsp;
          <Button variant="primary" onClick={handleLogin} >Login</Button>
          &nbsp;
          <Button variant="primary" onClick={handleReset} >Reset Password</Button>
          {
            reseting&&
            <div>Sending reset email to {email}</div>
          }
          {
            reset&&
            <div>Reset email sent to {email}, check Spam folder.</div>
          }
          {
            resetError&&
            <div>Error sending reset email sent to {email}. Please enter correct email.</div>
          }
          
        </div>
        }
        {creating &&
          <Button variant="primary" onClick={handleCreate} >Create New Login</Button>
        }
        </Modal.Footer>
      </Modal>
  );
}

export default LoginModal